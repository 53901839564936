import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { LanguageContext } from "../containers/languageprovider";
import {
  PRIVACY_COOKIE_URL_EN,
  PRIVACY_COOKIE_URL_IT, TERMS_AND_CONDITIONS_URL_IT,
  TERMS_AND_CONDITIONS_URL_EN
} from "../config/config";
import globalStyles from "./globalStyles";
import { FacebookIcon, LinkedinIcon } from "./iconlist";
import LikeDialog from "./LikeDialog";
import SearchBuildings from "./SearchBuildings/SearchBuildings";
//import CookieConsent from "react-cookie-consent";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#141B4D",
    width: "100%",
    minHeight: "100px",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "1600",
  },
  logo: {
    paddingLeft: 0,
    height: 50,
  },
  FooterStyle: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "18px",
    color: "rgba(255, 255, 255, 0.4)",
  },
  DividerFooterStyle: {
    marginTop: "60px",
    marginBottom: "25px",
    border: "1px solid rgba(255, 255, 255, 0.4)",
    //background:  "rgba(255, 255, 255, 0.4)"
  },
  FooterMenu: {
    color: "rgba(255, 255, 255, 0.4)",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  LogoContainer: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  ButtonContainer: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },

  buttonLike: {
    /* CERCA IL TUO PROSSIMO UFFICIO */
    width: "240px",
    border: "1px solid #FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",

    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "13px",
    lineHeight: "16px",

    color: "#FFFFFF",

    textAlign: "center",
    letterSpacing: "0.03em",

    //border: "none",
    //width:"100%",
    height: "50px",
    //backgroundColor:"rgba(255, 255, 255, 0.4)",
    position: "relative",
    //borderRadius: theme.shape.borderRadius,

    "&:hover": {
      backgroundColor: "white",
      color: "#141B4D",
      border: "none",
    },
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  search: {
    /* CERCA IL TUO PROSSIMO UFFICIO */
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "13px",
    lineHeight: "16px",
    padding: "10px 20px",
    textAlign: "center",
    letterSpacing: "0.03em",

    color: "#141B4D",
    border: "none",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    position: "relative",
    borderRadius: theme.shape.borderRadius,

    "&:hover": {
      backgroundColor: "white",
      border: "none",
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    padding: "8px",
    width: "80%",
  },
  iconButton: {
    float: "right",
  },
  LastChild: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
    },
  },
}));

//#3f51b5

export default function Footer(props) {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const { dictionary, userLanguage } = React.useContext(LanguageContext);
  const [openSearchDialog, setopenSearchDialog] = React.useState(false);
  const [openLikeDialog, setopenLikeDialog] = React.useState(false);

  return (
    <div className={classes.footer}>
      <Container className={classes.container}>
        {!props.company && (
          <Grid container spacing={3}>
            <Grid item md={4} xs={12} className={classes.LogoContainer}>
              <Link
                rel="noopener noreferrer"
                className={classes.FooterMenu}
                component={RouterLink}
                to="/"
              >
                <img
                  className={classes.logo}
                  src="/images/logo-white.svg"
                  alt="logo"
                />
              </Link>
            </Grid>

            <Grid item container md={4} xs={12} spacing={1}>
              <Grid item xs={12}>
                <Typography className={classes.FooterMenu}>
                  Piazza Mirabello 2, 20121 Milano
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.FooterMenu}>
                  P.IVA e C.F: 11745710969
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.FooterMenu}>
                  <Link
                    href="mailto:info@d4re.com"
                    className={globalClasses.InheritLink}
                  >
                    info@d4re.com
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12} style={{ textAlign: "end" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    endIcon={<SearchIcon />}
                    className={classes.search}
                    variant="outlined"
                    color="primary"
                    onClick={() => setopenSearchDialog(true)}
                  >
                    {dictionary.SearchNextOffice.toUpperCase()}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    className={classes.ButtonContainer}
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        style={{
                          background: "#ffffff55",
                          minWidth: 0,
                          marginRight: 10,
                        }}
                      >
                        <a
                          href="https://facebook.com/d4re.srl"
                          rel="noreferrer"
                          target="_blank"
                          className={globalClasses.InheritLink}
                        >
                          <FacebookIcon
                            fill="#141B4D"
                            style={{ overflow: "visible" }}
                            viewBox="-5 -4 22 22"
                          />
                        </a>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button style={{ background: "#ffffff55", minWidth: 0 }}>
                        <a
                          href="https://linkedin.com/company/d4re/"
                          rel="noreferrer"
                          target="_blank"
                          className={globalClasses.InheritLink}
                        >
                          <LinkedinIcon
                            fill="#141B4D"
                            style={{ overflow: "visible", color: "blue" }}
                            viewBox="-2 -4 24 24"
                          />
                        </a>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Divider className={classes.DividerFooterStyle} />
        <Grid className={classes.FooterStyle} container spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography className={classes.FooterMenu}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                className={classes.FooterMenu}
                href={
                  userLanguage === "it"
                    ? PRIVACY_COOKIE_URL_IT
                    : PRIVACY_COOKIE_URL_EN
                }
              >
                {dictionary.PrivacyCookiePolicy}
              </Link>
            </Typography>
          </Grid>
          <Grid item md={2} xs={12}>
            <Typography className={classes.FooterMenu}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                className={classes.FooterMenu}
                href={
                  userLanguage === "it"
                    ? TERMS_AND_CONDITIONS_URL_IT
                    : TERMS_AND_CONDITIONS_URL_EN
                }
              >
                {dictionary.TermsAndConditions}
              </Link>
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}></Grid>

          <Grid item md={2} xs={12} className={classes.LastChild}>
            <Typography className={classes.FooterMenu}>
              {`© ${new Date().getFullYear()} D4RE srl`}
            </Typography>
          </Grid>
        </Grid>
        <SearchBuildings
          type="dialogSearch"
          openDialog={openSearchDialog}
          setOpenDialog={setopenSearchDialog}
        />
        <LikeDialog
          open={openLikeDialog}
          setOpen={setopenLikeDialog}
          onClose={() => setopenLikeDialog(false)}
        />
      </Container>
    </div>
  );
}

/*
<CookieConsent
                location="bottom"
                buttonText="Ok"
                cookieName="CookieLawAccept"
                style={{ background: "linear-gradient(90deg, #CDE763 0.03%, #C5E464 7.37%, #AEDD67 18.88%, #8AD06B 33.12%, #5AC071 48.72%, #58BD75 59.16%, #52B37F 68.49%, #49A292 77.39%, #3B8AAB 86.03%, #296CCC 94.39%, #1C55E5 99.72%)" ,color: "#141B4D"}}
                buttonStyle={{ background: "#141B4D",color: "#CDE763", fontSize: "13px" }}
                expires={150}                                                
              >
                {dictionary.CookieLawMsg}
                
            </CookieConsent>

*/

/*
<div id="id_cookie_alert" class="cookiepolicy">Navigando questo sito, accetti la nostra <a href="https://d4re.com/CookiePolicy.pdf" target="blank" style="text-decoration: underline; color: #141B4D;">cookie policy</a>.<span style="background-color: #141B4D; padding: 6px; color: #CDE763; margin-left: 10px;" onclick="closeCookieLaw()">Accetto</span><img onclick="closeCookieLaw()" src="/images/Vector.png" style="float: right; padding-top:4px;"></div>
*/
