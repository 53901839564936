import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import CirclePopper from "../../../CirclePopper/CirclePopper";
import { format } from "date-fns";
import {
  NoYesWithValueUtils,
  displayUnitResultPriceText,
  selftCasting,
  toggleValue,
} from "../../../utils";

import {
  updateUnitAssetClass,
  updateUnitBusiness,
  updateUnitCoworking,
  updateUnitDealType,
  updateUnitDivisible,
  updateUnitFloor,
  updateUnitInvestment,
  updateUnitListingDate,
  updateUnitPrice,
  updateUnitPriceType,
  updateUnitPriceUnit,
  updateUnitRenovationDate,
  updateUnitRenovationGrade,
  updateUnitRenovationStatus,
  updateUnitServiceCharges,
  updateUnitSizeArchive,
  updateUnitSizeTerraces,
  updateUnitSizeType,
  updateUnitState,
  updateUnitSublet,
  updateUnitTotalSize,
} from "../../../../reducers/UnitSlice";
import {
  ASSET_CLASSES,
  DEAL_TYPES,
  DEAL_TYPE_LEASE_INT,
  DEAL_TYPE_SALE_INT,
  TYPE_SIZE_EDIT_BUILDING,
  UNIT_FLOOR,
  UNIT_ONLINE_STATUS_OFFLINE,
  UNIT_ONLINE_STATUS_ONLINE,
  UNIT_PRICE_CLASSIFICATION,
  UNIT_RENOVATION_GRADE,
  UNIT_RENOVATION_STATUS,
} from "../../../../config/config";
import { updateBuildingRooftopArea } from "../../../../reducers/BuildingSlice";
import FormatNumberTextfield from "../../../utils/FormatNumberTextfield";
import YesWithValueDropDown from "../../../YesWithValueDropDown/YesWithValueDropDown";

const UnitInformations = ({
  classes,
  dictionary,
  unit,
  dispatch,
  unitErrors,
  setUnitErrors,
  unitFloorOptions,
  setChangeExclusiveArchiveSelector,
  changeExclusiveArchiveSelector,
  changeUnitSizeTerracesSelector,
  setChangeUnitSizeTerracesSelector,
}) => {
  // Get today's date in the format yyyy-MM-dd
  const today = format(new Date(), "yyyy-MM-dd");
  const [priceTypeLabel, setPriceTypeLabel] = useState(" ");

  const changeUnitFloorsHandler = (e) => {
    if (unitErrors.floor) setUnitErrors((prev) => ({ ...prev, floor: false }));
    const payload = selftCasting(e.target);
    dispatch(updateUnitFloor(payload));
  };
  const changeUnitAssetClassHandler = (e) => {
    if (unitErrors.asset_class)
      setUnitErrors((prev) => ({ ...prev, asset_class: false }));

    const payload = selftCasting(e.target);
    dispatch(updateUnitAssetClass(payload));
  };
  const changeUnitSizeHandler = (e) => {
    if (unitErrors.size) setUnitErrors((prev) => ({ ...prev, size: false }));

    const payload = selftCasting(e.target);
    const numericPayload = payload.replace(/\D/g, "");

    dispatch(updateUnitTotalSize(numericPayload));
  };
  const changeUnitSizeTypeHandler = (e) => {
    if (unitErrors.type_size)
      setUnitErrors((prev) => ({ ...prev, type_size: false }));

    const payload = selftCasting(e.target);
    dispatch(updateUnitSizeType(payload));
  };
  const changeUnitPriceTypeHandler = (e) => {
    if (unitErrors.price_type)
      setUnitErrors((prev) => ({ ...prev, price_type: false }));

    const payload = selftCasting(e.target);
    // console.log("price_type",payload)
    dispatch(updateUnitPriceType(payload));
  };
  const changeUnitServiceChargesHandler = (e) => {
    if (unitErrors.service_charges)
      setUnitErrors((prev) => ({ ...prev, services_charges: false }));

    const payload = selftCasting(e.target);
    dispatch(updateUnitServiceCharges(payload));
  };
  const changeUnitDealTypeHandler = (e) => {
    if (unitErrors.deal_type)
      setUnitErrors((prev) => ({ ...prev, deal_type: false }));

    const payload = selftCasting(e.target);
    dispatch(updateUnitDealType(payload));
  };
  const changeUnitPriceUnitHandler = (e) => {
    if (unitErrors.price_unit)
      setUnitErrors((prev) => ({ ...prev, price_unit: false }));

    const payload = selftCasting(e.target);
    // console.log("price  range", payload)
    dispatch(updateUnitPriceUnit(payload));
  };
  const changeUnitPriceHandler = (e) => {
    if (unitErrors.price) setUnitErrors((prev) => ({ ...prev, price: false }));

    const payload = selftCasting(e.target);
    dispatch(updateUnitPrice(payload));
  };
  const changeUnitSubletHandler = () => {
    if (unitErrors.sublet)
      setUnitErrors((prev) => ({ ...prev, sublet: false }));

    const payload = toggleValue(unit.sublet);
    dispatch(updateUnitSublet(payload));
  };
  const changeUnitCoworkingHandler = () => {
    if (unitErrors.coworking)
      setUnitErrors((prev) => ({ ...prev, coworking: false }));

    const payload = toggleValue(unit.coworking);
    dispatch(updateUnitCoworking(payload));
  };
  const changeUnitInvestmentHandler = () => {
    if (unitErrors.investment)
      setUnitErrors((prev) => ({ ...prev, investment: false }));

    const payload = toggleValue(unit.investment);
    dispatch(updateUnitInvestment(payload));
  };
  const changeUnitBusinessHandler = () => {
    if (unitErrors.business)
      setUnitErrors((prev) => ({ ...prev, business: false }));

    const payload = toggleValue(unit.business);
    dispatch(updateUnitBusiness(payload));
  };

  const changeUnitDivisibleHandler = () => {
    if (unitErrors.divisible)
      setUnitErrors((prev) => ({ ...prev, divisible: false }));

    const payload = toggleValue(unit.divisible);
    dispatch(updateUnitDivisible(payload));
  };
  const changeUnitStateHandler = (e) => {
    if (unitErrors.state) setUnitErrors((prev) => ({ ...prev, state: false }));

    const payload = selftCasting(e.target);
    // console.log("value status", payload)
    dispatch(updateUnitState(payload));
  };
  const changeListingDateHandler = (e) => {
    if (unitErrors.listing_date)
      setUnitErrors((prev) => ({ ...prev, listing_date: false }));

    const payload = selftCasting(e.target);

    dispatch(updateUnitListingDate(payload));
  };

  const changeRenovationStatusHandler = (e) => {
    if (unitErrors.renovation_status)
      setUnitErrors((prev) => ({ ...prev, renovation_status: false }));

    let payload = selftCasting(e.target);
    payload = payload === "notSpecified" ? null : payload;

    dispatch(updateUnitRenovationStatus(payload));
  };

  const changeRenovationDateHandler = (e) => {
    if (unitErrors.renovationdate)
      setUnitErrors((prev) => ({ ...prev, renovationdate: false }));

    const payload = selftCasting(e.target);
    dispatch(updateUnitRenovationDate(payload));
  };

  const changeRenovationGradeHandler = (e) => {
    if (unitErrors.renovationgrade)
      setUnitErrors((prev) => ({ ...prev, renovationgrade: false }));

    let payload = selftCasting(e.target);
    payload = payload === "notSpecified" ? null : payload;

    dispatch(updateUnitRenovationGrade(payload));
  };

  const handleInputFocus = (event) => {
    event.target.select(); // Select the content of the input
  };

  //Refs for the input fields
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  //function to cancel the event when we wheel mouse
  const handleWheel = (e) => {
    //cancel the event
    e.preventDefault();
  };

  // Attach/detach the wheel event listener on component mount/unmount
  useEffect(() => {
    inputRefs.forEach((inputRef) => {
      const input = inputRef.current;
      if (input) {
        input.addEventListener("wheel", handleWheel, { passive: false });
      }
    });

    return () => {
      inputRefs.forEach((inputRef) => {
        const input = inputRef.current;
        if (input) {
          input.removeEventListener("wheel", handleWheel);
        }
      });
    };
  }, [changeExclusiveArchiveSelector, changeUnitSizeTerracesSelector]);

  useEffect(() => {
    if (unit?.state === 1) {
      dispatch(updateUnitListingDate(today));
    }
  }, [unit?.state]);

  useEffect(() => {
    if (unit.unitIsNew) {
      dispatch(updateUnitListingDate(today));
    }
  }, [unit.unitIsNew]);

  React.useEffect(() => {
    if (!(unit.size > 0 && unit.price > 0)) {
      setPriceTypeLabel("");
      return;
    }
    if (unit.deal_type === DEAL_TYPE_SALE_INT) {
      const price =
        unit.price_unit === "sqm"
          ? Math.floor(unit.price * unit.size)
          : unit.price;
      setPriceTypeLabel(`${dictionary.PriceDisplayed} ${price} €`);
    } else if (unit.deal_type === DEAL_TYPE_LEASE_INT) {
      const price =
        unit.price_unit === "sqm"
          ? unit.price
          : Math.ceil(unit.price / unit.size);
      setPriceTypeLabel(
        `${dictionary.PriceDisplayed} ${price} €/${dictionary.sqm}/${dictionary.year}`
      );
    } else {
      setPriceTypeLabel("");
    }
  }, [unit.price, unit.price_unit, unit.size, unit.deal_type]);

  return (
    <>
      <Grid
        item
        xs={12}
        style={{ marginTop: "2rem" }}
        id="unit-information-section"
      >
        <Typography className={classes.MenuTitle}>
          {dictionary.UnitInformations}
        </Typography>
      </Grid>
      <Grid item id="unit-specifics-section">
        <Typography
          className={classes.subtitle}
          style={{ fontWeight: "600", marginBottom: "1.5rem" }}
        >
          {dictionary.Specifics}
        </Typography>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="floor-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>{dictionary.UnitFloor}*</InputLabel>
            <Select
              value={unit.floor}
              label={`${dictionary.UnitFloor}*`}
              onChange={changeUnitFloorsHandler}
              name="number"
            >
              {unitFloorOptions.map((floorOption, index) => (
                <MenuItem key={index} value={floorOption.value}>
                  {floorOption.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} id="asset_class-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>{dictionary.AssetClass}*</InputLabel>
            <Select
              value={unit.asset_class}
              label={`${dictionary.AssetClass}*`}
              onChange={changeUnitAssetClassHandler}
              name="number"
            >
              {Object.entries(ASSET_CLASSES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {dictionary[value]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="size-section">
          <FormatNumberTextfield
            value={unit.size}
            onChangeHandler={changeUnitSizeHandler}
            error={unitErrors.size}
            inputRef={inputRefs[0]}
            label={`${dictionary.UnitSize}*`}
            handleInputFocus={handleInputFocus}
          />
        </Grid>
        <Grid item xs={6} id="type_size-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>{dictionary.SizeType}*</InputLabel>
            <Select
              value={unit.type_size}
              label={`${dictionary.SizeType}*`}
              onChange={changeUnitSizeTypeHandler}
              error={unitErrors.type_size}
              name="number"
            >
              {Object.entries(TYPE_SIZE_EDIT_BUILDING).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <CirclePopper text={dictionary.GLAInfo} />
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={3}
        style={{ marginTop: "1rem" }}
        id="price_type-section"
      >
        <Grid item xs={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{dictionary.SalePriceClassification}*</InputLabel>
            <Select
              value={unit.price_type}
              label={`${dictionary.SalePriceClassification}*`}
              onChange={changeUnitPriceTypeHandler}
              error={unitErrors.price_type}
              name="number"
            >
              {Object.entries(UNIT_PRICE_CLASSIFICATION).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {dictionary[value]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <CirclePopper text={dictionary.GLAInfo} />
        </Grid>
        <Grid item xs={6} id="service_charges-section">
          <TextField
            label={dictionary.serviceCharges}
            value={unit.service_charges}
            fullWidth
            variant="outlined"
            onChange={changeUnitServiceChargesHandler}
            type="number"
            inputProps={{ min: 0 }}
            onFocus={handleInputFocus}
            error={unitErrors.service_charges}
            inputRef={inputRefs[1]}
          />
        </Grid>
      </Grid>
      <Grid item id="unit-transaction-type-section">
        <Typography
          className={classes.subtitle}
          style={{ fontWeight: "600", marginBottom: "1.5rem" }}
        >
          {dictionary.TransactionType}
        </Typography>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="deal_type-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>{dictionary.DealType}*</InputLabel>
            <Select
              value={unit.deal_type}
              label={`${dictionary.DealType}*`}
              onChange={changeUnitDealTypeHandler}
              error={unitErrors.deal_type}
              name="number"
            >
              {Object.entries(DEAL_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {dictionary[value]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} id="price_unit-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>{dictionary.PriceRange}*</InputLabel>
            {unit.deal_type === DEAL_TYPE_LEASE_INT && (
              <Select
                value={unit.price_unit}
                label={`${dictionary.PriceRange}*`}
                onChange={changeUnitPriceUnitHandler}
                error={unitErrors.price_unit}
              >
                <MenuItem value={"sqm"}>{dictionary.SqmYear}</MenuItem>
                <MenuItem value={"total"}>{dictionary.AnnualRent}</MenuItem>
              </Select>
            )}
            {unit.deal_type === DEAL_TYPE_SALE_INT && (
              <Select
                value={unit.price_unit}
                label={`${dictionary.PriceRange}*`}
                onChange={changeUnitPriceUnitHandler}
                error={unitErrors.price_unit}
              >
                <MenuItem value={"total"}>{dictionary.PricePerUnit}</MenuItem>
                <MenuItem value={"sqm"}>{dictionary.PricePerSqm}</MenuItem>
              </Select>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={3}
        style={{ marginTop: "1rem" }}
        id="price-section"
      >
        <Grid item xs={6}>
          {unit.deal_type === DEAL_TYPE_LEASE_INT && (
            <TextField
              label={`${dictionary.LeasePrice}*`}
              value={unit.price}
              fullWidth
              variant="outlined"
              onChange={changeUnitPriceHandler}
              type="number"
              inputProps={{ min: 0 }}
              onFocus={handleInputFocus}
              error={unitErrors.price}
              helperText={priceTypeLabel}
              inputRef={inputRefs[2]}
            />
          )}
          {unit.deal_type === DEAL_TYPE_SALE_INT && (
            <TextField
              label={`${dictionary.SalePrice}*`}
              value={unit.price}
              fullWidth
              variant="outlined"
              onChange={changeUnitPriceHandler}
              type="number"
              inputProps={{ min: 0 }}
              onFocus={handleInputFocus}
              error={unitErrors.price}
              helperText={priceTypeLabel}
              inputRef={inputRefs[3]}
            />
          )}
        </Grid>
      </Grid>

      <Grid
        item
        container
        spacing={3}
        style={{ marginTop: "1rem" }}
        id="sublet-section"
      >
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(unit.sublet)}
                onChange={changeUnitSubletHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${unitErrors.sublet ? "red" : ""}`,
                }}
              >
                {dictionary.Sublet}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="coworking-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(unit.coworking)}
                onChange={changeUnitCoworkingHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${unitErrors.coworking ? "red" : ""}`,
                }}
              >
                {dictionary.Coworking}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={3}
        style={{ marginTop: "1rem" }}
        id="investment-section"
      >
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(unit.investment)}
                onChange={changeUnitInvestmentHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={dictionary.Investment}
          />
        </Grid>
        <Grid item xs={6} id="business-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(unit.business)}
                onChange={changeUnitBusinessHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${unitErrors.business ? "red" : ""}`,
                }}
              >
                {dictionary.Business}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item id="unit-exclusive-areas-section">
        <Typography
          className={classes.subtitle}
          style={{ fontWeight: "600", marginBottom: "1.5rem" }}
        >
          {dictionary.ExclusiveAreas}
        </Typography>
      </Grid>

      <YesWithValueDropDown
        dropDownLabel={dictionary.ExclusiveArchive}
        textFieldLabel={dictionary.ExclusiveArchiveValue}
        sectionId={"size_archive-section"}
        dispatch={dispatch}
        dictionary={dictionary}
        field={"size_archive"}
        value={unit.size_archive}
        updateValueHandler={updateUnitSizeArchive}
        error={unitErrors.size_archive}
        setErrors={setUnitErrors}
        inputRef={inputRefs[3]}
        handleInputFocus={handleInputFocus}
      />

      <YesWithValueDropDown
        dropDownLabel={dictionary.RooftopTerrcaces}
        textFieldLabel={dictionary.Rooftop}
        sectionId={"size_terraces-section"}
        dispatch={dispatch}
        dictionary={dictionary}
        field={"size_terraces"}
        value={unit.size_terraces}
        updateValueHandler={updateUnitSizeTerraces}
        error={unitErrors.size_terraces}
        setErrors={setUnitErrors}
        inputRef={inputRefs[3]}
        handleInputFocus={handleInputFocus}
      />

      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="divisible-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(unit.divisible)}
                onChange={changeUnitDivisibleHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${unitErrors.divisible ? "red" : ""}`,
                }}
              >
                {dictionary.DivisibleSpace}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item id="unit-status-section">
        <Typography
          className={classes.subtitle}
          style={{ fontWeight: "600", marginBottom: "1.5rem" }}
        >
          {dictionary.UnitStatus}
        </Typography>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="state-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>{dictionary.OnlineStatus}*</InputLabel>
            <Select
              value={unit.state}
              label={`${dictionary.OnlineStatus}*`}
              onChange={changeUnitStateHandler}
              name="number"
            >
              <MenuItem style={{ fontWeight: "bold", pointerEvents: "none" }}>
                <span>{dictionary.online}</span>
              </MenuItem>
              {Object.entries(UNIT_ONLINE_STATUS_ONLINE).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {dictionary[value]}
                </MenuItem>
              ))}
              <MenuItem style={{ fontWeight: "bold", pointerEvents: "none" }}>
                <span>{dictionary.offline}</span>
              </MenuItem>
              {Object.entries(UNIT_ONLINE_STATUS_OFFLINE).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {dictionary[value]}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} id="listing_date-section">
          <TextField
            id="listing_date-section"
            label={dictionary.listing_date}
            type="date"
            value={unit.listing_date}
            fullWidth
            variant="outlined"
            onChange={changeListingDateHandler}
            inputProps={{ max: unit.state != 2 ? today : "" }} // Specify the maximum allowed date
            InputLabelProps={{
              shrink: true,
            }}
            error={unitErrors.listing_date}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="renovation_status-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>{dictionary.RenovationStatus}*</InputLabel>
            <Select
              value={unit.renovation_status}
              label={`${dictionary.RenovationStatus}*`}
              onChange={changeRenovationStatusHandler}
              error={unitErrors.renovation_status}
              name="number"
            >
              {Object.entries(UNIT_RENOVATION_STATUS).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {dictionary[value]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="renovationdate-section"
            label={dictionary.RenovationDate}
            type="date"
            value={unit.renovationdate}
            fullWidth
            variant="outlined"
            onChange={changeRenovationDateHandler}
            inputProps={{ max: today }} // Specify the maximum allowed date
            InputLabelProps={{
              shrink: true,
            }}
            error={unitErrors.renovationdate}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="renovationgrade-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>Grade*</InputLabel>
            <Select
              value={unit.renovationgrade}
              label="Exclusive Archive"
              onChange={changeRenovationGradeHandler}
              error={unitErrors.renovationgrade}
              name="number"
            >
              {Object.entries(UNIT_RENOVATION_GRADE).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {dictionary[value]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default UnitInformations;
