import {
  Button,
  Chip,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Hidden,
  Card,
  Divider,
} from "@material-ui/core";

import {
  sizelistname,
  askingrentlist,
  askingsaleslist,
  DecorateOfferorWithSearcher,
} from "../../../utils";

import React, { useContext, useState, Fragment } from "react";

import Auth from "../../../../auth/auth";
import { LanguageContext } from "../../../../containers/languageprovider";
import { useEffect } from "react";
import axios from "axios";
import { API_URL_ALL_OFFEROR } from "../../../../config/config";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "../../../../css/SearcherArea.css";
const useStyles = makeStyles((theme) => ({
  TableHead: {
    fontFamily: "Barlow, sans-serif, Verdana",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: 2,
    color: "#6a6a6a",
    padding: "0.5rem 0",
  },
  searchBox: {
    maxWidth: "200px",
    fontSize: "16px",
  },
  TableRowCell: {
    margin: "0 98px 0 0",
    fontFamily: "Barlow, sans-serif, Verdana, Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
    color: "#000",
    padding: "1rem 0",
  },
  GridSpacingSM: {
    [theme.breakpoints.down("sm")]: {
      gap: "16px 0px",
    },
  },
  TitleContainer: {
    padding: "0 24px",
  },
  MenuTitle: {
    margin: "0",
    fontFamily: "Montserrat",
    fontSize: "36px",
    fontWeight: 600,
  },
  mainContainer: {
    padding: "12px 24px",
  },
  filterSizeLabel: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "32px",
  },
  mobileValueText: {
    fontSize: "14px",
    whiteSpace: "normal",
  },
  mobileLabelText: {
    fontSize: "14px",
    whiteSpace: "normal",
  },
  LengthText: {
    transition: "0.25s ease",
  },
  AnimatedText: {
    animation: `$shake 200ms ${theme.transitions.easing.easeInOut}`,
    transform: "translateX(0%)",
  },
  "@keyframes shake": {
    "0%": {
      transform: "translateX(0%)",
    },
    "25%": {
      transform: "translateX(5px)",
    },
    "50%": {
      transform: "translateX(-5px)",
    },
    "75%": {
      transform: "translateX(5px)",
    },
    "100%": {
      transform: "translateY(0)",
    },
  },
  TableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
  },
}));

const AssignedResearches = () => {
  const classes = useStyles();
  const { dictionary } = useContext(LanguageContext);
  const theme = useTheme();
  const xsBreakpoint = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true,
  });

  const [searchers, setSearchers] = useState([]);
  const [filteredSearchers, setFilteredSearchers] = useState([]);

  const [pagedRequests, setPagedRequests] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchText, setSearchText] = useState("");
  const hideSearchAndAddButton = false;

  //useHistory to redirect the user once he click on the offeror
  const history = useHistory();

  //

  const handleSearchTextChange = (event) => {
    const inputText = event.target.value;
    const filteredData = searchers.filter((item) => {
      // console.log(
      //   "searchable : ",
      //   item.searcher.info.getSearcheableText(dictionary)
      // );
      return item.searcher.info
        .getSearcheableText(dictionary)
        .includes(inputText.toLowerCase());
    });

    // console.log("filteredData : ", filteredData);
    setFilteredSearchers(filteredData);
    setSearchText(inputText);
  };

  const handleClearSearchText = () => {
    const inputText = "";

    const filteredData = searchers.filter((item) => {
      // console.log(
      //   "searchable : ",
      //   item.searcher.info.getSearcheableText(dictionary)
      // );
      return item.searcher.info
        .getSearcheableText(dictionary)
        .includes(inputText.toLowerCase());
    });

    // console.log("filteredData : ", filteredData);
    setFilteredSearchers(filteredData);
    setSearchText(inputText);
  };

  const handleChangePage = (e, newPage) => {
    setPagedRequests(
      filteredSearchers.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      )
    );
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    var rows = parseInt(event.target.value, 10);

    setRowsPerPage(rows);
    setPage(0);
    setPagedRequests(filteredSearchers.slice(0, rows));
  };

  const handleClickOfferor = (id) => {
    history.push(`/offerors/${id}`);
  };

  useEffect(() => {
    axios
      .get(API_URL_ALL_OFFEROR, {
        headers: {
          Authorization: "Bearer " + Auth.getToken(),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        res.data.all.forEach(function (offeror, i) {
          // console.log("Searcher: %o",searcher);
          DecorateOfferorWithSearcher(offeror);
          // console.log("Searcher post: %o",searcher);
        });

        setFilteredSearchers(res.data.all);
        setSearchers(res.data.all);
      });
  }, []);

  useEffect(() => {
    setPagedRequests(filteredSearchers.slice(0, rowsPerPage));
  }, [filteredSearchers, rowsPerPage]);

  const formatCreatedDate = (timestamp) => {
    const dateObj = new Date(timestamp);
    const formattedDate = `${dateObj.getDate()}/${
      dateObj.getMonth() + 1
    }/${dateObj.getFullYear()}`;
    return formattedDate;
  };

  const formatFromDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `from ${month.toString().padStart(2, "0")}/${year}`;
  };

  const titleGenerator = (assetClass, location) => {
    const asset_class =
      assetClass === 1 ? "Office" : assetClass === 2 ? "Retail" : "Industrial";
    return asset_class + " " + location;
  };

  const getSizeLabelsByIds = (ids) => {
    const labels = [];
    for (const id of ids) {
      const size = sizelistname.find((s) => s.id === id);
      if (size) {
        labels.push(size.label);
      }
    }
    switch (labels.length) {
      case 0:
        return "";
      case 1:
        return labels[0] + "; ";
      default:
        return `${labels.length} sizes ; `;
    }
  };

  const getPriceLabelsByIds = (ids, deal_type) => {
    const prices = [];
    for (const id of ids) {
      const price =
        deal_type === 1
          ? askingrentlist.find((s) => s.id === id)
          : askingsaleslist.find((s) => s.id === id);
      if (price) {
        prices.push(price.label);
      }
    }
    switch (prices.length) {
      case 0:
        return "";
      case 1:
        return prices[0] + " year ; ";
      default:
        return `${prices.length} prices ; `;
    }
  };

  // useEffect(() => {
  //   console.log("sizelistname: ", sizelistname);
  //   console.log("askingrentlist : ", askingrentlist);
  //   console.log("askingsaleslist : ", askingsaleslist);
  // }, []);

  return (
    <Grid container direction="column">
      <Grid item style={{ width: "100%" }}>
        <Grid
          container
          item
          justifyContent="space-between"
          className={classes.TitleContainer}
          alignItems="center"
        >
          <Grid item>
            <Typography className={classes.MenuTitle}>
              {dictionary.Requests}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* MAIN AREA */}
      <Grid item style={{ width: "100%" }} className={classes.mainContainer}>
        <Grid item xs={12} container>
          <Grid
            container
            spacing={2}
            style={{
              marginTop: "12px",
              marginBottom: "20px",
            }}
            justifyContent="space-between"
          >
            <Grid item xs={12} md={3}>
              <TextField
                className={classes.searchBox}
                value={searchText}
                onChange={handleSearchTextChange}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  ),
                  endAdornment: searchText && (
                    <IconButton onClick={handleClearSearchText}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                placeholder="Search"
                fullWidth
              />
            </Grid>
            {hideSearchAndAddButton && (
              <Grid item xs={12} md={2} alignItems="center">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>Send New Request</span>

                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      minWidth: "40px",
                      width: "40px",
                      borderRadius: "50%",
                      marginLeft: "1rem",
                    }}
                  >
                    <AddIcon />
                  </Button>
                </div>
              </Grid>
            )}
          </Grid>
          {xsBreakpoint && (
            <Box width="100%" style={{ zIndex: 10 }}>
              <Hidden smUp={true} implementation="css">
                <Grid container item xs={12}>
                  <Card
                    elevation={0}
                    style={{ overflowX: "auto", width: "100%" }}
                  >
                    {pagedRequests.length > 0 && (
                      <>
                        <Grid
                          style={{
                            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                            marginTop: "10px",
                          }}
                          container
                          xs={12}
                          direction="column"
                        >
                          {pagedRequests.length > 0 &&
                            pagedRequests.map((request, i) => (
                              <Fragment key={`llpaged-${i}-${request}`}>
                                <Box
                                  onClick={() => handleClickOfferor(request.id)}
                                  width={"100%"}
                                  borderLeft="1px solid rgba(0, 0, 0, 0.12)"
                                  borderRight="1px solid rgba(0, 0, 0, 0.12)"
                                  p={2}
                                >
                                  <Grid item container spacing={3}>
                                    <Grid
                                      style={{
                                        maxWidth: "100%",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                      item
                                      xs={10}
                                    >
                                      <span
                                        className={"mobileText"}
                                        style={{
                                          color: "#1c55e5",
                                        }}
                                      >
                                        {`#${request.id} - ${request.searcher.info.getSearcherTitle( dictionary )} `}
                                      </span>

                                      <Typography noWrap>
                                        <span className={"mobileText"}>
                                          <span>
                                            {request.searcher.info.getPriceLabel(
                                              dictionary
                                            )}
                                          </span>
                                          <span
                                            style={{ paddingRight: "0.2em" }}
                                          >
                                            {request.searcher.info.getSizeLabel(
                                              dictionary
                                            )}
                                            ;
                                          </span>
                                          <span
                                            style={{ paddingRight: "0.2em" }}
                                          >
                                            {request.searcher.info.getFormattedFromDate(
                                              dictionary
                                            )}
                                          </span>
                                        </span>
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      style={{
                                        maxWidth: "100%",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                      item
                                      xs={5}
                                    >
                                      <span className={"mobileText"}>
                                        {dictionary.DealType}
                                      </span>
                                      <br />
                                      <p
                                        className={"mobileText"}
                                        style={{ margin: "5px 0" }}
                                      >
                                        {request.searcher.deal_type === 1
                                          ? dictionary.Lease
                                          : dictionary.sale}
                                      </p>
                                    </Grid>

                                    <Grid item xs={5}>
                                      <span className={"mobileText"}>
                                        {dictionary.Status}
                                      </span>
                                      <br />
                                      <Chip
                                        label={dictionary[request.phase]}
                                        style={request.searcher.info.getPhaseChipStyle(
                                          dictionary
                                        )}
                                      />
                                    </Grid>

                                    <Grid
                                      style={{
                                        position: "relative",
                                        zIndex: 20,
                                      }}
                                      item
                                      container
                                      alignItems="center"
                                      xs={2}
                                    ></Grid>
                                  </Grid>
                                </Box>
                                <Divider />
                              </Fragment>
                            ))}

                          <TablePagination
                            component="div"
                            labelDisplayedRows={(info) =>
                              dictionary.TableResults.replace(
                                "{1}",
                                `${info.from} - ${info.to}`
                              ).replace("{2}", info.count)
                            }
                            count={filteredSearchers.length}
                            labelRowsPerPage={
                              xsBreakpoint ? "" : dictionary.labelRowsPerPage
                            }
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Grid>
                      </>
                    )}
                    {pagedRequests.length <= 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={5}>
                            <Typography>{dictionary.NoResults} </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Card>
                </Grid>
              </Hidden>
            </Box>
          )}
          {!xsBreakpoint && (
            <Grid item xs={12}>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.TableHead}
                      >
                        {dictionary.Request}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.TableHead}
                      >
                        {dictionary.DealType}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.TableHead}
                      >
                        {dictionary.Assigned}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.TableHead}
                      >
                        {dictionary.Status}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.TableHead}
                      >
                        <StarBorderIcon style={{ color: "gray" }} />
                      </TableCell>
                    </TableRow>

                    {pagedRequests.length === 0 && searchText !== "" && (
                      <TableRow className={classes.TableRow}>
                        <TableCell component="th" scope="row" colSpan={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <h3>{dictionary.NoResultFilter}</h3>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}

                    {pagedRequests.map((request) => (
                      <TableRow
                        key={request.id}
                        className={classes.TableRow}
                        onClick={() => handleClickOfferor(request.id)}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.TableRowCell}
                        >
                          <p
                            style={{
                              margin: 0,
                              color: "#1c55e5",
                              fontWeight: "500",
                            }}
                          >
                            {`#${request.id} - ${request.searcher.info.getSearcherTitle( dictionary )} `}
                          </p>
                          <span
                            style={{
                              fontSize: "14px",
                              margin: 0,
                              // fontWeight: "500",
                            }}
                          >
                            <span>
                              {request.searcher.info.getPriceLabel(dictionary)}
                            </span>
                            <span style={{ paddingRight: "0.5em" }}>
                              {request.searcher.info.getSizeLabel(dictionary)};
                            </span>
                            <span style={{ paddingRight: "0.5em" }}>
                              {request.searcher.info.getFormattedFromDate(
                                dictionary
                              )}
                            </span>

                            {/*{getPriceLabelsByIds(request.searcher.price)}*/}
                            {/*{getSizeLabelsByIds(request.searcher.size)}*/}
                            {/*{formatFromDate(request.searcher.available_from)}*/}
                          </span>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.TableRowCell}
                        >
                          {request.searcher?.info.getDealTypeLabel(dictionary)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.TableRowCell}
                        >
                          {formatCreatedDate(request.created_at)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.TableRowCell}
                        >
                          <Chip
                            label={request.info.getPhaseLabel(dictionary)}
                            style={request.info.getPhaseChipStyle(dictionary)}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.TableRowCell}
                        >
                          <p style={{ color: "#1c55e5", fontWeight: "bold" }}>
                            {request.info.getTotalStars()}
                          </p>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                labelDisplayedRows={(info) =>
                  dictionary.TableResults.replace(
                    "{1}",
                    `${info.from} - ${info.to}`
                  ).replace("{2}", info.count)
                }
                count={filteredSearchers.length}
                labelRowsPerPage={
                  xsBreakpoint ? "" : dictionary.labelRowsPerPage
                }
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssignedResearches;
