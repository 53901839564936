import React, { useState, useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Snackbar, makeStyles, Grid, Typography, TextField } from "@material-ui/core";
import { LanguageContext } from "../../containers/languageprovider";
import "../../css/EditHtmlStlyes.css";
import {NumericUtils} from "../utils";

const useStyles = makeStyles((theme) => ({
    SectionContainer: {
        marginTop: theme.spacing(2),
    },
    boxHtml: {
        minHeight: "300px",
        borderRadius: "5px",
        overflow: "auto",
        width: 1,
        border: "1px solid lightgray",
        padding: "5px",
    },
    LengthText: {
        transition: "0.25s ease",
    },
}));



export default function FormatNumberTextfield(
    {
    value,
    label,
    onChangeHandler,
    error,
    inputRef,
    handleInputFocus,
    }
                                              ) {
    const { dictionary, userLanguage } = React.useContext(LanguageContext);
    const [displaySize, setDisplaySize] = useState('');


    useEffect(() => {
        setDisplaySize(NumericUtils.fromNumberToFormatted(value, userLanguage));
    }, [value,userLanguage]);


    return (
        <>
            <TextField
                label={label}
                value={displaySize}
                fullWidth
                variant="outlined"
                onChange={onChangeHandler}
                inputProps={{ min: 0 }}
                onFocus={handleInputFocus}
                error={error}
                inputRef={inputRef}
            />

        </>
    );
}
