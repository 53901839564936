import React from "react";
//import ReactDOM from 'react-dom';
import "./css/styles.css";
import App from "./App";
//import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";
import store from "./store/store"; // Your Redux store

/*ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);*/

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
    rootElement
  );
} else {
  render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
