import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import {
  updateBuildingAccessTurnstiles,
  updateBuildingAuditorium,
  updateBuildingBar,
  updateBuildingBicycleParking,
  updateBuildingBms,
  updateBuildingCanteen,
  updateBuildingChangingRooms,
  updateBuildingChargingStationsNumber,
  updateBuildingCoveredParkingsNumber,
  updateBuildingDataRoom,
  updateBuildingElectSubstation,
  updateBuildingEmergencyLightSystem,
  updateBuildingEntranceReception,
  updateBuildingGenerator,
  updateBuildingGoodsLift,
  updateBuildingHighEffElectSystem,
  updateBuildingIndipendentHVAC,
  updateBuildingLeisureArea,
  updateBuildingLoadingBay,
  updateBuildingMeetingRooms,
  updateBuildingMotorcycleParking,
  updateBuildingOutdoorCarParkingsNumber,
  updateBuildingPipeHVAC2,
  updateBuildingPipeHVAC4,
  updateBuildingRooftopArea,
  updateBuildingSecurityGuards24h,
  updateBuildingSmokeDetectors,
  updateBuildingSplitHVAC,
  updateBuildingSprinkler,
  updateBuildingUps,
  updateBuildingVisitorsParkingsNumber,
  updateBuildingWarehouseArchive,
} from "../../../reducers/BuildingSlice";
import { NoYesWithValueUtils, selftCasting, toggleValue } from "../../utils";
import { ENTERANCE_RECEPTION } from "../../../config/config";
import YesWithValueDropDown from "../../YesWithValueDropDown/YesWithValueDropDown";

const BuildingFacilities = ({
  classes,
  dictionary,
  building,
  dispatch,
  buildingErrors,
  setBuildingErrors,
  changeRoomsSelector,
}) => {
  //Services
  const changeEntranceReceptionHandler = (e) => {
    if (buildingErrors.entrance_reception)
      setBuildingErrors((prev) => ({ ...prev, entrance_reception: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingEntranceReception(payload));
  };

  const changeSecurityGuards24hHandler = () => {
    const payload = toggleValue(building._24_hour_security_guards);

    dispatch(updateBuildingSecurityGuards24h(payload));
  };
  const changeAccessTurnstilesHandler = () => {
    const payload = toggleValue(building.access_turnstiles);
    dispatch(updateBuildingAccessTurnstiles(payload));
  };
  const changeLoadingBayHandler = () => {
    const payload = toggleValue(building.loading_bay);
    dispatch(updateBuildingLoadingBay(payload));
  };
  const changeGoodsLiftHandler = () => {
    const payload = toggleValue(building.seperate_goods_lift);
    dispatch(updateBuildingGoodsLift(payload));
  };

  const changeAuditoriumHandler = () => {
    const payload = toggleValue(building.auditorium);
    dispatch(updateBuildingAuditorium(payload));
  };
  const changeLeisureAreaHandler = () => {
    const payload = toggleValue(building.shared_leisure_area);
    dispatch(updateBuildingLeisureArea(payload));
  };
  const changeMeetingRoomsHandler = () => {
    const payload = toggleValue(building.shared_meeting_rooms);
    dispatch(updateBuildingMeetingRooms(payload));
  };
  const changeCanteenHandler = () => {
    const payload = toggleValue(building.canteen);
    dispatch(updateBuildingCanteen(payload));
  };
  const changeBarHandler = () => {
    const payload = toggleValue(building.bar);
    dispatch(updateBuildingBar(payload));
  };
  //Mobility
  const changeCoveredParkingsNumberHandler = (e) => {
    if (buildingErrors.contract_expire)
      setBuildingErrors((prev) => ({ ...prev, contract_expire: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingCoveredParkingsNumber(payload));
  };
  const changeOutdoorCarParkingsNumberHandler = (e) => {
    if (buildingErrors.outdoor_car_parking)
      setBuildingErrors((prev) => ({ ...prev, outdoor_car_parking: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingOutdoorCarParkingsNumber(payload));
  };
  const changeVisitorsParkingsNumberHandler = (e) => {
    if (buildingErrors.visitors_parking)
      setBuildingErrors((prev) => ({ ...prev, visitors_parking: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingVisitorsParkingsNumber(payload));
  };
  const changeChargingStationsNumberHandler = (e) => {
    if (buildingErrors.charging_stations)
      setBuildingErrors((prev) => ({ ...prev, charging_stations: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingChargingStationsNumber(payload));
  };
  const changeMotorcycleParkingHandler = () => {
    const payload = toggleValue(building.motorcycle_parking);
    dispatch(updateBuildingMotorcycleParking(payload));
  };
  const changeBicycleParkingHandler = () => {
    const payload = toggleValue(building.bicycle_parking);
    dispatch(updateBuildingBicycleParking(payload));
  };
  //Security and Electrical System
  const changeSprinklerHandler = () => {
    const payload = toggleValue(building.sprinkler_watermist);
    dispatch(updateBuildingSprinkler(payload));
  };
  const changeSmokeDetectorsHandler = () => {
    const payload = toggleValue(building.smoke_detectors_system);
    dispatch(updateBuildingSmokeDetectors(payload));
  };
  const changeEmergencyLightSystemHandler = () => {
    const payload = toggleValue(building.emergency_light_system);
    dispatch(updateBuildingEmergencyLightSystem(payload));
  };
  const changeHighEffElectSystemHandler = () => {
    const payload = toggleValue(building.high_efficency_electrical_system);
    dispatch(updateBuildingHighEffElectSystem(payload));
  };
  const changeUpsHandler = () => {
    const payload = toggleValue(building.ups);
    dispatch(updateBuildingUps(payload));
  };
  const changeGeneratorHandler = () => {
    const payload = toggleValue(building.generator);
    dispatch(updateBuildingGenerator(payload));
  };
  const changeDataRoomHandler = () => {
    const payload = toggleValue(building.data_room);
    dispatch(updateBuildingDataRoom(payload));
  };
  const changeElectSubstationHandler = () => {
    const payload = toggleValue(building.electrical_substation);
    dispatch(updateBuildingElectSubstation(payload));
  };
  const changePipeHVAC2Handler = () => {
    const payload = toggleValue(building._2_pipes_air_con_heating_system);
    dispatch(updateBuildingPipeHVAC2(payload));
  };
  const changePipeHVAC4Handler = () => {
    const payload = toggleValue(building._4_pipes_air_con_heating_system);
    dispatch(updateBuildingPipeHVAC4(payload));
  };
  const changeSplitHVACHandler = () => {
    const payload = toggleValue(building.split_air_con_heating_system);
    dispatch(updateBuildingSplitHVAC(payload));
  };
  const changeIndipendentHVACHandler = () => {
    const payload = toggleValue(building.indipendent_air_con_heating_system);
    dispatch(updateBuildingIndipendentHVAC(payload));
  };
  //Control System
  const changeBmsHandler = () => {
    const payload = toggleValue(building.bms);
    dispatch(updateBuildingBms(payload));
  };

  const handleInputFocus = (event) => {
    event.target.select(); // Select the content of the input
  };

  //Refs for the input fields
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  //function to cancel the event when we wheel mouse
  const handleWheel = (e) => {
    //cancel the event
    e.preventDefault();
  };

  // Attach/detach the wheel event listener on component mount/unmount
  useEffect(() => {
    inputRefs.forEach((inputRef) => {
      const input = inputRef.current;
      if (input) {
        input.addEventListener("wheel", handleWheel, { passive: false });
      }
    });

    return () => {
      inputRefs.forEach((inputRef) => {
        const input = inputRef.current;
        if (input) {
          input.removeEventListener("wheel", handleWheel);
        }
      });
    };
  }, [changeRoomsSelector]);

  return (
    <>
      <Grid item xs={12} style={{ marginTop: "2rem" }} id="facilities-section">
        <Typography className={classes.MenuTitle}>
          {dictionary.BuildingFacilities}
        </Typography>
      </Grid>
      <Grid item id="services-section">
        <Typography className={classes.subtitle} style={{ fontWeight: "600" }}>
          {dictionary.Service}
        </Typography>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="entrance_reception-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>{dictionary.entranceReception}</InputLabel>
            <Select
              value={building.entrance_reception}
              label={dictionary.entranceReception}
              onChange={changeEntranceReceptionHandler}
              name="number"
              error={buildingErrors.entrance_reception}
            >
              {Object.entries(ENTERANCE_RECEPTION).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {dictionary[value]}
                </MenuItem>
              ))}{" "}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <YesWithValueDropDown
        dropDownLabel={dictionary.lockers}
        textFieldLabel={`N. ${dictionary.lockers}`}
        sectionId={"changing_rooms_lockers-section"}
        dispatch={dispatch}
        dictionary={dictionary}
        field={"changing_rooms_lockers"}
        value={building.changing_rooms_lockers}
        updateValueHandler={updateBuildingChangingRooms}
        error={buildingErrors.changing_rooms_lockers}
        setErrors={setBuildingErrors}
        inputRef={inputRefs[0]}
        handleInputFocus={handleInputFocus}
      />
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="_24_hour_security_guards-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building._24_hour_security_guards)}
                onChange={changeSecurityGuards24hHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${
                    buildingErrors._24_hour_security_guards ? "red" : ""
                  }`,
                }}
              >
                {dictionary.securityGuards}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="access_turnstiles-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.access_turnstiles)}
                onChange={changeAccessTurnstilesHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.access_turnstiles ? "red" : ""}`,
                }}
              >
                {dictionary.accessTurnstiles}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="loading_bay-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.loading_bay)}
                onChange={changeLoadingBayHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.loading_bay ? "red" : ""}`,
                }}
              >
                {dictionary.loadingBay}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="seperate_goods_lift-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.seperate_goods_lift)}
                onChange={changeGoodsLiftHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.seperate_goods_lift ? "red" : ""}`,
                }}
              >
                {dictionary.GoodsLift}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item id="shared-area-section">
        <Typography className={classes.subtitle} style={{ fontWeight: "600" }}>
          {dictionary.shared_areas}
        </Typography>
      </Grid>

      <YesWithValueDropDown
        dropDownLabel={dictionary.Rooftoparea}
        textFieldLabel={dictionary.RooftopareaSqm}
        sectionId={"shared_rooftop_area-section"}
        dispatch={dispatch}
        dictionary={dictionary}
        field={"shared_rooftop_area"}
        value={building.shared_rooftop_area}
        updateValueHandler={updateBuildingRooftopArea}
        error={buildingErrors.shared_rooftop_area}
        setErrors={setBuildingErrors}
        inputRef={inputRefs[1]}
        handleInputFocus={handleInputFocus}
      />
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="auditorium-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.auditorium)}
                onChange={changeAuditoriumHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{ color: `${buildingErrors.auditorium ? "red" : ""}` }}
              >
                {dictionary.Auditorium}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="shared_leisure_area-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.shared_leisure_area)}
                onChange={changeLeisureAreaHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.shared_leisure_area ? "red" : ""}`,
                }}
              >
                {dictionary.LeisureArea}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="shared_meeting_rooms-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.shared_meeting_rooms)}
                onChange={changeMeetingRoomsHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.shared_meeting_rooms ? "red" : ""}`,
                }}
              >
                {dictionary.MeetingRooms}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="canteen-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.canteen)}
                onChange={changeCanteenHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.canteen ? "red" : ""}`,
                }}
              >
                {dictionary.canteen}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="bar-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.bar)}
                onChange={changeBarHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.bar ? "red" : ""}`,
                }}
              >
                {dictionary.bar}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item id="mobility-section">
        <Typography className={classes.subtitle} style={{ fontWeight: "600" }}>
          {dictionary.mobility}
        </Typography>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="contract_expire-section">
          <TextField
            label={dictionary.Coveredparkings}
            value={building.contract_expire}
            onChange={changeCoveredParkingsNumberHandler}
            fullWidth
            variant="outlined"
            type="number"
            inputProps={{ min: 0 }}
            onFocus={handleInputFocus}
            error={buildingErrors.contract_expire}
            inputRef={inputRefs[2]}
          />
        </Grid>
        <Grid item xs={6} id="outdoor_car_parking-section">
          <TextField
            label={dictionary.outdoorParking}
            value={building.outdoor_car_parking}
            onChange={changeOutdoorCarParkingsNumberHandler}
            fullWidth
            variant="outlined"
            type="number"
            inputProps={{ min: 0 }}
            onFocus={handleInputFocus}
            error={buildingErrors.outdoor_car_parking}
            inputRef={inputRefs[3]}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="visitors_parking-section">
          <TextField
            label={dictionary.visitorParking}
            value={building.visitors_parking}
            onChange={changeVisitorsParkingsNumberHandler}
            fullWidth
            variant="outlined"
            type="number"
            inputProps={{ min: 0 }}
            onFocus={handleInputFocus}
            error={buildingErrors.visitors_parking}
            inputRef={inputRefs[4]}
          />
        </Grid>
        <Grid item xs={6} id="charging_stations-section">
          <TextField
            label={dictionary.chargingStations}
            value={building.charging_stations}
            onChange={changeChargingStationsNumberHandler}
            fullWidth
            variant="outlined"
            type="number"
            inputProps={{ min: 0 }}
            onFocus={handleInputFocus}
            error={buildingErrors.charging_stations}
            inputRef={inputRefs[5]}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="motorcycle_parking-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.motorcycle_parking)}
                onChange={changeMotorcycleParkingHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.motorcycle_parking ? "red" : ""}`,
                }}
              >
                {dictionary.motorcycleParking}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="bicycle_parking-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.bicycle_parking)}
                onChange={changeBicycleParkingHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.bicycle_parking ? "red" : ""}`,
                }}
              >
                {dictionary.bicycleParking}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item id="security-section">
        <Typography className={classes.subtitle} style={{ fontWeight: "600" }}>
          {dictionary.SecurityAndElectricalSystem}
        </Typography>
      </Grid>

      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="sprinkler_watermist-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.sprinkler_watermist)}
                onChange={changeSprinklerHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.sprinkler_watermist ? "red" : ""}`,
                }}
              >
                {dictionary.Sprinkler}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="smoke_detectors_system-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.smoke_detectors_system)}
                onChange={changeSmokeDetectorsHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${
                    buildingErrors.smoke_detectors_system ? "red" : ""
                  }`,
                }}
              >
                {dictionary.smokeDetectors}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="emergency_light_system-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.emergency_light_system)}
                onChange={changeEmergencyLightSystemHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${
                    buildingErrors.emergency_light_system ? "red" : ""
                  }`,
                }}
              >
                {dictionary.lightSystem}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="high_efficency_electrical_system-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.high_efficency_electrical_system)}
                onChange={changeHighEffElectSystemHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${
                    buildingErrors.high_efficency_electrical_system ? "red" : ""
                  }`,
                }}
              >
                {dictionary.highEff}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="ups-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.ups)}
                onChange={changeUpsHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.ups ? "red" : ""}`,
                }}
              >
                UPS
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="generator-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.generator)}
                onChange={changeGeneratorHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.generator ? "red" : ""}`,
                }}
              >
                {dictionary.generator}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="data_room-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.data_room)}
                onChange={changeDataRoomHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.data_room ? "red" : ""}`,
                }}
              >
                {dictionary.DataRoom}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="electrical_substation-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.electrical_substation)}
                onChange={changeElectSubstationHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.electrical_substation ? "red" : ""}`,
                }}
              >
                {dictionary.ElectSubstation}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item id="heating-section">
        <Typography className={classes.subtitle} style={{ fontWeight: "600" }}>
          {dictionary.HeatingAirCondition}
        </Typography>
      </Grid>

      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="_2_pipes_air_con_heating_system-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building._2_pipes_air_con_heating_system)}
                onChange={changePipeHVAC2Handler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${
                    buildingErrors._2_pipes_air_con_heating_system ? "red" : ""
                  }`,
                }}
              >
                {dictionary._2pipeHVAC}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="_4_pipes_air_con_heating_system-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building._4_pipes_air_con_heating_system)}
                onChange={changePipeHVAC4Handler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${
                    buildingErrors._4_pipes_air_con_heating_system ? "red" : ""
                  }`,
                }}
              >
                {dictionary._4pipeHVAC}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="split_air_con_heating_system-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.split_air_con_heating_system)}
                onChange={changeSplitHVACHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${
                    buildingErrors.split_air_con_heating_system ? "red" : ""
                  }`,
                }}
              >
                {dictionary.SplitHVAC}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="indipendent_air_con_heating_system-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.indipendent_air_con_heating_system)}
                onChange={changeIndipendentHVACHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${
                    buildingErrors.indipendent_air_con_heating_system
                      ? "red"
                      : ""
                  }`,
                }}
              >
                {dictionary.IndipendentHVAC}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item id="control-section">
        <Typography className={classes.subtitle} style={{ fontWeight: "600" }}>
          {dictionary.ControlSystem}
        </Typography>
      </Grid>

      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="bms-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.bms)}
                onChange={changeBmsHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.bms ? "red" : ""}`,
                }}
              >
                BMS
              </span>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BuildingFacilities;
