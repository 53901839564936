import { Avatar, Grid, Typography, makeStyles } from "@material-ui/core";
import { CloudUploadOutlined } from "@material-ui/icons";
import React from "react";
import { useDropzone } from "react-dropzone";
import globalStyles from "../globalStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

const getClassName = (className, isActive) => {
  if (!isActive) return className;
  return `${className} ${className}-active`;
};

const useStyles = makeStyles((theme) => ({
  textCenter: {
    textAlign: "center",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DropzoneBox = ({
  style,
  onDrop,
  accept,
  dictionary,
  disabled,
  info,
  error,
  imagesAreUploading,
}) => {
  const classes = useStyles();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <Grid
      style={{ ...style, pointerEvents: disabled ? "none" : "" }}
      item
      xs={12}
      className={" " + getClassName("dropzone", isDragActive)}
      {...getRootProps()}
    >
      <Grid
        style={{
          ...style,
          pointerEvents: disabled ? "none" : "",
          borderColor: error ? "#f44336" : "",
          position:"relative"
        }}
        item
        direction="column"
        container
        justifyContent="center"
        alignItems="center"
        className={globalStyles().DropzoneContainer}
      >
        {imagesAreUploading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        {!imagesAreUploading && (
          <>
            <Grid item>
              <Avatar style={{ background: "rgba(28, 85, 229, 0.12)" }}>
                <CloudUploadOutlined htmlColor={error ? "#f44336" : "blue"} />
              </Avatar>
            </Grid>
            <Grid item>
              <input
                disabled={disabled}
                className="dropzone-input"
                {...getInputProps()}
              />
              {disabled && (
                <p className="dropzone-content">
                  {dictionary.MaxImagesDropzone}
                </p>
              )}
              {!disabled && (
                <div className={classes.textCenter}>
                  {isDragActive ? (
                    <p
                      className="dropzone-content"
                      style={{ color: error ? "#f44336" : "" }}
                    >
                      {dictionary.ReleaseDrop}
                    </p>
                  ) : (
                    <p
                      className="dropzone-content"
                      style={{ color: error ? "#f44336" : "" }}
                    >
                      {dictionary.DragAndDrop}
                    </p>
                  )}
                  {info && (
                    <Typography
                      style={{
                        color: error ? "#f44336" : "rgba(0, 0, 0, 0.6)",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {info}
                    </Typography>
                  )}
                </div>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default DropzoneBox;
