import {
  Button,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import React, { useState, useContext, } from "react";
import { LanguageContext } from "../../containers/languageprovider";
import SignUpForm from "../../components/signupForm";
const useStyles = makeStyles((theme) => ({
  DialogTitle: {
    position: "relative",
  },
  CloseButton: {
    position: "absolute",
    right: "4%",
    top: "10px",
    fontSize: "20px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  DialogContent: {
    maxWidth: "434px",
    padding: "40px 30px 25px",
  },
  Title: {
    margin: "0",
    marginBottom: "0.7rem",
    textAlign: "center",
    fontSize: "36px",
  },
  Subtitle: {
    fontSize: "20px",
    textAlign: "justify",
    marginBottom: "1rem",
  },
  Text: {
    marginBottom: "1rem",
    textAlign: "justify",
    fontSize: "15px",
  },
  ButtonContainer: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    marginTop: "1.5rem",
    textAlign: "center",
  },
  SubmitButton: {
    maxHeight: "48px",
    textTransform:"uppercase",
    fontSize:"20px",
    padding: "1rem",
    width: "100%",
    alignSelf: "center",
  },
  NoThanks: {
    marginBottom: "2px",
    fontSize: "14px",
  },
  CloseText: {
    margin: "0",
    color: "#1c55e5",
    cursor: "pointer",
    fontSize: "14px",
    "&:hover": {
      color: "black",
    },
  },
}));

const SearchRequestsStep3 = ({ user, closeDialog, openSignUp }) => {
  const classes = useStyles();
  const { dictionary, userLanguage } = useContext(LanguageContext);

  return (
    <>
      <DialogContent className={classes.DialogContent}>
        <h4 className={classes.Title}>{dictionary.ThankYou}</h4>
        <p className={classes.Subtitle}>
          {dictionary.ReceivedRequest}
        </p>
        <p className={classes.Text}>
          {dictionary.D4reIsProcessing}
        </p>
        {user ? (
          <p className={classes.Text}>
            {dictionary.PersonalAreaMessage1}
            <span style={{ fontWeight: "bold" }}> {dictionary.PersonalAreaMessage2}</span>
            {dictionary.PersonalAreaMessage3}
          </p>
        ) : (
          <p className={classes.Text}>
            {dictionary.IfYouWantToBeUpdated}
          </p>
        )}

        <p className={classes.Text} style={{ marginBottom: "5px" }}>
          {dictionary.ThankYouForYourTrust}
        </p>
        <p className={classes.Text} style={{ margin: "0" }}>
          D4RE
        </p>
        <div className={classes.ButtonContainer}>
          {user ? (
            <Button
              color="primary"
              variant="contained"
              className={classes.SubmitButton}
              onClick={closeDialog}
              style={{marginBottom:"15px"}}
            >
              {dictionary.ContinueBrowsing}
            </Button>
          ) : (
            <>
              <Button
                color="primary"
                variant="contained"
                className={classes.SubmitButton}
                style={{marginBottom:"10px"}}
                onClick={openSignUp}
              >
                {dictionary.YesPlease}
              </Button>
              <span className={classes.NoThanks}> {dictionary.NoThanks}</span>
              <p className={classes.CloseText} onClick={closeDialog}>
                {dictionary.CloseAndContinueBrowsing}
              </p>
            </>
          )}
        </div>
      </DialogContent>
    </>
  );
};

export default SearchRequestsStep3;
