import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import CirclePopper from "../../CirclePopper/CirclePopper";
import {
  updateBuildingArcheticts,
  updateBuildingBasinPeopleSqm,
  updateBuildingBreeam,
  updateBuildingConstruction,
  updateBuildingDeliveryDate,
  updateBuildingDeliveryDateRefurbish,
  updateBuildingEnergyCertificate,
  updateBuildingFloor,
  updateBuildingGrade,
  updateBuildingHeritageBuilding,
  updateBuildingHighwayDistanceKms,
  updateBuildingLeed,
  updateBuildingLoadNumber,
  updateBuildingRefurbishedYear,
  updateBuildingRestrictionsSuperintendence,
  updateBuildingSizeType,
  updateBuildingSmartBuilding,
  updateBuildingTenantsNumber,
  updateBuildingTotalArea,
  updateBuildingUndergroundFloors,
  updateBuildingWalkability,
  updateBuildingWarehouseArchive,
  updateBuildingWell,
  updateBuildingYearOfConstruction,
} from "../../../reducers/BuildingSlice";
import { format } from "date-fns";
import { selftCasting, toggleValue } from "../../utils";
import {
  ENERGY_CERTIFICATES,
  SUSTAINABILITIES,
  SUSTAINABILITIES_BREAM,
  WORKPLACE_WELLNESS,
  CONSTRUCTION_STATUS_ALL,
  CONSTRUCTION_STATUS_SHOW_CONSTRUCTION_DATE,
  FIELDS_TO_NULLIFY,
  TYPE_SIZE_EDIT_BUILDING,
  RENOVATION_GRADES,
} from "../../../config/config";
import FormatNumberTextfield from "../../utils/FormatNumberTextfield";
import YesWithValueDropDown from "../../YesWithValueDropDown/YesWithValueDropDown";

const BuildingInformations = ({
  classes,
  dictionary,
  building,
  dispatch,
  buildingErrors,
  setBuildingErrors,
  changeWarehouseSelector,
}) => {
  // Get today's date in the format yyyy-MM-dd
  const today = format(new Date(), "yyyy-MM-dd");

  const changeTotalAreaHandler = (e) => {
    if (buildingErrors.size) {
      setBuildingErrors((prev) => ({ ...prev, size: false }));
    }

    const payload = selftCasting(e.target);
    const numericPayload = payload.replace(/\D/g, "");
    dispatch(updateBuildingTotalArea(numericPayload));
  };

  const changeSizeTypeHandler = (e) => {
    if (buildingErrors.type_size)
      setBuildingErrors((prev) => ({ ...prev, type_size: false }));
    const payload = selftCasting(e.target);
    dispatch(updateBuildingSizeType(payload));
  };
  const changeBuildingFloorsHandler = (e) => {
    if (buildingErrors.floors)
      setBuildingErrors((prev) => ({ ...prev, floors: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingFloor(payload));
  };
  const changeUndergroundFloorsHandler = (e) => {
    if (buildingErrors.underground_floors)
      setBuildingErrors((prev) => ({ ...prev, underground_floors: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingUndergroundFloors(payload));
  };

  const changeTenantsNumberHandler = (e) => {
    if (buildingErrors.tenants_number)
      setBuildingErrors((prev) => ({ ...prev, tenants_number: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingTenantsNumber(payload));
  };
  const changeBuildingArchetictsHandler = (e) => {
    if (buildingErrors.building_design)
      setBuildingErrors((prev) => ({ ...prev, building_design: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingArcheticts(payload));
  };
  const changeWalkabilityHandler = (e) => {
    if (buildingErrors.walkability)
      setBuildingErrors((prev) => ({ ...prev, walkability: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingWalkability(payload));
  };
  const changeBasinPeopleSqmHandler = (e) => {
    if (buildingErrors.basin)
      setBuildingErrors((prev) => ({ ...prev, basin: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingBasinPeopleSqm(payload));
  };
  const changeHighwayDistanceKmsHandler = (e) => {
    if (buildingErrors.highwaydistance)
      setBuildingErrors((prev) => ({ ...prev, highwaydistance: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingHighwayDistanceKms(payload));
  };
  const changeLoadNumberHandler = (e) => {
    if (buildingErrors.loadpoints)
      setBuildingErrors((prev) => ({ ...prev, loadpoints: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingLoadNumber(payload));
  };
  const changeLeedHandler = (e) => {
    if (buildingErrors.sustainability)
      setBuildingErrors((prev) => ({ ...prev, sustainability: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingLeed(payload));
  };
  const changeBreeamHandler = (e) => {
    if (buildingErrors.sustainability_breeam)
      setBuildingErrors((prev) => ({ ...prev, sustainability_breeam: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingBreeam(payload));
  };
  const changeWellHandler = (e) => {
    if (buildingErrors.workplace_wellness)
      setBuildingErrors((prev) => ({ ...prev, workplace_wellness: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingWell(payload));
  };
  const changeEnergyCertifiateHandler = (e) => {
    if (buildingErrors.energy_performance_certificate)
      setBuildingErrors((prev) => ({
        ...prev,
        energy_performance_certificate: false,
      }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingEnergyCertificate(payload));
  };

  const changeConstructionHandler = (e) => {
    if (buildingErrors.construction_status)
      setBuildingErrors((prev) => ({
        ...prev,
        construction_status: false,
      }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingConstruction(payload));
    const selectedConstructionStatus = e.target.value;

    // FIELDS_TO_NULLIFY is an object that maps construction statuses to fields that need to be nullified

    const fieldsToNull = FIELDS_TO_NULLIFY[selectedConstructionStatus];

    if (fieldsToNull) {
      fieldsToNull.forEach((field) => {
        if (building[field] !== null) {
          if (field === "constructiondate") {
            dispatch(updateBuildingYearOfConstruction(null));
          } else if (field === "delivery_date_construction") {
            dispatch(updateBuildingDeliveryDate(null));
          } else if (field === "refurbishment_year") {
            dispatch(updateBuildingRefurbishedYear(null));
          } else if (field === "delivery_date_refurbish") {
            dispatch(updateBuildingDeliveryDateRefurbish(null));
          }
        }
      });
    }
  };

  const changeYearOfConstructionHandler = (e) => {
    if (buildingErrors.constructiondate)
      setBuildingErrors((prev) => ({
        ...prev,
        constructiondate: false,
      }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingYearOfConstruction(payload));
  };

  const changeDeliveryDateHandler = (e) => {
    if (buildingErrors.delivery_date_construction)
      setBuildingErrors((prev) => ({
        ...prev,
        delivery_date_construction: false,
      }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingDeliveryDate(payload));
  };

  const changeRefurbishedYearHandler = (e) => {
    if (buildingErrors.refurbishment_year)
      setBuildingErrors((prev) => ({
        ...prev,
        refurbishment_year: false,
      }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingRefurbishedYear(payload));
  };

  const changeDeliveryDateRefurbishHandler = (e) => {
    if (buildingErrors.delivery_date_refurbish)
      setBuildingErrors((prev) => ({
        ...prev,
        delivery_date_refurbish: false,
      }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingDeliveryDateRefurbish(payload));
  };

  const changeHeritageBuildingHandler = () => {
    const payload = toggleValue(building.heritage_building);
    dispatch(updateBuildingHeritageBuilding(payload));
  };

  const changeRestrictionsSuperintendenceHandler = () => {
    const payload = toggleValue(building.restrictions_superintendence);
    dispatch(updateBuildingRestrictionsSuperintendence(payload));
  };
  const changeSmartBuildingHandler = () => {
    const payload = toggleValue(building.smart_building);
    dispatch(updateBuildingSmartBuilding(payload));
  };
  const changeGradeHandler = (e) => {
    if (buildingErrors.renovationgrade)
      setBuildingErrors((prev) => ({
        ...prev,
        renovationgrade: false,
      }));

    let payload = selftCasting(e.target);
    payload = payload === "notSpecified" ? null : payload;
    dispatch(updateBuildingGrade(payload));
  };

  const handleInputFocus = (event) => {
    event.target.select(); // Select the content of the input
  };

  //Refs for the input fields
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  //function to cancel the event when we wheel mouse
  const handleWheel = (e) => {
    //cancel the event
    e.preventDefault();
  };

  // Attach/detach the wheel event listener on component mount/unmount
  useEffect(() => {
    inputRefs.forEach((inputRef) => {
      const input = inputRef.current;
      if (input) {
        input.addEventListener("wheel", handleWheel, { passive: false });
      }
    });

    return () => {
      inputRefs.forEach((inputRef) => {
        const input = inputRef.current;
        if (input) {
          input.removeEventListener("wheel", handleWheel);
        }
      });
    };
  }, [changeWarehouseSelector]);

  return (
    <>
      <Grid item xs={12} style={{ marginTop: "2rem" }} id="information-section">
        <Typography className={classes.MenuTitle}>
          {dictionary.BuildingInformations}
        </Typography>
      </Grid>
      <Grid item id="specifics-section">
        <Typography
          className={classes.subtitle}
          style={{ fontWeight: "600", marginBottom: "1.5rem" }}
        >
          {dictionary.Specifics}
        </Typography>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="size-section">
          <FormatNumberTextfield
            value={building.size}
            onChangeHandler={changeTotalAreaHandler}
            error={buildingErrors.size}
            inputRef={inputRefs[0]}
            label={`${dictionary.TotalArea} / sqm*`}
            handleInputFocus={handleInputFocus}
          />
        </Grid>
        <Grid item xs={6} id="type_size-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>{`${dictionary.SizeType}*`}</InputLabel>
            <Select
              value={building.type_size}
              label={`${dictionary.SizeType}*`}
              onChange={changeSizeTypeHandler}
              name="number"
              error={buildingErrors.type_size}
            >
              {Object.entries(TYPE_SIZE_EDIT_BUILDING).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <CirclePopper text={dictionary.GLAInfo} />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="floors-section">
          <TextField
            label={`${dictionary.BuildingFloors}*`}
            value={building.floors}
            fullWidth
            variant="outlined"
            onChange={changeBuildingFloorsHandler}
            type="number"
            inputProps={{ min: 0 }}
            onFocus={handleInputFocus}
            error={buildingErrors.floors}
            inputRef={inputRefs[1]}
          />
        </Grid>
        <Grid item xs={6} id="underground_floors-section">
          <TextField
            label={`${dictionary.UndergroundFloors}`}
            value={building.underground_floors}
            fullWidth
            variant="outlined"
            onChange={changeUndergroundFloorsHandler}
            type="number"
            inputProps={{ min: 0 }}
            onFocus={handleInputFocus}
            error={buildingErrors.underground_floors}
            inputRef={inputRefs[2]}
          />
        </Grid>
      </Grid>
      <YesWithValueDropDown
        dropDownLabel={dictionary.WarehouseArchive}
        textFieldLabel={`${dictionary.SizeArchive} / sqm`}
        sectionId={"archive_warehouse_area-section"}
        dispatch={dispatch}
        dictionary={dictionary}
        field={"archive_warehouse_area"}
        value={building.archive_warehouse_area}
        updateValueHandler={updateBuildingWarehouseArchive}
        error={buildingErrors.archive_warehouse_area}
        setErrors={setBuildingErrors}
        inputRef={inputRefs[3]}
        handleInputFocus={handleInputFocus}
      />
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="tenants_number-section">
          <TextField
            label={dictionary.NoTenants}
            value={building.tenants_number}
            fullWidth
            variant="outlined"
            onChange={changeTenantsNumberHandler}
            type="number"
            inputProps={{ min: 0 }}
            onFocus={handleInputFocus}
            error={buildingErrors.tenants_number}
            inputRef={inputRefs[4]}
          />
        </Grid>
        <Grid item xs={6} id="building_design-section">
          <TextField
            value={building.building_design}
            label={dictionary.BuildingArchitect}
            fullWidth
            variant="outlined"
            onChange={changeBuildingArchetictsHandler}
            error={buildingErrors.building_design}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="walkability-section">
          <TextField
            label={dictionary.Walkability}
            value={building.walkability}
            fullWidth
            variant="outlined"
            type="number"
            onChange={changeWalkabilityHandler}
            inputProps={{ min: 0 }}
            onFocus={handleInputFocus}
            error={buildingErrors.walkability}
            inputRef={inputRefs[5]}
          />
          <CirclePopper text={dictionary.UsefulInfoRetailAssets} />
        </Grid>
        <Grid item xs={6} id="basin-section">
          <TextField
            label={dictionary.basin}
            value={building.basin}
            fullWidth
            variant="outlined"
            onChange={changeBasinPeopleSqmHandler}
            type="text"
            inputProps={{ min: 0 }}
            onFocus={handleInputFocus}
            error={buildingErrors.basin}
            inputRef={inputRefs[6]}
          />
          <CirclePopper text={dictionary.UsefulInfoRetailAssets} />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="highwaydistance-section">
          <TextField
            label={dictionary.highwaydistance}
            value={building.highwaydistance}
            fullWidth
            variant="outlined"
            onChange={changeHighwayDistanceKmsHandler}
            type="number"
            inputProps={{ min: 0 }}
            onFocus={handleInputFocus}
            error={buildingErrors.highwaydistance}
            inputRef={inputRefs[7]}
          />
        </Grid>
        <Grid item xs={6} id="loadpoints-section">
          <TextField
            label={dictionary.loadpoints}
            value={building.loadpoints}
            fullWidth
            variant="outlined"
            onChange={changeLoadNumberHandler}
            type="number"
            inputProps={{ min: 0 }}
            onFocus={handleInputFocus}
            error={buildingErrors.loadpoints}
            inputRef={inputRefs[8]}
          />
        </Grid>
      </Grid>
      <Grid item id="certifications-section">
        <Typography
          className={classes.subtitle}
          style={{ fontWeight: "600", marginBottom: "1.5rem" }}
        >
          {dictionary.certifications}
        </Typography>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="sustainability-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>LEED</InputLabel>
            <Select
              value={building.sustainability}
              label="LEED"
              onChange={changeLeedHandler}
              name="number"
              error={buildingErrors.sustainability}
            >
              {Object.entries(SUSTAINABILITIES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {dictionary[value]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} id="sustainability_breeam-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>Breeam</InputLabel>
            <Select
              value={building.sustainability_breeam}
              label="Breeam"
              onChange={changeBreeamHandler}
              name="number"
              error={buildingErrors.sustainability_breeam}
            >
              {Object.entries(SUSTAINABILITIES_BREAM).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {dictionary[value]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="workplace_wellness-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>Well</InputLabel>
            <Select
              value={building.workplace_wellness}
              label="Well"
              onChange={changeWellHandler}
              name="number"
              error={buildingErrors.workplace_wellness}
            >
              {Object.entries(WORKPLACE_WELLNESS).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {dictionary[value]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} id="energy_performance_certificate-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>{dictionary.energyCertificate}</InputLabel>
            <Select
              value={building.energy_performance_certificate}
              label={dictionary.energyCertificate}
              onChange={changeEnergyCertifiateHandler}
              name="number"
              error={buildingErrors.energy_performance_certificate}
            >
              {Object.entries(ENERGY_CERTIFICATES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {dictionary[value]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item id="status-section">
        <Typography
          className={classes.subtitle}
          style={{ fontWeight: "600", marginBottom: "1.5rem" }}
        >
          {dictionary.buildingStatus}
        </Typography>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="construction_status-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>{dictionary.constructionStatus}*</InputLabel>
            <Select
              value={building.construction_status}
              label={`${dictionary.constructionStatus}*`}
              onChange={changeConstructionHandler}
              error={buildingErrors.construction_status}
            >
              {Object.entries(CONSTRUCTION_STATUS_ALL).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {dictionary[value]}
                </MenuItem>
              ))}
              {/*<MenuItem value={"Existing"}>Existing</MenuItem>*/}
              {/*<MenuItem value={"In construction"}>In construction</MenuItem>*/}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} id="section">
          {Object.keys(CONSTRUCTION_STATUS_SHOW_CONSTRUCTION_DATE).includes(
            building.construction_status
          ) && (
            <TextField
              id="constructiondate-section"
              label={dictionary.YearOfConstruction}
              type="date"
              value={building.constructiondate}
              fullWidth
              variant="outlined"
              onChange={changeYearOfConstructionHandler}
              inputProps={{ max: today }} // Specify the maximum allowed date
              InputLabelProps={{
                shrink: true,
              }}
              error={buildingErrors.constructiondate}
            />
          )}
          {building.construction_status === "5" && (
            <TextField
              id="delivery_date_construction-section"
              label={dictionary.DeliveryDate}
              type="date"
              value={building.delivery_date_construction}
              fullWidth
              variant="outlined"
              onChange={changeDeliveryDateHandler}
              inputProps={{ min: today }} // Specify the minimum allowed date
              InputLabelProps={{
                shrink: true,
              }}
              error={buildingErrors.delivery_date_construction}
            />
          )}
          {building.construction_status === "2" && (
            <TextField
              style={{ marginTop: "1rem" }}
              id="refurbishment_year-section"
              label={dictionary.refurbishmentYear}
              type="date"
              value={building.refurbishment_year}
              fullWidth
              variant="outlined"
              onChange={changeRefurbishedYearHandler}
              inputProps={{ max: today }} // Specify the maximum allowed date
              InputLabelProps={{
                shrink: true,
              }}
              error={buildingErrors.refurbishment_year}
            />
          )}
          {building.construction_status === "4" && (
            <TextField
              style={{ marginTop: "1rem" }}
              id="delivery_date_refurbish-section"
              label={dictionary.deliveryDateRefurbish}
              type="date"
              value={building.delivery_date_refurbish}
              fullWidth
              variant="outlined"
              onChange={changeDeliveryDateRefurbishHandler}
              inputProps={{ min: today }} // Specify the minimum allowed date
              InputLabelProps={{
                shrink: true,
              }}
              error={buildingErrors.delivery_date_refurbish}
            />
          )}
        </Grid>
      </Grid>
      {/*<Grid item container spacing={3} style={{ marginTop: "1rem" }}>*/}
      {/*  <Grid item xs={6}>*/}
      {/*    <FormControl fullWidth variant="outlined">*/}
      {/*      <InputLabel>Refurbished</InputLabel>*/}
      {/*      <Select*/}
      {/*        value={building.renovation_status}*/}
      {/*        label="Refurbished"*/}
      {/*        onChange={changeRefurbishedHandler}*/}
      {/*      >*/}
      {/*        <MenuItem value={"Refurbished"}>Refurbished</MenuItem>*/}
      {/*        <MenuItem value={"To be refurbished"}>To be refurbished</MenuItem>*/}
      {/*        <MenuItem value={"In refurbished"}>In refurbished</MenuItem>*/}
      {/*        <MenuItem value={"Not specified"}>Not specified</MenuItem>*/}
      {/*      </Select>*/}
      {/*    </FormControl>*/}
      {/*  </Grid>*/}
      {/*  <Grid item xs={6}>*/}
      {/*    {building.renovation_status === "Refurbished" && (*/}
      {/*      <TextField*/}
      {/*        id="date"*/}
      {/*        label="Refurbished year"*/}
      {/*        type="date"*/}
      {/*        value={building.refurbishment_year }*/}
      {/*        fullWidth*/}
      {/*        variant="outlined"*/}
      {/*        onChange={changeRefurbishedYearHandler}*/}
      {/*        inputProps={{ max: today }} // Specify the maximum allowed date*/}
      {/*      />*/}
      {/*    )}*/}
      {/*    {building.renovation_status === "In refurbished" && (*/}
      {/*      <TextField*/}
      {/*        id="date"*/}
      {/*        label="Delivery date refubrish"*/}
      {/*        type="date"*/}
      {/*        value={building.delivery_date_refurbish }*/}
      {/*        fullWidth*/}
      {/*        variant="outlined"*/}
      {/*        onChange={changeDeliveryDateRefurbishHandler}*/}
      {/*        inputProps={{ min: today }} // Specify the minimum allowed date*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="smart_building-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.heritage_building)}
                onChange={changeHeritageBuildingHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.heritage_building ? "red" : ""}`,
                }}
              >
                {dictionary.heritageBuilding}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="restrictions_superintendence-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.restrictions_superintendence)}
                onChange={changeRestrictionsSuperintendenceHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${
                    buildingErrors.restrictions_superintendence ? "red" : ""
                  }`,
                }}
              >
                {dictionary.restrictionsSuperintendence}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="smart_building-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(building.smart_building)}
                onChange={changeSmartBuildingHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${buildingErrors.smart_building ? "red" : ""}`,
                }}
              >
                {dictionary.smartBuilding}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="renovationgrade-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>Grade</InputLabel>
            <Select
              value={
                building.renovationgrade
                  ? building.renovationgrade
                  : "notSpecified"
              }
              label="Grade"
              onChange={changeGradeHandler}
              name="number"
              error={buildingErrors.renovationgrade}
            >
              {Object.entries(RENOVATION_GRADES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {dictionary[value]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default BuildingInformations;
