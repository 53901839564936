import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
const Iubenda = () => {
    if(window.location.protocol.toLowerCase() == 'http:'){
        return "";
    }
    return (
        <Helmet>
            <script
              type="text/javascript"
              src="https://cdn.iubenda.com/cs/ccpa/stub.js"
            ></script>
            <script
              type="text/javascript"
              src="https://cdn.iubenda.com/cs/iubenda_cs.js"
              charset="UTF-8"
              async
            ></script>
        </Helmet>
    );
};
export default Iubenda;


