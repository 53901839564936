import React, { useContext, useState, useEffect } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  makeStyles,
  Button,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { LanguageContext } from "../../../containers/languageprovider";
import { DecorateUnit, routeReplace } from "../../utils";
import {
  API_ALL_BUILDING_UNITS_V2,
  API_DELETE_UNIT_V2,
  API_DUPLICATE_UNIT_V2,
  API_PROPOSE_NEW_BUILDING_V2,
  API_URL_NEW_BUILDING_V2, SUPER_ADMIN,
    API_APPROVE_NEW_BUILDING_V2,
} from "../../../config/config";
import axios from "axios";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";

import globalStyles from "../../globalStyles";
import { Helmet } from "react-helmet";
import Footer from "../../Footer";
import { Container, CssBaseline, Grid, Hidden } from "@material-ui/core";
import DrawerDashBoard from "../../DrawerDashBoard";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import DeletePopUp from "../../DeletePopUp/DeletePopUp";
import Auth from "../../../auth/auth";
import { resetUnitSlice } from "../../../reducers/UnitSlice";

const columns = ["Floor", "TotalArea", "DealType", "AssetClass"];

const useStyles = makeStyles((theme) => ({
  ButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  ContainedButton: {
    padding: "0.5rem 1.5rem",
    fontSize: "15px",
    marginTop: "1rem",
    marginRight: "10px",
  },
  AddUnitTextButton: {
    fontSize: "12px",
    textDecoration: "underline",
    marginBottom: "2rem",
  },
}));

function MenuPopupState({
  editUnitHandler,
  duplicateUnitHandler,
  showDeleteUnitPopUp,
}) {
  const { dictionary, userLanguage } = useContext(LanguageContext);
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <MoreVertIcon
            {...bindTrigger(popupState)}
            style={{ cursor: "pointer" }}
          />

          <Menu {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                editUnitHandler();
                popupState.close(); // Close the menu when Edit is clicked
              }}
            >
              <Grid item container style={{ padding: "0.3rem" }}>
                <Grid item style={{ marginRight: "1rem" }}>
                  <EditIcon fontSize="small" />
                </Grid>
                <Grid item>{dictionary.edit}</Grid>
              </Grid>
            </MenuItem>
            <MenuItem
              onClick={() => {
                duplicateUnitHandler();
                popupState.close(); // Close the menu when Duplicate is clicked
              }}
            >
              <Grid item container style={{ padding: "0.3rem" }}>
                <Grid item style={{ marginRight: "1rem" }}>
                  <FileCopyIcon fontSize="small" />
                </Grid>
                <Grid item>{dictionary.Duplicate}</Grid>
              </Grid>
            </MenuItem>
            <MenuItem
              onClick={() => {
                showDeleteUnitPopUp();
                popupState.close(); // Close the menu when Delete is clicked
              }}
            >
              <Grid item container style={{ padding: "0.3rem" }}>
                <Grid item style={{ marginRight: "1rem" }}>
                  <DeleteIcon fontSize="small" />
                </Grid>
                <Grid item>{dictionary.delete}</Grid>
              </Grid>
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}

const ManageUnits = () => {
  const history = useHistory();
  const { buildingId } = useParams();
  const { dictionary, userLanguage } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const isAdmin = Auth.getUser().role === SUPER_ADMIN

  const globalClasses = globalStyles();

  const classes = useStyles();

  //units list
  const [unitList, setUnitList] = useState([]);

  //open delete unit popUp
  const [openDeleteUnitPopUp, setOpenDeleteUnitPopUp] = useState(false);

  // id of the unit to be deleted
  const [idUnitToDelete, setIdUnitToDelete] = useState(-1);

  // show and hide the progress icon
  const [deleteInProcess, setDeleteInProcess] = useState(false);

  const createUnit = () => {
    axios
      .get(routeReplace(API_URL_NEW_BUILDING_V2, { buildingId: buildingId }))
      .then((payload) => {
        const unit = payload.data.record;
        history.push(`/buildings/edit/${buildingId}/units/${unit.id}`);
        //reset the unit form
        dispatch(resetUnitSlice());
      })
      .catch((err) => {
        //handling the error
      });
  };

  //fetch the units list when we load the page
  useEffect(() => {
    axios
      .get(routeReplace(API_ALL_BUILDING_UNITS_V2, { buildingId: buildingId }))
      .then((payload) => {
        //setting te units list
        const units = payload.data.all;
        units.forEach((unit) => DecorateUnit(unit));
        setUnitList(units);
      });
  }, [buildingId]);


  const editUnitHandler = (index) => {
    //redirect the user to where he can see the form to update the selected unit
    history.push(`/buildings/edit/${buildingId}/units/${index}`);
  };

  const duplicateUnitHandler = (index) => {
    //call the api for duplicating the unit
    const token = Auth.getToken();
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    axios
      .get(routeReplace(API_DUPLICATE_UNIT_V2, { unitId: index }))
      .then((payload) => {
        //refresh units
        const duplicatedUnitId = payload.data.record.id;
        refreshUnits();
        history.push(`/buildings/edit/${buildingId}/units/${duplicatedUnitId}`);
      });
    //refresh the list of units by calling the get API
  };

  const showDeleteUnitPopUp = (index) => {
    //show the delete unit popUp with confirm and cancel buttons
    setOpenDeleteUnitPopUp(true);
    //we will save the index (unit id ) in the state so we can use it once the user click on confirm the delete
    setIdUnitToDelete(index);
  };

  const hideDeleteUnitPopUp = () => {
    setOpenDeleteUnitPopUp(false);
  };

  const refreshUnits = () => {
    axios
      .get(routeReplace(API_ALL_BUILDING_UNITS_V2, { buildingId: buildingId }))
      .then((payload) => {
        const units = payload.data.all;
        units.forEach((unit) => DecorateUnit(unit));
        //setting te units list
        setUnitList(units);
      });
  };

  const deleteUnitHandler = () => {
    const token = Auth.getToken();
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    // we show the process Icon
    setDeleteInProcess(true);
    axios
      .delete(routeReplace(API_DELETE_UNIT_V2, { unitId: idUnitToDelete }))
      .then((payload) => {
        //close the popUp
        setOpenDeleteUnitPopUp(false);
        //refresh units
        refreshUnits();
        //unit deleted so processing is finished
        setDeleteInProcess(false);
      })
      .catch((err) => setDeleteInProcess(false));
  };

  //buttons logic

  const backToPortfolioClickHandler = () => {
    history.push(`/portfolio`);
  };

  const editBuildingClickHanlder = () => {
    history.push(`/buildings/edit/${buildingId}`);
  };

  const saveAndSendClickHanler = () => {
    //api logic then go to thank you page
    axios
      .get(
        routeReplace(API_PROPOSE_NEW_BUILDING_V2, { buildingId: buildingId })
      )
      .then((payload) => {
        history.push(`/buildings/thankyou/${buildingId}`);
      })
      .catch((err) => console.log(err));
  };


  const approveHandler = () => {
    //api logic then go to thank you page
    axios
        .get(
            routeReplace(API_APPROVE_NEW_BUILDING_V2, { buildingId: buildingId })
        )
        .then((payload) => {
          history.push(`/buildings/thankyou/${buildingId}`);
        })
        .catch((err) => console.log(err));
  };

  return (
    <main className={globalClasses.maincontent}>
      <Helmet>
        <title>{dictionary.EditBuilding}</title>
      </Helmet>
      <CssBaseline />
      <Container maxWidth={false} style={{minHeight: "70vh"}}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={12} className={globalClasses.ContainerContent}>
            <Grid
              container
              style={{ marginTop: "2rem" }}
              justifyContent="center"
            >
              <Grid item sm={12} md={8}>
                {/*Sections */}
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell key={column}>
                            {dictionary[column]}
                          </TableCell>
                        ))}
                        <TableCell>
                          <AddCircleIcon
                            color="primary"
                            fontSize="large"
                            onClick={createUnit}
                            style={{ cursor: "pointer" }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {unitList.length === 0 && (
                        <TableRow>
                          <TableCell
                            colSpan={columns.length + 1}
                            align="center"
                          >
                            {dictionary.NoUnits}
                          </TableCell>
                        </TableRow>
                      )}
                      {unitList.length > 0 &&
                        unitList.map((unit, id) => (
                          <TableRow key={unit.id}>
                            <TableCell>
                              {unit.info.getFloorLabel(userLanguage)}
                            </TableCell>
                            <TableCell>{unit.size}</TableCell>
                            <TableCell>
                              {unit.info.getDealTypeLabel(userLanguage)}
                            </TableCell>
                            <TableCell>
                              {unit.info.getAssetClassLabel(userLanguage)}
                            </TableCell>
                            <TableCell>
                              <MenuPopupState
                                editUnitHandler={() => editUnitHandler(unit.id)}
                                showDeleteUnitPopUp={() =>
                                  showDeleteUnitPopUp(unit.id)
                                }
                                duplicateUnitHandler={() =>
                                  duplicateUnitHandler(unit.id)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12}>
                <div className={classes.ButtonContainer}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="medium"
                    className={classes.ContainedButton}
                    onClick={backToPortfolioClickHandler}
                  >
                    {dictionary.BackToPortfolio}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="medium"
                    className={classes.ContainedButton}
                    onClick={editBuildingClickHanlder}
                  >
                    {dictionary.EditBuilding}
                  </Button>
                  {!isAdmin && unitList.length > 0 && (
                    <Button
                      color="primary"
                      variant="contained"
                      size="medium"
                      className={classes.ContainedButton}
                      onClick={saveAndSendClickHanler}
                    >
                      {dictionary.SaveAndSend}
                    </Button>
                  )}
                  {isAdmin && unitList.length > 0 && (
                      <Button
                          color="primary"
                          variant="contained"
                          size="medium"
                          className={classes.ContainedButton}
                          onClick={approveHandler}
                      >
                        {dictionary.BuildingApprove}
                      </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.ButtonContainer}>
                  <Button
                    color="secondary"
                    variant="text"
                    size="small"
                    className={classes.AddUnitTextButton}
                    onClick={createUnit}
                  >
                    {dictionary.AddAnOtherUnit}
                  </Button>
                </div>
              </Grid>
              <DeletePopUp
                deleteInProcess={deleteInProcess}
                openDeletePopUp={openDeleteUnitPopUp}
                hideDeleteConfirmationPopUp={hideDeleteUnitPopUp}
                handleDelete={deleteUnitHandler}
                title={dictionary.DeleteUnitConfirmationTitle}
                body={dictionary.DeleteUnitConfirmationText}
              />

              {/*      <Hidden smDown>
                  <Grid item xs={3}>
                    <UnitStickyMenu dictionary={dictionary} />
                  </Grid>
                </Hidden> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </main>
  );
};

export default ManageUnits;
