import { Container, Grid, Hidden } from "@material-ui/core";
import React, {useRef} from "react";
import DrawerDashBoard from "../../DrawerDashBoard";
import Footer from "../../Footer";
import globalStyles from "../../globalStyles";
import AssignedResearches from "./AssignedResearches/AssignedResearches";
import Auth from "../../../auth/auth";
import "../../../css/SearcherArea.css"

const Offerors = () => {
  const globalClasses = globalStyles();
  const footerRef = useRef();

  return (
    <main className={globalClasses.maincontent}>
      <Container maxWidth={false}>
        <Grid container justifyContent="space-between" spacing={3} wrap="nowrap">
          <Hidden smDown implementation="css">
          <div style={{height:"100%", minWidth: "230px", position: "relative"}}>
            <Grid item md={2} xs={12}>
              <DrawerDashBoard
                  footerRef={footerRef}
                sel="offerors"
              />
            </Grid>
          </div>
          </Hidden>

          <Grid
            item
            md={10}
            xs={12}
            className={globalClasses.ContainerContent}
          >
            <div className={"minHeight100"}>
              <AssignedResearches />
            </div>
          </Grid>
        </Grid>
      </Container>
      <div ref={footerRef}>
      <Footer />
      </div>
    </main>
  );
};

export default Offerors;
