import React, { Fragment} from "react";
import Typography from "@material-ui/core/Typography";
import {
    Grid,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import {returnStringTranslation} from "../../printLanguageSelect";
import {LanguageContext} from "../../../containers/languageprovider";
import DeleteIcon from "@material-ui/icons/Delete";
import DirectionsTransitIcon from "@material-ui/icons/DirectionsTransit";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import AirplanemodeActiveIcon from "@material-ui/icons/AirplanemodeActive";


const useStyles = makeStyles((theme) => ({

    NearbyInfoTitle: {
        fontFamily: "Barlow",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "21px",
        lineHeight: "24px",
        color: "#000000",
        paddingTop: "40px",
        paddingBottom: "20px",
    },
    NearbyPoint: {
        fontFamily: "Barlow",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        fontVariant: "all-small-caps",
        color: "#6a6a6a",
        // color: "#1C55E5",
        paddingTop: "40px",
    },
    IconNearbyPoint: {
        height: 19,
        width: 18,
        marginRight: 10,
    },
    interestsContaner: {
        borderBottom: "1px solid #d9d9d9",
        padding: "1rem",
    },
    interestItemContainer: {
        display: "flex",
        padding: "0.5rem 1rem",
    },
    interestTitle: {
        marginLeft: "10px",
        textTransform: "uppercase",
    },
    interestItem: {
        display: "flex",
        justifyContent: "flex-end",
    },


}));


export default function NewAmenities(props) {
    const {item, amenitiesCount, id, onDelete} = props;
    const classes = useStyles();
    const { dictionary, userLanguage } = React.useContext(LanguageContext);

    return (
        <Fragment>
            {item.list.length > 0 &&
            <Grid item container className={classes.interestsContaner}>
            <Grid item xs={12} className={classes.interestItemContainer}>
                <Grid item>
                    <img
                        className={
                            classes.IconNearbyPoint
                        }
                        src={item.icon}
                        alt={item.type}
                    />
                </Grid>
                {/*<AirplanemodeActiveIcon />*/}
                <Typography
                    className={classes.interestTitle}
                    style={{ fontWeight: "500" }}
                >
                    {returnStringTranslation(
                        item.description,
                        userLanguage
                    )}
                </Typography>
            </Grid>
            {item.list.map((element) => {
                return (<Fragment>
                    <Grid item container className={classes.interestItemContainer}>
                        <Grid item xs={6}>
                            <Typography>{element.name}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.interestItem}>
                            {element.mode === "walking" &&
                                element.duration_text}
                        </Grid>
                        <Grid item xs={2} className={classes.interestItem}>
                            {element.distance_text}
                        </Grid>
                        <Grid item xs={2} className={classes.interestItem}>
                            <DeleteIcon onClick={()=>{onDelete(element.name, id)}} />
                        </Grid>
                    </Grid>
                    </Fragment>
                );
            })}
            </Grid>}
        </Fragment>


    )






}