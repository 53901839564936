import React, { Fragment, useCallback, useEffect, useState } from "react";
import DropzoneBox from "../../../broker-views/DropzoneBox";
import {
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import axios from "axios";
import Auth from "../../../../auth/auth";
import { Carousel } from "react-responsive-carousel";
import { LeftIcon, RightIcon } from "../../../iconlist";
import ImageCard from "../../../broker-views/ImageCard";
import authMethods from "../../../../auth/auth";
import { selftCasting, routeReplace } from "../../../utils";
import MultiLanguageTextField from "../../../Buildings/Sections/MultiLanguageItems/MultiLanguageTextField";
import WarningPopUp from "../../../WarningPopUp/WarningPopUp";
import DeletePopUp from "../../../DeletePopUp/DeletePopUp";
import MultiLanguageUnitAttachments from "../MultiLanguageUnitAttachements";
import {
  updateUnitImages,
  updateUnitLinkVideo,
  updateUnitLinkVirualTour,
  updateUnitTextDescription,
} from "../../../../reducers/UnitSlice";
import {
    API_UNIT_IMAGES_DELETE_V2,
    API_UNIT_IMAGES_REORDER_V2,
    API_URL_ADD_UNIT_IMAGES_V2,
    API_URL_GET_LIST_IMAGES_V2,
    API_URL_UNITIMAGES,
} from "../../../../config/config";

const UnitDescription = ({
  classes,
  dictionary,
  unit,
  dispatch,
  unitErrors,
  setUnitErrors,
  unitId,
}) => {
  const theme = useTheme();

  const MobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // building images
  const [lastImage, setLastImage] = useState(1);
  const [openDeleteImagePopUp, setOpenDeleteImagePopUp] = useState(false);
  const [indexImage, setIndexImage] = useState(-1);

  const smBreakpoint = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  //warningPopUp'
  const [openWarningPopUp, setopenWarningPopUp] = useState(false);

  //body text
  const [bodyText, setBodyText] = useState("");

  // show and hide the progress icon
  const [deleteInProcess, setDeleteInProcess] = useState(false);

  const onDropImages = useCallback(
    (acceptedFiles) => {
      // Cancel the images error
      if (unitErrors.images)
        setUnitErrors((prev) => ({ ...prev, images: false }));

      // Check if the total loaded images exceed 3
      const totalUploadedImages = lastImage + acceptedFiles.length;

      console.log("lastImage : ", lastImage);
      console.log("acceptedFiles.length : ", acceptedFiles.length);

      // if (totalUploadedImages > 3) {
      //   setBodyText("Total loaded images cannot exceed 3.");
      //   setopenWarningPopUp(true); // Show error message
      //   return; // Do not proceed with API calls
      // }

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onload = function (e) {
          const token = Auth.getToken();
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          axios
            .post(routeReplace(API_URL_ADD_UNIT_IMAGES_V2, {unitId:unitId}), {
              image: e.target.result,
              description: null,
              order: lastImage + 1,
            })
            .then(() => {
              refreshImages();
            })
            .catch((error) => {
              if (error.response?.status === 413) {
                setBodyText(dictionary.imageUploadError);
              } else {
                setBodyText("An error occurred while uploading images.");
              }
              setopenWarningPopUp(true); // Show error message
            });
        };
        reader.readAsDataURL(file);
      });
    },
    [unitId, lastImage]
  );

  const reorderImages = async (orderChangeArray) => {
    const token = authMethods.getToken();
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    try {
      const res = await axios.post(
        routeReplace(API_UNIT_IMAGES_REORDER_V2, {unitId: unitId}),
        {
          order: orderChangeArray,
        }
      );
      if (res.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const moveImage = async (id, dir) => {
    console.log("move images");
    const dest = [];
    let orderId = id + 1;
    const nx = id + dir;
    const nxOrder = nx + 1;
    if (!unit.images[id] || !unit.images[nx]) {
      return;
    }

    const success = await reorderImages([
      {
        photo_id: unit.images[id].id,
        order_id: orderId + dir,
      },
      {
        photo_id: unit.images[nx].id,
        order_id: nxOrder - dir,
      },
    ]);

    if (success) {
      unit.images.forEach(function (e, i) {
        if (i == id) {
          dest.push(unit.images[nx]);
        } else if (i == nx) {
          dest.push(unit.images[id]);
        } else {
          dest.push(unit.images[i]);
        }
      });
      dispatch(updateUnitImages(dest));
    }
  };

  const refreshImages = () => {
    console.log("refresh the images !");
    const url = routeReplace(API_URL_GET_LIST_IMAGES_V2, {unitId:unitId});
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + Auth.getToken(),
          "Content-Type": "application/json",
        },
        params: {
          filter: "edit",
        },
      })
      .then((payload) => {
        dispatch(updateUnitImages(payload.data.all));

        setLastImage(payload.data.all.length);
      });
  };

  const handleDeleteImage = () => {
    const token = Auth.getToken();
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .delete(routeReplace(API_UNIT_IMAGES_DELETE_V2,{unitId:unit.images[indexImage].unit_id,imageId:unit.images[indexImage].id}))
      .then((payload) => {
        //refresh docs after deleting 1 doc.
        refreshImages();
        hideDeleteImageConfirmationPopUp();
        setIndexImage(-1);
      });
  };

  // Get the images and the docs when we load the page

  useEffect(() => {
    const url = routeReplace(API_URL_GET_LIST_IMAGES_V2,{unitId: unitId});
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + Auth.getToken(),
          "Content-Type": "application/json",
        },
        params: {
          filter: "edit",
        },
      })
      .then((payload) => {
        console.log("payload.data.all : ", payload.data.all);
        dispatch(updateUnitImages(payload.data.all));

        setLastImage(payload.data.all.length);
      });
  }, [unitId]);

  //show and hide the delete image warning popup

  const showDeleteImageConfirmationPopUp = (index) => {
    setIndexImage(index);
    setOpenDeleteImagePopUp(true);
  };

  const hideDeleteImageConfirmationPopUp = () => {
    setOpenDeleteImagePopUp(false);
  };

  //redux functions
  const changeLinkVirualTourHandler = (e) => {
    const payload = selftCasting(e.target);
    dispatch(updateUnitLinkVirualTour(payload));
  };

  const changeLinkVideoHandler = (e) => {
    const payload = selftCasting(e.target);
    dispatch(updateUnitLinkVideo(payload));
  };

  const changeTextDescriptionHandler = (e, language) => {
    //cancel the description error
    if (unitErrors.description)
      setUnitErrors((prev) => ({ ...prev, description: false }));

    const payload = {
      language: language,
      textDescription: selftCasting(e.target),
    };
    dispatch(updateUnitTextDescription(payload));
  };

  return (
    <>
      <Grid item xs={12} style={{ marginTop: "2rem" }} id="add-units-section">
        <Typography className={classes.MenuTitle}>
          {dictionary.UnitDescription}
        </Typography>
      </Grid>
      <Grid item id="unit-description-section">
        <Typography className={classes.subtitle} style={{ fontWeight: "600" }}>
          {dictionary.TextDescriptions}
        </Typography>
      </Grid>
        <Grid item id="text-section">
      <MultiLanguageTextField
        label={"description"}
        placeholder={dictionary.buildingDescriptionPlaceholder}
        textValue={unit.description}
        changeTextHandler={changeTextDescriptionHandler}
        popperText={dictionary.BuildingNameInfo}
        maxText={"1500"}
        minText={"5"}
        type={"HTML"}
        error={unitErrors.description}
      />
        </Grid>

      <Grid item id="unit-visual-section">
        <Typography
          className={classes.subtitle}
          style={{ fontWeight: "600", marginBottom: "0.5rem" }}
        >
          {dictionary.VisualContents}
        </Typography>
      </Grid>
      {/* Drag and drop section*/}
      <Grid
        item
        container
        xs={12}
        className={classes.SectionContainer + " " + classes.GridSpacingSM}
        spacing={smBreakpoint ? 0 : 4}
        id="unit-attachments-section"
      >
        <DropzoneBox
          info={dictionary.UnitImageInfo}
          onDrop={onDropImages}
          accept={".jpg, .jpeg, .png, .gif"}
          dictionary={dictionary}
          style={{ marginBottom: "0.5rem" }}
          error={unitErrors.images}
        />
        <Fragment>
          {unit.images.length > 0 && (
            <Grid item xs={12} container>
              <Grid item xs={12} style={{ padding: "16px 0px", width: 1 }}>
                <Carousel
                  centerMode={true}
                  centerSlidePercentage={MobileScreen ? 100 : 50}
                  showIndicators={false}
                  showThumbs={false}
                  autoPlay={false}
                  swipeable={false}
                  dynamicHeight={false}
                  showStatus={false}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <div
                        className={classes.ArrowsCarousel}
                        onClick={onClickHandler}
                        style={{ left: 15 }}
                      >
                        <LeftIcon
                          className={classes.IconArrow}
                          viewBox={"0 0 24 24"}
                          fill={"#ffffff"}
                          style={{ opacity: "0.8" }}
                        />
                        {/*   <img src="/images/ico/LeftArrowCarousel.svg" /> */}
                      </div>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <div
                        className={classes.ArrowsCarousel}
                        onClick={onClickHandler}
                        style={{ right: 15 }}
                      >
                        <RightIcon
                          className={classes.IconArrow}
                          viewBox={"0 0 60 60"}
                          fill={"#ffffff"}
                          style={{ opacity: "0.8" }}
                        />
                        {/*  <img src="/images/ico/RightArrowCarousel.svg" /> */}
                      </div>
                    )
                  }
                >
                  {unit.images.map((image, i) => (
                    <div key={i} className={classes.SliderCardContainer}>
                      {image.image && (
                        <ImageCard
                          move={moveImage}
                          mobile={true}
                          dictionary={dictionary}
                          imageSource={image.image}
                          loading={false}
                          isPrimary={true}
                          index={i}
                          onDelete={() => showDeleteImageConfirmationPopUp(i)}
                          fullheigth={false}
                          isBuildingImage={true}
                        />
                      )}
                      {!image.image && image.loading && (
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            height: "225px",
                            border: "1px solid #6a6a6a22",
                          }}
                        >
                          <CircularProgress />
                        </Grid>
                      )}
                    </div>
                  ))}
                </Carousel>
              </Grid>
            </Grid>
          )}
        </Fragment>
      </Grid>
      <Grid item className={classes.typeLinkVirtual}>
        <TextField
          placeholder={dictionary.TypeLinkVirtual}
          fullWidth
          variant="outlined"
          value={unit.virtual_tour}
          onChange={(e) => changeLinkVirualTourHandler(e)}
          error={unitErrors.virtual_tour}
        />
      </Grid>
      <Grid item>
        <TextField
          id="LinkVideoHelper-section"
          placeholder={dictionary.TypeLinkVideo}
          fullWidth
          variant="outlined"
          helperText={dictionary.LinkVideoHelper}
          value={unit.video}
          onChange={(e) => changeLinkVideoHandler(e)}
          error={unitErrors.LinkVideoHelper}
        />
      </Grid>

      <MultiLanguageUnitAttachments
        unit={unit}
        unitId={unitId}
        classes={classes}
        dictionary={dictionary}
        dispatch={dispatch}
        setBodyText={setBodyText}
        setopenWarningPopUp={setopenWarningPopUp}
      />

      <WarningPopUp
        openWarningPopUp={openWarningPopUp}
        hideWarningPopUp={() => setopenWarningPopUp(false)}
        body={bodyText}
      />
      <DeletePopUp
        deleteInProcess={deleteInProcess}
        openDeletePopUp={openDeleteImagePopUp}
        hideDeleteConfirmationPopUp={hideDeleteImageConfirmationPopUp}
        handleDelete={handleDeleteImage}
        title={dictionary.DeleteImageConfirmationTitle}
        body={dictionary.DeleteImageConfirmationText}
      />
    </>
  );
};

export default UnitDescription;
