import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import { LanguageContext } from "../containers/languageprovider";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import { useTheme, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import Rating from "@material-ui/lab/Rating";
import axios from "axios";
import Auth from "../auth/auth";
import globalStyles from "./globalStyles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  siteLogo: {
    marginBottom: theme.spacing(2),
  },

  dialogActions: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    justifyContent: "center",
  },
  DialogSearch: {
    minWidth: "400px",
    //paddingLeft:"80px",
    //paddingRight:"80px"
    //width:"400px"
  },

  SearchInfoAutoComplete: {
    marginTop: "20px",
    width: "240px",
  },

  DialogTitle: {
    paddingTop: "30px",
    textAlign: "center",
  },
  SearchBtn: {
    width: "240px",
    height: "46px",
    marginTop: "80px",
  },
  DialogContentClass: {
    margin: "auto",
    minWidth: "372px",
  },
  RateDiv: {
    width: "100%",
    textAlign: "center",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  SubTitle: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "23px",
    display: "flex",
    alignItems: "center",
    color: "#6A6A6A",
  },
}));

const CustomDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export default function LikeDialog(props) {
  const classes = useStyles();
  const user = Auth.getUser();
  const { dictionary } = React.useContext(LanguageContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const globalClasses = globalStyles();

  const { open, setOpen } = props;

  const [ratevalue, setRateValue] = React.useState(0);
  const [email, setEmail] = React.useState(user ? user.email : "");
  const [comment, setComment] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeComment = (event) => {
    setComment(event.target.value);
  };

  const handleOpen = (title, msg) => {
    setAlertSeverity(title);
    setAlertMsg(msg);
    setOpenAlert(true);
  };

  const SendComment = () => {
    if (ratevalue === 0) {
      setLoading(false);
      handleOpen("warning", dictionary.LeaveArating);
      return;
    }

    if (email === "") {
      setLoading(false);
      handleOpen("warning", dictionary.EmailRequired);
      return;
    }

    try {
      /*  hbspt.forms.create({
	region: "na1",
	portalId: "20219665",
	formId: "89d641ef-763d-4757-b8b7-c9a512f927fd"*/

      axios
        .post(
          "https://api.hsforms.com/submissions/v3/integration/submit/20219665/89d641ef-763d-4757-b8b7-c9a512f927fd",
          {
            fields: [
              {
                name: "email",
                value: email,
              },
              {
                name: "rate",
                value: ratevalue,
              },
              {
                name: "message",
                value: comment,
              },
            ],
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((payload) => {
          setLoading(false);
          handleOpen("success", dictionary.ThankYouLeaveMessage);
          handleClose();
        })
        .catch((error) => {
          setLoading(false);
          //console.log(error);
          handleOpen("error", dictionary.FieldIncomplete);
        });
    } catch (e) {
      setLoading(false);
      handleOpen("error", dictionary.FieldIncomplete);
    }
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <CustomDialogTitle onClose={handleClose} />

        <DialogContent className={classes.DialogContentClass}>
          <Typography className={classes.DialogTitle} variant="h6">
            {dictionary.LikeThisPage}
          </Typography>
          <div className={classes.RateDiv}>
            <Rating
              size="large"
              name="simple-controlled"
              value={ratevalue}
              onChange={(event, newValue) => {
                setRateValue(newValue);
              }}
            />
          </div>
          <Typography className={classes.SubTitle}>
            {dictionary.LeaveAcomment}
          </Typography>
          <div className={classes.RateDiv}>
            <TextField
              multiline
              fullWidth={true}
              rows={4}
              defaultValue={comment}
              onChange={handleChangeComment}
              variant="outlined"
            />
          </div>
          <div className={classes.RateDiv}>
            <TextField
              label={dictionary.Emailaddress}
              fullWidth={true}
              defaultValue={email}
              onChange={handleChangeEmail}
              variant="outlined"
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            autoFocus
            onClick={handleClose}
            color="primary"
            variant="outlined"
            className={globalClasses.buttonDialog}
          >
            {dictionary.cancelTooltip}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={globalClasses.buttonDialog}
            onClick={SendComment}
            disabled={loading}
          >
            {dictionary.SendRequest}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alertseverity}>
          {alertmsg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
