import React, { useEffect, useState, useContext } from "react";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Grid, Tab, Tabs, TextField, Typography } from "@material-ui/core";
import {Flag, normalizeMl, removeHTMLTags} from "../../../utils";
import { dictionaryList, languageOptions } from "../../../languages";
import CirclePopper from "../../../CirclePopper/CirclePopper";
import RichTextEditor from "../../../utils/RichTextEditor";
import {LanguageContext} from "../../../../containers/languageprovider";

const MultiLanguageTextField = ({
  sectionId,
  label,
  placeholder,
  textValue,
  changeTextHandler,
  popperText,
  maxText,
  minText,
  type,
  error,
}) => {
  const [multiLanguageTextTab, setmultiLanguageTextTab] = useState("gb");
  const [showIcon, setShowIcon] = useState(false);
  const { dictionary, userLanguage } = useContext(LanguageContext);


  // Create a new object with values initialized to false using map
  const showIconsInitiation = Object.fromEntries(
    Object.keys(languageOptions).map((key) => [key, false])
  );

  const [showValidIcons, setShowValidIcons] = useState(showIconsInitiation);

  const [showErrorIcons, setShowErrorIcons] = useState(showIconsInitiation);

  //Tabs
  const handleChangemultiLanguageTextTab = (event, newTab) => {
    setmultiLanguageTextTab(newTab);
  };

  const onChangeHandler = (e, languageId) => {
    setShowIcon(true);
    // console.log("e", e)
    changeTextHandler(e, languageId);
  };

  const onChangeHTMLHandler = (value, languageId) => {
    setShowIcon(true);
    const syntheticEvent = {
      target: {
        value: value,
      },
    };
    changeTextHandler(syntheticEvent, languageId);
  };

  useEffect(() => {
    const value=normalizeMl(textValue);
    Object.entries(languageOptions).map(([languageId, name]) => {
      // console.log("normalizeMl\n%o\n----------\n%o",textValue,value);
      const removedHTMLText = removeHTMLTags(value[languageId]);
      const isTextValid =
        removedHTMLText.length >= minText && removedHTMLText.length <= maxText;
      setShowValidIcons((prev) => ({ ...prev, [languageId]: isTextValid }));
      const isTextNotValid =
        removedHTMLText.length < minText || removedHTMLText.length > maxText;
      setShowErrorIcons((prev) => ({ ...prev, [languageId]: isTextNotValid }));
    });
  }, [textValue]);

  useEffect(() => {});

  // React.useEffect(()=>{
  //   console.log("textValue[languageId]", textValue)
  // }, [textValue])
  useEffect(() => {
    // I need a regex function to remove all the html tags from the text then I will see the size of text field
    // console.log("textValue : ", textValue["it"]);
  }, [textValue]);

  useEffect(() => {
    // console.log("showValidIcons : ", showValidIcons);
  }, [showValidIcons]);

  useEffect(() => {
    // console.log("showErrorIcons : ", showErrorIcons);
  }, [showErrorIcons]);

  return (
    <Grid item xs={12} id={sectionId}>
      <Tabs
        value={multiLanguageTextTab}
        onChange={handleChangemultiLanguageTextTab}
        centered
      >
        {Object.entries(languageOptions).map(([languageId, name]) => {
          return (
            <Tab
              value={languageId}
              style={{ minWidth: "30px" }}
              label={
                <div style={{ display: "flex" }}>
                  {showValidIcons[languageId] && (
                    <CheckCircleOutlineIcon
                      style={{
                        fontSize: "11px",
                        color: "green",
                        marginRight: "4px",
                      }}
                    />
                  )}
                  {showErrorIcons[languageId] && (
                    <ErrorOutlineIcon
                      style={{
                        fontSize: "11px",
                        color: "red",
                        marginRight: "4px",
                      }}
                    />
                  )}
                  <img src={Flag(languageId)} width="20" alt={name} />{" "}
                </div>
              }
            />
          );
        })}
      </Tabs>
      {Object.entries(languageOptions).map(
        ([languageId, name]) =>
          multiLanguageTextTab === languageId && (
            <>
              {type != "HTML" && (
                <>
                  <TextField
                    label={`${dictionaryList[userLanguage][label]} ${
                      minText > 0 ? " *" : ""
                    }`}
                    placeholder={`${dictionaryList[languageId][placeholder]} ${
                      minText > 0 ? " *" : ""
                    }`}
                    fullWidth
                    variant="outlined"
                    value={textValue[languageId]}
                    onChange={(e) => onChangeHandler(e, languageId)}
                    error={error}
                  />
                  <Grid
                    item
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <CirclePopper text={popperText} />
                    <Typography style={{ fontSize: "12px" }}>
                      {textValue[languageId].length} / {maxText}
                    </Typography>
                  </Grid>
                </>
              )}
              {type === "HTML" && (
                <>
                  <RichTextEditor
                    editval={textValue[languageId]}
                    onChange={(e) => onChangeHTMLHandler(e, languageId)}
                    maxLength={maxText}
                    error={error}
                    placeholder={placeholder}
                  />
                </>
              )}
            </>
          )
      )}
    </Grid>
  );
};

export default MultiLanguageTextField;
