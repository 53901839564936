import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import React from "react";

export const NAME_APP = "D4RE";

export const WEB_URL = process.env.REACT_APP_WEB_URL || "https://d4re.com/";
export const MAIL_SUPPORT = process.env.REACT_MAIL_SUPPORT || "assistenza@d4re.com";

export const API_URL =
  process.env.REACT_APP_API_URL || window.location.origin + "/api/";

export const WHATSAPP_PHONE_NUMBER =
  process.env.REACT_APP_WHATSAPP_PHONE_NUMBER || "+393468596113";

export const BLOG_URL =
  process.env.REACT_APP_BLOG_URL || "https://blog.d4re.com/";

export const PRIVACY_COOKIE_URL_IT =
  "https://s3.d4re.com/common/docs/privacy-cookie-policy-it-20230418.pdf";
export const PRIVACY_COOKIE_URL_EN =
  "https://s3.d4re.com/common/docs/privacy-cookie-policy-en-20230406.pdf";
export const TERMS_AND_CONDITIONS_URL_IT =
  "/pdf/Condizioni_generali_del_servizio_D4RE.pdf";
export const TERMS_AND_CONDITIONS_URL_EN =
  "/pdf/terms_and_conditons_of_use_of_the_platform_en.pdf";

// FE ROUTES
export const ROUTE_PORTFOLIO_EDIT_BUILDING = "/buildings/edit/:buildingId";
export const ROUTE_PORTFOLIO_BUILDING_EDITOR_HP = "/buildings/edit/:buildingId/units";
export const WEB_ROUTE_SEARCHER_MANAGEMENT = "/searchers-management/:searcherId";
export const WEB_ROUTE_SEARCHER = "/searches/:searcherId";
export const WEB_ROUTE_OFFEROR = "/offerors/:offerorId";
export const ROUTE_TEASER_EDIT_ITEM = "/teaser/edit/:teaserId";

export const BUILDING_FORM_REQUEST = API_URL + "forms/information";
export const BUILDING_FORM_APPOINTMENT = API_URL + "forms/appointment";
export const BUILDING_FORM_PROPERTY_INSPECTION =
  API_URL + "forms/property-inspection";
export const PROPOSE_BUILDING_FORM_REQUEST =
  API_URL + "forms/propose-buildings";

export const PROPOSE_BUILDING_CHANGE =
  API_URL + "forms/propose-building-change";

export const LONGLIST_MAX_SELECTED_BUILDINGS = 50;

export const SERVICES_FORM_REQUEST = API_URL + "forms/services";
export const BUILDING_SUGGEST_MACROAREAS = API_URL + "suggest_macroareas";

export const BUILDING_JSON_IMPORT = API_URL + "buildings/uploadbuildingjson";
export const UNIT_JSON_IMPORT = API_URL + "units/uploadunitjson/";

export const API_SERVICE_TIERS = API_URL + "tiers/getalltiers";

export const COMPANY_LOGO_ENDPOINT = API_URL + "companiesLogo/";

export const API_BROKER_PORTFOLIO = API_URL + "portfolio/";

export const API_LONGLIST = API_URL + "longlist/mylonglistentities";
export const API_LONGLIST_TEST = API_URL + "longlist/get-teasers";

export const API_LONGLIST_GET = API_URL + "longlist/mylonglists";

export const API_LONGLIST_SINGLE = API_URL + "longlist/get/";

export const API_LONGLIST_ADD = API_URL + "longlist/add";

export const API_LONGLIST_EDIT = API_URL + "longlist/update/";

export const API_LONGLIST_PUBLISH = API_URL + "longlist/publish/";

export const API_LONGLIST_DELETE = API_URL + "longlist";

export const API_LONGLIST_REQUEST_TENANT_GET =
  API_URL + "longlistrequest/tenant/";

export const API_LONGLIST_REQUEST_COMPANY_GET =
  API_URL + "longlistrequest/company/";

export const API_LONGLIST_REQUEST_REJECT = API_URL + "longlistrequest/reject/";

export const API_LONGLIST_REQUEST_CANCEL = API_URL + "longlistrequest/cancel/";

export const API_LONGLIST_REQUEST_ADD = API_URL + "longlistrequest/add";

export const API_LONGLIST_REQUEST_ACCEPT = API_URL + "longlistrequest/accept/";

export const API_BROKER_SET_MANDATE_DATE =
  API_URL + "portfolio/setmandatedate/";

export const API_PORTFOLIO_SET_TIER = API_URL + "portfolio/settier/";
export const API_PORTFOLIO_REQUEST_TIER = API_URL + "portfolio/requesttier/";
export const API_PORTFOLIO_SET_UNIT_STATE = API_URL + "units/setunitstate/";
export const API_PORTFOLIO_SET_UNIT_STATE_ROUTE = API_URL + "v2/units/setunitstate/:unitId";
export const API_PORTFOLIO_GET_BUILDING_MY_UNITS =
  API_URL + "units/allbrokerunits/{company_id}/building/{building_id}";
export const API_PORTFOLIO_GET_BUILDING_MY_UNITS_ROUTE = API_URL + "units/allbrokerunits/:companyId/building/:buildingId";
export const API_PORTFOLIO_CHANGE_BUILDING_STATE =
  API_URL + "buildings/setbuildingstate/";

export const AUTH_URL = API_URL;
export const API_AUTH_LOGIN = API_URL + "login"
export const API_AUTH_VERIFYTOKEN = API_URL + "verifytoken"
export const API_AUTH_SIGNUP = API_URL + "signup"
export const API_AUTH_RECOVERPASSWORD = API_URL + "recoverpassword"
export const API_AUTH_RECOVERINFO = API_URL + "recoverinfo"
export const API_AUTH_LOGOUT = API_URL + "logout"
export const API_AUTH_CHANGEPASSWORD = API_URL + "changepassword"
export const API_AUTH_REFRESH = API_URL + "refresh"

export const API_URL_USERS = API_URL + "users";
export const API_URL_USER_ROUTE = API_URL + "users/:userId";
export const API_URL_USER_REFRESH_ROUTE = API_URL + "user/refresh/:userId";
export const API_URL_COMPANIES = API_URL + "companies";
export const API_URL_ALLCOMPANIES = API_URL + "allcompanies";

export const API_URL_BROKER_COMPANIES = API_URL + "allbrokercompanies";

//teaser specific
export const API_URL_TEASER = API_URL + "teaser";

export const API_URL_GET_MY_TEASERS = API_URL + "myteasers";
export const API_URL_TEASER_FLOORS = API_URL + "teaserfloor";
export const API_URL_TEASER_TRANSPORT_OPTIONS =
  API_URL + "teasertransportoption";
export const API_URL_TEASER_FEATURES = API_URL + "teasergeneralfeature/info";
export const API_URL_TEASER_IMAGE_UPLOAD = API_URL + "teaseruploadphoto";
export const API_URL_TEASER_IMAGE_REORDER = API_URL + "teaserreorderphotos";
export const API_URL_TEASER_IMAGE_DELETE = API_URL + "teaserdeletephoto";
export const API_URL_TEASER_TO_DRAFT_LISTING = API_URL + "teaser/tobuilding/";
export const API_URL_TEASER_PUBLISH = API_URL + "teaser/publish/";
export const API_URL_PREVIEW_TEASER_PDF = API_URL + "teaser/preview/";

export const API_URL_TEASER_RECIPIENT = API_URL + "teaserrecipient";
export const API_URL_LONGLIST_RECIPIENT = API_URL + "longlistrecipient";

//end of teaser specific

export const API_URL_LONGLIST_PREVIEW = API_URL + "longlist/preview/";
export const API_URL_LONGLIST_PREVIEW_HTML = API_URL + "longlist/preview-html/";

export const API_RESULTS_COUNT = API_URL + "buildings/search/resultscount";

export const API_URL_BUILDING = API_URL + "building/";
export const API_URL_BUILDING_SLUG_NEW = API_URL_BUILDING + "slug/";

export const API_URL_BUILDINGS = API_URL + "buildings";
export const API_URL_BUILDING_SLUG = API_URL + "buildings/slug";

export const API_URL_ALLBUILDINGS = API_URL + "allbuildings";
export const API_URL_FEATUREDBUILDINGS = API_URL + "featuredbuildings";
export const API_URL_ALLBUILDINGIMAGES = API_URL + "allbuildingimages";
export const API_URL_BUILDINGIMAGES = API_URL + "buildingimages";
export const API_URL_BUILDINGIMAGES_REORDER =
  API_URL_BUILDINGIMAGES + "/reorder";

export const API_URL_ALLBUILDINGDOCS = API_URL + "allbuildingdocs";
export const API_URL_BUILDINGDOCS = API_URL + "buildingdocs";

export const API_URL_FAVORITES = API_URL + "favorites";
export const API_URL_ALLFAVORITES = API_URL + "allfavorites";

export const API_URL_ALLBUILDINGSCOMPANIES = API_URL + "allbuildingscompanies";
export const API_URL_ALLUNITSCOMPANIES = API_URL + "allunitscompanies";
export const API_URL_BUILDINGSCOMPANIES = API_URL + "buildingscompanies";

// old images API
export const API_URL_ALLUNITIMAGES = API_URL + "allunitimages";
export const API_URL_UNITIMAGES = API_URL + "unitimages";
export const API_URL_ALLUNITDOCS = API_URL + "allunitdocs";
export const API_URL_UNITDOCS = API_URL + "unitdocs";

export const API_URL_SEARCH_NEARBY = API_URL + "searchnearby";
export const API_URL_SEARCH_NEARBY_TEASER = API_URL + "searchnearbyteaser";
export const API_URL_SEARCH_NEARBY_LAT_LONG =
  API_URL + "getnearbybycoordinates";

export const API_URL_UNITS = API_URL + "units";
export const API_URL_UNIT = API_URL + "unit";
export const API_URL_ALLUNITS = API_URL + "allunits";

export const API_URL_MACROAREA = API_URL + "macroarea";
export const API_URL_ALLMACROAREA = API_URL + "allmacroarea";
export const API_URL_MACROAREABUILDINGS = API_URL + "macroareabuildings";

export const API_URL_PLACETYPES = API_URL + "placetypes";
export const API_URL_COMMERCIALFEATURES = API_URL + "commercialfeatures";
export const API_URL_TECHNICALFEATURES = API_URL + "technicalfeatures";

export const API_URL_ALLCOMMERCIALFEATURES = API_URL + "allcommercialfeatures";
export const API_URL_ALLTECHNICALFEATURES = API_URL + "alltechnicalfeatures";

export const API_URL_NEWS = API_URL + "news";
export const API_URL_NEWS_CATEGORY = API_URL + "newscategory";
export const API_URL_ALLNEWS_CATEGORY = API_URL + "allnewscategory";

export const API_URL_NEWS_IMAGES = API_URL + "newsimages";
export const API_URL_ALL_NEWS_IMAGES = API_URL + "allnewsimages";
export const API_URL_ALL_NEWS = API_URL + "allnews";
export const API_URL_LAST_NEWS = API_URL + "lastnews";

export const API_URL_LOCATIONS = API_URL + "location";

export const API_SEARCHER = API_URL + "searcher/";
export const API_SEARCHER_ROUTE = API_URL + "searcher/:searcherId";
export const API_SEARCHER_URL_ROUTE = API_URL + "searcher/url/:searcherId";
export const API_SEARCHER_REQUEST = API_SEARCHER + "request";
export const API_SEARCHER_UNITS = API_SEARCHER + "units/";
export const API_SEARCHER_UNIT = API_SEARCHER + "unit/";
export const API_SEARCHER_UNIT_ROUTE = API_URL + "searcher/unit/:searcherId";
export const API_SHARE = API_URL + "share/";
export const API_SHARE_SEARCHER_UNIT = API_URL + "share/jwt-searcher-unit/:searcherUnitId";
export const API_SHARE_BUILDING = API_SHARE + "jwt-building/";
export const API_SHARE_BUILDING_BY_TOKEN =
  API_SHARE + "share-building-by-token/";
export const WEB_SHARE_BUILDING = WEB_URL + "/s/";

export const API_SEARCHER_REQUEST_GUEST = API_URL + "searcher/request-guest";
export const API_URL_ALLSEARCHERS = API_URL + "searcher/all";
export const API_URL_ALL_OFFEROR = API_URL + "offeror/all";

export const API_OFFEROR = API_URL + "offeror/";
export const API_OFFEROR_COMPANY = API_OFFEROR + "companies/";
export const API_OFFEROR_UNITS = API_OFFEROR + "units/";
export const API_OFFEROR_MATCHING = API_OFFEROR + "matching/";
export const API_OFFEROR_MATCHING_ROUTE = API_URL + "offeror/matching/:offerorId";

export const SEARCHER_PHASE_RECEIVED = "received";
export const SEARCHER_PHASE_TAKEN_OVER = "taken-over";
export const SEARCHER_PHASE_SUBMITTED = "submitted";
export const SEARCHER_PHASE_MATCHED = "matched";

export const OFFEROR_PHASE_RECEIVED = "received";
export const OFFEROR_PHASE_TAKEN_OVER = "taken-over";
export const OFFEROR_PHASE_SUBMITTED = "submitted";
export const OFFEROR_PHASE_MATCHED = "matched";

/*
 * V2 API
 * this version use strict auth to do actions
 */

// building
export const API_ADD_NEW_BUILDING_V2 = API_URL + "v2/buildings/new";
export const API_EDIT_NEW_BUILDING_V2 =
  API_URL + "v2/buildings/edit/:buildingId";

export const API_DELETE_NEW_BUILDING_V2 =
  API_URL + "v2/buildings/delete/:buildingId";

export const API_URL_NEW_BUILDING_V2 =
  API_URL + "v2/buildings/:buildingId/unit/new";
export const API_ALL_BUILDING_UNITS_V2 = API_URL + "v2/buildings/:buildingId/units";
export const API_ALL_BUILDING_IMAGES_V2 = API_URL + "v2/buildings/:buildingId/images";
export const API_ALL_BUILDING_DOCS_V2 = API_URL + "v2/buildings/:buildingId/docs";
export const API_BUILDING_DOCS_DELETE_V2 = API_URL + "v2/buildings/:buildingId/docs/delete/:docId";
export const API_BUILDING_DOCS_ADD_V2 = API_URL +"v2/buildings/:buildingId/docs/add";
export const API_BUILDING_IMAGES_ADD_V2 = API_URL +"v2/buildings/:buildingId/images/add";
export const API_BUILDING_IMAGES_REORDER_V2 = API_URL +"v2/buildings/:buildingId/images/reorder";
export const API_BUILDING_IMAGES_DELETE_V2 = API_URL +"v2/buildings/:buildingId/images/delete/:imageId";
export const API_PROPOSE_NEW_BUILDING_V2 =
  API_URL + "v2/buildings/propose/:buildingId";

export const API_APPROVE_NEW_BUILDING_V2 =
  API_URL + "v2/buildings/approve/:buildingId";



// v2 unit images API
export const API_URL_GET_LIST_IMAGES_V2 = API_URL + "v2/unit/:unitId/images";
export const API_URL_ADD_UNIT_IMAGES_V2 =
  API_URL + "v2/unit/:unitId/images/add";
//reorder unit images
export const API_UNIT_IMAGES_REORDER_V2 =
  API_URL + "v2/unit/:unitId/images/reorder";
export const API_UNIT_IMAGES_DELETE_V2 =
  API_URL + "v2/unit/:unitId/images/delete/:imageId";

export const API_UNIT_DOCS_LIST_V2 = API_URL + "v2/unit/:unitId/docs";
export const API_UNIT_DOCS_ADD_V2 = API_URL + "v2/unit/:unitId/docs/add";
export const API_UNIT_DOCS_REORDER_V2 =
  API_URL + "v2/unit/:unitId/docs/reorder";
export const API_UNIT_DOCS_RENAME_V2 =
  API_URL + "v2/unit/:unitId/docs/rename/:docId";
export const API_UNIT_DOCS_DELETE_V2 =
  API_URL + "v2/unit/:unitId/docs/delete/:docId";

//retrieve the draft unit
export const API_EDIT_UNIT_V2 = API_URL + "v2/units/edit/:unitId";
export const API_URL_EDIT_UNIT_V2 = API_URL + `v2/units/edit/:unitId`;
export const API_DELETE_UNIT_V2 = API_URL + "v2/units/delete/:unitId";
export const API_DUPLICATE_UNIT_V2 = API_URL + "v2/units/duplicate/:unitId";

/*
 *
 * =============================================================================
 *
 */
export const OFFEROR="offeror";
export const SEARCHER = "searcher";;
export const MANAGEMENT = "management";;

export const FEATURE_OFFEROR = "offeror";
export const FEATURE_SEARCHER = "searcher";
export const FEATURE_PREMIUM = "premium";
export const FEATURE_EDITOR = "editor";
export const FEATURE_LONG_LIST = "longList";
export const FEATURE_TEASER = "teaser";
export const FEATURE_PORTFOLIO = "portfolio";
export const FEATURE_TEMPLATE_BNP = "template_BNP";
export const featuresList = [
  FEATURE_PREMIUM,
  FEATURE_OFFEROR,
  FEATURE_SEARCHER,

  FEATURE_TEMPLATE_BNP,
  FEATURE_EDITOR,

  FEATURE_LONG_LIST,
  FEATURE_TEASER,
  FEATURE_PORTFOLIO,
];


export const PAYMENT_DONE="done";
export const PAYMENT_TODO="todo";
export const PAYMENT_STATES = {
  [PAYMENT_TODO]: PAYMENT_TODO,
  [PAYMENT_DONE]: PAYMENT_DONE,
};

export const OTHER = 0;
export const TENANT = 1;
export const BROKER = 2;
export const LANDLORD = 3;
export const ASSETMANAGER = 4;
export const SUPER_ADMIN = 9;

export const ACCESS_LEVEL_TEASER = FEATURE_LONG_LIST;
export const ACCESS_LEVEL_LONG_LIST = FEATURE_LONG_LIST;
export const ACCESS_LEVEL_SEARCHER = FEATURE_SEARCHER;

export const DEAL_TYPE_LEASE_INT = 1;
export const DEAL_TYPE_LEASE = "lease";
export const DEAL_TYPE_SALE_INT = 2;
export const DEAL_TYPE_SALE = "sale";

export const PRICE_TYPE_ONLINE = 0;
export const PRICE_TYPE_PRIVATE_NEGOTIATION = 1;
export const PRICE_TYPE_ON_DEMAND = 2;
export const PRICE_TYPE_NEGOTIATION_CLOSED = 4;

export const DEAL_TYPES = {
  [DEAL_TYPE_LEASE_INT]: DEAL_TYPE_LEASE,
  [DEAL_TYPE_SALE_INT]: DEAL_TYPE_SALE,
};

export const BUILDING_STATE_OFFLINE = 0;
export const BUILDING_STATE_ONLINE = 1;
export const BUILDING_STATE_PROPOSED_DRAFT = 2;
export const BUILDING_STATE_DRAFT = 3;

export const UNIT_STATE_AVAILABLE = 1;
export const UNIT_STATE_AVAILABLE_FROM = 2;
export const UNIT_STATE_NOT_AVAILABLE = 3;
export const UNIT_STATE_STANDBY = 4;
export const UNIT_STATE_OFFMARKET = 5;

export const UNIT_STATE_LABELS = [
  { state: UNIT_STATE_AVAILABLE, label: "unitStateAvailable" },
  { state: UNIT_STATE_AVAILABLE_FROM, label: "unitStateAvailableFrom" },
  { state: UNIT_STATE_NOT_AVAILABLE, label: "unitStateNotAvailable" },
  { state: UNIT_STATE_STANDBY, label: "unitStateStandby" },
  { state: UNIT_STATE_OFFMARKET, label: "unitStateOffmarket" },
];

export const ASSET_CLASS_OFFICE = "office";
export const ASSET_CLASS_OFFICE_INT = 1;
export const ASSET_CLASS_RETAIL = "retail";
export const ASSET_CLASS_RETAIL_INT = 2;
export const ASSET_CLASS_INDUSTRIAL = "industrial";
export const ASSET_CLASS_INDUSTRIAL_INT = 3;

export const ASSET_CLASSES = {
  [ASSET_CLASS_OFFICE_INT]: ASSET_CLASS_OFFICE,
  [ASSET_CLASS_RETAIL_INT]: ASSET_CLASS_RETAIL,
  [ASSET_CLASS_INDUSTRIAL_INT]: ASSET_CLASS_INDUSTRIAL,
};
export const MAX_COMPARE = 3; //Numero massimo degli immobili da comparare

//export const GOOGLE_API_KEY = "AIzaSyDeiX8hdFmMNsdiPnKN-Xh1URY6RA5qVZA";  //LAB4IT
export const GOOGLE_API_KEY = "AIzaSyAKsRbQfFGodn1LkRsI5LU6-_6Ew9U5a78"; //D4RE
export const GOOGLE_REGION = "IT"; //D4RE

export const tagManagerArgs = {
  gtmId: "GTM-W7GF42T",
};

export const CUSTOM_TEMPLATES = {
  TEMPLATE_BNP: {
    id: "4",
    img_src: "/images/broker/template1.png",
    dictionary_id: "LongListTemplateBNP",
  },
};

export const SUSTAINABILITIES_BREAM = {
  0: "none",
  1: "Outstanding",
  2: "Excellent",
  3: "VeryGood",
  4: "Good",
  5: "Pass",
  6: "Unclassified",
};

export const SUSTAINABILITIES = {
  0: "none",
  1: "Platinum",
  3: "Gold",
  2: "Silver",
  4: "Ongoing",
};

export const WORKPLACE_WELLNESS = {
  0: "none",
  1: "Platinum",
  3: "Gold",
  2: "Silver",
  4: "Ongoing",
};

export const ENERGY_CERTIFICATES = {
  0: "none",
  1: "A+",
  3: "A",
  2: "B",
  4: "C",
  5: "D",
  6: "E",
  7: "F",
  8: "G",
  9: "Ongoing",
};

export const ENTERANCE_RECEPTION = {
  0: "none",
  1: "Reception",
  3: "24hReception",
};

export const CONSTRUCTION_STATUS_ALL = {
  0: "NotSpecified",
  1: "existingBuilding",
  2: "refurbished",
  3: "toBeRefurbished",
  4: "inRefurbishment",
  5: "inConstruction",
};

// export const CONSTRUCTION_STATUS_CONSTRUCTION = {
//   1:"existingBuilding",
//   5:"inConstruction",
// };
//
// export const CONSTRUCTION_STATUS_REFURBISH = {
//   2:"refurbished",
//   3:"toBeRefurbished",
//   4:"inRefurbishment",
//   0:"NotSpecified",
// };
export const CONSTRUCTION_STATUS_SHOW_CONSTRUCTION_DATE = {
  1: "existingBuilding",
  2: "refurbished",
  3: "toBeRefurbished",
  4: "inRefurbishment",
};

export const TYPE_SIZE_ALL = {
  1: "NIA",
  2: "GIA",
  3: "GLA",
  4: "NOA",
  5: "NLA",
};

export const TYPE_SIZE_EDIT_BUILDING = {
  3: "GLA",
  5: "NLA",
};

export const RENOVATION_GRADES = {
  notSpecified: "NotSpecified",
  1: "A",
  2: "B",
  3: "C",
  4: "AGreen",
};

export const FIELDS_TO_NULLIFY = {
  0: [
    "constructiondate",
    "delivery_date_construction",
    "refurbishment_year",
    "delivery_date_refurbish",
  ],
  1: [
    "delivery_date_construction",
    "refurbishment_year",
    "delivery_date_refurbish",
  ],
  2: ["delivery_date_construction", "delivery_date_refurbish"],
  3: [
    "constructiondate",
    "delivery_date_construction",
    "refurbishment_year",
    "delivery_date_refurbish",
  ],
  4: ["delivery_date_construction", "refurbishment_year"],
  5: ["refurbishment_year", "delivery_date_refurbish", "constructiondate"],
};

//Alert messages
export const ALERT_BUILDING_MESSAGES = [
  {
    field: "name",
    message: "Building Name is required (Eng/Ita)",
    target: "name-section",
  },
  {
    field: "address",
    message: "Building Address is required",
    target: "address-section",
  },
  {
    field: "images",
    message: "Building Images are required (3 photos)",
    target: "images-section",
  },
  {
    field: "description",
    message: "Building Description is required (Eng/Ita)",
    target: "description-section",
  },
  {
    field: "selling_points",
    message: "Building Highlights are required (Eng/Ita)",
    target: "selling_points-section",
  },
  {
    field: "size",
    message: "Building Total Area is required",
    target: "size-section",
  },
  {
    field: "floors",
    message: "Number of Floors in the building are required",
    target: "floors-section",
  },
];

export const ALERT_UNIT_MESSAGES = [];

export const UNIT_FLOOR = [
  { level: (floor) => floor === undefined, label: "-",},
  { level: (floor) => floor === FLOOR_GROUNDFLOOR, label: "Groundfloor",},
  { level: (floor) => floor === FLOOR_MEZZANINE, label: "Mezzanine",},
  { level: (floor) => floor === FLOOR_ENTIRE_BUILDING, label: "UnitEntireBuilding",},
  { level: (floor) => true, label: "Floor_N" }, // default value, leave last
];

export const UNIT_PRICE_CLASSIFICATION = {
  0: "PriceOnline",
  1: "PrivateNegotiation",
};

export const UNIT_ONLINE_STATUS_ONLINE = {
  1: "Available",
  2: "AvailableFrom",
};
export const UNIT_ONLINE_STATUS_OFFLINE = {
  3: "NotAvailableNotSharable",
  4: "StandBy",
  5: "OffMarket",
};

export const UNIT_RENOVATION_STATUS = {
  0: "NotSpecified",
  1: "CatA",
  2: "CatB",
  3: "C",
  4: "shellAndCore",
};

export const UNIT_RENOVATION_GRADE = {
  0: "NotSpecified",
  1: "A",
  2: "B",
  3: "C",
};

export const UNIT_MANDATE_AGREEMENT = {
  1: "Exclusive",
  2: "NonExclusive",
  3: "WithoutMandate",
};

export const BUILDING_STATES_EDITABLES_IN_PORTFOLIO = [BUILDING_STATE_DRAFT];
export const BUILDING_UNIT_STATES_EDITABLES_IN_PORTFOLIO = [
  BUILDING_STATE_OFFLINE,
  BUILDING_STATE_ONLINE,
];

export const FLOOR_GROUNDFLOOR = 0;
export const FLOOR_MEZZANINE = 100000;
export const FLOOR_ENTIRE_BUILDING = 100001;
