import SvgIcon from "@material-ui/core/SvgIcon";

export function LeftIcon(props) {
  return (
    <SvgIcon {...props}>
      <circle cx="12" cy="12" r="6" fill="#1C55E5" />
      <path
        fill={props.fill}
        d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm0 9V8l-4 4 4 4v-3h4v-2h-4z"
      />
    </SvgIcon>
  );
}

export function RightIcon(props) {
  return (
    <SvgIcon {...props}>
      <circle cx="30" cy="30" r="16.875" fill="#1C55E5" />
      <path
        d="M30.625 5.625C44.425 5.625 55.625 16.825 55.625 30.625C55.625 44.425 44.425 55.625 30.625 55.625C16.825 55.625 5.625 44.425 5.625 30.625C5.625 16.825 16.825 5.625 30.625 5.625ZM30.625 28.125H20.625V33.125H30.625V40.625L40.625 30.625L30.625 20.625V28.125Z"
        fill={props.fill}
      />
    </SvgIcon>
  );
}

export function PrefIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0H24V24H0z" />
      <path
        d="M19 14v3h3v2h-3.001L19 22h-2l-.001-3H14v-2h3v-3h2zm1.243-9.243c2.262 2.268 2.34 5.88.236 8.235l-1.42-1.418c1.331-1.524 1.261-3.914-.232-5.404-1.503-1.499-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.991-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451l8.432 8.446L12 21.485 3.52 12.993c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228 2.349-2.109 5.979-2.039 8.242.228z"
        fill={props.fill}
      />
    </SvgIcon>
  );
}

export function EuroIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1.95-9H15v2h-4.95a2.5 2.5 0 0 0 4.064 1.41l1.7 1.133A4.5 4.5 0 0 1 8.028 13H7v-2h1.027a4.5 4.5 0 0 1 7.788-2.543L14.114 9.59A2.5 2.5 0 0 0 10.05 11z"
        fill="#000"
      />
    </SvgIcon>
  );
}

/*32 * 29 */
export function StarIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928L12 18.26zm0-2.292l4.247 2.377-.949-4.773 3.573-3.305-4.833-.573L12 5.275l-2.038 4.42-4.833.572 3.573 3.305-.949 4.773L12 15.968z"
        fill="#000"
      />
    </SvgIcon>
  );
}

export function SolutionIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M21 20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v16zM11 5H5v14h6V5zm8 8h-6v6h6v-6zm0-8h-6v6h6V5z"
        fill={props.color ? props.color : "#000"}
      />
    </SvgIcon>
  );
}

export function MapSvgIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zm4.95-7.778a7 7 0 1 0-9.9 0L12 20.9l4.95-4.95zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
        fill="#1C55E5"
      />
    </SvgIcon>
  );
}

export function MapLineIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M2 5l7-3 6 3 6.303-2.701a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V5zm12.935 2.204l-6-3L4 6.319v12.648l5.065-2.17 6 3L20 17.68V5.033l-5.065 2.17z"
        fill="#000"
      />
    </SvgIcon>
  );
}

export function PlusIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
        fill={props.fill}
      />
    </SvgIcon>
  );
}

export function OnlyPlusIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12 12V0H16V12H28V16H16V28H12V16H0V12H12Z" fill={props.fill} />
    </SvgIcon>
  );
}

export function MinusIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-5-9h10v2H7v-2z"
        fill={props.fill}
      />
    </SvgIcon>
  );
}

export function AirPlayIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12.4 13.533l5 6.667a.5.5 0 0 1-.4.8H7a.5.5 0 0 1-.4-.8l5-6.667a.5.5 0 0 1 .8 0zM18 19v-2h2V5H4v12h2v2H2.992A.994.994 0 0 1 2 18V4c0-.552.455-1 .992-1h18.016c.548 0 .992.445.992 1v14c0 .552-.455 1-.992 1H18z"
        fill={props.fill}
      />
    </SvgIcon>
  );
}

export function PropertyIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z"
        fill="#000"
      />
    </SvgIcon>
  );
}

export function LeftIcon2(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12 7.5L6 7.5L6 12L5.24537e-07 6L6 -5.24537e-07L6 4.5L12 4.5L12 7.5Z"
        fill="#1C55E5"
      />
    </SvgIcon>
  );
}

export function PersonSvgIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.00004 15C12.662 15 15.865 16.575 17.607 18.925L15.765 19.796C14.347 18.116 11.847 17 9.00004 17C6.15304 17 3.65304 18.116 2.23504 19.796L0.394043 18.924C2.13604 16.574 5.33804 15 9.00004 15ZM9.00004 0C10.3261 0 11.5979 0.526784 12.5356 1.46447C13.4733 2.40215 14 3.67392 14 5V8C14 9.28846 13.5025 10.5272 12.6114 11.4578C11.7203 12.3884 10.5043 12.9391 9.21704 12.995L9.00004 13C7.67396 13 6.40219 12.4732 5.46451 11.5355C4.52683 10.5979 4.00004 9.32608 4.00004 8V5C4.00012 3.71154 4.49758 2.47284 5.3887 1.54222C6.27982 0.611607 7.49579 0.0609194 8.78304 0.00500011L9.00004 0ZM9.00004 2C8.23483 1.99996 7.49853 2.29233 6.94178 2.81728C6.38504 3.34224 6.04994 4.06011 6.00504 4.824L6.00004 5V8C5.99929 8.7809 6.30306 9.53133 6.84681 10.0918C7.39057 10.6523 8.13144 10.9787 8.91201 11.0016C9.69258 11.0245 10.4513 10.7422 11.027 10.2145C11.6027 9.68691 11.95 8.9556 11.995 8.176L12 8V5C12 4.20435 11.684 3.44129 11.1214 2.87868C10.5588 2.31607 9.79569 2 9.00004 2Z"
        fill={props.fill}
      />
    </SvgIcon>
  );
}

export function RightGreenIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M14 2.44784e-06C21.728 3.79904e-06 28 6.27201 28 14C28 21.728 21.728 28 14 28C6.272 28 -3.79904e-06 21.728 -2.44784e-06 14C-1.09663e-06 6.272 6.272 1.09663e-06 14 2.44784e-06ZM14 12.6L8.4 12.6L8.4 15.4L14 15.4L14 19.6L19.6 14L14 8.4L14 12.6Z"
        fill={props.fill || "#5AC071"}
      />
    </SvgIcon>
  );
}
export function LeftGreenIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M14 28C6.272 28 5.48316e-07 21.728 1.22392e-06 14C1.89952e-06 6.272 6.272 -1.89952e-06 14 -1.22392e-06C21.728 -5.48316e-07 28 6.272 28 14C28 21.728 21.728 28 14 28ZM14 15.4L19.6 15.4L19.6 12.6L14 12.6L14 8.4L8.4 14L14 19.6L14 15.4Z"
        fill={props.fill || "#5AC071"}
      />
    </SvgIcon>
  );
}

export function LogoD4REIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M78.9693 1.53227L5.97839 43.7685C4.14537 44.8234 2.62265 46.3433 1.56372 48.1749C0.504789 50.0066 -0.0529162 52.0853 -0.0531731 54.2014V138.476C-0.0592595 140.598 0.495432 142.684 1.55468 144.523C2.61393 146.361 4.14007 147.887 5.97839 148.945L79.7975 191.613C81.367 192.516 83.1456 192.991 84.9558 192.991C86.7661 192.991 88.5446 192.516 90.1142 191.613C91.7042 190.709 93.0257 189.399 93.9431 187.816C94.8606 186.233 95.3411 184.434 95.3355 182.604V113.249C95.3317 106.304 93.499 99.4828 90.022 93.4724C86.545 87.462 81.5466 82.4748 75.5304 79.0134L31.1849 53.2464L84.9828 22.1639L149.115 59.1926L96.9019 89.3202C100.85 95.4306 103.031 102.515 103.204 109.79L149.241 83.1938V96.4917L103.204 123.069V147.107L149.205 120.457V133.376L103.204 160.098V184.063L163.969 148.945C165.808 147.887 167.334 146.361 168.393 144.523C169.452 142.684 170.007 140.598 170.001 138.476V54.2014C170.001 52.0853 169.443 50.0066 168.384 48.1749C167.325 46.3433 165.802 44.8234 163.969 43.7685L91.0144 1.53227C89.1821 0.477774 87.1055 -0.077179 84.9918 -0.077179C82.8782 -0.077179 80.8016 0.477774 78.9693 1.53227ZM74.5941 113.249V164.621L20.7962 133.448V71.2833L65.2497 97.0322C68.0899 98.6796 70.448 101.045 72.0879 103.891C73.7278 106.737 74.592 109.964 74.5941 113.249Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export function TwitterIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M14.3 2.54999C13.54 2.54987 12.8103 2.84811 12.2679 3.38056C11.7256 3.91301 11.4139 4.63709 11.4 5.39699L11.372 6.97199C11.3704 7.05656 11.3509 7.13982 11.3148 7.21632C11.2787 7.29282 11.2269 7.36082 11.1627 7.41587C11.0985 7.47092 11.0233 7.51178 10.9422 7.53575C10.8611 7.55973 10.7758 7.56629 10.692 7.55499L9.13102 7.34299C7.07702 7.06299 5.10902 6.11699 3.22102 4.54399C2.62302 7.85399 3.79102 10.147 6.60402 11.916L8.35102 13.014C8.43403 13.0662 8.50299 13.1379 8.55187 13.2228C8.60075 13.3078 8.62806 13.4035 8.63141 13.5015C8.63477 13.5995 8.61407 13.6968 8.57111 13.7849C8.52816 13.873 8.46426 13.9493 8.38502 14.007L6.79302 15.17C7.74002 15.229 8.63902 15.187 9.38502 15.039C14.103 14.097 17.24 10.547 17.24 4.69099C17.24 4.21299 16.228 2.54999 14.3 2.54999ZM9.40002 5.35999C9.41747 4.39604 9.71891 3.45866 10.2666 2.66521C10.8142 1.87177 11.5838 1.25751 12.4789 0.899359C13.3741 0.541207 14.3549 0.455072 15.2988 0.65174C16.2426 0.848407 17.1074 1.31914 17.785 2.00499C18.496 1.99999 19.101 2.17999 20.454 1.35999C20.119 2.99999 19.954 3.71199 19.24 4.69099C19.24 12.333 14.543 16.049 9.77702 17C6.50902 17.652 1.75702 16.581 0.39502 15.159C1.08902 15.105 3.90902 14.802 5.53902 13.609C4.16002 12.7 -1.32898 9.46999 2.27802 0.785992C3.97102 2.76299 5.68802 4.10899 7.42802 4.82299C8.58602 5.29799 8.87002 5.28799 9.40102 5.36099L9.40002 5.35999Z"
        fill="#939393"
      />
    </SvgIcon>
  );
}

export function FacebookIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M7 11.5H9.5L10.5 7.5H7V5.5C7 4.47 7 3.5 9 3.5H10.5V0.14C10.174 0.0970001 8.943 0 7.643 0C4.928 0 3 1.657 3 4.7V7.5H0V11.5H3V20H7V11.5Z"
        fill={props.fill}
      />
    </SvgIcon>
  );
}

export function LinkedinIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M4.93994 2.00002C4.93968 2.53046 4.72871 3.03906 4.35345 3.41394C3.97819 3.78883 3.46937 3.99929 2.93894 3.99902C2.40851 3.99876 1.89991 3.78779 1.52502 3.41253C1.15014 3.03727 0.939676 2.52846 0.939942 1.99802C0.940207 1.46759 1.15117 0.958988 1.52644 0.584103C1.9017 0.209218 2.41051 -0.00124153 2.94094 -0.000976312C3.47137 -0.000711096 3.97998 0.210257 4.35486 0.585517C4.72975 0.960777 4.94021 1.46959 4.93994 2.00002ZM4.99994 5.48002H0.999942V18H4.99994V5.48002ZM11.3199 5.48002H7.33994V18H11.2799V11.43C11.2799 7.77002 16.0499 7.43002 16.0499 11.43V18H19.9999V10.07C19.9999 3.90002 12.9399 4.13002 11.2799 7.16002L11.3199 5.48002Z"
        fill={props.fill}
      />
    </SvgIcon>
  );
}

export function EmailIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M16.364 13.536L14.95 12.12L16.364 10.706C16.8316 10.2424 17.2031 9.69108 17.4571 9.08358C17.7111 8.47608 17.8425 7.8244 17.844 7.16595C17.8454 6.5075 17.7168 5.85525 17.4655 5.24664C17.2142 4.63804 16.8451 4.08506 16.3795 3.61947C15.9139 3.15387 15.361 2.78483 14.7524 2.53351C14.1437 2.2822 13.4915 2.15357 12.833 2.15502C12.1746 2.15646 11.5229 2.28794 10.9154 2.54192C10.3079 2.79589 9.75656 3.16736 9.29301 3.63499L7.87901 5.04999L6.46401 3.63599L7.88001 2.22199C9.19283 0.909166 10.9734 0.171631 12.83 0.171631C14.6866 0.171631 16.4672 0.909166 17.78 2.22199C19.0928 3.53481 19.8304 5.31538 19.8304 7.17199C19.8304 9.02859 19.0928 10.8092 17.78 12.122L16.365 13.536H16.364ZM13.536 16.364L12.121 17.778C10.8082 19.0908 9.02762 19.8283 7.17101 19.8283C5.3144 19.8283 3.53383 19.0908 2.22101 17.778C0.90819 16.4652 0.170654 14.6846 0.170654 12.828C0.170654 10.9714 0.90819 9.19081 2.22101 7.87799L3.63601 6.46398L5.05001 7.87998L3.63601 9.29398C3.16838 9.75754 2.79692 10.3089 2.54294 10.9164C2.28896 11.5239 2.15748 12.1756 2.15604 12.834C2.1546 13.4925 2.28323 14.1447 2.53454 14.7533C2.78585 15.3619 3.1549 15.9149 3.62049 16.3805C4.08609 16.8461 4.63906 17.2151 5.24767 17.4665C5.85628 17.7178 6.50853 17.8464 7.16698 17.845C7.82543 17.8435 8.47711 17.712 9.08461 17.4581C9.69211 17.2041 10.2435 16.8326 10.707 16.365L12.121 14.951L13.536 16.365V16.364ZM12.828 5.75699L14.243 7.17199L7.17201 14.242L5.75701 12.828L12.828 5.75799V5.75699Z"
        fill="#939393"
      />
    </SvgIcon>
  );
}

export const FilterIcon = () => {
  return (
    <svg
      fill="#141B4D"
      id="Layer_1"
      height="24"
      viewBox="0 0 64 64"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g id="CIRCLE_8_">
          <g>
            <path d="m32 31.204c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm0-12c-2.168 0-4 1.832-4 4s1.832 4 4 4 4-1.832 4-4-1.832-4-4-4z" />
          </g>
        </g>
        <g id="LINE_129_">
          <g>
            <path d="m32 19.204c-1.104 0-2-.896-2-2v-7.204c0-1.104.896-2 2-2s2 .896 2 2v7.204c0 1.105-.896 2-2 2z" />
          </g>
        </g>
        <g id="LINE_130_">
          <g>
            <path d="m32 56c-1.104 0-2-.896-2-2v-24.796c0-1.104.896-2 2-2s2 .896 2 2v24.796c0 1.104-.896 2-2 2z" />
          </g>
        </g>
        <g id="CIRCLE_9_">
          <g>
            <path d="m48 46.796c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm0-12c-2.168 0-4 1.832-4 4s1.832 4 4 4 4-1.832 4-4-1.832-4-4-4z" />
          </g>
        </g>
        <g id="LINE_131_">
          <g>
            <path d="m48 34.796c-1.104 0-2-.896-2-2v-22.796c0-1.104.896-2 2-2s2 .896 2 2v22.796c0 1.104-.896 2-2 2z" />
          </g>
        </g>
        <g id="LINE_132_">
          <g>
            <path d="m48 56c-1.104 0-2-.896-2-2v-9.204c0-1.104.896-2 2-2s2 .896 2 2v9.204c0 1.104-.896 2-2 2z" />
          </g>
        </g>
        <g id="CIRCLE_10_">
          <g>
            <path d="m16 50.796c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm0-12c-2.168 0-4 1.832-4 4s1.832 4 4 4 4-1.832 4-4-1.832-4-4-4z" />
          </g>
        </g>
        <g id="LINE_133_">
          <g>
            <path d="m16 56c-1.104 0-2-.896-2-2v-5.204c0-1.104.896-2 2-2s2 .896 2 2v5.204c0 1.104-.896 2-2 2z" />
          </g>
        </g>
        <g id="LINE_134_">
          <g>
            <path d="m16 38.796c-1.104 0-2-.896-2-2v-26.796c0-1.104.896-2 2-2s2 .896 2 2v26.796c0 1.104-.896 2-2 2z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export function CompareIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M4 4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V15C18 15.2652 17.8946 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16H14V19C14 19.552 13.55 20 12.993 20H1.007C0.875127 20.0008 0.744397 19.9755 0.622322 19.9256C0.500247 19.8757 0.389233 19.8022 0.295659 19.7093C0.202084 19.6164 0.127793 19.5059 0.0770543 19.3841C0.0263156 19.2624 0.000129374 19.1319 0 19L0.00300002 5C0.00300002 4.448 0.453 4 1.01 4H4ZM2.003 6L2 18H12V6H2.003ZM6 4H14V14H16V2H6V4Z"
        fill={props.fill}
      />
    </SvgIcon>
  );
}

export function MarkerIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <circle fill={props.fill} cx="18" cy="18" r="18" />
          <path
            fill={props.color}
            d="M17.38,8.17,9.81,12.54a1.24,1.24,0,0,0-.63,1.08v8.73a1.26,1.26,0,0,0,.63,1.09l7.66,4.42a1.08,1.08,0,0,0,1.07,0h0a1.09,1.09,0,0,0,.54-.94V19.73a4.09,4.09,0,0,0-2-3.55l-4.61-2.66L18,10.3l6.65,3.84-5.41,3.12a4,4,0,0,1,.64,2.12l4.78-2.76V18l-4.77,2.75v2.49l4.77-2.75v1.34l-4.77,2.75v2.49l6.3-3.63a1.26,1.26,0,0,0,.63-1.09V13.62a1.24,1.24,0,0,0-.63-1.08L18.62,8.17A1.2,1.2,0,0,0,17.38,8.17Zm-.46,11.56v5.32l-5.58-3.22V15.39L16,18.05A1.94,1.94,0,0,1,16.92,19.73Z"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function ContactEmailIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM18 4.238L10.072 11.338L2 4.216V16H18V4.238ZM2.511 2L10.061 8.662L17.502 2H2.511Z"
        fill="black"
      />
    </SvgIcon>
  );
}

/*
<defs>
        <style>.cls-1{fill:#1c55e5;}.cls-2{fill:#fff;}</style></defs>    
*/

/*
    <path d="M13.05 10.496L18 15.6916L13.05 20.8871L11.636 19.4029L14.172 16.7401L1 16.7412V14.6419H14.172L11.636 11.9802L13.05 10.496ZM4.95 0L6.364 1.48414L3.828 4.14593H17V6.24513H3.828L6.364 8.90692L4.95 10.3911L0 5.19553L4.95 0V0Z" fill={props.fill}/>
 
<svg width="170" height="193" viewBox="0 0 170 193" fill="none" xmlns="http://www.w3.org/2000/svg">
 
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="18" cy="18" r="18" fill="#F5F5F5"/>
</svg>

<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">

</svg>


</svg>*/

/*
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">

</svg>




*/
