import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Auth from "../auth/auth";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { LanguageContext } from "../containers/languageprovider";
import { Fragment } from "react";
import { Typography } from "@material-ui/core";
import {
  PRIVACY_COOKIE_URL_IT,
  PRIVACY_COOKIE_URL_EN, TERMS_AND_CONDITIONS_URL_IT, TERMS_AND_CONDITIONS_URL_EN,
  AUTH_URL
} from "../config/config";
import axios from "axios";
import "../css/Signup.css"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//1= private
//2=company

//0 = OTHER
//1= TENANT
//2= BROKER
//3= LANDLORD
//4= ASSET MANAGER

const useStyles = makeStyles((theme) => ({
  FormControl: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: 48,
      borderRadius: "6px",
    },
  },

  TitlesContainer: {
    textAlign: "center",
  },
  TextField: {
    "& .MuiOutlinedInput-root": {
      height: 48,
      borderRadius: "6px",
    },
  },
  LinkTerm: {
    textDecoration: "underline",
  },
  TermContract: {
    fontFamily: "Barlow, sans-serif, Verdana",
    fontSize: "10px",
    lineHeight: "13px",
    color: "#B6B6B6",
  },
  subTitle: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalpaper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
    height: "46px",
  },
  radioUser: {
    color: "#1C55E5",
  },
  radioUserType: {
    margin: "2px",
    minWidth: "70px",
    height: "46px",
    border: "1px solid #1C55E5",
    boxSizing: "border-box",
    borderRadius: "9px",
    padding:"7px",

    background: "rgba(28, 85, 229, 0.1)",
  },
  radioUserTypeActive: {
    margin: "2px",
    minWidth: "70px",
    height: "46px",
    border: "1px solid #B6B6B6",
    boxSizing: "border-box",
    borderRadius: "9px",
    background: "white",
    padding:"9px",
  },
  GridInfoOptions: {
    paddingBottom: "20px",
  },
}));

export default function SignUpForm(pars) {
  const classes = useStyles();
  const { userLanguage } = React.useContext(LanguageContext);
  const [signUpInProgress, setSignUpInProgress] = useState(false);
  const { dictionary } = React.useContext(LanguageContext);
  const [values, setValues] = useState({
    name: "",
    surname: "",
    email: "",
    companyname: "",
    phoneNumber: "",
    contract_terms_and_conditions: false,
    accept_privacy: false,
    subjecttype: 1,
    role: 1,
  });

  const [open, setOpen] = useState(false);
  const [msgmodal, setMsgModal] = useState("");
  const [msgTitle, setMsgTitle] = useState("");
  const [loading, setLoading] = React.useState(false);

  const [formError, setFormError] = useState({
    firstName: {
      error: false,
      message: "",
    },
    lastName: {
      error: false,
      message: "",
    },
    email: {
      error: false,
      message: "",
    },
    phoneNumber: {
      error: false,
      message: "",
    },
    userType: {
      error: false,
      message: "",
    },
    accept_contact: {
      error: false,
      message: "",
    },
    accept_privacy: {
      error: false,
      message: "",
    },
  });

  const handleCheck = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };

  const handleOpen = (title, msg) => {
    setMsgTitle(title);
    setMsgModal(msg);
    setOpen(true);


  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (prop) => (event) => {
    // console.log("handleChange : ", event.target.value);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeRadio = (prop) => (event) => {
    setValues({ ...values, [prop]: Number(event.target.value) });
  };

  const formIsValidHandler = () => {
    let formIsValid = true;
    // Check if firstName is empty
    if (!values.name) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        firstName: {
          error: true,
          message: `${dictionary.FirstNameRequired}`,
        },
      }));
    }
    // Check if LastName is empty
    if (!values.surname) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        lastName: {
          error: true,
          message: `${dictionary.LastNameRequired}`,
        },
      }));
    }
    // Check if email is empty
    if (!values.email) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        email: {
          error: true,
          message: `${dictionary.EmailRequired}` ,
        },
      }));
    }
    // Check if phoneNumber is empty
    if (!values.phoneNumber) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        phoneNumber: {
          error: true,
          message: `${dictionary.PhoneRequired}`,
        },
      }));
    }
    // Check if email is empty
    if (!values.role) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        userType: {
          error: true,
          message: `${dictionary.UserTypeRequired}`,
        },
      }));
    }

    // Check if accept_contact is empty
    if (!values.contract_terms_and_conditions) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        accept_contact: {
          error: true,
          message: `${dictionary.AcceptTermsAndConditions}`,
        },
      }));
    }
    // Check if accept_privacy is empty
    if (!values.accept_privacy) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        accept_privacy: {
          error: true,
          message: `${dictionary.AcceptPrivacyRequired}`,
        },
      }));
    }

    return formIsValid;
  };

  const resetErrorsHanlder = () => {
    setFormError({
      firstName: {
        error: false,
        message: "",
      },
      lastName: {
        error: false,
        message: "",
      },
      email: {
        error: false,
        message: "",
      },
      phoneNumber: {
        error: false,
        message: "",
      },
      userType: {
        error: false,
        message: "",
      },
      accept_contact: {
        error: false,
        message: "",
      },
      accept_privacy: {
        error: false,
        message: "",
      },
    });
  };


  const getEmailErrorMessage = (errorCode) => {
    switch (errorCode) {
      case "1005":
        return `${dictionary.email1005}`;
      case "1007":
        return `${dictionary.email1007}`;
      default:
        return `${dictionary.email1005}`;
    }
  };

  const signUpUser = async (e) => {
    e.preventDefault();
    resetErrorsHanlder();
    let formIsValid = formIsValidHandler();
    if (formIsValid) {
      setSignUpInProgress(true);
      setLoading(true);
      await axios.post(AUTH_URL + "signup", {
        email: values.email,
        phoneNumber: values.phoneNumber,
        companyname: values.companyname,
        surname: values.surname,
        name: values.name,
        subjecttype: values.subjecttype,
        role: values.role,
        contract_terms_and_conditions: values.contract_terms_and_conditions,
        accept_privacy: values.accept_privacy,
        language: userLanguage
      })
          .then( async (payload) => {
            if (window.dataLayer) {
              window.dataLayer.push({ event: "RegistrazioneCompletata" });
            }
            setLoading(false);
            await handleOpen("success", dictionary.RegistrationSucces + values.email);
            setTimeout(  () => {
              pars?.infoClose();
              setSignUpInProgress(false);
            },  1000)
          })
          .catch((error) => {
            setLoading(false);
            const errorCode = error.response.data.errors.email[0];
            if (errorCode){
              const errorMessage = getEmailErrorMessage(errorCode);
              setFormError((prevState) => ({
                ...prevState,
                email: {
                  error: true,
                  message: errorMessage,
                },
              }));
              handleOpen("error", errorMessage);
            } else {
              handleOpen("error", `${dictionary.GenericError}`);
            }
            setSignUpInProgress(false);
          });
    }

    setLoading(false);
  };

  useEffect(() => {
    setValues({
      name: pars?.name,
      surname: pars?.surname,
      email: pars?.email,
      companyname: pars?.companyname,
      phoneNumber: pars?.phoneNumber,
      contract_terms_and_conditions: false,
      accept_privacy: false,
      subjecttype: 1,
      role: 1,
    });
  }, [pars]);

  return (
    <Fragment>
      <form className={classes.form} noValidate onSubmit={signUpUser} >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
                className={classes.TextField}
              variant="outlined"
              error={formError.firstName.error}
              helperText={formError.firstName.message}
              fullWidth
              id="name"
              label={dictionary.FirstName + "*"}
              name="name"
              autoFocus
              value={values.name}
              onChange={(event) => {
                setFormError((prevState) => ({
                  ...prevState,
                  firstName: {
                    error: false,
                    message: "",
                  },
                }));
                handleChange("name")(event);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                className={classes.TextField}
              variant="outlined"
              error={formError.lastName.error}
              helperText={formError.lastName.message}
              fullWidth
              id="surname"
              label={dictionary.LastName + "*"}
              name="surname"
              autoFocus
              value={values.surname}
              onChange={(event) => {
                setFormError((prevState) => ({
                  ...prevState,
                  lastName: {
                    error: false,
                    message: "",
                  },
                }));
                handleChange("surname")(event);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                className={classes.TextField}
              variant="outlined"
              error={formError.email.error}
              helperText={formError.email.message}
              required
              fullWidth
              id="email"
              label={dictionary.Emailaddress}
              name="email"
              autoComplete="email"
              autoFocus
              value={values.email}
              onChange={(event) => {
                setFormError((prevState) => ({
                  ...prevState,
                  email: {
                    error: false,
                    message: "",
                  },
                }));
                handleChange("email")(event);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                className={classes.TextField}
              variant="outlined"
              error={formError.phoneNumber.error}
              helperText={formError.phoneNumber.message}
              required
              fullWidth
              id="phoneNumber"
              label={dictionary.phonenumber}
              name="phoneNumber"
              autoComplete="phoneNumber"
              autoFocus
              value={values.phoneNumber}
              onChange={(event) => {
                setFormError((prevState) => ({
                  ...prevState,
                  phoneNumber: {
                    error: false,
                    message: "",
                  },
                }));
                handleChange("phoneNumber")(event);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.subTitle}>
              {dictionary.Whatsyourrole  + "*"}
            </Typography>
          </Grid>
              <RadioGroup
                aria-label="subjecttype"
                name="role"
                value={values.role}
                onChange={handleChangeRadio("role")}
                row={true}
                xs={12}
              >
                <FormControlLabel
                    xs={4}
                  className={
                    values.role === 1
                      ? classes.radioUserType
                      : classes.radioUserTypeActive
                  }
                  value={1}
                  control={<Radio color="primary" />}
                  label={dictionary.Tenant}
                />
                <FormControlLabel
                    xs={4}
                  className={
                    values.role === 2
                      ? classes.radioUserType
                      : classes.radioUserTypeActive
                  }
                  value={2}
                  control={<Radio color="primary" />}
                  label={dictionary.Broker}
                />
                <FormControlLabel
                    xs={4}
                  className={
                    values.role === 3
                      ? classes.radioUserType
                      : classes.radioUserTypeActive
                  }
                  value={3}
                  control={<Radio color="primary" />}
                  label={dictionary.Landlord}
                />
              </RadioGroup>
          <Grid item xs={12}>
            <Typography className={classes.TermContract}>
              {dictionary.TermRow1}
            </Typography>
            <FormControl className={classes.FormControl}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.contract_terms_and_conditions}
                  style={{
                    color: formError.accept_contact.error ? "#f44336" : "",
                    margin:"0"
                  }}
                  onChange={handleCheck}
                  name="contract_terms_and_conditions"
                  color="primary"
                  required
                />
              }
              label={
                <Fragment>
                  {dictionary.accepttermandConditions + " "}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.LinkTerm}
                    href={
                      userLanguage === "it"
                        ? TERMS_AND_CONDITIONS_URL_IT
                        : TERMS_AND_CONDITIONS_URL_EN
                    }
                  >
                    {dictionary.accepttermandConditions2}
                  </Link>
                  {" " + dictionary.accepttermandConditions3}
                </Fragment>
              }
            />
            {formError.accept_contact.error && (
              <p
                style={{
                  color: "#f44336",
                  fontSize: "12px",
                  paddingLeft: "2rem",
                }}
              >
                {formError.accept_contact.message}
              </p>
            )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.FormControl}>
            <Typography className={classes.TermContract}>
              {dictionary.TermRow2}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.accept_privacy}
                  onChange={handleCheck}
                  name="accept_privacy"
                  color="primary"
                  required
                  style={{
                    color: formError.accept_privacy.error ? "#f44336" : "",
                    margin:"0"
                  }}
                />
              }
              label={
                <Fragment>
                  {dictionary.acceptPrivacy + " "}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.LinkTerm}
                    href={
                      userLanguage === "it"
                        ? PRIVACY_COOKIE_URL_IT
                        : PRIVACY_COOKIE_URL_EN
                    }
                  >
                    {dictionary.acceptPrivacy2}
                  </Link>
                </Fragment>
              }
            />
            {formError.accept_privacy.error && (
              <p
                style={{
                  color: "#f44336",
                  fontSize: "12px",
                  paddingLeft: "2rem",
                }}
              >
                {formError.accept_privacy.message}
              </p>
            )}
            </FormControl>
          </Grid>
          <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}
          >
            {signUpInProgress ? (
                <CircularProgress size={24} style={{ color: "white" }} />
            ) : (
                dictionary.SignUp
            )}
          </Button>
          {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
          )}

          <Grid className={classes.GridInfoOptions} container>
            <Grid item xs={6}>
              {pars.isDialog? (
                  <Link onClick={() => {pars.handleLoginAction("recover")}}  variant="body2">
                    {dictionary.confirmInstructions}
                  </Link>
              ):(
                  <Link component={RouterLink} to="/recoverinfo" variant="body2">
                    {dictionary.confirmInstructions}
                  </Link>
              )}

            </Grid>
            <Grid item xs={6}>
              {pars.isDialog? (
                  <Link onClick={() => {pars.handleLoginAction("login")}} style={{ float: "right" }}  variant="body2">
                    {dictionary.Alreadyhaveaccount}
                  </Link>
              ):(
                  <Link component={RouterLink} to="/login" style={{ float: "right" }}  variant="body2">
                    {dictionary.Alreadyhaveaccount}
                  </Link>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={msgTitle}>
          {msgmodal}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
