import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Snackbar,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import React, { Fragment, useCallback, useEffect, useState } from "react";

import DropzoneBox from "../../broker-views/DropzoneBox";
import axios from "axios";
import {
  API_ALL_BUILDING_IMAGES_V2,
  API_BUILDING_DOCS_ADD_V2,
  API_BUILDING_IMAGES_ADD_V2,
  API_BUILDING_IMAGES_DELETE_V2,
  API_BUILDING_IMAGES_REORDER_V2,
  API_URL_BUILDINGIMAGES,
  API_URL_BUILDINGIMAGES_REORDER,
} from "../../../config/config";
import _ from "lodash";
import { LeftIcon, RightIcon } from "../../iconlist";
import ImageCard from "../../broker-views/ImageCard";
import {
  updateBuildingDataRoomURL,
  updateBuildingFeatured,
  updateBuildingHighlights,
  updateBuildingImages,
  updateBuildingLinkVideo,
  updateBuildingLinkVirualTour,
  updateBuildingTextDescription,
} from "../../../reducers/BuildingSlice";

import Auth from "../../../auth/auth";
import WarningPopUp from "../../WarningPopUp/WarningPopUp";
import { Carousel } from "react-responsive-carousel";
import DeletePopUp from "../../DeletePopUp/DeletePopUp";
import MultiLanguageTextField from "./MultiLanguageItems/MultiLanguageTextField";
import authMethods from "../../../auth/auth";
import MultiLanguageAttachments from "./MultiLanguageItems/MultiLanguageAttachments";
import { routeReplace, selftCasting, toggleValue } from "../../utils";
import { Alert } from "@material-ui/lab";

const BuildingDescription = ({
  classes,
  dictionary,
  building,
  dispatch,
  buildingId,
  buildingErrors,
  setBuildingErrors,
  lastImage,
  setLastImage,
  lastDoc,
  setLastDoc,
}) => {
  const user = Auth.getUser();

  const theme = useTheme();

  const MobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // building images

  const [openDeleteImagePopUp, setOpenDeleteImagePopUp] = useState(false);
  const [indexImage, setIndexImage] = React.useState(-1);

  //warningPopUp'
  const [openWarningPopUp, setopenWarningPopUp] = React.useState(false);

  //body text
  const [bodyText, setBodyText] = React.useState("");

  const smBreakpoint = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  // show and hide the progress icon
  const [deleteInProcess, setDeleteInProcess] = useState(false);

  const [imagesAreUploading, setImagesAreUploading] = useState(false);

  const onDropImages = useCallback(
    (acceptedFiles) => {
      // Cancel the images error
      if (buildingErrors.images)
        setBuildingErrors((prev) => ({ ...prev, images: false }));

      acceptedFiles.forEach((file) => {
        setImagesAreUploading(true);
        const reader = new FileReader();

        reader.onload = function (e) {
          const token = Auth.getToken();
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          axios
            .put(
              routeReplace(API_BUILDING_IMAGES_ADD_V2, {
                buildingId: buildingId,
              }),
              {
                image: e.target.result,
                description: null,
                building_id: buildingId,
                order: lastImage + 1,
              }
            )
            .then(() => {
              refreshImages();
              setImagesAreUploading(false);
            })
            .catch((error) => {
              if (error.response?.status === 413) {
                setBodyText(dictionary.imageUploadError);
              } else {
                setBodyText("An error occurred while uploading images.");
              }
              setopenWarningPopUp(true); // Show error message
            });
        };
        reader.readAsDataURL(file);
      });
    },
    [buildingId, lastImage]
  );

  const reorderImages = async (buildingId, orderChangeArray) => {
    const token = authMethods.getToken();
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    try {
      const res = await axios.post(
        routeReplace(API_BUILDING_IMAGES_REORDER_V2, {
          buildingId: buildingId,
        }),
        {
          order: orderChangeArray,
        }
      );
      if (res.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const moveImage = async (id, dir) => {
    console.log("move images");
    const dest = [];
    let orderId = id + 1;
    const nx = id + dir;
    const nxOrder = nx + 1;
    if (!building.images[id] || !building.images[nx]) {
      return;
    }

    const success = await reorderImages(buildingId, [
      {
        photo_id: building.images[id].id,
        order_id: orderId + dir,
      },
      {
        photo_id: building.images[nx].id,
        order_id: nxOrder - dir,
      },
    ]);

    if (success) {
      building.images.forEach(function (e, i) {
        if (i == id) {
          dest.push(building.images[nx]);
        } else if (i == nx) {
          dest.push(building.images[id]);
        } else {
          dest.push(building.images[i]);
        }
      });
      dispatch(updateBuildingImages(dest));
    }
  };

  const refreshImages = () => {
    console.log("refresh the images !");
    axios
      .get(
        routeReplace(API_ALL_BUILDING_IMAGES_V2, { buildingId: buildingId }),
        {
          headers: {
            Authorization: "Bearer " + Auth.getToken(),
            "Content-Type": "application/json",
          },
          params: {
            filter: "edit",
          },
        }
      )
      .then((payload) => {
        console.log("payload.data.all : ", payload.data.all);
        console.log("payload.data.all.length : ", payload.data.all.length);
        dispatch(updateBuildingImages(payload.data.all));

        setLastImage(payload.data.all.length);
      });
  };

  const handleDeleteImage = () => {
    const token = Auth.getToken();
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    setDeleteInProcess(true);
    axios
      .delete(
        routeReplace(API_BUILDING_IMAGES_DELETE_V2, {
          buildingId: building.images[indexImage].building_id,
          imageId: building.images[indexImage].id,
        })
      )
      .then((payload) => {
        //refresh docs after deleting 1 doc.
        refreshImages();
        hideDeleteImageConfirmationPopUp();
        setIndexImage(-1);
        setDeleteInProcess(false);
      });
  };

  //show and hide the delete image warning popup
  const showDeleteImageConfirmationPopUp = (index) => {
    setIndexImage(index);
    setOpenDeleteImagePopUp(true);
  };

  const hideDeleteImageConfirmationPopUp = () => {
    setOpenDeleteImagePopUp(false);
  };

  //Redux functions

  const changeFeaturedHandler = () => {
    const payload = toggleValue(building.featured);
    dispatch(updateBuildingFeatured(payload));
  };

  const changeLinkVirualTourHandler = (e) => {
    //cancel the  error
    if (buildingErrors.virtual_tour)
      setBuildingErrors((prev) => ({ ...prev, virtual_tour: false }));
    const payload = selftCasting(e.target);
    dispatch(updateBuildingLinkVirualTour(payload));
  };

  const changeLinkVideoHandler = (e) => {
    //cancel the  error
    if (buildingErrors.video)
      setBuildingErrors((prev) => ({ ...prev, video: false }));
    const payload = selftCasting(e.target);
    dispatch(updateBuildingLinkVideo(payload));
  };

  const changeTextDescriptionHandler = (e, language) => {
    //cancel the description error
    if (buildingErrors.description)
      setBuildingErrors((prev) => ({ ...prev, description: false }));

    const payload = {
      language: language,
      textDescription: selftCasting(e.target),
    };
    dispatch(updateBuildingTextDescription(payload));
  };

  const changeHighlightsHandler = (e, language) => {
    //cancel the description error
    if (buildingErrors.selling_points)
      setBuildingErrors((prev) => ({ ...prev, selling_points: false }));
    const payload = {
      language: language,
      highlights: selftCasting(e.target),
    };
    dispatch(updateBuildingHighlights(payload));
  };

  const changeDataRoomUrlHandler = (e) => {
    //cancel the  error
    if (buildingErrors.data_room_url)
      setBuildingErrors((prev) => ({ ...prev, data_room_url: false }));
    const payload = selftCasting(e.target);
    dispatch(updateBuildingDataRoomURL(payload));
  };

  return (
    <>
      {user.role === 9 && (
        <>
          <Grid item xs={12} style={{ marginTop: "2rem" }}>
            <Typography className={classes.MenuTitle}>
              {dictionary.BuildingHomePage}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(building.featured)}
                  name="checkedB"
                  color="primary"
                  onChange={changeFeaturedHandler}
                />
              }
              label="featured"
            />
          </Grid>
        </>
      )}

      <Grid item xs={12} style={{ marginTop: "2rem" }} id="description-section">
        <Typography className={classes.MenuTitle}>
          {dictionary.BuildingDescription}
        </Typography>
      </Grid>
      <Grid item id="visual-section">
        <Typography
          className={classes.subtitle}
          style={{ fontWeight: "600", marginBottom: "0.5rem" }}
        >
          {dictionary.VisualContents}
        </Typography>
      </Grid>
      {/* Drag and drop section*/}
      <Grid
        item
        container
        xs={12}
        className={classes.SectionContainer + " " + classes.GridSpacingSM}
        spacing={smBreakpoint ? 0 : 4}
        id="images-section"
      >
        <DropzoneBox
          info={dictionary.TeaserImageInfo}
          disabled={false}
          onDrop={onDropImages}
          accept={".jpg, .jpeg, .png, .gif"}
          dictionary={dictionary}
          style={{ marginBottom: "0.5rem" }}
          error={buildingErrors.images}
          imagesAreUploading={imagesAreUploading}
        />
        <Fragment>
          {building.images.length > 0 && (
            <Grid item xs={12} container>
              <Grid item xs={12} style={{ padding: "16px 0px", width: 1 }}>
                <Carousel
                  centerMode={true}
                  centerSlidePercentage={MobileScreen ? 100 : 50}
                  showIndicators={false}
                  showThumbs={false}
                  autoPlay={false}
                  swipeable={false}
                  dynamicHeight={false}
                  showStatus={false}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <div
                        className={classes.ArrowsCarousel}
                        onClick={onClickHandler}
                        style={{ left: 15 }}
                      >
                        <LeftIcon
                          className={classes.IconArrow}
                          viewBox={"0 0 24 24"}
                          fill={"#ffffff"}
                          style={{ opacity: "0.8" }}
                        />
                        {/*   <img src="/images/ico/LeftArrowCarousel.svg" /> */}
                      </div>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <div
                        className={classes.ArrowsCarousel}
                        onClick={onClickHandler}
                        style={{ right: 15 }}
                      >
                        <RightIcon
                          className={classes.IconArrow}
                          viewBox={"0 0 60 60"}
                          fill={"#ffffff"}
                          style={{ opacity: "0.8" }}
                        />
                        {/*  <img src="/images/ico/RightArrowCarousel.svg" /> */}
                      </div>
                    )
                  }
                >
                  {building.images.map((image, i) => (
                    <div key={i} className={classes.SliderCardContainer}>
                      {image.image && (
                        <ImageCard
                          move={moveImage}
                          mobile={true}
                          dictionary={dictionary}
                          imageSource={image.image}
                          loading={false}
                          isPrimary={true}
                          index={i}
                          onDelete={() => showDeleteImageConfirmationPopUp(i)}
                          fullheigth={false}
                          isBuildingImage={true}
                        />
                      )}
                      {!image.image && image.loading && (
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            height: "225px",
                            border: "1px solid #6a6a6a22",
                          }}
                        >
                          <CircularProgress />
                        </Grid>
                      )}
                    </div>
                  ))}
                </Carousel>
              </Grid>
            </Grid>
          )}
        </Fragment>
      </Grid>
      <Grid item className={classes.typeLinkVirtual} id="virtual_tour-section">
        <TextField
          placeholder={dictionary.TypeLinkVirtual}
          fullWidth
          variant="outlined"
          value={building.virtual_tour}
          onChange={(e) => changeLinkVirualTourHandler(e)}
          error={buildingErrors.virtual_tour}
        />
      </Grid>
      <Grid item id="video-section">
        <TextField
          placeholder={dictionary.TypeLinkVideo}
          fullWidth
          variant="outlined"
          helperText={dictionary.LinkVideoHelper}
          value={building.video}
          onChange={(e) => changeLinkVideoHandler(e)}
          error={buildingErrors.video}
        />
      </Grid>
      <Grid item id="text-section">
        <Typography className={classes.subtitle} style={{ fontWeight: "600" }}>
          {dictionary.TextDescriptions}
        </Typography>
      </Grid>

      <Grid item id="text-section">
        <Typography className={classes.subtitle} style={{ fontWeight: "600" }}>
          {dictionary.description}
        </Typography>
      </Grid>

      <MultiLanguageTextField
        sectionId="description-section"
        label={"description"}
        placeholder={dictionary.buildingDescriptionPlaceholder}
        textValue={building.description}
        changeTextHandler={changeTextDescriptionHandler}
        popperText={dictionary.BuildingNameInfo}
        maxText={"1500"}
        minText={"5"}
        type={"HTML"}
        error={buildingErrors.description}
      />

      <Grid item id="text-section">
        <Typography className={classes.subtitle} style={{ fontWeight: "600" }}>
          {dictionary.Highlights}
        </Typography>
      </Grid>

      <MultiLanguageTextField
        sectionId="selling_points-section"
        label={"Highlights"}
        placeholder={dictionary.buildingHighlightsPlaceholder}
        textValue={building.selling_points}
        changeTextHandler={changeHighlightsHandler}
        popperText={dictionary.HighlightsInfo}
        maxText={"1500"}
        minText={"5"}
        type={"HTML"}
        error={buildingErrors.selling_points}
      />

      <MultiLanguageAttachments
        building={building}
        buildingId={buildingId}
        classes={classes}
        dictionary={dictionary}
        dispatch={dispatch}
        setBodyText={setBodyText}
        setopenWarningPopUp={setopenWarningPopUp}
        lastDoc={lastDoc}
        setLastDoc={setLastDoc}
      />
      {user.role === 9 && (
        <>
          <Grid item>
            <Typography
              className={classes.subtitle}
              style={{ fontWeight: "600", marginBottom: "1rem" }}
            >
              {dictionary.DataRoomURL}
            </Typography>
          </Grid>
          <Grid item id="data_room_url-section">
            <TextField
              placeholder={dictionary.DataRoomTypeLink}
              fullWidth
              variant="outlined"
              value={building.data_room_url}
              onChange={(e) => changeDataRoomUrlHandler(e)}
              error={buildingErrors.data_room_url}
            />
          </Grid>
        </>
      )}
      <WarningPopUp
        openWarningPopUp={openWarningPopUp}
        hideWarningPopUp={() => setopenWarningPopUp(false)}
        body={bodyText}
      />
      <DeletePopUp
        deleteInProcess={deleteInProcess}
        openDeletePopUp={openDeleteImagePopUp}
        hideDeleteConfirmationPopUp={hideDeleteImageConfirmationPopUp}
        handleDelete={handleDeleteImage}
        title={dictionary.DeleteImageConfirmationTitle}
        body={dictionary.DeleteImageConfirmationText}
      />
    </>
  );
};

export default BuildingDescription;
