import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    makeStyles,
    IconButton,
    Button,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import SearchRequestsStep1 from "./Steps/SearchRequestsStep1";
import SearchRequestsStep2 from "./Steps/SearchRequestsStep2";
import SearchRequestsStep3 from "./Steps/SearchRequestsStep3";
import CloseIcon from "@material-ui/icons/Close";
import Auth from "../auth/auth";
import SignUpForm from "../components/signupForm";
import { LanguageContext } from "../containers/languageprovider";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import {
    API_SEARCHER_REQUEST,
    API_SEARCHER_REQUEST_GUEST,
    API_URL_BROKER_COMPANIES,
} from "../config/config";
import authMethods from "../auth/auth";
import {
    sizelistname,
    askingrentlist,
    askingsaleslist,
} from "../components/utils";
import "../css/SearcherArea.css"

const useStyles = makeStyles((theme) => ({
    SignUpFormTitleContainer: {
        fontFamily:"Barlow, sans-serif, Verdana",
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
    },
    DialogTitle: {
        paddingTop: "0",
    },
    CloseButton: {
        position: "absolute",
        right: "2%",
        top: "10px",
        fontSize: "20px",
        fontWeight: "bold",
        cursor: "pointer",
    },
    closeDialogButton: {
        position: "absolute",
        right: "0px",
        top: "3px",
        color: theme.palette.grey[500],
    },
    dialog: {
        overflow: "hidden",
        backgroundImage: "url(./images/howItWorksModal.webp)",
        width: "50px",
        height:"50px",
        "& .MuiDialog-paper": {
            maxHeight: "100%",
        },
    },
    submit: {
        fontFamily: "Montserrat, Verdana, sans‑serif",
        fontSize:"16px"
    },
    submitMobile: {
        fontSize: "14px",
    }
}));

const HowItWorksModal = ({
                                 open,
                                 closeDialog,
                                 searchRequestData,
                                 type,
                             setOpenSearchRequestsModal
                             }) => {
    const classes = useStyles();
    const { dictionary } = React.useContext(LanguageContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <>
            <Dialog
                open={open}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{
                    backgroundImage:  "url(./images/howItWorksModal.webp)",
                    backgroundSize: "cover",
            }}
                PaperProps={{
                    style: {
                        position: "relative",
                        width: "380px",
                        height:"760px",
                        padding: "23px 15px 26px",
                        borderRadius:"4px",
                        border: "solid 1px #e5e5e5",
                        backgroundImage:  "url(https://s3.d4re.com/common/assets/howitworks720.png)",
                        backgroundSize: "cover",
                    },
                }}

            >
                <div  className={classes.CloseButton} onClick={closeDialog}>
                    <CloseIcon />
                </div>
                {isMobile? ( <>
                        <div style={{width:"100%", display:"flex", justifyContent: "center"}}> <span className={"How-it-works-title-mobile"}>{dictionary.HowItWorksTitle}</span></div>
                    <div style={{display: "flex", flexDirection: "row", height: "70%"}}>
                        <div style={{width: "13%", height: "100%"}}></div>
                        <div style={{width: "80%", height: "100%", flexDirection: "column", display: "flex", fontSize: "12px"}}>
                            <div style={{height: "5%"}}></div>
                            <div style={{height: "20%"}}>
                                <p className={"points-title"}>{dictionary.ActivateYourSearch}</p>
                                <p className={"points-paragraph"}>{dictionary.ActivateYourSearchBody}</p>
                            </div>
                            <div style={{height: "49%"}}>
                                <p className={"points-title"}>{dictionary.DiscoverProperties}</p>
                                <p className={"points-paragraph"}>{dictionary.DiscoverPropertiesBody}
                                    {dictionary.DiscoverPropertiesBodySecond}</p>
                            </div>
                            <div style={{height: "20%"}}>
                                <p className={"points-title"}>{dictionary.MakePerfectMatch}</p>
                                <p className={"points-paragraph"}>{dictionary.MakePerfectMatchBody}</p>
                            </div>
                        </div>
                    </div>
                    <div  style={{height:"20%", textAlign:"center"}}>
                    <span className={"Trova-limmobile-che-mobile"}
                    >
                    <p className={"text-style-1-mobile"}> {dictionary.FindTheProperty} </p>
                {dictionary.FindThePropertyBody}
                    </span>
                    </div>
                    <div  style={{width:"100%", height:"20%", display:"flex", flexDirection:"column", justifyContent:"flex-end" }}>
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submitMobile}
                    >
                {dictionary.SendD4REaRequest}
                    </Button>
                    <div style={{width:"100%", display:"flex", justifyContent:"center", marginTop:"2px"}}>
                    <a  href="#" className={"Not-now-tank-you"}>
                        {dictionary.NotNowThankYou}
                    </a>
                    </div>
                    </div>
                    </>
                ): (
                    <>
                        <div style={{width:"100%", display:"flex", justifyContent: "center"}}> <span className={"How-it-works-title"}>{dictionary.HowItWorksTitle}</span></div>
                        <div style={{display: "flex", flexDirection: "row", height: "75%"}}>
                            <div style={{width: "13%", height: "100%"}}></div>
                            <div style={{width: "80%", height: "100%", flexDirection: "column", display: "flex"}}>
                                <div style={{marginBottom: "20px"}}>
                                    <p className={"points-title"}>{dictionary.ActivateYourSearch}</p>
                                    <p className={"points-paragraph"}>{dictionary.ActivateYourSearchBody}</p>
                                </div>
                                <div style={{marginBottom: "33px"}}>
                                    <p className={"points-title"}>{dictionary.DiscoverProperties}</p>
                                    <p className={"points-paragraph"}>{dictionary.DiscoverPropertiesBody}
                                        {dictionary.DiscoverPropertiesBodySecond}</p>
                                </div>
                                <div style={{marginBottom: "0px"}}>
                                    <p className={"points-title"}>{dictionary.MakePerfectMatch}</p>
                                    <p className={"points-paragraph"}>{dictionary.MakePerfectMatchBody}</p>
                                </div>
                            </div>

                        </div>
                        <div  style={{height:"20%", textAlign:"center"}}>
                <span className={"Trova-limmobile-che"}
                >
                    <p className={"text-style-1"}> {dictionary.FindTheProperty} </p>
                    {dictionary.FindThePropertyBody}
                </span>
                        </div>
                        <div  style={{width:"100%", height:"20%", display:"flex", flexDirection:"column", justifyContent:"flex-end" }}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={()=> {
                                    closeDialog()
                                    setOpenSearchRequestsModal(true)
                                }}
                            >
                                {dictionary.SendD4REaRequest}
                            </Button>
                            <div style={{width:"100%", display:"flex", justifyContent:"center", marginTop:"2px"}}>
                                <a  href="#" className={"Not-now-tank-you"} onClick={closeDialog}>
                                    {dictionary.NotNowThankYou}
                                </a>
                            </div>
                        </div>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default HowItWorksModal;
