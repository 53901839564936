import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { selftCasting } from "../../../utils";

import axios from "axios";
import { API_URL_BROKER_COMPANIES } from "../../../../config/config";
import { Autocomplete } from "@material-ui/lab";
import { updateUnitBroker } from "../../../../reducers/UnitSlice";

const Broker = ({
  classes,
  dictionary,
  unit,
  dispatch,
  unitErrors,
  setUnitErrors,
  allCompanies,
}) => {
  useEffect(() => {
    console.log("unit.mandate_broker : ", unit.mandate_broker);
  }, [unit.mandate_broker]);

  const changeUnitBrokerHandler = (event, newSelectedCompanies) => {
    if (unitErrors.mandate_broker)
      setUnitErrors((prevErrors) => ({
        ...prevErrors,
        brokers: false,
      }));
    // Extract and store only the IDs from the selected options
    const selectedIds = newSelectedCompanies.map((option) => option.id);
    dispatch(updateUnitBroker(selectedIds));
  };

  return (
    <>
      <Grid item xs={12} style={{ marginTop: "2rem" }} id="broker-section">
        <Typography className={classes.MenuTitle}>
          {dictionary.Broker}
        </Typography>
      </Grid>

      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="checkbox-autocomplete"
            options={allCompanies}
            disableCloseOnSelect
            value={allCompanies.filter((option) =>
              unit.brokers.includes(option.id)
            )}
            onChange={changeUnitBrokerHandler}
            getOptionLabel={(option) => option.companyname}
            renderInput={(params) => (
              <TextField
                {...params}
                label={dictionary.Broker}
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Broker;
