import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { Link } from "react-scroll";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertMessages = ({ unitErrors, handleClose }) => {
  const [alertMessages, setAlertMessages] = useState([]);

  // Use useEffect to update alertMessages when unitErrors changes
  useEffect(() => {
    console.log("unitErrors newAlertMessages called : %o", unitErrors);
    const newAlertMessages = [];
    // for (const key in unitErrors) {
    //   if (unitErrors.hasOwnProperty(key) && unitErrors[key])
    //
    // }
    Object.entries(unitErrors).forEach(([key, errorCarrier]) => {
      if (typeof errorCarrier === "boolean") {
        return;
      }
      // const error = unitErrors[key];
      // console.log(`Key: ${key}, Value: ${JSON.stringify(error)}`);
      // const { messages, target } = unitErrors[key];
      // console.log("message :", messages);
      // console.log("target :", target);
      errorCarrier.messages.forEach((message, index) => {
        const elementKey = `${key}|${index}`;
        // console.log("ElementKey %o",elementKey);
        if (message.length === 0) {
          return;
        }
        newAlertMessages.push(
          <Alert
            key={elementKey}
            severity="error"
            onClose={() => handleClose(elementKey)}
            style={{
              marginBottom: "5px",
              position: "relative",
            }}
            action={
              <div
                style={{
                  display: "flex",
                  width: "100px",
                  justifyContent: "space-around",
                }}
              >
                <Link
                  to={errorCarrier.target}
                  smooth={true}
                  duration={500}
                  offset={-50} // Offset for smooth scroll
                  className="scroll-link"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  Add
                </Link>
                <span
                  style={{ margin: 0, cursor: "pointer", fontSize: "15px" }}
                  onClick={() => handleClose(elementKey)}
                >
                  X
                </span>
              </div>
            }
          >
            {message}
          </Alert>
        );
      });
    });

    setAlertMessages(newAlertMessages);
  }, [unitErrors]);
  return (
    <Grid item xs={12} md={8} xl={6} style={{ margin: "1rem auto" }}>
      {alertMessages}
    </Grid>
  );
};

export default AlertMessages;
