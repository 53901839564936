import React, { useCallback, useEffect, useState } from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  Grid,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Flag, routeReplace } from "../../utils";
import { dictionaryList, languageOptions } from "../../languages";
import CirclePopper from "../../CirclePopper/CirclePopper";
import DropzoneBox from "../../broker-views/DropzoneBox";
import UpdateDocumentPopUp from "../../UpdateDocumentPopUp";
import DeletePopUp from "../../DeletePopUp/DeletePopUp";
import {
  API_UNIT_DOCS_ADD_V2,
  API_UNIT_DOCS_DELETE_V2,
  API_UNIT_DOCS_LIST_V2,
  API_URL_UNITDOCS,
} from "../../../config/config";
import axios from "axios";
import Auth from "../../../auth/auth";
import { updateUnitAttachements } from "../../../reducers/UnitSlice";
import UpdateUnitDocPopUp from "../UpdateUnitDocPopUp/UpdateUnitDocPopUp";

function MenuPopupState({
  index,
  handleClickOpenDialogDoc,
  showDeleteDocumentConfirmationPopUp,
}) {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <MoreVertIcon {...bindTrigger(popupState)} />

          <Menu {...bindMenu(popupState)}>
            <MenuItem onClick={handleClickOpenDialogDoc(index)}>
              <Grid item container style={{ padding: "0.3rem" }}>
                <Grid item style={{ marginRight: "1rem" }}>
                  <EditIcon fontSize="small" />
                </Grid>
                <Grid item>Rename</Grid>
              </Grid>
            </MenuItem>
            <MenuItem
              onClick={() => showDeleteDocumentConfirmationPopUp(index)}
            >
              <Grid item container style={{ padding: "0.3rem" }}>
                <Grid item style={{ marginRight: "1rem" }}>
                  <DeleteIcon fontSize="small" />
                </Grid>
                <Grid item>Delete</Grid>
              </Grid>
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}

const MultiLanguageUnitAttachments = ({
  classes,
  dictionary,
  unit,
  unitId,
  dispatch,
  setopenWarningPopUp,
  setBodyText,
}) => {
  const [multiLanguageTab, setmultiLanguageTab] = useState("gb");
  const [indexDoc, setIndexDoc] = React.useState(-1);
  const [lastDoc, setLastDoc] = useState(1);
  const [openDeleteDocPopUp, setOpenDeleteDocPopUp] = useState(false);
  const [openDialogDoc, setOpenDialogDoc] = React.useState(false);

  //alert message
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  // show and hide the progress icon
  const [deleteInProcess, setDeleteInProcess] = useState(false);

  //Tabs
  const handleChangemultiLanguageTab = (event, newTab) => {
    setmultiLanguageTab(newTab);
  };

  const handleClickOpenDialogDoc = (index) => (event) => {
    setOpenDialogDoc(true);
    setIndexDoc(index);
  };

  //show and hide the delete document warning popup
  const showDeleteDocumentConfirmationPopUp = (index) => {
    setIndexDoc(index);
    setOpenDeleteDocPopUp(true);
  };

  const hideDeleteDocumentConfirmationPopUp = () => {
    setOpenDeleteDocPopUp(false);
  };

  //Per i documenti
  const handleCloseDialogDocWithOutSaving = () => {
    const checkEmptyDesc = JSON.parse(unit.attachements[indexDoc].description);
    if (checkEmptyDesc.gb === "" || checkEmptyDesc.it === "") {
      setAlertMsg(dictionary.descProvide);
      setAlertSeverity("error");
      setOpen(true);
      return;
    }
    setOpenDialogDoc(false);
  };

  const onDropDocs = useCallback(
    (acceptedFiles) => {
      acceptedFiles.map((file) => {
        const reader = new FileReader();
        const fileName = file.name
          .replaceAll("-", " ")
          .replaceAll("_", " ")
          .replace(/\.[^/.]+$/, "");
        reader.onload = function (e) {
          const token = Auth.getToken();
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          // Check the file type and set the doctype accordingly
          let doctype = 1; // Default to PDF
          if (file.type.startsWith("image/")) {
            doctype = 2; // Set to image
          }
          const newDescription = {};
          Object.entries(languageOptions).map(([languageId, name]) => {
            return (newDescription[languageId] = fileName);
          });

          console.log("new description  : ", newDescription);
          axios
            .put(routeReplace(API_UNIT_DOCS_ADD_V2, { unitId: unitId }), {
              doc: e.target.result,
              description: JSON.stringify({
                ...newDescription,
              }),
              unit_id: unitId,
              order: lastDoc + 1,
              doctype: doctype, // Use the doctype determined above
            })
            .then((payload) => {
              setLastDoc((prevState) => prevState + 1);
              //refresh the docs after adding a doc
              refreshDocs();
            })
            .catch((error) => {
              if (error.response?.status === 413) {
                setBodyText(dictionary.docUploadError);
                setopenWarningPopUp(true);
              }
            });
        };
        reader.readAsDataURL(file);
        return file;
      });
    },
    [unitId, lastDoc]
  );

  const refreshDocs = () => {
    console.log("refresh the docs !");
    axios
      .get(routeReplace(API_UNIT_DOCS_LIST_V2, { unitId: unitId }), {
        headers: {
          Authorization: "Bearer " + Auth.getToken(),
          "Content-Type": "application/json",
        },
      })
      .then((payload) => {
        dispatch(updateUnitAttachements(payload.data.all));
        let last = 0;
        if (payload.data.all.length > 0) {
          last = payload.data.all.length + 1;
        }
        setLastDoc(last);
      });
  };

  const handleDeleteDocument = () => {
    console.log("indexDoc : ", indexDoc);
    console.log("unit.attachements[indexDoc] : ", unit.attachements[indexDoc]);
    const token = Auth.getToken();
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .delete(
        routeReplace(API_UNIT_DOCS_DELETE_V2, {
          unitId: unit.attachements[indexDoc].unit_id,
          docId: unit.attachements[indexDoc].id,
        })
      )
      .then((payload) => {
        //refresh docs after deleting 1 doc.
        refreshDocs();
        hideDeleteDocumentConfirmationPopUp();
        setIndexDoc(-1);
      });
  };

  // we display the attachements once we got the building id from the url
  useEffect(() => {
    axios
      .get(routeReplace(API_UNIT_DOCS_LIST_V2, { unitId: unitId }), {
        headers: {
          Authorization: "Bearer " + Auth.getToken(),
          "Content-Type": "application/json",
        },
      })
      .then((payload) => {
        dispatch(updateUnitAttachements(payload.data.all));
        let last = 0;

        if (payload.data.all.lenght > 0) {
          last = payload.data.all.slice(-1)[0].order + 1;
        }

        setLastDoc(last);
      });
  }, [unitId]);

  return (
    <>
      <Grid item id="attachments-section">
        <Typography className={classes.subtitle} style={{ fontWeight: "600" }}>
          {dictionary.Attachments}
        </Typography>
      </Grid>
      {/*Attachement Drag and drop section*/}
      <Grid item>
        <DropzoneBox
          info={dictionary.TeaserDocInfo}
          onDrop={onDropDocs}
          accept={".pdf, .jpg, .jpeg, .png, .gif"}
          dictionary={dictionary}
          style={{ marginBottom: "0.5rem", cursor: "pointer" }}
        />
      </Grid>
      {unit.attachements.length > 0 && (
        <Grid item xs={12}>
          <Tabs
            value={multiLanguageTab}
            onChange={handleChangemultiLanguageTab}
            centered
          >
            {Object.entries(languageOptions).map(([id, name]) => (
              <Tab
                value={id}
                className={classes.Tab}
                label={<img src={Flag(id)} width="20" alt={name} />}
              />
            ))}
          </Tabs>

          {unit.attachements.map((attachement, index) => (
            <Paper
              style={{
                padding: "0.5rem 1rem",
                borderRadius: "0",
                fontSize: "15px",
              }}
            >
              <Grid item container>
                <Grid item xs={9}>
                  {JSON.parse(attachement.description)[multiLanguageTab]}
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    textAlign: "end",
                    cursor: "pointer",
                  }}
                >
                  <MenuPopupState
                    index={index}
                    handleClickOpenDialogDoc={handleClickOpenDialogDoc}
                    showDeleteDocumentConfirmationPopUp={
                      showDeleteDocumentConfirmationPopUp
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Grid>
      )}
      <Grid item style={{ marginTop: "0.5rem" }}>
        <CirclePopper text={dictionary.BuildingNameInfo} />
      </Grid>
      <UpdateUnitDocPopUp
        unitId={unitId}
        openDialogDoc={openDialogDoc}
        dictionary={dictionary}
        docToChange={unit.attachements[indexDoc]}
        indexDoc
        cancelChanges={handleCloseDialogDocWithOutSaving}
        refreshDocs={refreshDocs}
        setAlertMsg={setAlertMsg}
        setAlertSeverity={setAlertSeverity}
        setOpen={setOpen}
        setOpenDialogDoc={setOpenDialogDoc}
      />

      <DeletePopUp
        deleteInProcess={deleteInProcess}
        openDeletePopUp={openDeleteDocPopUp}
        hideDeleteConfirmationPopUp={hideDeleteDocumentConfirmationPopUp}
        handleDelete={handleDeleteDocument}
        title={dictionary.DeleteDocumentConfirmationTitle}
        body={dictionary.DeleteDocumentConfirmationText}
      />
    </>
  );
};

export default MultiLanguageUnitAttachments;
