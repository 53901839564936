import it from "../translations/it.json";
import gb from "../translations/gb.json";
// import fr from "../translations/gb.json";

export const dictionaryList = { gb, it };
// export const dictionaryList = { gb, it, fr };

export const languageOptions = {
  gb: "English",
  it: "Italiano",
  // fr: "french",
};
export const siteLanguageMap = {
  it: "it",
  en: "gb",
  // fr: "fr",
  default: "gb",
};

export const isoLanguageMap = {
  it: "it",
  gb: "en",
  // fr: "fr",
};

export const isoCountryMap = {
  it: "it-IT",
  gb: "en-UK",
  // fr: "fr",
};

export const siteLanguageMapNumeric = {
   // fr: 3,
  it: 2,
  gb: 1,
  default: 2,
};
