import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { LanguageContext } from "../containers/languageprovider";

import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Auth from "../auth/auth";
import { BROKER, TENANT } from "../config/config";
import globalStyles from "./globalStyles";

export default function MenuUser(props) {
  const { dictionary } = React.useContext(LanguageContext);
  const user = Auth.getUser();
  const globalClasses = globalStyles();

  const path = window.location.pathname;

  const pathName = path.substring(path.lastIndexOf("/") + 1);

  const isTenantOrBroker = user?.role === 2 || user?.role === 1;
  const hideProfile = false;

  return (
    <Menu
      id="id-menu-user"
      anchorEl={props.anchorEl}
      keepMounted
      className={globalClasses.menu}
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
      classes={{
        paper: globalClasses.menuPaper,
      }}
    >
      {!user && (
        <MenuItem
          className={globalClasses.menuItem}
          onClick={() => {props.handleLoginAction("login")}}
        >
          <Typography> {dictionary.Login}</Typography>
        </MenuItem>
      )}
      {!user && (
        <MenuItem
          className={globalClasses.menuItem}
          onClick={() => {props.handleLoginAction("signup")}}
        >
          <Typography> {dictionary.Register}</Typography>
        </MenuItem>
      )}

      {hideProfile && (
        <Link component={RouterLink} to="/proposebuilding">
          <MenuItem
            selected={pathName === "profile"}
            className={globalClasses.menuItem}
            onClick={props.onClose}
          >
            <Typography>{dictionary.Profile}</Typography>
          </MenuItem>
        </Link>
      )}

      {user !== undefined && user !== null && user.isAdmin() && (
          <Link component={RouterLink} to="/searchers-management">
            <MenuItem
                selected={pathName === "searchers-management"}
                className={globalClasses.menuItem}
                onClick={props.onClose}
            >
              <Typography>{dictionary.SearchersManagement}</Typography>
            </MenuItem>
          </Link>
      )}

      {user !== undefined && user !== null && user.hasOfferor() && (
        <Link component={RouterLink} to="/offerors">
          <MenuItem
            selected={pathName === "offerors"}
            className={globalClasses.menuItem}
            onClick={props.onClose}
          >
            <Typography>{dictionary.Requests}</Typography>
          </MenuItem>
        </Link>
      )}

      {user !== undefined && user !== null && user.hasPortfolio() && (
        <Link component={RouterLink} to="/portfolio">
          <MenuItem
            selected={pathName === "portfolio"}
            className={globalClasses.menuItem}
            onClick={props.onClose}
          >
            <Typography>{dictionary.PortfolioMenu}</Typography>
          </MenuItem>
        </Link>
      )}

      {user !== undefined && user !== null && user.hasTeaser() && (
        <Link component={RouterLink} to="/teaser">
          <MenuItem
            selected={pathName === "teaser"}
            className={globalClasses.menuItem}
            onClick={props.onClose}
          >
            <Typography>{dictionary.TeaserMenu}</Typography>
          </MenuItem>
        </Link>
      )}

      {user !== undefined && user !== null && user.hasLongList() && (
        <Link component={RouterLink} to="/longlist">
          <MenuItem
            selected={pathName === "longlist"}
            className={globalClasses.menuItem}
            onClick={props.onClose}
          >
            <Typography>{dictionary.LonglistView}</Typography>
          </MenuItem>
        </Link>
      )}

      {user !== undefined && user !== null && user.hasSearcher() && (
          <Link component={RouterLink} to="/searches">
            <MenuItem
                selected={pathName === "searches"}
                className={globalClasses.menuItem}
                onClick={props.onClose}
            >
              <Typography>{dictionary.Searches}</Typography>
            </MenuItem>
          </Link>
      )}

      {user !== undefined && user !== null && user.isTenant() && (
          <Link component={RouterLink} to="/property-availability">
            <MenuItem
                selected={pathName === "property-availability"}
                className={globalClasses.menuItem}
                onClick={props.onClose}
            >
              <Typography>{dictionary.PropertyAvailability}</Typography>
            </MenuItem>
          </Link>
      )}

      {user !== undefined && user !== null && user.isAdmin() && (
        <Link component={RouterLink} to="/userslist">
          <MenuItem
            selected={pathName === "userslist"}
            className={globalClasses.menuItem}
            onClick={props.onClose}
          >
            <Typography>{dictionary.Users}</Typography>
          </MenuItem>
        </Link>
      )}
      {user !== undefined && user !== null && user.isAdmin() && (
        <Link component={RouterLink} to="/buildings">
          <MenuItem
            selected={pathName === "buildings"}
            className={globalClasses.menuItem}
            onClick={props.onClose}
          >
            <Typography>{dictionary.Buildings}</Typography>
          </MenuItem>
        </Link>
      )}

      <Divider />

      {user && !user.isBroker() && (
        <Link
          className={globalClasses.InheritLink}
          component={RouterLink}
          to="/favorites"
        >
          <MenuItem
            selected={pathName === "favorites"}
            className={globalClasses.menuItem}
            onClick={props.onClose}
          >
            <Typography>{dictionary.Favorites}</Typography>
          </MenuItem>
        </Link>
      )}

      {user && (
          <Link component={RouterLink} to="/changepassword">
            <MenuItem
                selected={pathName === "changepassword"}
                className={globalClasses.menuItem}
                onClick={props.onClose}
            >
              <Typography>{dictionary.ChangePassword}</Typography>
            </MenuItem>
          </Link>
      )}

      {user && (
        <MenuItem
          className={globalClasses.menuItem}
          onClick={() => {
            props.onClickLogout();
          }}
        >
          <Typography>{dictionary.Logout}</Typography>
        </MenuItem>
      )}

      {!user && (
        <Link onClick={() => {props.handleLoginAction("password")}}>
          <MenuItem className={globalClasses.menuItem} onClick={props.onClose}>
            <Typography>{dictionary.forgotttenPassword}</Typography>
          </MenuItem>
        </Link>
      )}
    </Menu>
  );
}
