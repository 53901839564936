import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 320;

const globalStyles = makeStyles((theme) => ({
  menuPaper: {
    paddingRight: 10,
  },
  maincontent: {
    flexGrow: 1,
    maxWidth:"100%",
    overflow: "auto",
    paddingTop:"80px"
  },
  ImageSlider: {
    minHeight: "540px",
    maxHeight: "540px",
    objectFit: "cover",
  },
  InfoPopper: {
    border: '1px solid ',
    borderColor: '#bdbdbd',
    borderRadius: '5px',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    maxWidth: '12.5rem',
    overflow: 'auto',
  },

  TitleSlider: {
    position: "absolute",
    top: "50%",
    left: "15%",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "60px",
    lineHeight: "66px",
    color: "#FFFFFF",
    [theme.breakpoints.down("lg")]: {
      fontSize: "50px",
      lineHeight: "38px",
      top: "50%",
      left: "18%",
      fontWeight: "600",
      display: "flex",
      alignItems: "flex-end",
      textAlign: "left",
      width: "80%",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "50px",
      lineHeight: "38px",
      top: "50%",
      left: "16px",
      fontWeight: "600",
      display: "flex",
      alignItems: "flex-end",
      textAlign: "left",
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
      lineHeight: "38px",
      left: "20px",
      top: "50%",
      fontWeight: "600",
      display: "flex",
      alignItems: "flex-end",
      textAlign: "left",
      width: "80%",
    },
  },
  AboutUsSubtitle: {
    width: "inherit",
    position: "absolute",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    marginTop: "2px",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      //left: "20px",
      //top: "120px",
      fontFamily: "Barlow",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      marginTop: "5px",
    },
  },
  SubTitleSlider: {
    position: "absolute",
    top: "75%",
    left: "0px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "66px",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      //left: "20px",
      //top: "120px",
      paddingTop: "30px",
      fontFamily: "Barlow",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "22px",
      textAlign: "left",
    },
  },
  HeaderContainer: {
    zIndex: 10000,
  },

  logoContainer: {
    zIndex: 1000,
    position: "absolute",
    //height: "140px",
    top: "49px",
    left: "60px",
    width: "140px",
    [theme.breakpoints.down("sm")]: {
      top: "37px",
      left: "20px",
      width: "140px",
    },
  },
  logo: {
    position: "absolute",
    height: "50px",
    top: "0px",
    left: "0px",
    width: "140px",
  },

  containerDashBoard: {
    minWidth: "150vh",
    paddingTop: theme.spacing(4) + 20,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      padding: "52px 0px 32px 0px !important",
    },
  },
  containerDashBoardNew: {
    paddingTop: theme.spacing(4) + 20,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      padding: "52px 0px 32px 0px !important",
    },
  },
  ContainerMain: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px 8px !important",
    },
  },

  TextOverflow: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },

  ContainerContent: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px 18px !important",
      overflow: "auto",
    },
  },

  ContainerListDashBoard: {
    //width: '200px',

    paddingTop: "25px",
    marginLeft: "24px",
    width: "225px",
    [theme.breakpoints.down("md")]: {
      width: "60px",
    },
  },

  drawerDashBoard: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaperDashBoard: {
    width: drawerWidth,
  },

  DividerClassName: {
    paddingTop: 20,
    paddingBottom: 48,
  },

  listDashBoard: {
    //minWidth:"240px",
    /*'&$selected': {
      backgroundColor: 'red',
      '&:hover': {
        backgroundColor: 'yellow',
      }
    }*/
  },

  TitleDashBoard: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "31px",
    color: "#000000",
  },

  SubTitleDashBoard: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "21px",
    lineHeight: "21px",
    paddingTop: "50px",
    color: "#000000",
  },
  FilterTitleDashBoard: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "54px",
    color: "#000000",
  },

  menu: {
    marginTop: "25px",
    //width:"220px",
    borderRadius: "10px",
  },
  menuItem: {
    "& p": {
      padding: "6px ",
    },
    width: "220px",
    padding: "0px 0px 0px 32px !important",
    "&.Mui-selected": {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
    "&.Mui-selected p": {
      backgroundColor: "#1C55E5 !important",
      borderRadius: "10px",
      color: "white !important",
      "& path": {
        fill: "white",
      },
      "&:hover": {
        backgroundColor: "#1C55E599 !important",
      },
    },
  },
  /*Header Icon */
  IconLoginUser: {
    backgroundColor: "white",
  },
  HeaderButtonName: {
    color: "#6a6a6a",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "24px",
    "&:disabled": {
      color: "#1C55E5",
      textTransform: "none",
    },
  },
  rightToolbarMenu: {
    zIndex: 1000,
    right: 50,
    top: 30,
    position: "absolute",
    marginLeft: "auto",
    marginRight: -12,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  rightToolbarCarousel: {
    display: "flex",
    alignItems: "center",
    zIndex: 1000,
    right: 50,
    top: 30,
    position: "absolute",
    marginLeft: "auto",
    marginRight: -12,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  HeaderButtonNameCarousel: {
    color: "#ffffff",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    "&:disabled": {
      color: "#ffffff",
      textTransform: "none",
    },
  },
  buttonLoginCarousel: {
    margin: "10px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "all-small-caps",
    color: "#ffffff",
    width: "200px",
    borderColor: "#ffffff",
    borderRadius: "10px",
    "&:hover": {
      background: "#1C55E5",
    },
  },

  buttonSecondary: {
    minWidth: "200px",
    margin: "10px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "all-small-caps",
    backgroundColor: "white",
    color: "#6A6A6A",
    width: "100px",
    borderColor: "#6a6a6a",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "white",
      color: "#6A6A6A",
      borderColor: "#6a6a6a",
      textDecoration: "underline",
    },
  },
  MediumPrimaryButton: {
    width: "100%",
    borderRadius: "10px",
    background: "#141B4D",
    fontFamily: "Barlow",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:disabled": {
      backgroundColor: "#141B4DCC",
      color: "white",
    },
    "&:hover": {
      backgroundColor: "#141B4DCC",
      color: "white",
    },
  },
  SearchBtn: {
    width: "100%",
    height: "56px",
    marginTop: "8px",
    textTransform: "none",
    borderRadius: "10px",
    background: "#141B4D",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "21px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:disabled": {
      backgroundColor: "#e0e0e0",
      color: "#adadad",
    },
    "&:hover": {
      backgroundColor: "#141B4DCC",
      color: "white",
    },
  },
  buttonNewSearch: {
    margin: "10px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "all-small-caps",
    color: "#6A6A6A",
    borderColor: "#6A6A6A",
    borderRadius: "10px",
    "&:hover": {
      background: "#141B4D",
      color: "white",
    },
  },

  buttonLogin: {
    minWidth: "200px",
    margin: "10px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "all-small-caps",
    backgroundColor: "#141B4D",
    color: "white",
    width: "100px",
    borderRadius: "10px",
    "&:hover": {
      background: "#141B4DCC",
    },
  },

  listButtonsMenu: {
    // marginTop:"30%"
  },

  linkbuttonLoginMenu: {
    // width:"100%",
    //paddingLeft:"16px",
    // paddingRight:"16px",
  },

  MenuToolbar: {
    paddingLeft: "100px",
  },
  HeaderCarouselLinkMenu: {
    marginRight: "60px",
    marginLeft: "16px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#ffffff",
  },
  HeaderLinkMenu: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#6A6A6A",
  },

  buttonPropose: {
    minWidth: "200px",
    width: "100%",
    height: "46px",
    margin: "10px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "all-small-caps",
    color: "white",
    background: "#141B4D",
    borderRadius: "10px",
    "&:hover": {
      background: "#141B4DCC",
      color: "white",
    },
  },

  buttonNewSearchMenu: {
    minWidth: "200px",
    width: "100%",
    height: "46px",
    margin: "10px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "all-small-caps",
    color: "#6A6A6A",
    borderColor: "#6A6A6A",
    borderRadius: "10px",
    "&:hover": {
      background: "#141B4D",
      color: "white",
    },
  },

  buttonLoginMenu: {
    margin: "10px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "all-small-caps",
    color: "#1C55E5",
    // width:"100%",
    height: "46px",
    borderRadius: "10px",
    "&:hover": {
      background: "#1C55E5",
      color: "white",
    },
  },
  sectionNameAbout: {
    // paddingLeft: "20px",
    paddingTop: "30px",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "29px",
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "32px",
    },
  },

  sectionName: {
    // paddingLeft: "20px",
    paddingTop: "30px",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "21px",
    lineHeight: "29px",
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "21px",
      lineHeight: "32px",
    },
  },
  drawerPaper: {
    //width: drawerWidth,
    width: "100%",
    backgroundColor: "#ffffff",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    position: "relative",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },

  LogoContainerHomePage: {
    paddingRight: "64px",
    paddingLeft: "64px",
    paddingTop: "43px",
    width: "100%",
    height: "100%",
  },

  sectionDescription: {
    // paddingLeft: "20px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "34px",
    lineHeight: "34px",
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
    },
  },
  TextSearch: {
    padding: "0",
    margin: "auto",
    fontSize: "16px",
    fontFamily: "Barlow",
    color: "#6a6a6a",
  },
  InheritLink: {
    WebkitUserDrag: "none",
    width: "inherit",
    color: "inherit",
    textDecoration: "inherit",
  },
  FlexColumn: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  drawerTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 24px !important",
    height: "74px",
  },
  rightToolbar: {
    marginLeft: "auto",
    marginRight: -12,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  FeaturedBuildings: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "21px",
    lineHeight: "34px",
    color: "#000000",
  },
  DropzoneContainer: {
    border: "1px dashed rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    width: "100%",
    padding: "32px 12px",
  },
  InputLabel: {
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "8px",
    color: "black",
  },
  StationButton: {
    padding: "12px 0px",
  },
  buttonDialogFull: {
    maxWidth: "300px",

    margin: "10px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "none",
    height: "36px",
    borderRadius: "10px",
  },
  buttonDialog: {
    minWidth: "146.71px",
    margin: "10px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "none",
    height: "36px",
    borderRadius: "10px",
  },
  buttonEdit: {
    marginRight: "10px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "all-small-caps",
    color: "white",

    borderRadius: "10px",
    "&:hover": {
      background: "#1C55E5",
      color: "white",
    },
  },
  buttonEditCancel: {
    marginRight: "10px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "all-small-caps",
    color: "#1C55E5",

    borderRadius: "10px",
    /*"&:hover": {      
      background: "#1C55E5",
      color:"white"
    },*/
  },

  InfoHelpDashBoard: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "21px",
    lineHeight: "29px",
    color: "#000000",
    paddingBottom: "50px",
  },

  MacroAreaBoxHomePage: {
    minHeight: "280px",
    background: "#F5F5F5",
    borderRadius: "10px",
    padding: "20px",
  },

  MediaKitHomePage: {
    minHeight: "280px",
    background:
      "linear-gradient(90deg, #CDE763 0.03%, #C5E464 7.37%, #AEDD67 18.88%, #8AD06B 33.12%, #5AC071 48.72%, #58BD75 59.16%, #52B37F 68.49%, #49A292 77.39%, #3B8AAB 86.03%, #296CCC 94.39%, #1C55E5 99.72%)",
    borderRadius: "16px",
  },
  MacroAreaInfoHomePage: {
    height: "34px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "34px",
    color: "#000000",
    paddingBottom: "60px",
  },

  MacroAreaInfoHomePagePagination: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
  },

  MediaKit1: {
    /* Ottobre 2021 */

    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    paddingTop: "25px",
    paddingLeft: "20px",
  },
  MediaKit2: {
    paddingTop: "25px",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "32px",
    lineHeight: "42px",
    color: "#FFFFFF",
  },
  MediaKit3: {
    height: "46px",
    borderRadius: "10px",
    background: "#FFFFFF",
    marginTop: "30px",
    marginLeft: "20px",
    marginRight: "20px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "46px",
    textAlign: "center",
    letterSpacing: "0.03em",
    fontVariant: "all-small-caps",
    color: "#1C55E5",
  },

  NameAreaMacroAreaBoxInfo: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    paddingTop: "16px",
    paddingLeft: "8px",
  },

  AvailableAreaMacroAreaBoxInfo: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    paddingLeft: "8px",
  },
  ImageMacroArea: {
    maxHeight: 78,
  },
  LinkImageCarousel: {
    height: "100%",
  },
  ImageCarousel: {
    height: "100%",
    objectFit: "cover",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  ButtonEmail: {
    borderRadius: "100%",
    background: "rgba(28, 85, 229, 1)",
    "&:hover": {
      backgroundColor: "rgba(28, 85, 229, 0.7) !important",
    },
  },
  ButtonWhatApp: {
    borderRadius: "100%",
    background: "rgba(77, 203, 91, 1)",
    "&:hover": {
      backgroundColor: "rgba(77, 203, 91, 0.7) !important",
    },
  },
  ButtonTelegram: {
    borderRadius: "100%",
    background: "rgba(29, 147, 210, 1)",
    "&:hover": {
      backgroundColor: "rgba(29, 147, 210, 0.7) !important",
    },
  },
  BluePlusButton: {
    backgroundColor: "#1c55e5",
    color: "white",
    fontSize: "20px",
    "&:hover": {
      backgroundColor: "#141b4d",
    },
  },


}));

export default globalStyles;
