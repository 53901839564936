import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Menu,
  Typography,
  withStyles,
} from "@material-ui/core";
import { DeleteOutlined, MoreVert } from "@material-ui/icons";
import React, { Fragment } from "react";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    minWidth: "200px",
  },
})((props) => (
  <Menu
    variant="menu"
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const ImageCard = ({
  imageSource,
  style,
  onDelete,
  loading,
  isPrimary,
  index,
  dictionary,
  mobile,
  move,
  fullheigth,
  isBuildingImage = false,
    height,
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorEl = React.useRef();

  const onClickDelete = () => {
    onDelete();
    setMenuOpen(false);
  };

  const handleClickMoveForward = () => {
    setMenuOpen(false);
    move(index, +1);
  };
  const handleClickMoveBackward = () => {
    setMenuOpen(false);
    move(index, -1);
  };

  const handleClickIcon = () => {
    if (!mobile) {
      onClickDelete();
      return;
    }
    setMenuOpen(true);
  };

  return (
    <Fragment>
      <div style={{ position: "relative" }}>
        <div
          style={{
            zIndex: loading ? 2 : 0,
            width: "100%",
            height: height ? height : "225px",
            backdropFilter: loading ? "blur(1px)" : "",
            transition: "backdrop-filter 0.5s ease",

            top: 0,
            left: 0,
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
          }}
        >
          {loading && <CircularProgress />}
        </div>
      </div>
      <div style={{ ...style, position: "relative" }}>
        <div
          style={{
            width: "100%",
            top: 5,
            position: "absolute",
            display: "flex",
            justifyContent: "space-between",
            padding: "4px 8px",
          }}
        >
          {isPrimary && (index === 0 || (index === 2 && !isBuildingImage)) ? (
            <Chip
              label={
                index === 0 ? dictionary.ImagePrimary : dictionary.LonglistOnly
              }
              style={{
                backgroundColor: "white",
                color: "rgba(28, 85, 229, 1)",
              }}
            />
          ) : (
            <div />
          )}
          <IconButton
            onClick={handleClickIcon}
            style={{ background: "white" }}
            size="small"
          >
            {mobile ? (
              <MoreVert ref={anchorEl} htmlColor="rgba(106, 106, 106, 1)" />
            ) : (
              <DeleteOutlined htmlColor="rgba(106, 106, 106, 1)" />
            )}
          </IconButton>
        </div>
        <img
          style={{
            width: "100%",
            height: height ? height : "225px",
            objectFit: fullheigth ? "contain" : "cover",
            borderRadius: "5px",
          }}
          src={imageSource}
          alt="building preview"
        />
      </div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl.current}
        keepMounted
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <Grid container direction="column" style={{ gap: "6px 0px" }}>
          {index !== 0 && (
            <Fragment>
              <Grid container item xs={12}>
                <Button
                  onClick={handleClickMoveBackward}
                  fullWidth
                  style={{ padding: 0 }}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    wrap="nowrap"
                    style={{ marginRight: "16px", padding: "0px 8px" }}
                  >
                    <Grid item xs={3} alignItems="center" container>
                      <span className="material-icons-outlined">west</span>
                    </Grid>
                    <Grid item xs={true}>
                      <Typography align="left">
                        {dictionary.moveBackward}
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Fragment>
          )}
          {index !== 2 && (
            <Fragment>
              <Grid container item xs={12}>
                <Button
                  onClick={handleClickMoveForward}
                  fullWidth
                  style={{ padding: 0 }}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    style={{ marginRight: "16px", padding: "0px 8px" }}
                  >
                    <Grid item xs={3} alignItems="center" container>
                      <span className="material-icons-outlined">east</span>
                    </Grid>
                    <Grid item xs={true}>
                      <Typography align="left">
                        {dictionary.moveForward}
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Fragment>
          )}
          <Grid item xs={12}>
            <Button onClick={onClickDelete} style={{ padding: 0 }} fullWidth>
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                wrap="nowrap"
                style={{ marginRight: "16px", padding: "0px 8px" }}
              >
                <Grid item xs={3} container alignItems="center">
                  <DeleteOutlined fontSize="small" />
                </Grid>
                <Grid item xs={true}>
                  <Typography align="left">{dictionary.delete}</Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </StyledMenu>
    </Fragment>
  );
};

export default ImageCard;
