import React, { useState } from "react";
import { Link } from "react-scroll";
import "./StickyMenu.css";

const UnitStickyMenu = ({ dictionary, isAdmin }) => {
  const [activeSection, setActiveSection] = useState(null);

  const handleSetActiveSection = (section) => {
    setActiveSection(section);
  };

  const handleLastLinkClick = (linkId) => {
    setTimeout(() => {
      setActiveSection(linkId);
    }, 500);
  };

  return (
    <nav className="sticky-menu" style={{ position: "fixed" }}>
      <ul>
        <li>
          <h3> {dictionary._2of2AddUnit}</h3>
        </li>
      </ul>
      <ul>
        <li>
          <Link
            to="add-units-section"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => handleSetActiveSection("add-units")}
            className={activeSection === "add-units" ? "active" : ""}
          >
            {dictionary.AddUnits}
          </Link>
        </li>
        <li>
          <Link
            to="unit-description-section"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => handleSetActiveSection("unit-description")}
            className={activeSection === "unit-description" ? "active" : ""}
          >
            {dictionary.UnitDescription}
          </Link>
          <ul>
            <li>
              <Link
                to="text-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("text")}
                className={activeSection === "text" ? "active" : ""}
              >
                {dictionary.TextDescriptions}
              </Link>
            </li>
            <li>
              <Link
                to="unit-visual-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("unit-visual")}
                className={activeSection === "unit-visual" ? "active" : ""}
              >
                {dictionary.Visual}
              </Link>
            </li>

            <li>
              <Link
                to="unit-attachments-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("unit-attachments")}
                className={activeSection === "unit-attachments" ? "active" : ""}
              >
                {dictionary.Attachments}
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link
            to="unit-information-section"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => handleSetActiveSection("unit-information")}
            className={activeSection === "unit-information" ? "active" : ""}
          >
            {dictionary.UnitInformations}
          </Link>
          <ul>
            <li>
              <Link
                to="unit-specifics-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("unit-specifics")}
                className={activeSection === "unit-specifics" ? "active" : ""}
              >
                {dictionary.Specifics}
              </Link>
            </li>
            <li>
              <Link
                to="unit-transaction-type-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() =>
                  handleSetActiveSection("unit-transaction-type")
                }
                className={
                  activeSection === "unit-transaction-type" ? "active" : ""
                }
              >
                {dictionary.TransactionType}
              </Link>
            </li>
            <li>
              <Link
                to="unit-exclusive-areas-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() =>
                  handleSetActiveSection("unit-exclusive-areas")
                }
                className={
                  activeSection === "unit-exclusive-areas" ? "active" : ""
                }
              >
                {dictionary.ExclusiveAreas}
              </Link>
            </li>
            <li>
              <Link
                to="unit-status-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("unit-status")}
                className={activeSection === "unit-status" ? "active" : ""}
              >
                {dictionary.UnitStatus}
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link
            to="unit-features-section"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => handleSetActiveSection("unit-features")}
            className={activeSection === "unit-features" ? "active" : ""}
          >
            {dictionary.UnitFeatures}
          </Link>
        </li>
        <li>
          <Link
            to="unit-mandate-agreement-section"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => handleSetActiveSection("unit-mandate-agreement")}
            onClick={() => handleLastLinkClick("unit-mandate-agreement")}
            className={
              activeSection === "unit-mandate-agreement" ? "active" : ""
            }
          >
            {dictionary.MandateAgreement}
          </Link>
        </li>
        {isAdmin && (
          <li>
            <Link
              to="broker-section"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onSetActive={() => handleSetActiveSection("unit-broker")}
              onClick={() => handleLastLinkClick("unit-broker")}

              className={activeSection === "unit-broker" ? "active" : ""}
            >
              {dictionary.Broker}
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default UnitStickyMenu;
