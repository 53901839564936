import { createSlice } from "@reduxjs/toolkit";
import { languageOptions } from "../components/languages";
import {normalizeMl} from "../components/utils";

const name = {};
const address = {};
const addressdetails = {};
const description = {};
const selling_points = {};

// Dynamically generate properties based on languageOptions keys
Object.keys(languageOptions).forEach((key) => {
  name[key] = "";
  address[key] = "";
  addressdetails[key] = "";
  description[key] = "";
  selling_points[key] = "";
});
const initialData = {
      name: name,
      address: address,
      addressdetails: addressdetails,
      macroarea_list: "",
      lng: "",
      lat: "",
      amenities: null,
      featured: 1,
      images: [],
      virtual_tour: "",
      video: "",
      description: description,
      selling_points: selling_points,
      attachements: [],
      data_room_url: "",
      //Specifics
      size: 0,
      type_size: 3,
      floors: 0,
      underground_floors: 0,
      archive_warehouse_area: null,
      tenants_number: 0,
      building_design: "",
      walkability: 0,
      basin: 0,
      highwaydistance: 0,
      loadpoints: 0,
      //Certifications
      sustainability: 0,
      sustainability_breeam: 0,
      workplace_wellness: 0,
      energy_performance_certificate: 0,
      //Building status
      construction_status: "1",
      constructiondate: null,
      delivery_date_construction: null,
      renovation_status: "Refurbished",
      refurbishment_year: null,
      delivery_date_refurbish: null,
      heritage_building: 0,
      restrictions_superintendence: 0,
      smart_building: 0,
      renovationgrade: null,
      //Services
      entrance_reception: 0,
      changing_rooms_lockers: null,
      _24_hour_security_guards: 0,
      access_turnstiles: 0,
      loading_bay: 0,
      seperate_goods_lift: 0,
      //Shared area
      shared_rooftop_area: null,
      auditorium: 0,
      shared_leisure_area: 0,
      shared_meeting_rooms: 0,
      canteen: 0,
      bar: 0,
      //Mobility
      contract_expire: 0,
      outdoor_car_parking: 0,
      visitors_parking: 0,
      charging_stations: 0,
      motorcycle_parking: 0,
      bicycle_parking: 0,
      //Security and Electrical System
      sprinkler_watermist: 0,
      smoke_detectors_system: 0,
      emergency_light_system: 0,
      high_efficency_electrical_system: 0,
      ups: 0,
      generator: 0,
      data_room: 0,
      electrical_substation: 0,
      _2_pipes_air_con_heating_system: 0,
      _4_pipes_air_con_heating_system: 0,
      split_air_con_heating_system: 0,
      indipendent_air_con_heating_system: 0,
      //Control System
      bms: 0,

      buildingIsNew: false,
    };

const BuildingSlice = createSlice({
  name: "building",
  initialState: initialData,
  reducers: {
    resetBuildingSlice: () => initialData,
    updateBuildingSlice: (state, action) => {
      const { payload } = action;
      const allowedKeys = Object.keys(state);
      const updatedState = { ...state };

      const MlFields=["address","name","description","addressdetails","selling_points"];
      for (const key in payload) {
        for (const key in payload) {
          if (allowedKeys.includes(key) && payload[key] !== null) {
            if (key === "refurbishment_year" && typeof payload[key] === "number") {
              if (payload[key] >= 1000 && payload[key] <= 9999) {
                updatedState[key] = `${payload[key]}-01-01`;
              }
            } else {
              updatedState[key] =  MlFields.includes(key) ? normalizeMl(payload[key]) : payload[key];
            }
          }
        }

      }

      return updatedState;
    },
    updateBuildingName: (state, action) => {
      const { language, name } = action.payload;
      state.name[language] = name;
    },
    updateBuildingAddress: (state, action) => {
      const { language, address } = action.payload;
      state.address[language] = address;
    },
    updateBuildingAddressDetails: (state, action) => {
      const { language, addressDetails } = action.payload;
      state.addressdetails[language] = addressDetails;
    },
    updateBuildingMacroarea: (state, action) => {
      const { payload } = action;
      state.macroarea_list = payload;
    },
    updateBuildingLongitude: (state, action) => {
      const { payload } = action;
      state.lng = payload;
    },
    updateBuildingLatitude: (state, action) => {
      const { payload } = action;
      state.lat = payload;
    },
    updateBuildingAmenties: (state, action) => {
      const { payload } = action;
      state.amenities = payload;
    },
    updateBuildingFeatured: (state, action) => {
      const { payload } = action;
      state.featured = payload;
    },
    updateBuildingImages: (state, action) => {
      const { payload } = action;
      state.images = payload;
    },
    updateBuildingLinkVirualTour: (state, action) => {
      const { payload } = action;
      state.virtual_tour = payload;
    },
    updateBuildingLinkVideo: (state, action) => {
      const { payload } = action;
      state.video = payload;
    },
    updateBuildingTextDescription: (state, action) => {
      const { language, textDescription } = action.payload;
      state.description[language] = textDescription;
    },
    updateBuildingHighlights: (state, action) => {
      const { language, highlights } = action.payload;
      state.selling_points[language] = highlights;
    },
    updateBuildingAttachements: (state, action) => {
      const { payload } = action;
      state.attachements = payload;
    },
    updateBuildingDataRoomURL: (state, action) => {
      const { payload } = action;
      state.data_room_url = payload;
    },
    //Specifics
    updateBuildingTotalArea: (state, action) => {
      const { payload } = action;
      state.size = payload;
    },
    updateBuildingSizeType: (state, action) => {
      const { payload } = action;
      state.type_size = payload;
    },
    updateBuildingFloor: (state, action) => {
      const { payload } = action;
      state.floors = payload;
    },
    updateBuildingUndergroundFloors: (state, action) => {
      const { payload } = action;
      state.underground_floors = payload;
    },
    updateBuildingWarehouseArchive: (state, action) => {
      const { payload } = action;
      state.archive_warehouse_area = payload;
    },

    updateBuildingTenantsNumber: (state, action) => {
      const { payload } = action;
      state.tenants_number = payload;
    },
    updateBuildingArcheticts: (state, action) => {
      const { payload } = action;
      state.building_design = payload;
    },
    updateBuildingWalkability: (state, action) => {
      const { payload } = action;
      state.walkability = payload;
    },
    updateBuildingBasinPeopleSqm: (state, action) => {
      const { payload } = action;
      state.basin = payload;
    },
    updateBuildingHighwayDistanceKms: (state, action) => {
      const { payload } = action;
      state.highwaydistance = payload;
    },
    updateBuildingLoadNumber: (state, action) => {
      const { payload } = action;
      state.loadpoints = payload;
    },
    //Certifications
    updateBuildingLeed: (state, action) => {
      const { payload } = action;
      state.sustainability = payload;
    },
    updateBuildingBreeam: (state, action) => {
      const { payload } = action;
      state.sustainability_breeam = payload;
    },
    updateBuildingWell: (state, action) => {
      const { payload } = action;
      state.workplace_wellness = payload;
    },
    updateBuildingEnergyCertificate: (state, action) => {
      const { payload } = action;
      state.energy_performance_certificate = payload;
    },
    //Building status
    updateBuildingConstruction: (state, action) => {
      const { payload } = action;
      state.construction_status = payload;
    },
    updateBuildingYearOfConstruction: (state, action) => {
      const { payload } = action;
      state.constructiondate = payload;
    },
    updateBuildingDeliveryDate: (state, action) => {
      const { payload } = action;
      state.delivery_date_construction = payload;
    },
    updateBuildingRefurbished: (state, action) => {
      const { payload } = action;
      state.renovation_status = payload;
    },
    updateBuildingRefurbishedYear: (state, action) => {
      const { payload } = action;
      state.refurbishment_year = payload;
    },
    updateBuildingDeliveryDateRefurbish: (state, action) => {
      const { payload } = action;
      state.delivery_date_refurbish = payload;
    },
    updateBuildingHeritageBuilding: (state, action) => {
      const { payload } = action;
      state.heritage_building = payload;
    },
    updateBuildingRestrictionsSuperintendence: (state, action) => {
      const { payload } = action;
      state.restrictions_superintendence = payload;
    },
    updateBuildingSmartBuilding: (state, action) => {
      const { payload } = action;
      state.smart_building = payload;
    },
    updateBuildingGrade: (state, action) => {
      const { payload } = action;
      state.renovationgrade = payload;
    },
    //Services
    updateBuildingEntranceReception: (state, action) => {
      const { payload } = action;
      state.entrance_reception = payload;
    },
    updateBuildingChangingRooms: (state, action) => {
      const { payload } = action;
      state.changing_rooms_lockers = payload;
    },

    updateBuildingSecurityGuards24h: (state, action) => {
      const { payload } = action;
      state._24_hour_security_guards = payload;
    },
    updateBuildingAccessTurnstiles: (state, action) => {
      const { payload } = action;
      state.access_turnstiles = payload;
    },
    updateBuildingLoadingBay: (state, action) => {
      const { payload } = action;
      state.loading_bay = payload;
    },
    updateBuildingGoodsLift: (state, action) => {
      const { payload } = action;
      state.seperate_goods_lift = payload;
    },
    //Shared area
    updateBuildingRooftopArea: (state, action) => {
      const { payload } = action;
      state.shared_rooftop_area = payload;
    },

    updateBuildingAuditorium: (state, action) => {
      const { payload } = action;
      state.auditorium = payload;
    },
    updateBuildingLeisureArea: (state, action) => {
      const { payload } = action;
      state.shared_leisure_area = payload;
    },
    updateBuildingMeetingRooms: (state, action) => {
      const { payload } = action;
      state.shared_meeting_rooms = payload;
    },
    updateBuildingCanteen: (state, action) => {
      const { payload } = action;
      state.canteen = payload;
    },
    updateBuildingBar: (state, action) => {
      const { payload } = action;
      state.bar = payload;
    },
    //Mobility
    updateBuildingCoveredParkingsNumber: (state, action) => {
      const { payload } = action;
      state.contract_expire = payload;
    },
    updateBuildingOutdoorCarParkingsNumber: (state, action) => {
      const { payload } = action;
      state.outdoor_car_parking = payload;
    },
    updateBuildingVisitorsParkingsNumber: (state, action) => {
      const { payload } = action;
      state.visitors_parking = payload;
    },
    updateBuildingChargingStationsNumber: (state, action) => {
      const { payload } = action;
      state.charging_stations = payload;
    },
    updateBuildingMotorcycleParking: (state, action) => {
      const { payload } = action;
      state.motorcycle_parking = payload;
    },
    updateBuildingBicycleParking: (state, action) => {
      const { payload } = action;
      state.bicycle_parking = payload;
    },
    //Security and Electrical System
    updateBuildingSprinkler: (state, action) => {
      const { payload } = action;
      state.sprinkler_watermist = payload;
    },
    updateBuildingSmokeDetectors: (state, action) => {
      const { payload } = action;
      state.smoke_detectors_system = payload;
    },
    updateBuildingEmergencyLightSystem: (state, action) => {
      const { payload } = action;
      state.emergency_light_system = payload;
    },
    updateBuildingHighEffElectSystem: (state, action) => {
      const { payload } = action;
      state.high_efficency_electrical_system = payload;
    },
    updateBuildingUps: (state, action) => {
      const { payload } = action;
      state.ups = payload;
    },
    updateBuildingGenerator: (state, action) => {
      const { payload } = action;
      state.generator = payload;
    },
    updateBuildingDataRoom: (state, action) => {
      const { payload } = action;
      state.data_room = payload;
    },
    updateBuildingElectSubstation: (state, action) => {
      const { payload } = action;
      state.electrical_substation = payload;
    },
    updateBuildingPipeHVAC2: (state, action) => {
      const { payload } = action;
      state._2_pipes_air_con_heating_system = payload;
    },
    updateBuildingPipeHVAC4: (state, action) => {
      const { payload } = action;
      state._4_pipes_air_con_heating_system = payload;
    },
    updateBuildingSplitHVAC: (state, action) => {
      const { payload } = action;
      state.split_air_con_heating_system = payload;
    },
    updateBuildingIndipendentHVAC: (state, action) => {
      const { payload } = action;
      state.indipendent_air_con_heating_system = payload;
    },
    //Control System
    updateBuildingBms: (state, action) => {
      const { payload } = action;
      state.bms = payload;
    },

    updateBuildingIsNew: (state, action) => {
      const { payload } = action;
      state.buildingIsNew = payload;
    },
  },
});

export const {
  resetBuildingSlice,
  updateBuildingSlice,
  updateBuildingName,
  updateBuildingAddress,
  updateBuildingAddressDetails,
  updateBuildingMacroarea,
  updateBuildingLongitude,
  updateBuildingLatitude,
  updateBuildingAmenties,
  updateBuildingFeatured,
  updateBuildingImages,
  updateBuildingLinkVirualTour,
  updateBuildingLinkVideo,
  updateBuildingTextDescription,
  updateBuildingHighlights,
  updateBuildingAttachements,
  updateBuildingDataRoomURL,
  updateBuildingTotalArea,
  updateBuildingSizeType,
  updateBuildingFloor,
  updateBuildingUndergroundFloors,
  updateBuildingWarehouseArchive,
  updateBuildingTenantsNumber,
  updateBuildingArcheticts,
  updateBuildingWalkability,
  updateBuildingBasinPeopleSqm,
  updateBuildingHighwayDistanceKms,
  updateBuildingLoadNumber,
  updateBuildingLeed,
  updateBuildingBreeam,
  updateBuildingWell,
  updateBuildingEnergyCertificate,
  updateBuildingConstruction,
  updateBuildingYearOfConstruction,
  updateBuildingDeliveryDate,
  updateBuildingRefurbished,
  updateBuildingRefurbishedYear,
  updateBuildingDeliveryDateRefurbish,
  updateBuildingHeritageBuilding,
  updateBuildingRestrictionsSuperintendence,
  updateBuildingSmartBuilding,
  updateBuildingGrade,
  updateBuildingEntranceReception,
  updateBuildingChangingRooms,
  updateBuildingSecurityGuards24h,
  updateBuildingAccessTurnstiles,
  updateBuildingLoadingBay,
  updateBuildingGoodsLift,
  updateBuildingRooftopArea,
  updateBuildingAuditorium,
  updateBuildingLeisureArea,
  updateBuildingMeetingRooms,
  updateBuildingCanteen,
  updateBuildingBar,
  updateBuildingCoveredParkingsNumber,
  updateBuildingOutdoorCarParkingsNumber,
  updateBuildingVisitorsParkingsNumber,
  updateBuildingChargingStationsNumber,
  updateBuildingMotorcycleParking,
  updateBuildingBicycleParking,
  updateBuildingSprinkler,
  updateBuildingSmokeDetectors,
  updateBuildingEmergencyLightSystem,
  updateBuildingHighEffElectSystem,
  updateBuildingUps,
  updateBuildingGenerator,
  updateBuildingDataRoom,
  updateBuildingElectSubstation,
  updateBuildingPipeHVAC2,
  updateBuildingPipeHVAC4,
  updateBuildingSplitHVAC,
  updateBuildingIndipendentHVAC,
  updateBuildingBms,
  updateBuildingIsNew,
} = BuildingSlice.actions;

export default BuildingSlice.reducer;
