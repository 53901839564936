import { del, get, set } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.
import create from "zustand";
import { persist } from "zustand/middleware";

// Custom storage object
const storage = {
  getItem: async (name) => {
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    await set(name, value);
  },
  removeItem: async (name) => {
    await del(name);
  },
};

export const searchStore = create(
  persist(
    (set, get) => ({
      variables: {}, // Object to store key-value variables
      cacheSearch: (key, value) => {
        console.log(`Caching value for key: ${key}`);
        set((state) => ({
          variables: {
            ...state.variables,
            [key]: value,
          },
        }));
      },
      clearSearch: (key) =>
        set((state) => {
          const { [key]: _, ...remainingVariables } = state.variables;
          return {
            variables: remainingVariables,
          };
        }),
      clearAll: () =>
        set({
          variables: {}, // Reset the variables object to clear all cached data
        }),
      getValue: (key) => {
        console.log(`Getting value for key: ${key}`);

        const value = get().variables[key];
        return value !== undefined ? value : null;
      },
    }),
    {
      name: "search-store",
    }
  )
);

export const resultsStore = create(
  persist(
    (set, get) => ({
      results: undefined,
      setResults: (val) => set({ results: val }),
    }),
    {
      getStorage: () => storage,
      name: "results-store", // name of item in the storage (must be unique)
    }
  )
);

export const buildingsStore = create(
  persist(
    (set, get) => ({
      macroAreaBuildings: undefined,
      cities: [],
      setMacro: (val) =>
        set({
          macroAreaBuildings: val,
          cities: [...new Set(val?.map((e) => e.city))],
        }),
      _hasHydrated: false,
    }),
    {
      getStorage: () => storage,
      name: "buildings-store", // name of item in the storage (must be unique)
      // map cities when store is hydrated
      onRehydrateStorage: () => (state) => {
        buildingsStore.setState({
          _hasHydrated: true,
        });
      }, // check if hydrated bcs we are using async storage ( indexed db )
    }
  )
);

export const newsStore = create(
  persist(
    (set, get) => ({
      news: undefined,
      lastNews: undefined,
      categories: undefined,
      setNews: (value) => {
        set({ news: value });
      },
      addLastNews: (value) => {
        let currentNews = get().news;
        if (!currentNews) {
          currentNews = value;
        }
        value.forEach((element) => {
          if (currentNews.filter((e) => e.id === element.id).length <= 0) {
            currentNews.push(element);
          }
        });
        set({ news: currentNews, lastNews: value });
      },
      setCategories: (value) => {
        set({ categories: value });
      },
    }),
    {
      getStorage: () => storage,
      name: "news-data", // name of item in the storage (must be unique)
    }
  )
);
