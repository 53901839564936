import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { selftCasting } from "../../../utils";

import {
  updateUnitMandateAgreement,
  updateUnitMandateBroker,
} from "../../../../reducers/UnitSlice";
import { UNIT_MANDATE_AGREEMENT } from "../../../../config/config";
import { Autocomplete } from "@material-ui/lab";

const MandateAgreement = ({
  classes,
  dictionary,
  unit,
  dispatch,
  unitErrors,
  setUnitErrors,
  allCompanies,
}) => {
  const changeMandateAgreementHandler = (e) => {
    if (unitErrors.mandate_agreement)
      setUnitErrors((prevErrors) => ({
        ...prevErrors,
        mandate_agreement: false,
      }));

    const selectValue = selftCasting(e.target);
    dispatch(updateUnitMandateAgreement(selectValue));
    if (selectValue === 1) {
      dispatch(updateUnitMandateBroker([]));
    }
  };

  const changeMandateBrokerHandler = (event, newSelectedCompanies) => {
    if (unitErrors.mandate_broker)
      setUnitErrors((prevErrors) => ({
        ...prevErrors,
        mandate_broker: false,
      }));
    // Extract and store only the IDs from the selected options
    const selectedIds = newSelectedCompanies.map((option) => option.id);
    dispatch(updateUnitMandateBroker(selectedIds));
  };

  useEffect(() => {
    console.log("unit.mandate_broker : ", unit.mandate_broker);
  }, [unit.mandate_broker]);

  return (
    <>
      <Grid
        item
        xs={12}
        style={{ marginTop: "2rem" }}
        id="mandate_broker-section"
      >
        <Typography className={classes.MenuTitle}>
          {dictionary.MandateAgreement}
        </Typography>
      </Grid>

      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="unit-mandate-agreement-section">
          <FormControl fullWidth variant="outlined">
            <InputLabel>{dictionary.MandateAgreement}*</InputLabel>
            <Select
              value={unit.mandate_agreement}
              label={dictionary.MandateAgreement}
              onChange={changeMandateAgreementHandler}
              error={unitErrors.mandate_agreement}
              name="number"
            >
              {Object.entries(UNIT_MANDATE_AGREEMENT).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {dictionary[value]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {unit.mandate_agreement !== 1 && (
          <Grid item xs={6}>
            <Autocomplete
              multiple
              id="checkbox-autocomplete"
              options={allCompanies}
              disableCloseOnSelect
              value={allCompanies.filter((option) =>
                unit.mandate_broker.includes(option.id)
              )}
              onChange={changeMandateBrokerHandler}
              getOptionLabel={(option) => option.companyname}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={dictionary.SelectCompanies}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={unitErrors.mandate_broker}
                />
              )}
              
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MandateAgreement;
