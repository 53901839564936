import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { LanguageContext } from "../../containers/languageprovider";

const WarningPopUp = ({
  openWarningPopUp,
  hideWarningPopUp,
  // title,
  body,
}) => {
  const { dictionary } = React.useContext(LanguageContext);
  return (
    <Dialog
      open={openWarningPopUp}
      onClose={hideWarningPopUp}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
      style={{ padding: "0 1rem" }}
    >
      <DialogTitle id="alert-dialog-title">
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineIcon style={{ color: "#f48f28", fontSize: "4rem" }} />
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            textAlign: "center",
            color: "#000000",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ marginBottom: "0.5rem" }}>
        <Button
          onClick={hideWarningPopUp}
          style={{ color: "gray", fontSize: "18px",marginRight:"1rem" }}
        >
          {dictionary.Close}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningPopUp;
