import React, { Fragment, useContext } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {isoLanguageMap, languageOptions} from "./languages";
import { LanguageContext } from "../containers/languageprovider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { Flag } from "./utils";
import { KeyboardArrowDown } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";


export default function LanguageSelector(props) {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const history = useHistory();
  const location = useLocation();

  // set selected language by calling context method
  const handleLanguageChange = (id) => (e) => {
    userLanguageChange(id);
    setAnchorEl(null);

    const linkTags = document.querySelectorAll('link[rel="alternate"]');
    const canonicalTags = document.querySelector('link[rel="canonical"]');
    if(linkTags.length>1){
      linkTags.forEach((link) =>{
          // console.log("Alternate [%o]: %o",id ,linkTags);
        if(link.hreflang === isoLanguageMap[id]){
          // console.log("Alternate [%o] -> [%o]",id ,link.href);
          window.history.pushState(null,'',link.href);
          // canonicalTags.href=link.href;
        }
      })
    }



    // const pathParts = location.pathname.split('/');
    // const langIndex = pathParts.length - 1;
    //
    // if (Object.keys(isoLanguageMap).includes(pathParts[langIndex])) {
    //   pathParts[langIndex] = id;
    //   const newPath = pathParts.join('/');
    //
    //   history.push(newPath);
    // }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Button
        style={props.style}
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img
          style={{ borderRadius: "4px" }}
          src={Flag(userLanguage)}
          width="32"
          alt="id"
        />

        <KeyboardArrowDown htmlColor={props.arrowColor || "#6a6a6a"} />
      </Button>

      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.entries(languageOptions).map(([id, name]) => (
          <MenuItem key={id} value={id} onClick={handleLanguageChange(id)}>
            <ListItemIcon>
              <img src={Flag(id)} width="20" alt="id"></img>
            </ListItemIcon>
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}
