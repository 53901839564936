import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { Link } from "react-scroll";
import { ALERT_BUILDING_MESSAGES } from "../../../../config/config";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertMessages = ({ buildingErrors, handleClose }) => {
  const [alertMessages, setAlertMessages] = useState([]);

  // Use useEffect to update alertMessages when buildingErrors changes
  useEffect(() => {
    console.log("buildingErrors newAlertMessages called : %o",buildingErrors);
    const newAlertMessages = [];
    // for (const key in buildingErrors) {
    //   if (buildingErrors.hasOwnProperty(key) && buildingErrors[key])
    //
    // }
    Object.entries(buildingErrors).forEach(([key,errorCarrier]) => {
      if(typeof errorCarrier === "boolean"){
        return;
      }
      // const error = buildingErrors[key];
      // console.log(`Key: ${key}, Value: ${JSON.stringify(error)}`);
      // const { messages, target } = buildingErrors[key];
      // console.log("message :", messages);
      // console.log("target :", target);
      errorCarrier.messages.forEach((message,index) => {
        const elementKey=`${key}|${index}`
        // console.log("ElementKey %o",elementKey);
        if (message.length===0) {
          return;
        }
        newAlertMessages.push(
            <Alert
                key={elementKey}
                severity="error"
                onClose={() => handleClose(elementKey)}
                style={{
                  marginBottom: "5px",
                  position: "relative",
                }}
                action={
                  <div
                      style={{
                        display: "flex",
                        width: "100px",
                        justifyContent: "space-around",
                      }}
                  >
                    <Link
                        to={errorCarrier.target}
                        smooth={true}
                        duration={500}
                        offset={-50} // Offset for smooth scroll
                        className="scroll-link"
                        style={{textDecoration: "underline", cursor: "pointer"}}
                    >
                      Add
                    </Link>
                    <span
                        style={{margin: 0, cursor: "pointer", fontSize: "15px"}}
                        onClick={() => handleClose(elementKey)}
                    >
                      X
                    </span>
                  </div>
                }
            >
              {message}
            </Alert>
        );

      });
    }
    );

    setAlertMessages(newAlertMessages);
  }, [ buildingErrors]);

  useEffect(() => {
    console.log("Alert Messages : ");
    console.log(alertMessages);
  }, [alertMessages]);

  return (
    <Grid item xs={12} md={8} xl={6} style={{ margin: "1rem auto" }}>
      {alertMessages}
    </Grid>
  );
};

export default AlertMessages;
