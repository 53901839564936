import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { Counter, selftCasting, sortMacroareas } from "../../utils";
import { isoLanguageMap } from "../../languages";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Autocomplete } from "@material-ui/lab";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode-place-id";
import {
  API_URL_ALLMACROAREA,
  API_URL_SEARCH_NEARBY_LAT_LONG,
  BUILDING_SUGGEST_MACROAREAS,
  GOOGLE_API_KEY,
  GOOGLE_REGION,
} from "../../../config/config";
import axios from "axios";
import _ from "lodash";
import {
  updateBuildingAmenties,
  updateBuildingLatitude,
  updateBuildingAddress,
  updateBuildingAddressDetails,
  updateBuildingLongitude,
  updateBuildingMacroarea,
  updateBuildingName,
} from "../../../reducers/BuildingSlice";
import MultiLanguageTextField from "./MultiLanguageItems/MultiLanguageTextField";
import MultiLanguageLocationField from "./MultiLanguageItems/MultiLanguageLocationField";
import BuildingAmenities from "../../BuildingAmenities";
import NewAmenities from "./NewAmenities";
import { returnStringTranslation } from "../../printLanguageSelect";
import { LocationOnOutlined } from "@material-ui/icons";
import MultipleMacroareasDialog from "../../broker-views/Teaser/MultipleMacroareasDialog";
import {LanguageContext} from "../../../containers/languageprovider";

Geocode.setApiKey(GOOGLE_API_KEY);
// cerco gli edifici in Italia
Geocode.setRegion(GOOGLE_REGION);

const BuildingNameAndLocation = ({
  classes,
  dictionary,
  userLanguage,
  building,
  dispatch,
  buildingErrors,
  setBuildingErrors,
}) => {
  const [buildingAddress, setBuildingAddress] = useState("");
  const [mapPosition, setMapPosition] = React.useState(null);
  const [geolocationNotFound, setGeolocationNotFound] = React.useState(false);
  const [addressdetails, setAddressdetails] = React.useState({});
  const [citySelect, setCitySelect] = React.useState([]);
  const [macroAreaSelect, setMacroAreaSelect] = React.useState([]);
  const [processingRequest, setProcessingRequest] = React.useState(false);
  const [amenities, setAmenities] = React.useState([]);
  const [loadingAmenities, setLoadingAmenities] = React.useState(false);
  const [loadingMacroarea, setLoadingMacroarea] = React.useState(false);
  const [macroareaArray, setMacroareaArray] = React.useState([]);
  const [allMacroareas, setAllMacroAreas] = React.useState([]);
  //open dialog in case the address has 2 macroareas array for multiple macroareas option
  const [openMacroareasDialog, setOpenMacroareasDialog] = React.useState(false);
  const mapRef = useRef();

  const counter = new Counter((val) => {
    const on = val > 0;
    setProcessingRequest(on);
  });

  const getMacroareaById = (id) => {
    return allMacroareas.find((building) => building.id === id);
  };

  React.useEffect(() => {
    if (building) {
      if (building?.macroarea_list && building?.macroarea_list.length > 0) {
        const id = Number(building.macroarea_list);
        const fetchedMacroarea = getMacroareaById(id);

        if (fetchedMacroarea) {
          const filteredMacroarea = [fetchedMacroarea];
          setMacroAreaSelect(filteredMacroarea);
        }
      }
      if (building.amenities?.length > 0) {
        setAmenities(building.amenities);
      }
    }
  }, [building]);

  const handleChangeAddress = (e, languageId) => {
    //resetting the errors
    setBuildingErrors((prevErrors) => ({
      ...prevErrors,
      address: false,
      addressdetails: false,
    }));
    //setting the language
    const isoLanguageId = isoLanguageMap[languageId];
    Geocode.setLanguage(isoLanguageId);
    Geocode.setLocationType("ROOFTOP");
    const val = e.target.value || "";
    if (val === "") {
      setMapPosition(null);
      setGeolocationNotFound(false);
      const payloadAddress = { language: languageId, address: "" };
      dispatch(updateBuildingAddress(payloadAddress));

      const payloadAddressDetails = {
        language: languageId,
        addressDetails: "",
      };
      dispatch(updateBuildingAddressDetails(payloadAddressDetails));

      //setBuildingAddress("");
      setAddressdetails({});
      setMacroAreaSelect([]);
      return;
    }
    if (e.reason !== "select-option") {
      const payloadAddress = {
        language: languageId,
        address: val,
      };
      dispatch(updateBuildingAddress(payloadAddress));

      return;
    }

    counter.inc(1741);

    Geocode.fromAddress(val).then(
      (response) => {
        console.log("response from Geocode: ");
        //set the lat and lng properties
        console.log("google response : ", response);

        const location = response.results[0].geometry.location;
        const placeId = response.results[0].place_id;
        const { lat, lng } = location;

        dispatch(updateBuildingLongitude(lng));
        dispatch(updateBuildingLatitude(lat));

        // take always the city from the google results

        counter.inc(1760);
        setLoadingAmenities(true);
        const amenities = axios
          .get(API_URL_SEARCH_NEARBY_LAT_LONG, {
            // headers: {Authorization: "Bearer " + Auth.getToken(), "Content-Type": "application/json",},
            params: {
              ...location,
              language: userLanguage,
            },
          })
          .then(
            (res) => {
              counter.dec(1771);
              if (res.data.all) {
                console.log("amenties : ", res.data.all);
                setAmenities(res.data.all);
                dispatch(updateBuildingAmenties(res.data.all));
                setLoadingAmenities(false);
              }
            },
            (error) => {
              counter.dec(1777);
              //in the case of error we will set the amenities to null
              dispatch(updateBuildingAmenties(null));
              setLoadingAmenities(false);
            }
          );

        counter.inc(1781);
        setLoadingMacroarea(true);
        const res = axios
          .post(BUILDING_SUGGEST_MACROAREAS, location, {
            // headers: {Authorization: "Bearer " + Auth.getToken(), "Content-Type": "application/json",},
          })
          .then(
            (res) => {
              counter.dec(1787);
              if (res.data.all) {
                const filteredMacroAreas = res.data.all.filter(
                  (macroarea) => macroarea.id !== 27
                );

                if (filteredMacroAreas.length === 1) {
                  setMacroAreaSelect(filteredMacroAreas);
                } else if (filteredMacroAreas.length > 1) {
                  setMacroareaArray(filteredMacroAreas);
                  setOpenMacroareasDialog(true);
                } else {
                  setMacroAreaSelect(res.data.all);
                }
              }
              setLoadingMacroarea(false);
            },
            (error) => {
              counter.dec(1792);
              setLoadingMacroarea(false);
            }
          );

        const country =
          response.results[0].address_components.find((e) =>
            e.types.includes("country")
          )?.long_name || "";

        if (e.reason === "select-option") {
          const payloadAddress = {
            language: languageId,
            address: response.results[0].formatted_address,
          };
          console.log("payloadAddress 1 : ", payloadAddress);
          dispatch(updateBuildingAddress(payloadAddress));

          const payloadAddressDetails = {
            language: languageId,
            addressDetails: response.results[0],
          };
          console.log("payloadAddressDetails 1 : ", payloadAddressDetails);

          dispatch(updateBuildingAddressDetails(payloadAddressDetails));

          //getting the address of other languages using the lat & lng

          Object.entries(isoLanguageMap).forEach(([id, name]) => {
            // Define options with componentRestrictions to set the language

            if (name !== isoLanguageId) {
              //changing the language
              Geocode.setLanguage(name);

              Geocode.fromPlaceId (placeId).then((response) => {
                counter.dec();
                const payloadAddress = {
                  language: id,
                  address: response.results[0].formatted_address,
                };
                console.log("payloadAddress 2 : ", payloadAddress);

                dispatch(updateBuildingAddress(payloadAddress));
                const payloadAddressDetails = {
                  language: id,
                  addressDetails: response.results[0],
                };
                console.log(
                  "payloadAddressDetails 2 : ",
                  payloadAddressDetails
                );

                dispatch(updateBuildingAddressDetails(payloadAddressDetails));
              });
            }
          });

          //dispatch(updateBuildinga)
        }

        counter.dec(1804);
        setMapPosition({ lat, lng });
        mapRef.current.panTo({ lat, lng });
        setGeolocationNotFound(false);
      },
      (error) => {
        counter.dec(1811);
        setMapPosition(null);
        setGeolocationNotFound(true);
      }
    );
  };

  React.useEffect(() => {
    if (
      typeof mapPosition == "undefined" ||
      mapPosition === null ||
      mapPosition.sizeof == 0
    ) {
      return;
    }
  }, [mapPosition, userLanguage]);

  React.useEffect(() => {
    axios.get(API_URL_ALLMACROAREA, {}).then((payload) => {
      const sortedMacroareas = sortMacroareas(payload.data.all);
      // update state and cache for macro areas
      setAllMacroAreas(sortedMacroareas);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (macroAreaSelect.length > 0) {
      const macroareaId = macroAreaSelect[0].id.toString();
      dispatch(updateBuildingMacroarea(macroareaId));
    }
  }, [macroAreaSelect]);

  const removeTargetItem = (targetName, idx) => {
    setAmenities((prevAmenities) => {
      if (!prevAmenities || !prevAmenities[idx]) {
        return prevAmenities;
      }
      const amenitiesCopy = JSON.parse(JSON.stringify(prevAmenities));
      const newList = amenitiesCopy[idx].list.filter(
        (item) => item.name !== targetName
      );
      amenitiesCopy[idx].list = newList;
      dispatch(updateBuildingAmenties(amenitiesCopy));
      return amenitiesCopy;
    });
  };

  const changeBuildingNameHandler = (e, language) => {
    //cancel the error
    if (buildingErrors.name)
      setBuildingErrors((prev) => ({ ...prev, name: false }));
    const payload = {
      language: language,
      name: selftCasting(e.target),
    };
    dispatch(updateBuildingName(payload));
  };

  const changeBuildingLongitudeHandler = (e) => {
    if (buildingErrors.lng)
      setBuildingErrors((prev) => ({ ...prev, lng: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingLongitude(payload));
  };

  const changeBuildingLatitudeHandler = (e) => {
    if (buildingErrors.lat)
      setBuildingErrors((prev) => ({ ...prev, lat: false }));

    const payload = selftCasting(e.target);
    dispatch(updateBuildingLatitude(payload));
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography className={classes.MenuTitle}>
          {dictionary.BuildingNameAndLocation}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          className={classes.subtitle}
          style={{ fontWeight: "600" }}
          id="name-section"
        >
          {dictionary.Name}
        </Typography>
      </Grid>
      <MultiLanguageTextField
        label={"BuildingName"}
        placeholder={"BuildingName"}
        textValue={building.name}
        changeTextHandler={changeBuildingNameHandler}
        popperText={dictionary.BuildingNameInfo}
        maxText={"30"}
        minText={"5"}
        error={buildingErrors.name}
      />

      <MultiLanguageLocationField
        classes={classes}
        buildingAddress={building.address}
        buildingAddressDetails={building.addressdetails}
        handleChangeAddress={handleChangeAddress}
        error={buildingErrors.address || buildingErrors.addressdetails}
      />
      {/* Map section*/}
      {/*Google maps grid */}
      <Grid
        item
        xs={12}
        style={{
          width: "100%",
          height: 400,
          display: "flex",
          justifyContent: "center",
          margin: "2rem 0",
        }}
      >
        <GoogleMap
          onLoad={(map) => {
            console.log("onLoad dunction");
            map.setCenter({ lat: 41.9028, lng: 12.4964 });
            map.setZoom(12);
            mapRef.current = map;
          }}
          mapContainerStyle={{
            width: " 100%",
            height: " 100%",
            margin: 0,
            padding: 0,
          }}
          onClick={(e) => {
            setMapPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
            setGeolocationNotFound(false);
          }}
          mapTypeId="roadmap"
          options={{
            styles: [],
          }}
        >
          {mapPosition && (
            <Marker
              onDragEnd={(e) => {
                setMapPosition({
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng(),
                });
                setGeolocationNotFound(false);
              }}
              clickable={false}
              draggable={true}
              position={mapPosition}
            />
          )}
        </GoogleMap>
      </Grid>
      <Grid item id="macroarea_list-section">
        <Autocomplete
          disabled={loadingMacroarea}
          classes={{
            inputRoot: classes.rootHeight,
          }}
          filterOptions={(opts, state) => {
            return _.filter(
              opts,
              (opt) =>
                opt.city.match(new RegExp(state.inputValue.toLowerCase(), "ig"))
                  ?.length > 0 ||
                opt.description.match(
                  new RegExp(state.inputValue.toLowerCase(), "ig")
                )?.length > 0
            );
          }}
          renderTags={(opts) => (
            <Typography>
              {returnStringTranslation(opts[0].description, userLanguage)}
            </Typography>
          )}
          getOptionLabel={(opt) =>
            returnStringTranslation(opt.description, userLanguage)
          }
          value={macroAreaSelect[0] || null} // Updated value structure
          onChange={(e, val, reason) => {
            setMacroAreaSelect(val ? [val] : []); // Updated onChange handler
          }}
          getOptionSelected={(opt) => macroAreaSelect[0]?.id === opt.id} // Updated getOptionSelected
          renderOption={(opt, state) => (
            <Grid container style={{ gap: 6 }}>
              <Grid item xs={"true"} container>
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="flex-start"
                  style={{ gap: 2 }}
                >
                  <Grid item>
                    <LocationOnOutlined fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 14 }}>{opt.city}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {returnStringTranslation(opt.description, userLanguage)}
                </Grid>
              </Grid>
            </Grid>
          )}
          id="macroarea-field"
          options={allMacroareas.filter((macroArea) => {
            const rv = citySelect
              ? macroArea.city.match(new RegExp(citySelect || "", "ig"))
              : true;
            return rv;
          })}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              label={dictionary.MacroArea}
              InputProps={{
                ...params.InputProps, // Spread to make sure other properties are passed down
                endAdornment: (
                  <>
                    {loadingMacroarea ? (
                      <InputAdornment position="end">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    ) : (
                      params.InputProps.endAdornment // The default dropdown arrow
                    )}
                  </>
                ),
              }}
              id="macroarea-section"
              error={buildingErrors.macroarea_list}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Typography className={`${classes.text} ${classes.editGps}`}>
          {dictionary.EditGPS}
        </Typography>
      </Grid>
      <Grid item container justifyContent="space-around">
        <Grid item md={4} xs={12} id="lng-section">
          <TextField
            label={dictionary.Longitude}
            placeholder={dictionary.Longitude}
            value={building.lng}
            fullWidth
            variant="outlined"
            onChange={(e) => changeBuildingLongitudeHandler(e)}
            name="float"
            style={{ marginBottom: "1rem" }}
            error={buildingErrors.lng}
          />
        </Grid>
        <Grid item md={4} xs={12} id="lat-section">
          <TextField
            label={dictionary.Latitude}
            placeholder={dictionary.Latitude}
            value={building.lat}
            fullWidth
            variant="outlined"
            onChange={(e) => changeBuildingLatitudeHandler(e)}
            name="float"
            error={buildingErrors.lat}
          />
        </Grid>
      </Grid>
      <Grid item id="amenities-section">
        <Accordion
          className={classes.accordionIneterests}
          disabled={!amenities || amenities.length === 0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.accordionSummary}
            expandIcon={
              loadingAmenities ? (
                <CircularProgress size={24} />
              ) : (
                amenities && amenities.length > 0 && <ExpandMoreIcon />
              )
            }
          >
            <Typography style={{ fontWeight: "500" }}>
              {dictionary.InterestsAndDistances}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "0" }}>
            {Array.isArray(amenities) && amenities.length > 0 && (
              <Grid container>
                {amenities.map((item, id) => {
                  return (
                    <NewAmenities
                      item={item}
                      id={id}
                      amenitiesCount={amenities.length}
                      onDelete={removeTargetItem}
                    />
                  );
                })}
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
      <MultipleMacroareasDialog
        open={openMacroareasDialog}
        setOpen={setOpenMacroareasDialog}
        setMacroAreaSelect={setMacroAreaSelect}
        macroareaArray={macroareaArray}
        setMacroareaArray={setMacroareaArray}
      />
    </>
  );
};

export default BuildingNameAndLocation;
