import React, { Fragment, useRef} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Divider, useMediaQuery } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Link } from "react-router-dom";
import { LanguageContext } from "../containers/languageprovider";
import Auth from "../auth/auth";
import { SUPER_ADMIN, BROKER, TENANT } from "../config/config";
import PeopleIcon from "@material-ui/icons/People";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import ApartmentIcon from "@material-ui/icons/Apartment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import RoomIcon from "@material-ui/icons/Room";
import StoreIcon from "@material-ui/icons/Store";
import SettingsIcon from "@material-ui/icons/Settings";
import EditAttributesIcon from "@material-ui/icons/EditAttributes";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import PolicyOutlinedIcon from "@material-ui/icons/PolicyOutlined";
import { useHistory } from "react-router-dom";
import LogoutDialog from "./LogoutDialog";
import globalStyles from "./globalStyles";
import CardTravelOutlinedIcon from "@material-ui/icons/CardTravelOutlined";
//import ContentPasteOutlined from '@material-ui/icons/ContentPasteOutlined';
import { PrefIcon, PersonSvgIcon } from "./iconlist.js";
import {CallMade, CallReceived, CallSplit, DescriptionOutlined} from "@material-ui/icons";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import { ReactComponent as SearchesIcon } from "../Images/download.svg";
const useStyles = makeStyles((theme)=> ({
  root: {
    marginTop: "20px",
    marginBottom: "20px",
    background: "#F5F5F5",
    borderRadius: "10px",
    width: "97%",

    "&$selected": {
      background: "#1C55E5",
      borderRadius: "10px",
      color: "white",
      "&:hover": {
        background: "#1C55E5",
        opacity: 0.6,
      },
    },
  },
  selected: {},
  IconActive: {
    color: "white",
  },
  iconNoActive: {
    color: "#6A6A6A",
  },

  PaperSendRequestInfo: {
    position:"static",
    padding: 20,
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    width:"400px",
    height:"955px",
  },
  PaperSendRequestInfoBottom: {
    position:"absolute",
    bottom: "0px",
    padding: 20,
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    width:"400px",
    height:"955px",
  },

  PaperSendRequestInfoFixed: {
    position: "fixed",
    top: "84px",
    padding: 20,
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.12)",
    borderRadius: "16px",
    width:"400px",
    height:"955px",
  },


  ContainerListDashBoard: {
   // position:  Auth.getUser().role === 9? "static" : "fixed",
    paddingTop: "25px",
    marginLeft: "24px",
    width: "200px",
    [theme.breakpoints.down("md")]: {
      width: "60px",
    },
  },


  ContainerListDashBoardAbsolute: {
    position:  "absolute",
    bottom: "10px",
    paddingTop: "25px",
    marginLeft: "24px",
    width: "200px",
    [theme.breakpoints.down("md")]: {
      width: "60px",
    },
  },


}));

export default function DrawerDashBoard(props) {
  const { dictionary } = React.useContext(LanguageContext);
  const [openLogoutDialog, setLogoutDialog] = React.useState(false);
  const user = Auth.getUser();
  const history = useHistory();
  const globalClasses = globalStyles();
  const classes = useStyles();
  // hides profile to everyone remove and readd as props if you want to show it to auth users
  const hideProfile = false;
  const [carouselCollider, setCarouselCollider] = React.useState(false);
  const [footerCollider, setFooterCollider] = React.useState(false);
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
  const lastLinkRef = useRef(null);



  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("lg"), { noSsr: true });

  const handleClick = () => {
    history.push("/");
  };

  React.useEffect(() => {
    if (window.performance && window.performance.navigation.type === 1) {
      scrollToTop()
    }
  }, [])



  function isOverlapping(element1, element2) {
    const rect1 = element1.getBoundingClientRect();
    const rect2 = element2.getBoundingClientRect();

    return !(
        rect1.bottom < rect2.top ||
        rect1.top > rect2.bottom ||
        rect1.right < rect2.left ||
        rect1.left > rect2.right
    );
  }

  React.useEffect(() => {
    let lastScrollTop = 0;
    let overlapStartScrollPosition = null;

    const handleScroll = () => {
      const overlapAmount = getOverlapAmount()
      if (lastLinkRef.current && props.footerRef && props.footerRef.current) {
        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (currentScrollTop > lastScrollTop) {
          if (isOverlapping(lastLinkRef.current, props.footerRef.current)) {
            setFooterCollider(true);
            overlapStartScrollPosition = currentScrollTop;
          }
        } else {
          // Scrolling up
          if (overlapStartScrollPosition && (overlapStartScrollPosition - currentScrollTop) >= overlapAmount) {
            setFooterCollider(false);
            overlapStartScrollPosition = null;
          }
        }
        lastScrollTop = currentScrollTop;
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  function scrollToTop() {
    window.scrollTo(0, 0);
  }









  const getWheigthLimit = () => {
    if (Auth.getUser().role === 1){
      return 800;
    } else if (Auth.getUser().role === 3){
      return 920;
    } else {
      return 935
    }
  }

  function getOverlapAmount() {
    const screenHeight = window.innerHeight;
    if (Auth.getUser().role === 1) {
      return 82 + (-82/80) * (screenHeight - 720);
    } else if (Auth.getUser().role === 2) {
      return 215 + (-215/215) * (screenHeight - 720);
    } else if (Auth.getUser().role === 3) {
      return 195 + (-195/200) * (screenHeight - 720);
    }
    return 200;
  }



  const getClassName = () => {
    const wheigth = window.innerHeight;
    const maxHeigth = getWheigthLimit();

    if (Auth.getUser().role === 9 || wheigth > maxHeigth || footerCollider === false){
      return classes.ContainerListDashBoard;
    }
    else if (footerCollider) {
      return classes.ContainerListDashBoardAbsolute;
    }
    else {
      return classes.ContainerListDashBoard;
    }
  };



  React.useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowHeight]);

  return (

    <div className={getClassName()} >
      <List className={globalClasses.listDashBoard}>
        {hideProfile && (
          <>
            <ListItem
              classes={{ root: classes.root, selected: classes.selected }}
              button
              key="profile"
              component={Link}
              to="/proposebuilding"
              selected={props.sel === "profile"}
            >
              <ListItemIcon>
                <PersonSvgIcon
                  fill={props.sel === "profile" ? "white" : "#6A6A6A"}
                  viewBox={"0 0 24 24"}
                />
              </ListItemIcon>
              {mdUp && <ListItemText primary={dictionary.Profile} />}
            </ListItem>
            <Divider />
          </>
        )}
        {user.role === TENANT && (
            <Fragment>
              {/* <Divider /> */}
              <ListItem
                  classes={{root: classes.root, selected: classes.selected}}
                  button
                  key="property_availability"
                  component={Link}
                  to="/property-availability"
                  selected={props.sel === "property_availability"}
              >
                <ListItemIcon>
                  <CardTravelOutlinedIcon
                      className={
                        props.sel === "property_availability"
                            ? classes.IconActive
                            : classes.iconNoActive
                      }
                  />
                </ListItemIcon>
                {mdUp && (
                    <ListItemText primary={dictionary.PropertyAvailability}/>
                )}
              </ListItem>
            </Fragment>
        )}

        {user.hasPortfolio() && (
            <Fragment>
              <ListItem
                  classes={{ root: classes.root, selected: classes.selected }}
                  button
                  key="portfolio"
                  component={Link}
                  to="/portfolio"
                  selected={props.sel === "portfolio"}
                  onClick={scrollToTop}
              >
                <ListItemIcon>
                  <CardTravelOutlinedIcon
                      className={
                        props.sel === "portfolio"
                            ? classes.IconActive
                            : classes.iconNoActive
                      }
                  />
                </ListItemIcon>
                {mdUp && <ListItemText primary={dictionary.PortfolioMenu} />}
              </ListItem>
            </Fragment>
        )}

        {user.isBroker() && (
            <Fragment>
              <ListItem
                  classes={{ root: classes.root, selected: classes.selected }}
                  button
                  key="leads-management"
                  component={Link}
                  to="/leads-management"
                  selected={props.sel === "leads-management"}
                  onClick={scrollToTop}
              >
                <ListItemIcon>
                  <RecentActorsIcon
                      className={
                        props.sel === "leads-management"
                            ? classes.IconActive
                            : classes.iconNoActive
                      }
                  />
                </ListItemIcon>
                {mdUp && <ListItemText primary={dictionary.LeadsManagement} />}
              </ListItem>

            </Fragment>
        )}


        {user.isAdmin() && (
            <ListItem
                classes={{ root: classes.root, selected: classes.selected }}
                button
                key="searchers-management"
                component={Link}
                to="/searchers-management"
                selected={props.sel === "searchers-management"}
            >
              <ListItemIcon>
                <CallSplit
                    style={{
                      width: "25px",
                      fill: `${props.sel === "searchers-management" ? "white" : "#6A6A6A"}`,
                    }}
                />
              </ListItemIcon>
              {mdUp && (
                  <ListItemText primary={dictionary.SearchersManagement} />
              )}
            </ListItem>

        )}


        {user.hasOfferor() && (
            <ListItem
                classes={{ root: classes.root, selected: classes.selected }}
                button
                key="offerors"
                component={Link}
                to="/offerors"
                selected={props.sel === "offerors"}
                onClick={scrollToTop}
            >
              <ListItemIcon>
                <CallReceived
                    style={{
                      width: "25px",
                      fill: `${props.sel === "offerors" ? "white" : "#6A6A6A"}`,
                    }}
                />
              </ListItemIcon>
              {mdUp && <ListItemText primary={dictionary.Requests} />}
            </ListItem>
        )}


        {user.hasSearcher() && (
            <Fragment>
              <ListItem
                  classes={{root: classes.root, selected: classes.selected}}
                  button
                  key="searches"
                  component={Link}
                  to="/searches"
                  selected={props.sel === "searches"}
                  onClick={scrollToTop}
              >
                <ListItemIcon>
                  <CallMade
                      style={{
                        width: "25px",
                        fill: `${props.sel === "searches" ? "white" : "#6A6A6A"}`,
                      }}
                  />
                </ListItemIcon>
                {mdUp && <ListItemText primary={dictionary.Searches}/>}
              </ListItem>
            </Fragment>
        )}

        {user.hasLongList() && (
          <Fragment>
            <ListItem
                classes={{ root: classes.root, selected: classes.selected }}
                button
                key="longlist"
                component={Link}
                to="/longlist"
                selected={props.sel === "longlist"}
                onClick={scrollToTop}
            >
              <ListItemIcon>
                <img
                    alt=""
                    src={
                      props.sel === "longlist"
                          ? "/images/broker/ContentPasteOutlined.svg"
                          : "/images/broker/ContentPasteOutlined_unselected.svg"
                    }
                    className={
                      props.sel === "longlist"
                          ? classes.IconActive
                          : classes.iconNoActive
                    }
                />
              </ListItemIcon>
              {mdUp && <ListItemText primary={dictionary.LonglistView} />}
            </ListItem>

          </Fragment>
        )}

        {user.hasTeaser() && (
            <Fragment>
              <ListItem
                  classes={{root: classes.root, selected: classes.selected}}
                  button
                  key="teaser"
                  component={Link}
                  to="/teaser/manage"
                  selected={props.sel === "teaser"}
                  onClick={scrollToTop}
              >
                <ListItemIcon>
                  <DescriptionOutlined
                      className={
                        props.sel === "teaser"
                            ? classes.IconActive
                            : classes.iconNoActive
                      }
                  />
                </ListItemIcon>
                {mdUp && <ListItemText primary={dictionary.TeaserMenu}/>}
              </ListItem>

            </Fragment>
        )}


        {user.role === SUPER_ADMIN && (
          <Fragment>

            <ListItem
              classes={{ root: classes.root, selected: classes.selected }}
              button
              key="users"
              component={Link}
              to="/userslist"
              selected={props.sel === "userslist"}
            >
              <ListItemIcon>
                <PeopleIcon
                  className={
                    props.sel === "userslist"
                      ? classes.IconActive
                      : classes.iconNoActive
                  }
                />
              </ListItemIcon>
              {mdUp && <ListItemText primary={dictionary.Users} />}
            </ListItem>

            <ListItem
              classes={{ root: classes.root, selected: classes.selected }}
              button
              key="companies"
              component={Link}
              to="/companies"
              selected={props.sel === "companies"}
            >
              <ListItemIcon>
                <AccountBalanceIcon
                  className={
                    props.sel === "companies"
                      ? classes.IconActive
                      : classes.iconNoActive
                  }
                />
              </ListItemIcon>
              {mdUp && <ListItemText primary={dictionary.Companies} />}
            </ListItem>
            <Divider />
            <ListItem
              classes={{ root: classes.root, selected: classes.selected }}
              button
              key="buildings"
              component={Link}
              to="/buildings"
              selected={props.sel === "buildings"}
            >
              <ListItemIcon>
                <ApartmentIcon
                  className={
                    props.sel === "buildings"
                      ? classes.IconActive
                      : classes.iconNoActive
                  }
                />
              </ListItemIcon>
              {mdUp && <ListItemText primary={dictionary.Buildings} />}
            </ListItem>
            <Divider />
            <ListItem
              classes={{ root: classes.root, selected: classes.selected }}
              button
              key="macroarea"
              component={Link}
              to="/macroarea"
              selected={props.sel === "macroarea"}
            >
              <ListItemIcon>
                <RoomIcon
                  className={
                    props.sel === "macroarea"
                      ? classes.IconActive
                      : classes.iconNoActive
                  }
                />
              </ListItemIcon>
              {mdUp && <ListItemText primary={dictionary.MacroArea} />}
            </ListItem>
            <ListItem
              classes={{ root: classes.root, selected: classes.selected }}
              button
              key="commercial_features"
              component={Link}
              to="/commercial_features"
              selected={props.sel === "commercial_features"}
            >
              <ListItemIcon>
                <StoreIcon
                  className={
                    props.sel === "commercial_features"
                      ? classes.IconActive
                      : classes.iconNoActive
                  }
                />
              </ListItemIcon>
              {mdUp && (
                <ListItemText primary={dictionary.commercial_features} />
              )}
            </ListItem>
            <ListItem
              classes={{ root: classes.root, selected: classes.selected }}
              button
              key="technical_features"
              component={Link}
              to="/technical_features"
              selected={props.sel === "technical_features"}
            >
              <ListItemIcon>
                <SettingsIcon
                  className={
                    props.sel === "technical_features"
                      ? classes.IconActive
                      : classes.iconNoActive
                  }
                />
              </ListItemIcon>
              {mdUp && <ListItemText primary={dictionary.technical_features} />}
            </ListItem>
            <ListItem
              classes={{ root: classes.root, selected: classes.selected }}
              button
              key="PlaceTypes"
              component={Link}
              to="/placetypes"
              selected={props.sel === "placetypes"}
            >
              <ListItemIcon>
                <RoomServiceIcon
                  className={
                    props.sel === "placetypes"
                      ? classes.IconActive
                      : classes.iconNoActive
                  }
                />
              </ListItemIcon>
              {mdUp && <ListItemText primary={dictionary.PlaceTypes} />}
            </ListItem>
            <Divider />
            <ListItem
              classes={{ root: classes.root, selected: classes.selected }}
              button
              key="news"
              component={Link}
              to="/listnews"
              selected={props.sel === "listnews"}
            >
              <ListItemIcon>
                <MenuBookIcon
                  className={
                    props.sel === "listnews"
                      ? classes.IconActive
                      : classes.iconNoActive
                  }
                />
              </ListItemIcon>
              {mdUp && <ListItemText primary={dictionary.News} />}
            </ListItem>
            <ListItem
              classes={{ root: classes.root, selected: classes.selected }}
              button
              key="newscategory"
              component={Link}
              to="/newscategory"
              selected={props.sel === "newscategory"}
            >
              <ListItemIcon>
                <EditAttributesIcon
                  className={
                    props.sel === "newscategory"
                      ? classes.IconActive
                      : classes.iconNoActive
                  }
                />
              </ListItemIcon>
              {mdUp && <ListItemText primary={dictionary.NewsCategory} />}
            </ListItem>
          </Fragment>
        )}
        <Divider />

        <ListItem
          classes={{ root: classes.root, selected: classes.selected }}
          button
          key="changepassword"
          component={Link}
          to="/changepassword"
          onClick={scrollToTop}
        >
          <ListItemIcon>
            <VpnKeyIcon
              className={
                props.sel === "changepassword"
                  ? classes.IconActive
                  : classes.iconNoActive
              }
            />
          </ListItemIcon>
          {mdUp && <ListItemText primary={dictionary.ChangePassword} />}
        </ListItem>
        {user.role !== BROKER && (
          <ListItem
            classes={{ root: classes.root, selected: classes.selected }}
            button
            key="favorites"
            component={Link}
            to="/favorites"
            selected={props.sel === "favorites"}
            onClick={scrollToTop}
          >
            <ListItemIcon >
              <PrefIcon
                fill={props.sel === "favorites" ? "white" : "#6A6A6A"}
                viewBox={"0 0 24 24"}
              />
            </ListItemIcon>
            {mdUp && <ListItemText primary={dictionary.Favorites} />}
          </ListItem>
        )}

        <ListItem
          classes={{ root: classes.root, selected: classes.selected }}
          button
          key="logout"
          onClick={() => setLogoutDialog(true)}
        >
          <ListItemIcon>
            <ExitToAppIcon
              className={
                props.sel === "logout"
                  ? classes.IconActive
                  : classes.iconNoActive
              }
            />
          </ListItemIcon>
          {mdUp && <ListItemText primary={dictionary.Logout} />}
        </ListItem>
      </List>
      <div ref={lastLinkRef}>
      <LogoutDialog
        open={openLogoutDialog}
        setOpen={setLogoutDialog}
        onClose={handleClick}
      />
      </div>
    </div>
  );
}
