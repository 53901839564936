import AppBar from "@material-ui/core/AppBar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { makeStyles, useTheme,   withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import PropTypes from "prop-types";
import React, { Fragment, useContext, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { LanguageContext } from "../containers/languageprovider";
import { useLocation } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import Auth from "../auth/auth";
import { BLOG_URL, NAME_APP } from "../config/config";
import DrawerMenu from "./DrawerMenu";
import Recover from "./recover";
/* import { Carousel } from "react-responsive-carousel";
import { Close } from "@material-ui/icons"; */

import { PersonSvgIcon } from "./iconlist.js";
import Dialog from "@material-ui/core/Dialog";
import MenuUser from "./MenuUser";
//import DialogActions from '@material-ui/core/DialogActions';
import {
  Box,
  Divider,
  Grid,
  Typography,
  /*   Chip,
  Popover,
  TextField,
  FormControl, */
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import globalStyles from "./globalStyles";
import LanguageSelector from "./LanguageSelector";
import LoginForm from "./loginForm";
import LogoutDialog from "./LogoutDialog";
import { returnStringTranslation } from "./printLanguageSelect";
import SignUpForm from "./signupForm";
/* import {
  EuroIcon,
  SolutionIcon,
  RightIcon,
  LeftIcon,
  PrefIcon,
} from "./iconlist.js";
import SwitchFavorites from "./switchFavorites";
import { ChevronLeft, ChevronRight } from "@material-ui/icons"; */
import SearchBuildings from "./SearchBuildings/SearchBuildings";
import SearchRequestsModal from "../SearcherRequestsModal/SearchRequestsModal";
import HowItWorksModal from "../SearcherRequestsModal/HowItWorksModal";

const drawerWidth = 0;

const useStyles = makeStyles((theme) => ({
  breadCrumpsStyle: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "all-small-caps",
    color: "#FFFFFF",
    paddingTop: 7,
    paddingLeft: 60,
  },

  breadCrumpsBar: {
    //height:36,
    minHeight: 6,
    //background: "linear-gradient(90deg, #CDE763 0.03%, #C5E464 7.37%, #AEDD67 18.88%, #8AD06B 33.12%, #5AC071 48.72%, #58BD75 59.16%, #52B37F 68.49%, #49A292 77.39%, #3B8AAB 86.03%, #296CCC 94.39%, #1C55E5 99.72%)"

    background: theme.breadCrumbsBar.background,
  },
  root: {
    display: "flex",
  },
  logo: {
    paddingLeft: 10,
    height: 32,
    cursor: "pointer",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  loginRegisterContainer: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },



  buildingNameHeader: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#000000",
    // marginLeft:"60px"
  },
  /*appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },*/
  menuButton: {
    //marginRight: theme.spacing(2),
    marginRight: "auto",
    color: "#1C55E5",
  },
  // necessary for content to be below app bar
  toolbar: {
    backgroundColor: "#ffffff",
    height: 74,
    overflow: "hidden",
  }, // theme.mixins.toolbar,
  drawerPaper: {
    //width: drawerWidth,
    width: "100%",
    backgroundColor: "#ffffff",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  exitButton: {
    marginLeft: "auto",
    marginRight: -12,
  },
  avatar: {
    height: 60,
    width: 60,
  },
  img: {
    height: 60,
  },
  companyLogo: {
    paddingLeft: 10,
    height: 72,
    cursor: "pointer",
  },
  loginButton: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,

    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },

  buttonLogin: {
    margin: "10px",
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "all-small-caps",
    color: "#1C55E5",
    width: "100px",
    borderRadius: "10px",
  },
  buttonLoginUser: {
    borderColor: "#1C55E5",
  },
  buildingPageInfo: {
    backgroundColor: "white",
    color: "black",
    padding: "20px 24px",
  },
  ArrowsCarousel: {
    width: "50px",
    height: "50px",
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 25px)",
    cursor: "pointer",
  },
  IconArrow: {
    width: "100%",
    height: "100%",
  },
}));


const BlackAndWhiteTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    fontSize:"12px",
  }
})(Tooltip);

//#3f51b5

function HeaderPublic(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { dictionary, userLanguage } = React.useContext(LanguageContext);
  const user = Auth.getUser();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openLogoutDialog, setLogoutDialog] = React.useState(false);
  const [openSearchDialog, setopenSearchDialog] = React.useState(false);
  const [anchorElPopover, setAnchorElPopover] = React.useState(null);
  const openPopover = Boolean(anchorElPopover);
  const idPopover = openPopover ? "simple-popover" : undefined;
  const [isCopied, setIsCopied] = React.useState(false);
  const [openHowItWorksDialog, setOpenHowItWorksDialog] = React.useState(false)


  const globalClasses = globalStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mdBr = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const [openSearchRequestsModal, setOpenSearchRequestsModal] =
    React.useState(false);
  const [searchRequestData, setSearchRequestData] = React.useState({
    assetClass: 1,
    dealType: 1,
    location: "",
  });

  const handleCloseSearchRequestsModal = () => {
    setOpenSearchRequestsModal(false);
  };


  //refreshes the page when im inside /proposebuilding and had submitted a proposal
  const handleProposeBuildingClick = () => {
    if (location.pathname === "/proposebuilding") {
      window.location.reload(); // Refresh the page
    }
  };






  const location = useLocation();

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    let myInterval = setInterval(() => {
      Auth.getToken();
    }, 5000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const GoHome = () => {
    if (props.company) return; // maybe link somewhere company related
    history.push("/");
  };

  const ConfirmLogin = () => {
    setOpen(false);
  };

  const container = window !== undefined ? window.document.body : undefined;

  // useEffect(() => {
  //   console.log("searchRequestData :", searchRequestData);
  // }, [searchRequestData]);



  const handleLoginAction  = (value) => {
    setMobileOpen(false);
    handleCloseMenu();
    setOpen(value)
  }


  const handleLogout = () => {
    setMobileOpen(false);
    handleCloseMenu();
    setLogoutDialog(true);
  }


  return (
    <Fragment>
      <CssBaseline />
      {props.AppBarVisible && (
        <AppBar position={props.Position} className={classes.appBar}>
          <Box display={props.company ? "none" : "initial"}>
            <Toolbar className={classes.toolbar}>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                style={{ width: "100%" }}
              >
                <Grid item xs>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    wrap="nowrap"
                    style={{ height: "100%", gap: "20px" }}
                  >
                    <Grid item>
                      <img
                        className={
                          props.company ? classes.companyLogo : classes.logo
                        }
                        style={{
                          // pointerEvents: props.company ? "none" : "initial",
                        }}
                        src={
                          props.company
                            ? props.company.logo || ""
                            : "/images/logo.svg"
                        }
                        alt="logo"
                        onClick={GoHome}
                      />
                    </Grid>
                    {!props.company && (
                      <Hidden smDown>
                        <Grid item>
                          <Link
                            className={globalClasses.HeaderLinkMenu}
                            color="inherit"
                            component={RouterLink}
                            to={{ pathname: BLOG_URL }}
                            target="_blank"
                          >
                            {dictionary.blog}
                          </Link>
                        </Grid>

                        {!mdBr && (
                          <Grid item>
                            <Link
                              className={globalClasses.HeaderLinkMenu}
                              color="inherit"
                              component={RouterLink}
                              to={{
                                pathname: "/services",
                                state: { scroll: true },
                              }}
                            >
                              {dictionary.Service}
                            </Link>
                          </Grid>
                        )}
                        {!mdBr && (
                          <Grid item>
                            <Link
                              className={globalClasses.HeaderLinkMenu}
                              color="inherit"
                              component={RouterLink}
                              to={{
                                pathname: "/about-us",
                                state: { scroll: true },
                              }}
                            >
                              {dictionary.AboutUs}
                            </Link>
                          </Grid>
                        )}
                      </Hidden>
                    )}
                  </Grid>
                </Grid>
                {!props.company && (
                  <Hidden smDown>
                    <Grid item xs>
                      <Grid container wrap="nowrap" justifyContent="center">
                        {!props.hideSearch && (
                          <Grid item xs="auto">
                            <Button
                              onClick={() => {
                                setopenSearchDialog(true);
                              }}
                              className={globalClasses.buttonSecondary}
                              variant="outlined"
                            >
                              {dictionary.StartNewSearch}
                            </Button>
                          </Grid>
                        )}
                        <Grid item xs="auto">
                          <Link
                            style={{ textDecoration: "none" }}
                            component={RouterLink}
                            to="/proposebuilding"
                          >
                            <Button
                              className={globalClasses.buttonLogin}
                              variant="outlined"
                              color="primary"
                              onClick={handleProposeBuildingClick}
                            >
                              {dictionary.ProposeBuilding}
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                    {mdBr && (
                      <Grid
                        item
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Link
                          className={globalClasses.HeaderLinkMenu}
                          color="inherit"
                          component={RouterLink}
                          to={{
                            pathname: "/about-us",
                            state: { scroll: true },
                          }}
                        >
                          {dictionary.AboutUs}
                        </Link>
                      </Grid>
                    )}
                    <Grid
                      container
                      item
                      xs
                      justifyContent="flex-end"
                      alignItems="center"
                      wrap="nowrap"
                    >
                      {!props.company && (
                        <Fragment>
                          <Button
                            className={classes.loginRegisterContainer}
                            onClick={handleClickMenu}
                          >
                            {user ? (
                              <Typography color="secondary">
                                {user.name}
                              </Typography>
                            ) : (
                                <BlackAndWhiteTooltip
                                    title={`${dictionary.headerRegisterTooltip}`}
                                    aria-label="add"

                                >
                                  <Typography
                                      color="secondary"
                                  >
                                    {`${dictionary.Login}/${dictionary.Register}`}
                                  </Typography>
                                </BlackAndWhiteTooltip>
                            )}
                            <PersonSvgIcon
                              style={{ margin: "0px 5px" }}
                              fill="#6a6a6a"
                              viewBox={"0 0 18 24"}
                            />
                          </Button>


                          <Divider
                            style={{
                              margin: "0px 10px",
                              height: "25px",
                              width: "2px",
                            }}
                            color="#6a6a6a"
                            orientation="vertical"
                          />

                          <LanguageSelector
                            style={{
                              fontFamily: "Barlow",
                              fontStyle: "normal",
                              fontWeight: "600",
                              fontSize: "13px",
                              lineHeight: "16px",
                              textAlign: "center",
                              letterSpacing: "0.03em",
                              textTransform: "uppercase",
                            }}
                          />
                        </Fragment>
                      )}
                    </Grid>
                  </Hidden>
                )}
                {props.company && (
                  <Grid
                    container
                    item
                    xs
                    justifyContent="flex-end"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    {!props.company && (
                      <Fragment>
                        <Button
                          className={classes.loginRegisterContainer}
                          onClick={handleClickMenu}
                        >
                          {user ? (
                            <Typography color="secondary">
                              {user.name}
                            </Typography>
                          ) : (
                              <BlackAndWhiteTooltip
                                  title={`${dictionary.headerRegisterTooltip}`}
                                  aria-label="add"

                              >
                            <Typography color="secondary">
                              {`${dictionary.Login}/${dictionary.Register}`}
                            </Typography>
                              </BlackAndWhiteTooltip>
                          )}
                          <PersonSvgIcon
                            style={{ margin: "0px 5px" }}
                            fill="#6a6a6a"
                            viewBox={"0 0 18 24"}
                          />
                        </Button>

                        <Divider
                          style={{
                            margin: "0px 10px",
                            height: "25px",
                            width: "2px",
                          }}
                          color="#6a6a6a"
                          orientation="vertical"
                        />
                        <LanguageSelector
                          style={{
                            fontFamily: "Barlow",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "13px",
                            lineHeight: "16px",
                            textAlign: "center",
                            letterSpacing: "0.03em",
                            textTransform: "uppercase",
                          }}
                        />
                      </Fragment>
                    )}
                  </Grid>
                )}
              </Grid>
              {props.company && (
                <LanguageSelector
                  style={{
                    fontFamily: "Barlow",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "16px",
                    textAlign: "center",
                    letterSpacing: "0.03em",
                    textTransform: "uppercase",
                  }}
                />
              )}
              {!props.company && (
                <Hidden mdUp>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>
              )}
            </Toolbar>
          </Box>

          {props.company && (
            <Toolbar className={classes.toolbar}>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item xs={6} sm={4}>
                  <img
                    className={classes.companyLogo}
                    src={props.company.logo || ""}
                    alt="logo"
                  />
                </Grid>
                <Hidden xsDown>
                  <Grid item xs={4} container justifyContent="center">
                    <Grid item xs={12}>
                      <Typography
                        className={classes.buildingNameHeader}
                        color="textSecondary"
                        align="center"
                      >
                        {returnStringTranslation(
                          props.building.name,
                          userLanguage
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Hidden>
                <Grid item xs={6} sm={4} container justifyContent="flex-end">
                  <Grid item>
                    <LanguageSelector
                      style={{
                        fontFamily: "Barlow",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "13px",
                        lineHeight: "16px",
                        textAlign: "center",
                        letterSpacing: "0.03em",
                        textTransform: "uppercase",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          )}
          <div className={classes.breadCrumpsBar}>
            {props.breadCrumpsBar && (
              <Breadcrumbs
                className={classes.breadCrumpsStyle}
                aria-label="breadcrumb"
              >
                <Link color="inherit" component={RouterLink} to="/">
                  {NAME_APP}
                </Link>

                <Link color="inherit" component={RouterLink} to="/buildings">
                  {dictionary.Buildings}
                </Link>
                <Link color="textPrimary" href="#" aria-current="page">
                  {dictionary.Details}
                </Link>
              </Breadcrumbs>
            )}
          </div>
        </AppBar>
      )}

      <nav className={classes.drawer} aria-label="Options Menù">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <DrawerMenu
              handleDrawerToggle={handleDrawerToggle}
              onClickSearch={() => setopenSearchDialog(true)}
              onClickLogout={handleLogout}
              handleLoginAction={handleLoginAction}
            />
          </Drawer>
        </Hidden>
      </nav>

      <Dialog
        fullScreen={fullScreen}
        open={open !== false}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <IconButton
              aria-label="close"
              className={globalClasses.closeButton}
              onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {open === "login"  && dictionary.welcomeBack}
          {open === "signup" && dictionary.CreateAnAccount}
          {open === "password"  && dictionary.RecoverPassword}
          {open === "recover" && dictionary.RecoverInfo}
        </DialogTitle>
        <DialogContent>
          {open === "login" && <LoginForm  infoClose={ConfirmLogin} handleLoginAction={handleLoginAction}/>}
          {open === "signup" && <SignUpForm isDialog={true} infoClose={ConfirmLogin} handleLoginAction={handleLoginAction}/>}
          {open === "password" && <Recover  isDialog={true}  handleLoginAction={handleLoginAction} infoClose={ConfirmLogin} what={"password"}/>}
          {open === "recover" && <Recover  isDialog={true}   handleLoginAction={handleLoginAction} infoClose={ConfirmLogin} />}
        </DialogContent>
      </Dialog>

      <MenuUser
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        onClickLogout={handleLogout}
        handleLoginAction={handleLoginAction}
      />
      <LogoutDialog
        open={openLogoutDialog}
        setOpen={setLogoutDialog}
        onClose={() => setLogoutDialog(false)}
      />
      <SearchBuildings
        type="dialogSearch"
        openDialog={openSearchDialog}
        setOpenDialog={setopenSearchDialog}
        setSearchRequestData={setSearchRequestData}
        setOpenSearchRequestsModal={setOpenSearchRequestsModal}
        setOpenHowItWorksDialog={setOpenHowItWorksDialog}
      />
      <SearchRequestsModal
        open={openSearchRequestsModal}
        setOpen={setOpenSearchRequestsModal}
        closeDialog={handleCloseSearchRequestsModal}
        searchRequestData={searchRequestData}
        setOpenHowItWorksDialog={setOpenHowItWorksDialog}
        type="home_page"
      />
      <HowItWorksModal open={openHowItWorksDialog} closeDialog={()=>{setOpenHowItWorksDialog(false)}} setOpenSearchRequestsModal={setOpenSearchRequestsModal} />
    </Fragment>
  );
}
/*
 */

HeaderPublic.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default HeaderPublic;

/*
<DialogActions>
            <Button onClick={handleClose} color="primary">              
              {dictionary.Close}
            </Button>            
          </DialogActions>

*/
