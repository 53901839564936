import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useContext, useEffect, useRef, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { LanguageContext } from "../../containers/languageprovider";
/* import { buildingsStore } from "../../lib/store";*/
import "../../css/SearcherArea.css"
import globalStyles from "../globalStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { API_URL_LOCATIONS } from "../../config/config";
import CircularProgress from "@material-ui/core/CircularProgress";
import RoomIcon from "@material-ui/icons/Room";
import { searchStore } from "../../lib/store";
import CloseIcon from "@material-ui/icons/Close";

const homePageSearchStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    minWidth: 120,
    maxWidth: "100%",
  },
  SearchContainer: {
    marginTop: "-80px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-100px",
      textAlign: "end",
    },
  },

  SelectClass: {
    textAlign: "left",
  },
  Option: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  OptionLocation: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "grey",
    fontSize: "0.8rem",
    width: "100%",
    marginTop: "0.5rem",
  },

  fabSearchMobile: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",


    //display: "flex",
    alignItems: "center",
    textAlign: "center",

    color: "#FFFFFF",
    textTransform: "none",

    width: "100%",
    borderRadius: "16px",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  fabSearch: {
    margin: theme.spacing(1),
    height: "86px",
    width: "100%",
    cursor:"default",
    background: "white",
    "&:hover": {
      background: "white",
    },
    "&:active": {
      background: "white",
    },
    "&:target": {
      background: "white",
    },
    textTransform: "none",
    borderRadius: "16px",
    [theme.breakpoints.down("sm")]: {
      /*display:"none"*/
      height: "406px",
    },
  },
}));

const dialogSearchStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  siteLogo: {
    marginBottom: theme.spacing(2),
  },
  TextSearch: {
    fontSize: "16px",
    fontFamily: "Barlow",
    color: "#6a6a6a",
  },

  dialogActions: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    justifyContent: "center",
  },

  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  SearchInfoAutoComplete: {
    marginTop: "20px",
    //width:"240px"
  },

  DialogTitle: {
    paddingTop: "20px",
    paddingBottom: "50px",
    //width:"240px",
    textAlign: "center",
  },

  SearchBtn: {
    height: "46px",
    width: "calc(100% - 16px)",
    marginRight: "8px",
    marginLeft: "8px",
    marginTop: "50px",
    marginBottom: "50px",
    color: "white",
    backgroundColor: "#141B4D",
    "&:disabled": {
      backgroundColor: "#e0e0e0",
      color: "#adadad",
    },
    "&:hover": {
      backgroundColor: "#141B4DCC",
      color: "white",
    },
  },
  DialogContentClass: {
    margin: "10px 20px",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "calc(100% - 16px)",
  },
  FormContainer: {
    width: "100%",
  },
  Option: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  OptionLocation: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "grey",
    fontSize: "0.8rem",
    width: "100%",
    marginTop: "0.5rem",
  },
  ctaRequest: {
    width: '341px',
    height: '38px',
    margin: '10px 0 11px 7px',
    fontFamily: 'Barlow, sans-serif, Verdana',
    fontSize: '13px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.46',
    letterSpacing: '-0.22px',
    color: '#212121', },
}));

const SearchBuildings = ({
  type,
  openDialog,
  setOpenDialog,
  selectedAssetClass,
  selectedDealType,
  searchRef,
  setSearchRequestData,
  setOpenSearchRequestsModal,
  setOpenHowItWorksDialog,
}) => {
  //commun variables
  const classes =
    type === "homePageSearch" ? homePageSearchStyles() : dialogSearchStyles();
  const globalClasses = globalStyles();
  const history = useHistory();
  const { dictionary, userLanguage } = useContext(LanguageContext);

  const inputLabel = useRef(null);
  const inputLabel2 = useRef(null);
  const [labelWidth, setLabelWidth] = useState(
    type === "homePageSearch" ? 0 : 132
  );
  const [labelWidth2, setLabelWidth2] = useState(
    type === "homePageSearch" ? 0 : 132
  );

  const [assetClassSelect, setAssetClass] = useState(1);
  const [dealType, setDealType] = useState(1);
  const [citySelect, setCitySelect] = useState("");
  const [locationSelect, setLocationSelect] = useState("");
  const [locationType, setLocationType] = useState("");
  const [firstLocationSelect, setFirstLocationSelect] = useState("");
  const [firstLocationType, setFirstLocationType] = useState("");
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMadeApiCall, setHasMadeApiCall] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [eventType, setEventType] = useState("");
  const [changeReason, setChangeReason] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [searchLocation, setSearchLocation] = useState("");

  useEffect(() => {
    if (setSearchRequestData)
      setSearchRequestData({
        assetClass: assetClassSelect,
        dealType: dealType,
        location: searchLocation,
      });
  }, [assetClassSelect, dealType, searchLocation]);

  //variables for searchDialog
  const cache = searchStore();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const observedElement = useRef(null);

  //functions
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeAssetClass = (event) => {
    setAssetClass(event.target.value);
  };

  const handleChangeDealType = (event) => {
    setDealType(event.target.value);
  };

  useEffect(() => {
    if (type === "homePageSearch") {
      setLabelWidth(inputLabel.current.offsetWidth);
      setLabelWidth2(inputLabel2.current.offsetWidth);
    }
  }, [type]);

  const searchUnits = () => {
    let stringSearch =
      "/searchresults?" +
      "&" +
      locationType +
      "=" +
      encodeURIComponent(locationSelect) +
      "&dealType=" +
      dealType +
      "&assetClass=" +
      assetClassSelect +
      "&language=" +
      userLanguage;

    let newString;
    newString = stringSearch.replace(/€/g, "");
    stringSearch = newString;

    newString = stringSearch.replace(/m²/g, "");
    stringSearch = newString;

    newString = stringSearch.replace(/ /g, "");
    stringSearch = newString;

    history.push(stringSearch);

    if (type === "dialogSearch") {
      if (window.location.pathname.split("/")[1] === "searchresults")
        history.go();
      setOpenDialog(false);
    }
  };

  useEffect(() => {
    console.log('Options:', results.slice(0, 20));
  }, [results]);


  const search = async (value) => {
    setCitySelect("");
    // setLocationSelect("");
    // setLocationType("");
    if (value.length < 2) {
      setOptionsOpen(false);
      setButtonEnabled(false);
      setResults([]);
      setHasMadeApiCall(false);
      return;
    }
    setLoading(true);
    setHasMadeApiCall(true);

    axios
      .get(
        API_URL_LOCATIONS +
          `/search?searchTerm=${value}&language=${userLanguage}&dealType=${dealType}&assetClass=${assetClassSelect}`
      )
      .then((response) => {
        setLoading(false);
        if (response.data.locations.length == 0) {
          setButtonEnabled(false);
          setResults([]);
          return;
        }
        const sortedResults = sortSuggestions(value, response.data.locations);
        setResults(sortedResults);
        setCitySelect(sortedResults[0].name);
        setFirstLocationSelect(sortedResults[0].name);
        setFirstLocationType(sortedResults[0].search);
        setButtonEnabled(true);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const typePriority = {
    administrative_area_level_3: 1,
    administrative_area_level_2: 2,
    administrative_area_level_1: 3,
    macro_area: 4,
    postal_code: 5,
    country: 6,
  };

  const sortSuggestions = (inputValue, suggestions) => {
    return suggestions.sort((a, b) => {
      if (a.name.startsWith(inputValue) && !b.name.startsWith(inputValue)) {
        return -1;
      } else if (
        !a.name.startsWith(inputValue) &&
        b.name.startsWith(inputValue)
      ) {
        return 1;
      } else if (a.type !== b.type) {
        return typePriority[a.type] - typePriority[b.type];
      } else {
        return a.name.localeCompare(b.name);
      }
    });
  };

  useEffect(() => {
    if (type === "dialogSearch") {
      if (selectedAssetClass != null) {
        setAssetClass(selectedAssetClass);
      }
      if (selectedDealType != null) {
        setDealType(selectedDealType);
      }
    }
  }, [openDialog]);


  //components variables
  const AssetClassSelector = (
    <FormControl fullWidth variant="outlined" className={classes.formControl}>
      <InputLabel ref={inputLabel}>{dictionary.AssetClass}</InputLabel>
      <Select
        labelWidth={labelWidth}
        value={assetClassSelect}
        onChange={handleChangeAssetClass}
      >
        <MenuItem value={1}>{dictionary.Office}</MenuItem>
        <MenuItem value={2}>{dictionary.Retail}</MenuItem>
        <MenuItem value={3}>{dictionary.Industrial}</MenuItem>
      </Select>
    </FormControl>
  );

  const DealTypeSelector = (
    <FormControl fullWidth variant="outlined" className={classes.formControl}>
      <InputLabel ref={inputLabel2}>{dictionary.DealType}</InputLabel>
      <Select
        labelWidth={labelWidth2}
        value={dealType}
        onChange={handleChangeDealType}
      >
        <MenuItem value={1}>{dictionary.ForRent}</MenuItem>
        <MenuItem value={2}>{dictionary.ForSale}</MenuItem>
      </Select>
    </FormControl>
  );

  const AdvansedSearch = (
    <FormControl required variant="outlined" className={classes.formControl}>
      <Autocomplete
        freeSolo={false}
        clearOnBlur={false}
        open={optionsOpen}
        onOpen={() => setOptionsOpen(true)}
        filterOptions={(x, y) => {
          return x;
          if (x.length == 0) {
          }

          return [x[0]];
        }}
        className={classes.SelectClass}
        getOptionSelected={(option, value) => {
          return option.name === value.name;
        }}
        getOptionLabel={(option) => {

          if (typeof option == "string") {
            return option;
          } else {
            return option.name;
          }
        }}
        options={results.slice(0, 20)}
        //loading={loading}
        renderOption={(option, starte, pos) => {
          const regexp = new RegExp(
            `(${searchTerm.trim().replace(/\s+/g, "|")})`,
            "gi"
          );
          const parts = option.name.split(regexp);

          return (
            <Fragment key={option.id}>
              <div className={classes.Option}>
                <div>
                  {parts.map((part, index) => (
                    <Fragment key={index}>
                      <span
                        style={{ fontWeight: index % 2 ? "bold" : "normal" }}
                      >
                        {part}
                      </span>
                    </Fragment>
                  ))}

                  {option.type === "administrative_area_level_3" && (
                    <span>,{option.label}</span>
                  )}
                  {option.type === "postal_code" && (
                    <span>,{option.label}</span>
                  )}
                  {option.type === "macroarea" && <span>,{option.label}</span>}
                </div>

                <div className={classes.OptionLocation}>
                  <div>
                    <RoomIcon style={{ fontSize: "0.8rem" }} />
                    {/*render type of location*/}
                    {(() => {
                      if (typeof typePriority[option.type] != "undefined") {
                        return <span>{dictionary[option.type]}</span>;
                      }
                      return <span> </span>;
                    })()}
                  </div>
                  {option.count}
                </div>
              </div>
            </Fragment>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onFocus={() => setOptionsOpen(true)}
            label={dictionary.location + " *"}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
        onInputChange={(event, newInputValue, type) => {
          setSearchLocation(newInputValue);
          setEventType(event?.type);
          setSearchTerm(newInputValue);
        }}
        onChange={(event, newValue, reason) => {
          setChangeReason(reason);
          handleChangeEvents(event, newValue, reason);
        }}
        noOptionsText={
          hasMadeApiCall ? (
            <div style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
              <span className="no-matches-found">{dictionary.CityNotFound} </span>
              <div class="Line-2"></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "black",
                }}
              >
                <span className="requestHomePageCTA">
                  <span style={{fontWeight: 600}}>{dictionary.SendARequestTitle}</span><br/>
                {dictionary.HelpYouToContact}</span>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    style={{
                      fontWeight: "500",
                      padding: "10px 15px",
                      fontFamily: "Montserrat, Barlow, sans-serif",

                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenSearchRequestsModal(true);
                      setOptionsOpen(false);
                      handleCloseDialog();
                    }}
                >
                  {dictionary.SendD4REaRequest}
                </Button>
                <div style={{width:"100%", display:"flex", justifyContent:"center", marginTop:"7px"}}>
                <a
                    onClick={()=>{
                      setOpenHowItWorksDialog(true)
                      handleCloseDialog();
                                }}
                    className={"How-it-works"} >
                  {dictionary.HowItWorks}
                </a>
                </div>
              </div>
            </div>
          ) : (
            <span className="no-matches-found">{dictionary.SearchByLocation}</span>
          )
        }
      />
    </FormControl>
  );

  const SearchButton = (
    <Button
      disabled={!buttonEnabled}
      className={
        type === "homePageSearch" ? globalClasses.SearchBtn : classes.SearchBtn
      }
      aria-disabled={!buttonEnabled}
      variant="contained"
      color="primary"
      onClick={handleButtonClick}
    >
      {dictionary.Search}
    </Button>
  );

  const handleChangeEvents = (event, newValue, reason) => {
    if (newValue == null) {
      setLocationSelect("");
      setLocationType("");
      setButtonEnabled(false);
      return;
    }
    if (reason == "select-option") {
      setButtonEnabled(true);
    }

    setLocationSelect(newValue.name);
    setLocationType(newValue.search);
    // searchUnits();
    //setCitySelect(newValue.name);
  };

  function handleButtonClick() {
    if (firstLocationSelect === "" || firstLocationType === "") {
      return;
    }
    setChangeReason("search-button-click");
    setLocationSelect(firstLocationSelect);
    setLocationType(firstLocationType);
  }

  // list of building
  useEffect(() => {
    if (!buttonEnabled || locationSelect === "" || locationType === "") {
      return;
    }
    searchUnits();
  }, [locationSelect, locationType]);

  // on change of the dealType, assetClassSelect
  // list of city
  useEffect(() => {
    search(searchTerm);
    setOptionsOpen(false);
  }, [dealType, assetClassSelect]);

  // on change of searchTerm
  // list of city
  useEffect(() => {
    if (eventType === "change") {
      search(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            // fired when the target element is no longer in the viewport
            setOptionsOpen(false);
          }
        });
      },
      { threshold: [0.9], rootMargin: "-60px" }
    );
    if (observedElement.current) observer.observe(observedElement.current);
  }, []);

  return type === "homePageSearch" ? (
    <div ref={observedElement}>
      <div
        ref={searchRef}
        className={`${classes.SearchContainer} home-page-search`}
      >
        <Grid container spacing={3}>
          <Grid item md={1} xs={12}></Grid>
          <Grid item md={10} xs={12}>
            <Fab
              className={classes.fabSearchMobile}
              color="primary"
              aria-label="add"
            >
              {dictionary.LookingFor}
            </Fab>
            <Fab
              component={"span"}
              variant="extended"
              className={classes.fabSearch}
              disableRipple
              disableFocusRipple
            >
              <Grid container spacing={3}>
                <Grid item md={2} xs={12}>
                  {AssetClassSelector}
                </Grid>
                <Grid item md={2} xs={12}>
                  {DealTypeSelector}
                </Grid>
                <Grid item md={6} xs={12}>
                  {AdvansedSearch}
                </Grid>
                <Grid item md={2} xs={12}>
                  {SearchButton}
                </Grid>
              </Grid>
            </Fab>
          </Grid>
          <Grid item md={1} xs={12}></Grid>
        </Grid>
      </div>
    </div>
  ) : (
    <Dialog
      fullScreen={fullScreen}
      open={openDialog}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="sm"
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
        <IconButton
          aria-label="close"
          className={globalClasses.closeButton}
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.DialogContentClass}>
        <Typography className={classes.DialogTitle} variant="h6">
          {dictionary.LookingFor}
        </Typography>

        <div className={classes.FormContainer}>
          {AssetClassSelector}
          {DealTypeSelector}
          {AdvansedSearch}
          {SearchButton}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SearchBuildings;
