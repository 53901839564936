import React, { useContext, useEffect, useState } from "react";
import DrawerDashBoard from "../../DrawerDashBoard";
import { Helmet } from "react-helmet";
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  Hidden,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import globalStyles from "../../globalStyles";
import Footer from "../../Footer";
import UnitStickyMenu from "../Sections/UnitStickyMenu";
import { LanguageContext } from "../../../containers/languageprovider";
import UnitDescription from "../Sections/UnitDescription/UnitDescription";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import UnitInformations from "../Sections/UnitInformations/UnitInformations";
import Auth from "../../../auth/auth";
import axios from "axios";
import {
  ALERT_UNIT_MESSAGES,
  API_DELETE_UNIT_V2,
  API_EDIT_NEW_BUILDING_V2,
  API_EDIT_UNIT_V2,
  API_URL_BROKER_COMPANIES,
  API_URL_EDIT_UNIT_V2,
  ASSET_CLASSES,
  DEAL_TYPE_LEASE,
  DEAL_TYPE_LEASE_INT,
  DEAL_TYPE_SALE,
  DEAL_TYPE_SALE_INT,
  DEAL_TYPES,
  TYPE_SIZE_EDIT_BUILDING,
  UNIT_MANDATE_AGREEMENT,
} from "../../../config/config";
import {
  customFloorOptions,
  processApiErrors,
  routeReplace,
  TranslationUtils,
} from "../../utils";
import UnitFeatures from "../Sections/UnitFeatures/UnitFeatures";
import MandateAgreement from "../Sections/MandateAgreement/MandateAgreement";
import Broker from "../Sections/Broker/Broker";
import AlertMessages from "../Sections/AlertMessages/AlertMessages";
import {
  resetUnitSlice,
  updateUnitIsNew,
  updateUnitSlice,
} from "../../../reducers/UnitSlice";
import Validator from "validator-d4re";

const useStyles = makeStyles((theme) => ({
  MenuTitle: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 500,
    marginBottom: "2rem",
  },
  Tab: {
    minWidth: "30px",
  },
  subtitle: {
    fontSize: "18px",
    marginTop: "1.5rem",
    textTransform: "capitalize",
  },
  text: {
    fontSize: "15px",
  },
  editGps: {
    margin: "10px 0 20px 0",
  },
  accordionIneterests: {
    marginTop: "20px",
    backgroundColor: "#eaeaea",
  },
  accordionSummary: {
    borderBottom: "1px solid #d9d9d9",
  },
  interestsContaner: {
    borderBottom: "1px solid #d9d9d9",
    padding: "1rem",
  },
  interestItemContainer: {
    display: "flex",
    padding: "0.5rem 1rem",
  },
  interestTitle: {
    marginLeft: "10px",
    textTransform: "uppercase",
  },
  interestItem: {
    display: "flex",
    justifyContent: "flex-end",
  },
  typeLinkVirtual: {
    marginBottom: "1rem",
  },
  ButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2rem",
  },
  SaveButton: {
    padding: "0.5rem 1.5rem",
    marginBottom: "2rem",
    marginRight: "10px",
  },
  ArrowsCarousel: {
    width: "30px",
    height: "30px",
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 25px)",
    cursor: "pointer",
  },
  IconArrow: {
    width: "100%",
    height: "100%",
    "&:hover": {
      opacity: 1,
      borderColor: "red",
    },
  },
}));

const AddUnit = () => {
  const user = Auth.getUser();
  //axios config
  const token = Auth.getToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  axios.defaults.headers.common["Content-Type"] = "application/json";

  const theme = useTheme();
  const smBreakpoint = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  //Get unit slice from redux
  const unit = useSelector((state) => state.unit);

  // Create unit errors dynamically based on the properties of the units
  const initialUnitErrors = {};
  Object.keys(unit).forEach((property) => {
    initialUnitErrors[property] = false;
  });

  const [unitErrors, setUnitErrors] = useState(initialUnitErrors);

  const { buildingId, unitId } = useParams();

  const globalClasses = globalStyles();
  const { dictionary, userLanguage } = useContext(LanguageContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  //unit floors
  const [unitFloors, setUnitFloors] = useState(0);

  //unit undeground floors
  const [unitUndergroundFloors, setUnitUndergroundFloors] = useState(0);

  // unit floor options
  const [unitFloorOptions, setUnitFloorOptions] = useState([{}]);
  const [changeExclusiveArchiveSelector, setChangeExclusiveArchiveSelector] =
    useState("null");
  const [changeUnitSizeTerracesSelector, setChangeUnitSizeTerracesSelector] =
    useState("null");

  //all companies
  const [allCompanies, setAllCompanies] = useState([]);

  //initiate all the companies
  useEffect(() => {
    axios
      .get(API_URL_BROKER_COMPANIES, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((companyPayLoad) => {
        let data = companyPayLoad.data.all;
        setAllCompanies(data);
      });
  }, []);

  /**
   * Create a unit related to a building
   * @param buildingId
   */

  useEffect(() => {
    //call the get building edit api to get the floors & undergoundfloors
    axios
      .get(routeReplace(API_EDIT_NEW_BUILDING_V2, { buildingId: buildingId }))
      .then((res) => {
        const { floors, underground_floors } = res.data.record;
        console.log("floors : ", floors);
        console.log("underground_floors : ", underground_floors);
        setUnitFloors(floors);
        setUnitUndergroundFloors(underground_floors);
      });
  }, [buildingId]);

  useEffect(() => {
    setUnitFloorOptions(
      customFloorOptions(unitUndergroundFloors, unitFloors, userLanguage)
    );
  }, [unitFloors, unitUndergroundFloors, userLanguage]);

  /*   //close the Alert message
  const handleClose = (fieldName) => {
    setUnitErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: false,
    }));
  }; */

  //close the Alert message
  const handleClose = (fullFieldName) => {
    const [fieldName, messageId] = fullFieldName.split("|");
    const oldErrorItem = unitErrors[fieldName];
    oldErrorItem.messages.splice(messageId, 1);
    const newErrorItem =
      oldErrorItem.messages.length > 0 ? { ...oldErrorItem } : false;
    setUnitErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: newErrorItem,
    }));
  };

  //fetch the building data when we load the page
  useEffect(() => {
    //clear the redux unit slice
    dispatch(resetUnitSlice());
    axios
      .get(routeReplace(API_EDIT_UNIT_V2, { unitId: unitId }))
      .then((res) => {
        console.log("record : ", res.data.record);
        let recordToUpdate = { ...res.data.record };

        // se ho entrambi i valori do precedenta in funzione del deal_type
        if (
          Number(recordToUpdate.asking_rent) > 0 &&
          Number(recordToUpdate.total_asking_rent) > 0
        ) {
          if (Number(recordToUpdate.deal_type) === DEAL_TYPE_SALE_INT) {
            recordToUpdate.price = recordToUpdate.total_asking_rent;
            recordToUpdate.price_unit = "total";
          }
          if (Number(recordToUpdate.deal_type) === DEAL_TYPE_LEASE_INT) {
            recordToUpdate.price = recordToUpdate.asking_rent;
            recordToUpdate.price_unit = "sqm";
          }
        } else if (Number(recordToUpdate.total_asking_rent) > 0) {
          recordToUpdate.price = recordToUpdate.total_asking_rent;
          recordToUpdate.price_unit = "total";
        } else if (Number(recordToUpdate.asking_rent) > 0) {
          recordToUpdate.price = recordToUpdate.asking_rent;
          recordToUpdate.price_unit = "sqm";
        } else {
          recordToUpdate.price = "0";
          recordToUpdate.price_unit = "sqm";
        }
        recordToUpdate.technical_features =
          typeof recordToUpdate.technical_features === "string"
            ? recordToUpdate.technical_features.split(",").map(Number)
            : [];

        recordToUpdate.commercial_features =
          typeof recordToUpdate.commercial_features === "string"
            ? recordToUpdate.commercial_features.split(",").map(Number)
            : [];
        // console.log("to UP technical_features %o\ncommercial_features %o "
        //     ,recordToUpdate.technical_features
        //     ,recordToUpdate.commercial_features);

        dispatch(updateUnitSlice(recordToUpdate));

        //set the unit is new variable
        const unitIsNew = res.data.record.new;
        dispatch(updateUnitIsNew(unitIsNew));
      })
      .catch((err) => {
        //redirect to the list of units if there is any error while retreiving the unit
        history.push(`/buildings/edit/${buildingId}/units`);
      });
  }, [unitId]);

  const formIsValid = () => {
    // console.log("Validation unit: %o", unit);
    let isValid = true;
    let validators = [];
    // always the same
    const messages = TranslationUtils.getCustomMessages(userLanguage);

    // linked to the form
    let rules = {
      floor: ["required", "integer"],
      asset_class: [
        "required",
        "integer",
        // , `in:${Object.keys(ASSET_CLASSES).join(",")}`
      ],
      deal_type: [
        "required",
        "integer",
        // , `in:${Object.keys(DEAL_TYPES).join(",")}`
      ],
      type_size: [
        "required",
        "integer",
        //  , `in:${Object.keys(TYPE_SIZE_EDIT_BUILDING).join(",")}`
      ],
      mandate_agreement: [
        "required",
        "integer",
        //  , `in:${Object.keys(UNIT_MANDATE_AGREEMENT).join(",")}`
      ],
      size: ["required", "integer", "min:1", "max:10000000"],
      service_charges: ["present"],
      price: ["required", "integer", "min:1"],
      price_type: ["required"],
      price_unit: ["required"],
      size_archive: ["with_value_gt0"],
      size_terraces: ["with_value_gt0"],
      state: ["required"],
      renovation_status: ["required"],
      renovationgrade: ["required"],
      //mandate_broker: ["required"],
    };

    // linked to the form
    let fields = {
      floor: dictionary.validation_field_unit_floor,
      asset_class: dictionary.validation_field_unit_asset_class,
      deal_type: dictionary.validation_field_unit_deal_type,
      type_size: dictionary.validation_field_unit_type_size,
      size: dictionary.validation_field_unit_size,
      service_charges: dictionary.validation_field_unit_service_charges,
      price: dictionary.validation_field_unit_price,
      size_archive: dictionary.validation_field_unit_size_archive,
      size_terraces: dictionary.validation_field_unit_size_terraces,
      price_type: dictionary.validation_field_unit_price_type,
      price_unit: dictionary.validation_field_unit_price_unit,
      state: dictionary.validation_field_unit_state,
      renovation_status: dictionary.validation_field_unit_renovation_status,
      renovationgrade: dictionary.validation_field_unit_renovationgrade,
      mandate_broker: dictionary.validation_field_unit_mandate_broker,
    };

    const primary = Validator.make(unit, rules, messages, fields);
    primary.extend(
      "with_value_gt0",
      (name, value, params) => {
        let subValidator = null;
        // console.log("with_value_gt0 [%o]: %o",name,unit[name]);
        if (name === "size_archive" && changeExclusiveArchiveSelector == 1) {
          subValidator = Validator.make(
            unit,
            { [name]: ["required", "integer", "min:1"] },
            messages,
            fields
          );
          if (subValidator.passes()) {
            return true;
          }
        } else if (
          name === "size_terraces" &&
          setChangeUnitSizeTerracesSelector == 1
        ) {
          subValidator = Validator.make(
            unit,
            { [name]: ["required", "integer", "min:1"] },
            messages,
            fields
          );
          if (subValidator.passes()) {
            return true;
          }
        }
        if (subValidator === null) {
          return true;
        }

        const previousError = primary.errors[name] || [];
        subValidator.errors[name].forEach((subError) =>
          previousError.push(subError)
        );
        primary.errors[name] = previousError;

        return false;
      },
      null
    );

    validators.push({ validator: primary });

    validators.forEach((validation) => {
      // console.log("Verr: %o\n%o",validation.validator.messages,validation.validator.customMessages)
      if (validation.validator.passes()) {
        return;
      }
      const errors = validation.validator.getErrors();
      let collectErrors = {};
      // console.log("Remember Error: %o",errors);

      Object.entries(errors).forEach(([ruleKey, errorList]) => {
        // ruleKey is the language (it/gb) in multilanguage fields
        const key = validation.key !== undefined ? validation.key : ruleKey;
        const target = `${key}-section`;

        if (collectErrors[key] === undefined) {
          collectErrors[key] = { messages: [], target: target };
          if (key !== ruleKey) {
            collectErrors[key].languages = [];
          }
        }
        errorList.forEach((errorItem) =>
          collectErrors[key].messages.push(errorItem)
        );
        if (
          key !== ruleKey &&
          collectErrors[key].languages.includes(ruleKey) === false
        ) {
          collectErrors[key].languages.push(ruleKey);
        }

        // console.log("Key %o(%o) errors: %o",validation.key,ruleKey,collectErrors)
      });

      Object.entries(collectErrors).forEach(([key, value]) =>
        setUnitErrors((prev) => ({ ...prev, [key]: value }))
      );
      // setBuildingErrors((prev) => {
      //       console.log("Collect %o Prev %o",collectErrors,prev);
      //       return prev;
      // });

      isValid = false;
    });
    return isValid;
  };

  useEffect(() => {
    console.log("unitErrors : ", unitErrors);
    console.log("unit : ", unit);
  }, [unitErrors]);

  const saveUnitHandler = () => {
    const isValid = formIsValid();
    if (!isValid) {
      return;
    }

    // Convert the arrays to comma-separated strings
    const technicalFeaturesString = unit.technical_features.join(",");
    const commercialFeaturesString = unit.commercial_features.join(",");

    // Determine the property name based on the price_unit
    const priceUnitProperty =
      unit.price_unit === "sqm" ? "asking_rent" : "total_asking_rent";
    const otherPriceUnit =
      unit.price_unit === "sqm" ? "total_asking_rent" : "asking_rent";


    // Create the data object to send in the PATCH request
    const data = {
      // Other properties...
      ...unit,
      technical_features: technicalFeaturesString,
      commercial_features: commercialFeaturesString,
      [priceUnitProperty]: unit.price,
      [otherPriceUnit]: 0,
    };

    // console.log("data unit:", data);
    axios
      .patch(routeReplace(API_URL_EDIT_UNIT_V2, { unitId: unitId }), data)
      .then((payload) => {
        //redirect to the manage units page so the user can see the new unit with the list of units
        history.push(`/buildings/edit/${buildingId}/units`);
      })
      .catch((error) => {
        //parsing the errors to process
        processApiErrors(
          error.response.data.errors,
          unitErrors,
          setUnitErrors
        );
      });
  };

  const cancelClickHandler = () => {
    //check if the record have new true
    if (unit.unitIsNew) {
      // if yes we delete the cirrent unit and we redirect to the units page
      const token = Auth.getToken();
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios
        .delete(routeReplace(API_DELETE_UNIT_V2, { unitId: unitId }))
        .then((payload) => {
          history.push(`/buildings/edit/${buildingId}/units`);
        })
        .catch((err) => console.log(err));
      //if no we redirect to the units page
    } else {
      history.push(`/buildings/edit/${buildingId}/units`);
    }
  };

  return (
    <main className={globalClasses.maincontent}>
      <Helmet>
        <title>{dictionary.EditBuilding}</title>
      </Helmet>
      <CssBaseline />
      <Container maxWidth={false}>
        <Grid container spacing={3} wrap="nowrap">
          <Grid
            item
            md={true}
            xs={12}
            className={globalClasses.ContainerContent}
            style={{ marginTop: "1rem" }}
          >
            <Grid container>
              <Grid item sm={12} md={9}>
                {/*Sections */}
                <div style={{ marginLeft: `${smBreakpoint ? "0" : "2rem"}` }}>
                  <UnitDescription
                    classes={classes}
                    dispatch={dispatch}
                    dictionary={dictionary}
                    unit={unit}
                    unitErrors={unitErrors}
                    setUnitErrors={setUnitErrors}
                    unitId={unitId}
                  />
                  <UnitInformations
                    classes={classes}
                    dictionary={dictionary}
                    dispatch={dispatch}
                    unitErrors={unitErrors}
                    setUnitErrors={setUnitErrors}
                    unit={unit}
                    unitFloorOptions={unitFloorOptions}
                    setChangeExclusiveArchiveSelector={
                      setChangeExclusiveArchiveSelector
                    }
                    changeExclusiveArchiveSelector={
                      changeExclusiveArchiveSelector
                    }
                    changeUnitSizeTerracesSelector={
                      changeUnitSizeTerracesSelector
                    }
                    setChangeUnitSizeTerracesSelector={
                      setChangeUnitSizeTerracesSelector
                    }
                  />

                  <UnitFeatures
                    classes={classes}
                    dictionary={dictionary}
                    dispatch={dispatch}
                    unitErrors={unitErrors}
                    setUnitErrors={setUnitErrors}
                    unit={unit}
                  />

                  <MandateAgreement
                    classes={classes}
                    dictionary={dictionary}
                    dispatch={dispatch}
                    unitErrors={unitErrors}
                    setUnitErrors={setUnitErrors}
                    unit={unit}
                    allCompanies={allCompanies}
                  />

                  {user.role === 9 && (
                    <Broker
                      classes={classes}
                      dictionary={dictionary}
                      dispatch={dispatch}
                      unitErrors={unitErrors}
                      setUnitErrors={setUnitErrors}
                      unit={unit}
                      allCompanies={allCompanies}

                    />
                  )}

                  {/* Alert messages */}
                  <AlertMessages
                    unitErrors={unitErrors}
                    handleClose={handleClose}
                  />
                  <Grid item xs={12}>
                    <div className={classes.ButtonContainer}>
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        className={classes.SaveButton}
                        onClick={cancelClickHandler}
                      >
                        {dictionary.cancelTooltip}
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        className={classes.SaveButton}
                        onClick={saveUnitHandler}
                      >
                        {dictionary.SaveUnit}
                      </Button>
                    </div>
                  </Grid>
                </div>
              </Grid>

              <Hidden smDown>
                <Grid item xs={3}>
                  <UnitStickyMenu
                    dictionary={dictionary}
                    isAdmin={user.role === 9}
                  />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </main>
  );
};

export default AddUnit;
