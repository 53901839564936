import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import React, {Fragment} from "react";
import { LanguageContext } from "../../containers/languageprovider";
import {PRIVACY_COOKIE_URL_EN, PRIVACY_COOKIE_URL_IT, TERMS_AND_CONDITIONS_URL_IT, TERMS_AND_CONDITIONS_URL_EN} from "../../config/config";
import {Link} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  DialogContent: {
    maxWidth: "434px",
    padding: "16px 30px 25px",
  },
  DialogTitle: {
    paddingTop: "0",
  },
  TitlesContainer: {
    textAlign: "center",
    marginBottom: "19px",
  },
  Title: {
    fontSize: "22px",
    fontFamily:"Monserrat, sans-serif, Verdana",
    fontWeight:"500",
    lineHeight:"1.45",
    color: "#391400",
    margin: "0",
  },
  TextField: {
    "& .MuiOutlinedInput-root": {
      height: 48,
      borderRadius: "6px",
    },
  },
  SubmitButton: {
    textTransform: "uppercase",
    padding: "0.5rem auto",
    fontSize: "20px",
    borderRadius: "6px",
    width: "100%",
  },
  SubmitButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  Step: {
    fontSize: "14px",
  },
}));
const SearchRequestsStep2 = ({
  goBackStep,
  goNextStep,
  name,
  setName,
  surname,
  setSurname,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  companyName,
  setCompanyName,
  nameError,
  setNameError,
  surNameError,
  setSurNameError,
  emailError,
  setEmailError,
  phoneNumberError,
  setPhoneNumberError,
  processing,
}) => {
  const classes = useStyles();
  const { dictionary, userLanguage } = React.useContext(LanguageContext);


  return (
    <>
      <DialogContent className={classes.DialogContent}>
        <div className={classes.TitlesContainer}>
          <p className={classes.Title}>{dictionary.ContactInfo}</p>
          <span className={classes.Step}>2/2</span>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              className={classes.TextField}
              id="outlined-multiline-flexible"
              label={`${dictionary.FirstName}*`}
              multiline
              maxRows={4}
              value={name}
              onChange={(e) => {
                setNameError("");
                setName(e.target.value);
              }}
              variant="outlined"
              error={nameError}
              helperText={nameError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              className={classes.TextField}
              id="outlined-multiline-flexible"
              label={`${dictionary.LastName}*`}
              multiline
              maxRows={4}
              value={surname}
              onChange={(e) => {
                setSurNameError("");
                setSurname(e.target.value);
              }}
              variant="outlined"
              error={surNameError}
              helperText={surNameError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              className={classes.TextField}
              id="outlined-multiline-flexible"
              label={`${dictionary.Emailaddress}*`}
              multiline
              maxRows={4}
              value={email}
              onChange={(e) => {
                setEmailError("");
                setEmail(e.target.value);
              }}
              variant="outlined"
              error={emailError}
              helperText={emailError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              className={classes.TextField}
              id="outlined-multiline-flexible"
              label={`${dictionary.phonenumber}*`}
              multiline
              maxRows={4}
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumberError("");
                setPhoneNumber(e.target.value);
              }}
              variant="outlined"
              error={phoneNumberError}
              helperText={phoneNumberError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              className={classes.TextField}
              id="outlined-multiline-flexible"
              label={`${dictionary.companyName}`}
              multiline
              maxRows={4}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
              {dictionary.ByClickingOnSendIDeclare}
              <a
                  href={
                    userLanguage === "it"
                        ? TERMS_AND_CONDITIONS_URL_IT
                        : TERMS_AND_CONDITIONS_URL_EN
                  }
                  target="_blank"
              >
                {dictionary.LegalInfoForm2}
              </a>
              {dictionary.LegalInfoForm3 + " "}
              <a
                  href={
                    userLanguage === "it"
                        ? PRIVACY_COOKIE_URL_IT
                        : PRIVACY_COOKIE_URL_EN
                  }
                  target="_blank"
              >
                {dictionary.LegalInfoForm4}
              </a>
          </Grid>
          <Grid item xs={12} className={classes.SubmitButtonContainer}>

            <Button
              onClick={goNextStep}
              color="primary"
              variant="contained"
              className={classes.SubmitButton}
              style={{ pointerEvents: processing ? "none" : "all" }}
            >
              {processing ? (
                <CircularProgress
                  size={24}
                  style={{
                    color: "white",
                  }}
                />
              ) : (
                dictionary.SubmitAvailabilityRequest
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default SearchRequestsStep2;
