import React, { Fragment} from "react";
import Typography from "@material-ui/core/Typography";
import {
    Grid,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import {returnStringTranslation} from "./printLanguageSelect";
import {LanguageContext} from "../containers/languageprovider";

const useStyles = makeStyles((theme) => ({

    NearbyInfoTitle: {
        fontFamily: "Barlow",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "21px",
        lineHeight: "24px",
        color: "#000000",
        paddingTop: "40px",
        paddingBottom: "20px",
    },
    NearbyPoint: {
        fontFamily: "Barlow",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        fontVariant: "all-small-caps",
        color: "#6a6a6a",
        // color: "#1C55E5",
        paddingTop: "40px",
    },
    IconNearbyPoint: {
        height: 19,
        width: 18,
        marginRight: 10,
    },


}));


export default function BuildingAmenities(props) {
    const {item, amenitiesCount, id} = props;
    const classes = useStyles();
    const { dictionary, userLanguage } = React.useContext(LanguageContext);

    return (
        <Fragment>
            <Typography
                className={classes.NearbyPoint}
                gutterBottom
            >
                <Grid container alignItems="center">
                    <Grid item>
                        <img
                            className={
                                classes.IconNearbyPoint
                            }
                            src={item.icon}
                            alt={item.type}
                        />
                    </Grid>
                    <Grid
                        item
                        style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            fontFamily: "Barlow",
                        }}
                    >
                        {returnStringTranslation(
                            item.description,
                            userLanguage
                        )}
                    </Grid>
                </Grid>
            </Typography>

            {item.list.map((element) => {
                return (
                    <Grid
                        container
                        justifyContent="space-between"
                    >
                        <Grid
                            xs={7}
                            item
                            // className={
                            //   classes.tableCellNearby1
                            // }
                            style={{ fontSize: "16px" }}
                        >
                            {element.name}
                        </Grid>
                        <Grid
                            xs={5}
                            container
                            spacing={1}
                            item
                            justifyContent="space-between"
                            style={{ whiteSpace: "nowrap" }}
                        >
                            <Grid
                                item
                                className={
                                    classes.tableCellNearby
                                }
                                style={{ fontWeight: 500 }}
                            >
                                {element.mode === "walking" &&
                                    element.duration_text}
                            </Grid>
                            <Grid
                                item
                                className={
                                    classes.tableCellNearby
                                }
                                style={{
                                    fontWeight: 500,
                                    textAlign: "right",
                                }}
                            >
                                {element.distance_text}
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}

            {amenitiesCount !==
                 id + 1 && (
                    <Divider
                        style={{ marginTop: "15px" }}
                    />
                )}
        </Fragment>


    )






}