import React from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from "../auth/auth";
import {
    SUPER_ADMIN,
    TENANT,
    BROKER,
    LANDLORD,
    ASSETMANAGER,
    OTHER,
    FEATURE_LONG_LIST,
    FEATURE_SEARCHER,
    FEATURE_OFFEROR,
    FEATURE_TEASER,
    FEATURE_PORTFOLIO,
} from "../config/config";

export default function PrivateRoute(props) {
  const user = Auth.getUser();

  if(!user){
    return (
        <Redirect
            to={{
              pathname: "/login",
            }}
        />
    );
  }

  if (user.use_temp_password) {
    return (
        <Redirect
            to={{
              pathname: "/changepassword",
            }}
        />
    );
  }
    console.log("Privarte Route User: %o",user);
  if (
      props.accesslevel === undefined ||
      (props.accesslevel === SUPER_ADMIN && user.role >= SUPER_ADMIN) ||
      (props.accesslevel === ASSETMANAGER && user.role >= ASSETMANAGER) ||
      (props.accesslevel === LANDLORD && user.role >= LANDLORD) ||
      (props.accesslevel === BROKER && user.role >= BROKER) ||
      (props.accesslevel === TENANT && user.role >= OTHER) ||
      (props.accesslevel === FEATURE_SEARCHER  && user.hasSearcher()) ||
      (props.accesslevel === FEATURE_OFFEROR  && user.hasOfferor()) ||
      (props.accesslevel === FEATURE_LONG_LIST && user.hasLongList()) ||
      (props.accesslevel === FEATURE_TEASER  && user.hasTeaser()) ||
      (props.accesslevel === FEATURE_PORTFOLIO  && user.hasPortfolio()) ||
      // (props.accesslevel === TENANT && (user.role === TENANT || user.role === OTHER)) ||
      false
  ) {
    return <Route {...props} />;
  }
  return (
      <Redirect
          to={{
            pathname: "/",
          }}
      />
  );

}
