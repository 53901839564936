import React, { Fragment, useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { LanguageContext } from "../../containers/languageprovider";
import axios from "axios";
import { API_URL_LOCATIONS } from "../../config/config";

const useStyles = makeStyles((theme) => ({
  DialogContent: {
    maxWidth: "434px",
    padding: "16px 30px 25px",
  },
  FormControl: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: 48,
      borderRadius: "6px",
    },
  },

  TitlesContainer: {
    textAlign: "center",
    marginBottom: "19px",
  },
  TextField: {
    "& .MuiOutlinedInput-root": {
      height: 48,
      borderRadius: "6px",
    },
  },
  Title: {
    fontSize: "22px",
    fontFamily:"Monserrat, sans-serif, Verdana",
    fontWeight:"500",
    lineHeight:"1.45",
    color: "#391400",
    margin: "0",
  },
  Subtitle: {
    fontSize: "12px",
    margin: "0",
    marginBottom: "0",
  },
  Select: {
    color: "#1c55e5",
  },
  SelectClass: {
    textAlign: "left",
  },
  Option: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  OptionLocation: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "grey",
    fontSize: "0.8rem",
    width: "100%",
    marginTop: "0.2rem",
  },
  Company: {
    margin: "0",
    marginBottom: "0.2rem",
    textAlign: "center",
  },
  SubmitButton: {
    textTransform: "uppercase",
    padding: "0.5rem auto",
    fontSize: "20px",
    borderRadius: "6px",
    width: "100%",
  },
  SubmitButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  Step: {
    fontSize: "14px",
  },
}));

export default function SearchRequestsStep1({
  type,
  user,
  goNextStep,
  assetClassSelect,
  setAssetClass,
  dealType,
  setDealType,
  selectedRentPrices,
  selectedSalePrices,
  selectedPrices,
  setselectedPrices,
  location,
  setLocation,
  locationError,
  setLocationError,
  selectedSizes,
  selectedCompanies,
  sizeError,
  selectedDate,
  handleChangeRents,
  handleChangeSizes,
  handleChangeCompanies,
  handleDateChange,
  allCompanies,
  sizelistname,
  askingrentlist,
  askingsaleslist,
  handleDescriptionChange,
  processing,
}) {
  const { dictionary, userLanguage } = useContext(LanguageContext);
  const classes = useStyles();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [eventType, setEventType] = useState("");
  const [changeReason, setChangeReason] = useState("");
  const [citySelect, setCitySelect] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasMadeApiCall, setHasMadeApiCall] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [locationSelect, setLocationSelect] = useState("");
  const [locationType, setLocationType] = useState("");
  const [firstLocationSelect, setFirstLocationSelect] = useState("");
  const [firstLocationType, setFirstLocationType] = useState("");

  const typePriority = {
    administrative_area_level_3: 1,
    administrative_area_level_2: 2,
    administrative_area_level_1: 3,
    macro_area: 4,
    postal_code: 5,
    country: 6,
  };

  const sortSuggestions = (inputValue, suggestions) => {
    return suggestions.sort((a, b) => {
      if (a.name.startsWith(inputValue) && !b.name.startsWith(inputValue)) {
        return -1;
      } else if (
        !a.name.startsWith(inputValue) &&
        b.name.startsWith(inputValue)
      ) {
        return 1;
      } else if (a.type !== b.type) {
        return typePriority[a.type] - typePriority[b.type];
      } else {
        return a.name.localeCompare(b.name);
      }
    });
  };

  const search = async (value) => {
    setCitySelect("");
    // setLocationSelect("");
    // setLocationType("");
    if (value.length < 2) {
      setOptionsOpen(false);
      setResults([]);
      setHasMadeApiCall(false);
      return;
    }
    setLoading(true);
    setHasMadeApiCall(true);

    axios
      .get(
        API_URL_LOCATIONS +
          `/search?searchTerm=${value}&language=${userLanguage}&dealType=${dealType}&assetClass=${assetClassSelect}`
      )
      .then((response) => {
        setLoading(false);

        // console.log("locations %o ", response.data.locations);
        if (response.data.locations.length == 0) {
          setButtonEnabled(false);
          setResults([]);
          return;
        }
        const sortedResults = sortSuggestions(value, response.data.locations);
        setResults(sortedResults);
        setCitySelect(sortedResults[0].name);
        setFirstLocationSelect(sortedResults[0].name);
        setFirstLocationType(sortedResults[0].search);
        console.log(
          "After search new location[%o]: %o",
          locationSelect,
          locationType
        );
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (eventType === "change") {
      // console.log("useeffect Searchterm: %o", searchTerm);
      search(searchTerm);
    }
  }, [searchTerm]);

  const handleChangeAssetClass = (event) => {
    setAssetClass(event.target.value);
  };

  const handleChangeDealType = (event) => {
    setDealType(event.target.value);
  };

  // useEffect(() => {
  //   console.log("askingrentlits : ", askingrentlist);
  // }, [askingrentlist]);

  // useEffect(() => {
  //   console.log("sizelistname : ", sizelistname);
  // }, [sizelistname]);

  // useEffect(() => {
  //   console.log("allCompanies : ", allCompanies);
  // }, [allCompanies]);

  return (
    <>
      <DialogContent className={classes.DialogContent}>
        <div className={classes.TitlesContainer}>
          <p className={classes.Title}>{dictionary.WhatAreYouSearchingFor}</p>
          {!user && <span className={classes.Step}>1/2</span>}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.FormControl}>
              <InputLabel id="demo-simple-select-filled-label">
                {dictionary.AssetClass}
              </InputLabel>
              <Select
                value={assetClassSelect}
                onChange={handleChangeAssetClass}
                className={classes.Select}
                label={dictionary.AssetClass}
              >
                <MenuItem value={1}>{dictionary.Office}</MenuItem>
                <MenuItem value={2}>{dictionary.Retail}</MenuItem>
                <MenuItem value={3}>{dictionary.Industrial}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.FormControl}>
              <InputLabel id="demo-simple-select-filled-label">
                {dictionary.DealType}
              </InputLabel>
              <Select
                value={dealType}
                onChange={handleChangeDealType}
                className={classes.Select}
                label={dictionary.DealType}
              >
                <MenuItem value={1}>{dictionary.ForRent}</MenuItem>
                <MenuItem value={2}>{dictionary.ForSale}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.FormControl}>
              <InputLabel id="demo-simple-select-filled-label">
                {dictionary.Price}
              </InputLabel>
              <Select
                labelId="checkbox-select-label"
                id="checkbox-select"
                label={dictionary.Price}
                multiple
                value={dealType === 1 ? selectedRentPrices : selectedSalePrices}
                onChange={handleChangeRents}
                style={{ color: "#1c55e5" }}
                renderValue={
                  dealType === 1
                    ? (selectedRentPrices) => selectedRentPrices.join(", ")
                    : (selectedSalePrices) => selectedSalePrices.join(", ")
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  MenuListProps: { disablePadding: true },
                }}
              >
                {dealType === 1
                  ? askingrentlist.map((option) => (
                      <MenuItem key={option.id} value={option.label}>
                        <Checkbox
                          checked={
                            selectedRentPrices.indexOf(option.label) > -1
                          }
                        />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))
                  : askingsaleslist.map((option) => (
                      <MenuItem key={option.id} value={option.label}>
                        <Checkbox
                          checked={
                            selectedSalePrices.indexOf(option.label) > -1
                          }
                        />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.FormControl}>
              <TextField
                variant="outlined"
                fullWidth
                error={locationError}
                label={dictionary.Location + "*"}
                value={location}
                onChange={(e) => {
                  setLocationError("");
                  setLocation(e.target.value);
                }}
                style={{ color: "#1c55e5" }}
                InputProps={{
                  style: {
                    color: "#1c55e5",
                  },
                }}
                helperText={locationError}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.FormControl}>
              <InputLabel id="demo-simple-select-filled-label">
                {dictionary.Size + "*"}
              </InputLabel>
              <Select
                labelId="checkbox-select-label"
                id="checkbox-select"
                label={dictionary.Size + "*"}
                error={sizeError}
                multiple
                value={selectedSizes}
                onChange={handleChangeSizes}
                style={{ color: "#1c55e5" }}
                renderValue={(selectedSizes) => selectedSizes.join(", ")}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  MenuListProps: { disablePadding: true },
                }}
              >
                {sizelistname.map((option) => (
                  <MenuItem key={option.id} value={option.label}>
                    <Checkbox
                      checked={selectedSizes.indexOf(option.label) > -1}
                    />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText style={{ color: "red" }}>
                {sizeError}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.FormControl}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                views={["year", "month"]}
                format="MM/yyyy"
                id="date-picker-inline"
                label={dictionary.AvailableFrom}
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputProps={{
                  style: {
                    color: "#1c55e5",
                    paddingLeft: "5px",
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
                className={classes.TextField}
              variant="outlined"
              fullWidth
              multiline
              placeholder={dictionary.SearcherRequestPlaceholder}
              onChange={handleDescriptionChange}
            />
          </Grid>
          <Grid item xs={12} className={classes.SubmitButtonContainer}>
            <Button
                onClick={goNextStep}
                color="primary"
                variant="contained"
                className={classes.SubmitButton}
                style={{ pointerEvents: processing ? "none" : "all" }}
            >
              {processing ? (
                  <CircularProgress
                      size={24}
                      style={{
                        color: "white",
                      }}
                  />
              ) : (
                  dictionary.SubmitAvailabilityRequest
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
}
