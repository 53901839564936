import { Grid, Paper, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  Parameter: {
    display: "flex",
    justifyContent: "center",
    padding: "0.5rem",
    color: "#1c55e5",
    fontSize: "16px",
    opacity: 0.9,
    fontFamily: "Barlow, sans-serif, Verdana, Arial",
    lineHeight: 2,
    fontWeight: "normal",
    borderRadius: "9.5px",
    border: "solid 1px rgba(0, 0, 0, 0.23)",
    boxShadow:"none",
  },
  Parameters: {
    marginBottom: "1rem",
  },
}));

const Parameters = ({ location, offer, size, fromDate }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.Parameters}>
      {location && (
        <Grid item xs={2}>
          <Paper className={classes.Parameter}><span style={{opacity: 0.45}}>{location}</span></Paper>
        </Grid>
      )}
      {offer && (
        <Grid item >
          <Paper className={classes.Parameter}><span style={{opacity: 0.45}}>{offer}</span></Paper>
        </Grid>
      )}
      {size && (
        <Grid item xs={2}>
          <Paper className={classes.Parameter}><span style={{opacity: 0.45}}>{size}</span></Paper>
        </Grid>
      )}
      {fromDate && (
        <Grid item xs={2}>
          <Paper className={classes.Parameter}><span style={{opacity: 0.45}}>{fromDate}</span></Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default Parameters;
