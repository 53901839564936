import { Grid, Tab, Tabs, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Flag } from "../../../utils";
import {
  dictionaryList,
  languageOptions,
  siteLanguageMap,
} from "../../../languages";
import { LanguageContext } from "../../../../containers/languageprovider";

const MultiLanguageLocationField = ({
  classes,
  buildingAddress,
  buildingAddressDetails,
  handleChangeAddress,
  error,
}) => {
  const { dictionary, userLanguage } = useContext(LanguageContext);
  const [multiLanguageLocationTab, setMultiLanguageLocationTab] = useState(
    siteLanguageMap.default
  );

  const [inputText, setInputText] = useState("");
  //Tabs
  const handleChangemultiLanguageTextTab = (event, newTab) => {
    setMultiLanguageLocationTab(newTab);
  };

  const [idLanguageTextChanged, setIdLanguageTextChanged] = useState("");
  const [addressSuggestions, setAddressSuggestions] = useState({});
  const autocompletor = useRef();

  useEffect(() => {
    Object.entries(languageOptions).map(([languageId, name]) => {
      setAddressSuggestions((prevstate) => ({
        ...prevstate,
        [languageId]: [],
      }));
    });
  }, []);

  useEffect(() => {
    //oce the buidingAddressChange
    // console.log("buildingAddress : ", buildingAddress);
    // console.log("idLanguageTextChanged : ", idLanguageTextChanged);
    if (
      buildingAddress[idLanguageTextChanged] === "" ||
      !buildingAddress[idLanguageTextChanged]
    ) {
      setAddressSuggestions((prevstate) => ({
        ...prevstate,
        [idLanguageTextChanged]: [],
      }));
      return;
    }
    if (!autocompletor.current) {
      autocompletor.current =
        new window.google.maps.places.AutocompleteService();
    }
    //    const valueLg = userLanguage;
    autocompletor.current.getPlacePredictions(
      {
        input: buildingAddress[idLanguageTextChanged],
        language: idLanguageTextChanged,
      },
      (res, status) => {
        const suggest = res?.map((e) => e.description);
        // console.log("suggest : ", suggest);
        setAddressSuggestions((prevstate) => ({
          ...prevstate,
          [idLanguageTextChanged]: res?.map((e) => e.description) || [],
        }));
      }
    );
  }, [buildingAddress, idLanguageTextChanged]);

  // useEffect(()=>{
  //   console.log("addressSuggestions: ",addressSuggestions)
  // },[addressSuggestions])

  return (
    <>
      <Grid item id="addressdetails-section">
        <Typography
          className={classes.subtitle}
          style={{ fontWeight: "600" }}
          id="location-section"
        >
          {dictionary.location}
        </Typography>
      </Grid>
      <Grid item xs={12} id="address-section">
        <Tabs
          value={multiLanguageLocationTab}
          onChange={handleChangemultiLanguageTextTab}
          centered
        >
          {Object.entries(languageOptions).map(([languageId, name]) => (
            <Tab
              value={languageId}
              className={classes.Tab}
              label={<img src={Flag(languageId)} width="20" alt={name} />}
            />
          ))}
        </Tabs>
        {Object.entries(languageOptions).map(
          ([languageId, name]) =>
            multiLanguageLocationTab === languageId && (
              <>
                <Autocomplete
                  filterOptions={(options) => options}
                  inputValue={
                    buildingAddressDetails[languageId]?.formatted_address
                  }
                  getOptionLabel={(opt) => opt}
                  onChange={(event, val, reason) => {
                    handleChangeAddress(
                      {
                        target: {
                          value: val || undefined,
                        },
                        reason,
                      },
                      languageId
                    );
                  }}
                  id="address-field"
                  options={addressSuggestions[languageId]}
                  renderInput={(params) => {
                    params.inputProps.value =
                      buildingAddressDetails[languageId]?.formatted_address;
                    return (
                      <TextField
                        onChange={(e) => {
                          handleChangeAddress(e, languageId);
                        }}
                        {...params}
                        value={
                          buildingAddressDetails[languageId]?.formatted_address
                        }
                        variant="outlined"
                        fullWidth
                        label={dictionaryList[userLanguage].Address + " *"}
                        onFocus={() => setIdLanguageTextChanged(languageId)}
                        error={error}
                        InputLabelProps={{
                          shrink:
                            buildingAddressDetails[languageId]
                              ?.formatted_address,
                        }}
                      />
                    );
                  }}
                  style={{ marginBottom: "1rem" }}
                />
              </>
            )
        )}
      </Grid>
    </>
  );
};

export default MultiLanguageLocationField;
