import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Flag } from "./utils";
import { languageOptions } from "./languages";
import Auth from "../auth/auth";
import axios from "axios";
import { API_URL_BUILDINGDOCS } from "../config/config";

const UpdateDocumentPopUp = ({
  openDialogDoc,
  dictionary,
  docToChange,
  indexDoc,
  cancelChanges,
  refreshDocs,
  setAlertMsg,
  setAlertSeverity,
  setOpen,
  setOpenDialogDoc,
}) => {
  const [englishText, setEnglishText] = useState("");
  const [italianText, setItalianText] = useState("");
  const [imageType, setImageType] = useState("");
  const [docText, setDocText] = useState({});

  useEffect(() => {
    //Inititiate the form with the data
    if (docToChange) {
      console.log("imagetype : ", docToChange?.doctype);
      //   setEnglishText(JSON.parse(docToChange?.description).gb);
      // setItalianText(JSON.parse(docToChange?.description).it);
      setImageType(docToChange?.doctype);
      Object.entries(languageOptions).map(([languageId, name]) => {
        setDocText((prev) => ({
          ...prev,
          [languageId]: JSON.parse(docToChange?.description)[languageId],
        }));
      });
    }
  }, [docToChange]);

  /*   const changeEnglishTextHandler = (e) => {
    setEnglishText(e.target.value);
  };
  const changeItalianTextHandler = (e) => {
    setItalianText(e.target.value);
  }; */

  const changeTextHanlder = (e, languageId) => {
    setDocText((prev) => ({ ...prev, [languageId]: e.target.value }));
  };

  const changeImageTypeHandler = (e) => {
    console.log("imagetype : ", e.target.value);
    setImageType(e.target.value);
  };

  const cancelHandler = () => {
    //revert changes and close the popUp
    // setEnglishText(JSON.parse(docToChange?.description).gb);
    //setItalianText(JSON.parse(docToChange?.description).it);
    Object.entries(languageOptions).map(([languageId, name]) => {
      setDocText((prev) => ({
        ...prev,
        [languageId]: JSON.parse(docToChange?.description)[languageId],
      }));
    });
    setImageType(docToChange?.doctype);
    cancelChanges();
  };

  const saveChangesHanalder = () => {
    Object.entries(languageOptions).map(([languageId, name]) => {
      if (docText[languageId] === "") {
        setAlertMsg(dictionary.descProvide);
        setAlertSeverity("error");
        setOpen(true);
        return;
      }
    });
    /*  if (italianText === "" || englishText === "") {
      setAlertMsg(dictionary.descProvide);
      setAlertSeverity("error");
      setOpen(true);
      return;
    } */
    setOpenDialogDoc(false);

    const token = Auth.getToken();
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    const newDescription = {};
    Object.entries(languageOptions).map(([languageId, name]) => {
      return (newDescription[languageId] = docText[languageId]);
    });

    // if (checkEmptyDesc.gb === "") {
    //   checkEmptyDesc.gn = "document";
    // }
    // if (checkEmptyDesc.it === "") {
    //   checkEmptyDesc.it = "documento";
    // }
    axios
      .patch(API_URL_BUILDINGDOCS + "/" + docToChange.id, {
        building_id: docToChange.building_id,
        doc: docToChange.doc,
        order: docToChange.order,
        doctype: imageType,
        description: JSON.stringify({...newDescription}),
      })
      .then((payload) => {
        //refresh the docs after the update.
        refreshDocs();
      });
  };
  return (
    <Dialog
      open={openDialogDoc}
      onClose={cancelHandler}
      aria-labelledby="form-dialog-title-doc"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title-doc">
        {dictionary.editTooltip}
      </DialogTitle>
      <DialogContent>
        {indexDoc !== -1 &&
          Object.entries(languageOptions).map(([languageId, name]) => (
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                value={docText[languageId]}
                onChange={(e) => changeTextHanlder(e, languageId)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ marginRight: "1rem" }}
                    >
                      <img src={Flag(languageId)} width="20" alt={name}></img>
                    </InputAdornment>
                  ),
                }}
                style={{ marginBottom: "1rem" }}
              />
            </Box>
          ))}
        {indexDoc !== -1 && docToChange?.doctype !== 1 && (
          <FormControl
            variant="outlined"
            fullWidth
            style={{ marginTop: "15px" }}
          >
            <InputLabel id="demo-simple-select-label">Image type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={imageType}
              onChange={changeImageTypeHandler}
              label="Image Type"
            >
              <MenuItem value={2}>Generic image</MenuItem>
              <MenuItem value={3}>Standard floor plan</MenuItem>
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions
        style={{ padding: "1rem", display: "flex", justifyContent: "center" }}
      >
        <Button
          onClick={saveChangesHanalder}
          color="primary"
          style={{ marginRight: "1rem" }}
        >
          {dictionary.saveTooltip}
        </Button>

        <Button onClick={cancelHandler} color="secondary">
          {dictionary.cancelTooltip}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDocumentPopUp;
