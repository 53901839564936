import React, { useState } from "react";
import FloatingWhatsApp from "react-floating-whatsapp";
import { WHATSAPP_PHONE_NUMBER } from "../../config/config";
import { LanguageContext } from "../../containers/languageprovider";
import CloseButton from "./CloseButton";
import logoD4RE from "./d4re_logo.png";
import "./WhatsappButton.css";

const WhatsappButton = () => {
  const { dictionary } = React.useContext(LanguageContext);

  const phoneNumber = encodeURIComponent(WHATSAPP_PHONE_NUMBER);
  const [closeWhatsapp, setcloseWhatsapp] = useState(false);
  const closeWhatsappIconHandler = () => {
    setcloseWhatsapp(true);
  };
  return (
    <div className={`${closeWhatsapp ? "hideButtons" : ""}`}>
      <FloatingWhatsApp
        phoneNumber={phoneNumber}
        accountName="D4RE"
        allowClickAway
        allowEsc
        chatMessage={dictionary.whatsappMessage}
        avatar={logoD4RE}
      />
      <CloseButton closeWhatsappIconHandler={closeWhatsappIconHandler} />
    </div>
  );
};

export default WhatsappButton;
