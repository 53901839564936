import { createSlice } from "@reduxjs/toolkit";

const CachedSearchKeySlice = createSlice({
  name: "cachedSearchKey ",
  initialState: "",
  reducers: {
    updateCashedSearchKey: (state, action) => {
      const { payload } = action;
      console.log("payload : ", payload);
      return payload
    },
  },
});

export const { updateCashedSearchKey } = CachedSearchKeySlice.actions;

export default CachedSearchKeySlice.reducer;
