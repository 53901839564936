import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { LanguageContext } from "../../containers/languageprovider";
import CircularProgress from "@material-ui/core/CircularProgress";

const DeletePopUp = ({
  deleteInProcess,
  openDeletePopUp,
  hideDeleteConfirmationPopUp,
  handleDelete,
  title,
  body,
}) => {
  const { dictionary } = React.useContext(LanguageContext);
  return (
    <Dialog
      open={openDeletePopUp}
      onClose={hideDeleteConfirmationPopUp}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
      style={{ padding: "0 1rem" }}
    >
      <DialogTitle id="alert-dialog-title">
        <div style={{ textAlign: "center" }}>
          <HighlightOffIcon style={{ color: "#f44336", fontSize: "5rem" }} />
          <p style={{ color: "#6d6868" }}>{title}</p>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ textAlign: "center", color: "#000000" }}
        >
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ marginBottom: "0.5rem" }}>
        <Button
          onClick={handleDelete}
          autoFocus
          style={{ color: "#f44336", marginRight: "0.5rem" }}
        >
          {deleteInProcess ? (
            <CircularProgress size={24} style={{ color: "#f44336" }} />
          ) : (
            dictionary.delete
          )}
        </Button>
        <Button onClick={hideDeleteConfirmationPopUp} style={{ color: "gray" }}>
          {dictionary.Abort}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePopUp;
