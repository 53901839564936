import { configureStore } from "@reduxjs/toolkit";
import buildingReducer from "../reducers/BuildingSlice";
import unitReducer from "../reducers/UnitSlice";
import CachedSearchKeySlice from "../reducers/CachedSearchKeySlice";

const store = configureStore({
  reducer: {
    building: buildingReducer,
    unit: unitReducer,
    cachedSearchKey: CachedSearchKeySlice,
  },
});

export default store;
