import {
  Button,
  Container,
  CssBaseline,
  Grid,
  Hidden,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import DrawerDashBoard from "../../DrawerDashBoard";
import globalStyles from "../../globalStyles";
import Footer from "../../Footer";
import { LanguageContext } from "../../../containers/languageprovider";
import BuildingNameAndLocation from "../Sections/BuildingNameAndLocation";
import BuildingDescription from "../Sections/BuildingDescription";
import BuildingInformations from "../Sections/BuildingInformations";
import BuildingFacilities from "../Sections/BuildingFacilities";
import BuildingStickyMenu from "../Sections/BuildingStickyMenu";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Auth from "../../../auth/auth";
import axios from "axios";
import {
  ALERT_BUILDING_MESSAGES,
  API_ALL_BUILDING_DOCS_V2,
  API_ALL_BUILDING_IMAGES_V2,
  API_BUILDING_IMAGES_ADD_V2,
  API_DELETE_NEW_BUILDING_V2,
  API_DELETE_UNIT_V2,
  API_EDIT_NEW_BUILDING_V2,
} from "../../../config/config";
import { isoLanguageMap, languageOptions } from "../../languages";
import AlertMessages from "../Sections/AlertMessages/AlertMessages";
import {
  resetBuildingSlice,
  updateBuildingAttachements,
  updateBuildingImages,
  updateBuildingIsNew,
  updateBuildingSlice,
} from "../../../reducers/BuildingSlice";
import { processApiErrors, routeReplace, TranslationUtils } from "../../utils";
import { updateUnitIsNew } from "../../../reducers/UnitSlice";
import Validator from "validator-d4re";

const useStyles = makeStyles((theme) => ({
  MenuTitle: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 500,
    marginBottom: "2rem",
  },
  Tab: {
    minWidth: "30px",
  },
  subtitle: {
    fontSize: "18px",
    marginTop: "1.5rem",
    textTransform: "capitalize",
  },
  text: {
    fontSize: "15px",
  },
  editGps: {
    margin: "10px 0 20px 0",
  },
  accordionIneterests: {
    marginTop: "20px",
    backgroundColor: "#eaeaea",
  },
  accordionSummary: {
    borderBottom: "1px solid #d9d9d9",
  },
  interestsContaner: {
    borderBottom: "1px solid #d9d9d9",
    padding: "1rem",
  },
  interestItemContainer: {
    display: "flex",
    padding: "0.5rem 1rem",
  },
  interestTitle: {
    marginLeft: "10px",
    textTransform: "uppercase",
  },
  interestItem: {
    display: "flex",
    justifyContent: "flex-end",
  },
  typeLinkVirtual: {
    marginBottom: "1rem",
  },
  ButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2rem",
  },
  SaveButton: {
    padding: "0.5rem 1.5rem",
    marginBottom: "2rem",
  },
  ArrowsCarousel: {
    width: "30px",
    height: "30px",
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 25px)",
    cursor: "pointer",
  },
  IconArrow: {
    width: "100%",
    height: "100%",
    "&:hover": {
      opacity: 1,
      borderColor: "red",
    },
  },
}));

const AddBuilding = () => {
  const building = useSelector((state) => state.building);

  // Create buildingErrors dynamically based on the properties of the building properties
  const initialBuildingErrors = {};
  Object.keys(building).forEach((property) => {
    initialBuildingErrors[property] = false;
  });

  const [buildingErrors, setBuildingErrors] = useState(initialBuildingErrors);

  const { buildingId } = useParams();
  const history = useHistory();

  const globalClasses = globalStyles();
  const classes = useStyles();
  const { dictionary, userLanguage } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [changeRoomsSelector, setChangeRoomsSelector] = useState("null");
  const [changeWarehouseSelector, setChangeWarehouseSelector] =
    useState("null");
  const [changeRoofTopSelector, setChangeRoofTopSelector] = useState("null");
  const [lastImage, setLastImage] = useState(1);
  const [lastDoc, setLastDoc] = useState(1);

  React.useEffect(() => {
    console.log("building add", building);
  }, [building]);

  const theme = useTheme();
  const smBreakpoint = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  //check the form validation
  const formIsValid = () => {
    let isValid = true;
    let validators = [];
    // always the same
    const messages = TranslationUtils.getCustomMessages(userLanguage);

    // linked to the form
    let rules = {
      size: ["required", "integer", "min:100"],
      floors: ["required", "integer", "min:1"],
      images: ["required", "array", "min:3"],
      changing_rooms_lockers: ["with_value_gt0"],
      archive_warehouse_area: ["with_value_gt0"],
      shared_rooftop_area: ["with_value_gt0"],
    };

    // linked to the form
    let fields = {
      size: dictionary.validation_field_building_size,
      floors: dictionary.validation_field_building_floors,
      images: dictionary.validation_field_building_images,
      changing_rooms_lockers:
        dictionary.validation_field_building_changing_rooms_lockers,
      archive_warehouse_area:
        dictionary.validation_field_building_archive_warehouse_area,
      shared_rooftop_area:
        dictionary.validation_field_building_shared_rooftop_area,
    };

    const primary = Validator.make(building, rules, messages, fields);
    primary.extend(
      "with_value_gt0",
      (name, value, params) => {
        let subValidator = null;
        // console.log("with_value_gt0 [%o]: %o",name,building[name]);
        if (name === "changing_rooms_lockers" && changeRoomsSelector == 1) {
          subValidator = Validator.make(
            building,
            { [name]: ["required", "integer", "min:1"] },
            messages,
            fields
          );
          if (subValidator.passes()) {
            return true;
          }
        } else if (
          name === "archive_warehouse_area" &&
          changeWarehouseSelector == 1
        ) {
          subValidator = Validator.make(
            building,
            { [name]: ["required", "integer", "min:1"] },
            messages,
            fields
          );
          if (subValidator.passes()) {
            return true;
          }
        } else if (
          name === "shared_rooftop_area" &&
          changeRoofTopSelector == 1
        ) {
          subValidator = Validator.make(
            building,
            { [name]: ["required", "integer", "min:1"] },
            messages,
            fields
          );
          if (subValidator.passes()) {
            return true;
          }
        }
        // No validation needed, so pass the validation
        if (subValidator === null) {
          return true;
        }

        const previuousError = primary.errors[name] || [];
        subValidator.errors[name].forEach((subError) =>
          previuousError.push(subError)
        );
        primary.errors[name] = previuousError;

        return false;
      },
      null
    );

    validators.push({ validator: primary });
    Object.entries({
      name: "validation_field_building_name",
      address: "validation_field_building_address",
    }).forEach(([element, label]) => {
      // console.log("Property: %o Label: %o",element,label)
      const rules = {};
      const fields = {};
      Object.keys(isoLanguageMap).forEach((language) => {
        rules[language] = ["required", "string", "min:5"];
        fields[language] = `${dictionary[label]}(${isoLanguageMap[language]})`;
      });
      // console.log("fields: %o",fields)
      validators.push({
        validator: Validator.make(building[element], rules, messages, fields),
        key: element,
      });
    });
    Object.entries({
      description: "validation_field_building_description",
      selling_points: "validation_field_building_selling_points",
    }).forEach(([element, label]) => {
      // console.log("Property: %o Label: %o",element,label)
      const rules = {};
      const fields = {};
      Object.keys(isoLanguageMap).forEach((language) => {
        rules[language] = ["required", "string", "min:10"];
        fields[language] = `${dictionary[label]}(${isoLanguageMap[language]})`;
      });
      // console.log("fields: %o",fields)
      validators.push({
        validator: Validator.make(building[element], rules, messages, fields),
        key: element,
      });
    });
    validators.forEach((validation) => {
      // console.log("Verr: %o\n%o",validation.validator.messages,validation.validator.customMessages)
      if (!validation.validator.fails()) {
        return;
      }
      const errors = validation.validator.getErrors();
      let collectErrors = {};
      // console.log("Remember Error: %o",errors);

      Object.entries(errors).forEach(([ruleKey, errorList]) => {
        // ruleKey is the language (it/gb) in multilanguage fields
        const key = validation.key !== undefined ? validation.key : ruleKey;
        const target = `${key}-section`;

        if (collectErrors[key] === undefined) {
          collectErrors[key] = { messages: [], target: target };
          if (key !== ruleKey) {
            collectErrors[key].languages = [];
          }
        }
        errorList.forEach((errorItem) =>
          collectErrors[key].messages.push(errorItem)
        );
        if (
          key !== ruleKey &&
          collectErrors[key].languages.includes(ruleKey) === false
        ) {
          collectErrors[key].languages.push(ruleKey);
        }

        // console.log("Key %o(%o) errors: %o",validation.key,ruleKey,collectErrors)
      });

      Object.entries(collectErrors).forEach(([key, value]) =>
        setBuildingErrors((prev) => ({ ...prev, [key]: value }))
      );
      // setBuildingErrors((prev) => {
      //       console.log("Collect %o Prev %o",collectErrors,prev);
      //       return prev;
      // });

      isValid = false;
    });
    return isValid;

    // if (building.size === 0) {
    //   setBuildingErrors((prev) => ({ ...prev, size: true }));
    //   isValid = false;
    // }
    // //floors
    // if (building.floors === 0) {
    //   setBuildingErrors((prev) => ({ ...prev, floors: true }));
    //   isValid = false;
    // }
    // if (building.changing_rooms_lockers === 0 && changeRoomsSelector == 1) {
    //   setBuildingErrors((prev) => ({ ...prev, changing_rooms_lockers: true }));
    //   isValid = false;
    // }

    // //name
    // Object.entries(languageOptions).map(([languageId, name]) => {
    //   if (building.name[languageId].length < 5) {
    //     setBuildingErrors((prev) => ({ ...prev, name: true }));
    //     isValid = false;
    //     return;
    //   }
    // });

    // //address
    // Object.entries(languageOptions).map(([languageId, name]) => {
    //   if (building.address[languageId] === "") {
    //     setBuildingErrors((prev) => ({ ...prev, address: true }));
    //     isValid = false;
    //     return;
    //   }
    // });
    // //images

    // if (building.images.length < 3) {
    //   setBuildingErrors((prev) => ({ ...prev, images: true }));
    //   isValid = false;
    //   return;
    // }

    // //description
    // Object.entries(languageOptions).map(([languageId, name]) => {
    //   if (building.description[languageId] === "") {
    //     setBuildingErrors((prev) => ({ ...prev, description: true }));
    //     isValid = false;
    //     return;
    //   }
    // });

    // //selling_points
    // Object.entries(languageOptions).map(([languageId, name]) => {
    //   if (building.selling_points[languageId] === "") {
    //     setBuildingErrors((prev) => ({ ...prev, selling_points: true }));
    //     isValid = false;
    //     return;
    //   }
    // });

    // //size
    // if (building.size === 0) {
    //   setBuildingErrors((prev) => ({ ...prev, size: true }));
    //   isValid = false;
    // }

    // //floors
    // if (building.floors === 0) {
    //   setBuildingErrors((prev) => ({ ...prev, floors: true }));
    //   isValid = false;
    // }

    // if (building.changing_rooms_lockers === 0 && changeRoomsSelector == 1) {
    //   setBuildingErrors((prev) => ({ ...prev, changing_rooms_lockers: true }));
    //   isValid = false;
    // }
  };

  useEffect(() => {
    console.log("buildingErrors : ", buildingErrors);
    console.log("building : ", building);
  }, [buildingErrors]);

  const saveBuildingHandler = () => {
    // we check if the mandatory fields are empty
    const isValid = formIsValid();

    if (isValid) {
      const token = Auth.getToken();
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      //copy the building object
      const buildingData = { ...building };

      //remove the images and the docs
      delete buildingData.images;
      delete buildingData.attachements;

      console.log("buildingData : ", buildingData);

      let payload = buildingData;

      if (payload?.refurbishment_year?.length > 0) {
        const year = new Date(payload.refurbishment_year).getFullYear();
        payload.refurbishment_year = year;
        console.log("updated payload", payload);
      }

      axios
        .patch(
          routeReplace(API_EDIT_NEW_BUILDING_V2, { buildingId: buildingId }),
          { ...payload }
        )
        .then((payload) => {
          //redirecting the user to the units page
          history.push(`/buildings/edit/${buildingId}/units`);
        })
        .catch((error) => {
          //parsing the errors to process
          processApiErrors(
            error.response.data.errors,
            buildingErrors,
            setBuildingErrors
          );
        });
    }
  };

  //close the Alert message
  const handleClose = (fullFieldName) => {
    const [fieldName, messageId] = fullFieldName.split("|");
    const oldErrorItem = buildingErrors[fieldName];
    oldErrorItem.messages.splice(messageId, 1);
    const newErrorItem =
      oldErrorItem.messages.length > 0 ? { ...oldErrorItem } : false;
    setBuildingErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: newErrorItem,
    }));
  };

  //fetch the building data when we load the page
  useEffect(() => {
    dispatch(resetBuildingSlice());

    // we display the attachements once we got the building id from the url
    axios
      .get(routeReplace(API_ALL_BUILDING_DOCS_V2, { buildingId: buildingId }), {
        headers: {
          Authorization: "Bearer " + Auth.getToken(),
          "Content-Type": "application/json",
        },
      })
      .then((payload) => {
        dispatch(updateBuildingAttachements(payload.data.all));
        let last = 0;

        if (payload.data.all.lenght > 0) {
          last = payload.data.all.slice(-1)[0].order + 1;
        }

        setLastDoc(last);
      });

    // Get the images and the docs when we load the page
    axios
      .get(
        routeReplace(API_ALL_BUILDING_IMAGES_V2, { buildingId: buildingId }),
        {
          headers: {
            Authorization: "Bearer " + Auth.getToken(),
            "Content-Type": "application/json",
          },
        }
      )
      .then((payload) => {
        console.log("payload.data.all : ", payload.data.all);
        dispatch(updateBuildingImages(payload.data.all));

        setLastImage(payload.data.all.length);
      });

    axios
      .get(routeReplace(API_EDIT_NEW_BUILDING_V2, { buildingId: buildingId }))
      .then((res) => {
        dispatch(updateBuildingSlice(res.data.record));
        const buildingIsNew = res.data.record.new;
        dispatch(updateBuildingIsNew(buildingIsNew));
      })
      .catch((err) => {
        history.push(`/portfolio`);
      });
  }, [buildingId]);

  const cancelClickHandler = () => {
    //check if the record have new true
    if (building.buildingIsNew) {
      console.log("is neeeew");
      const token = Auth.getToken();
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios
        .delete(
          routeReplace(API_DELETE_NEW_BUILDING_V2, { buildingId: buildingId })
        )
        .then(() => {
          history.push(`/portfolio`);
        })
        .catch((err) => console.log(err));
    } else {
      history.push(`/portfolio`);
    }
  };

  return (
    <main className={globalClasses.maincontent}>
      <Helmet>
        <title>{dictionary.EditBuilding}</title>
      </Helmet>
      <CssBaseline />
      <Container maxWidth={false}>
        <Grid container spacing={3} wrap="nowrap">
          <Grid
            item
            md={true}
            xs={12}
            className={globalClasses.ContainerContent}
            style={{ marginTop: "2rem" }}
          >
            <Grid container spacing={2}>
              <Grid item sm={12} md={9}>
                {/*Sections */}
                <div style={{ marginLeft: `${smBreakpoint ? "0" : "2rem"}` }}>
                  <BuildingNameAndLocation
                    classes={classes}
                    dictionary={dictionary}
                    userLanguage={userLanguage}
                    building={building}
                    dispatch={dispatch}
                    buildingErrors={buildingErrors}
                    setBuildingErrors={setBuildingErrors}
                  />
                  <BuildingDescription
                    classes={classes}
                    dictionary={dictionary}
                    building={building}
                    dispatch={dispatch}
                    buildingId={buildingId}
                    buildingErrors={buildingErrors}
                    setBuildingErrors={setBuildingErrors}
                    lastImage={lastImage}
                    setLastImage={setLastImage}
                    lastDoc={lastDoc}
                    setLastDoc={setLastDoc}
                  />
                  <BuildingInformations
                    classes={classes}
                    dictionary={dictionary}
                    building={building}
                    dispatch={dispatch}
                    buildingErrors={buildingErrors}
                    setBuildingErrors={setBuildingErrors}
                    changeWarehouseSelector={changeWarehouseSelector}
                  />
                  <BuildingFacilities
                    classes={classes}
                    dictionary={dictionary}
                    building={building}
                    dispatch={dispatch}
                    buildingErrors={buildingErrors}
                    setBuildingErrors={setBuildingErrors}
                    changeRoomsSelector={changeRoomsSelector}
                  />

                  {/* Alert messages */}
                  <AlertMessages
                    buildingErrors={buildingErrors}
                    handleClose={handleClose}
                  />
                  <Grid item xs={12}>
                    <div className={classes.ButtonContainer}>
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        className={classes.SaveButton}
                        onClick={cancelClickHandler}
                      >
                        {dictionary.cancelTooltip}
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        className={classes.SaveButton}
                        onClick={saveBuildingHandler}
                      >
                        {dictionary.SaveAndProceed}
                      </Button>
                    </div>
                  </Grid>
                </div>
              </Grid>

              <Hidden smDown>
                <Grid item xs={3}>
                  <BuildingStickyMenu dictionary={dictionary} />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </main>
  );
};

export default AddBuilding;
