import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Auth from "../auth/auth";
import { Helmet } from "react-helmet";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LanguageContext } from "../containers/languageprovider";
import { Typography } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalpaper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    margin: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  ContainerPage:{
    maxWidth: "444px",

  },
  ContainerDialog: {
    padding:"0px",
    margin:"0px",
  }
}));

export default function Recover(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [msgmodal, setMsgModal] = useState("");
  const [msgTitle, setMsgTitle] = useState("");
  const [loading, setLoading] = React.useState(false);
  const { dictionary } = React.useContext(LanguageContext);
  const [canCloseModal, setCanCloseModal] = React.useState(false);

  const handleOpen = (title, msg) => {
    setMsgTitle(title);
    setMsgModal(msg);
    setOpen(true);
    setCanCloseModal(true)
    if (title === "success" && props.isDialog) {
      setTimeout(props.infoClose, 1000)
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const recoverSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.what === "password") {
      Auth.recoverPassword(email)
        .then((payload) => {
          setLoading(false);
          handleOpen("success", dictionary.RecoverPasswordInstructions);
        })
        .catch((error) => {
          setLoading(false);
          handleOpen("error", dictionary[error]);
        });
    } else {
      Auth.recoverInfo(email)
        .then((payload) => {
          setLoading(false);
          handleOpen("success", dictionary.RecoverInfoInstructions);
        })
        .catch((error) => {
          setLoading(false);
          handleOpen("error", dictionary[error]);
        });
    }
  };

  /*
  {props.how==="info"  && dictionary.RecoverInfo}*/

  return (
    <Container component="main" className={props.isDialog? classes.ContainerDialog : classes.ContainerPage}>
      <CssBaseline />
      <Helmet>
        <title>
          {props.what === "password"
            ? dictionary.RecoverPassword
            : dictionary.RecoverInfo}
        </title>
      </Helmet>
      <div className={classes.paper} style={{marginTop: props.isDialog? "0px" : "164px",}}>
      {!props.isDialog &&
        <div>
          <Link component={RouterLink} to="/">
            <img
              style={{
                height: "100px",
                width: "auto",
                paddingRight: "10px",
                paddingTop: "10px",
              }}
              src="/images/logo.svg"
              alt="logo"
              className="siteLogo"
            />
          </Link>
        </div>}

        <form className={classes.form} noValidate onSubmit={recoverSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!props.isDialog &&
              <Typography variant="h5" gutterBottom>
                {props.what === "password"
                  ? dictionary.RecoverPassword
                  : dictionary.RecoverInfo}
              </Typography>}
              {props.isDialog &&
                  <Typography >
                    {props.what === "password"
                        ? dictionary.RecoverPasswordContent
                        : dictionary.RecoverInfoContent}
                  </Typography>}
            </Grid>
            <Grid item xs={12} >
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label={dictionary.Emailaddress}
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            {dictionary.SendRequest}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
          <Grid container justify="flex-end">
             <Grid item>
               {props.isDialog? (
                  <Link onClick={() => {props.handleLoginAction("login")}} variant="body2">
                    {dictionary.Alreadyhaveaccount}
                  </Link>
               ):(
                   <Link component={RouterLink} to="/login" variant="body2">
                     {dictionary.Alreadyhaveaccount}
                   </Link>
               )}
            </Grid>
          </Grid>
        </form>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={msgTitle}>
          {msgmodal}
        </Alert>
      </Snackbar>
    </Container>
  );
}
