import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useHistory } from "react-router-dom";
import { LanguageContext } from "../containers/languageprovider";
import DialogTitle from "@material-ui/core/DialogTitle";
import globalStyles from "./globalStyles";

export default function LogoutDialog(props) {
  const globalClasses = globalStyles();
  const history = useHistory();
  const { dictionary } = React.useContext(LanguageContext);
  const { open, setOpen } = props;

  const handleLogout = () => {
    setOpen(false);
    history.push("/logout");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>{dictionary.ConfirmExit}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dictionary.AreYouSureToLogOut}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          color="primary"
          variant="outlined"
          className={globalClasses.buttonDialog}
        >
          {dictionary.cancelTooltip}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={globalClasses.buttonDialog}
          onClick={handleLogout}
        >
          {dictionary.Logout}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
