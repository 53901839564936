import { filters } from "../lib/advancedFilters";
import { returnStringTranslation } from "./printLanguageSelect";
import {
  dictionaryList,
  isoCountryMap,
  isoLanguageMap,
  languageOptions,
} from "./languages";
import { LanguageContext } from "../containers/languageprovider";
import {
  DEAL_TYPES,
  ASSET_CLASSES,
  OFFEROR_PHASE_RECEIVED,
  OFFEROR_PHASE_SUBMITTED,
  OFFEROR_PHASE_TAKEN_OVER,
  SEARCHER_PHASE_MATCHED,
  SEARCHER_PHASE_RECEIVED,
  SEARCHER_PHASE_SUBMITTED,
  SEARCHER_PHASE_TAKEN_OVER,
  OFFEROR_PHASE_MATCHED,
  API_SHARE_BUILDING,
  DEAL_TYPE_LEASE_INT,
  DEAL_TYPE_SALE_INT,
  UNIT_FLOOR,
  API_SHARE_SEARCHER_UNIT,
  UNIT_STATE_AVAILABLE,
  BUILDING_STATE_OFFLINE,
  BUILDING_STATE_ONLINE,
  BUILDING_STATE_PROPOSED_DRAFT,
  BUILDING_STATE_DRAFT,
  UNIT_STATE_AVAILABLE_FROM,
  UNIT_STATE_OFFMARKET,
  UNIT_STATE_STANDBY,
  UNIT_STATE_NOT_AVAILABLE,
  UNIT_STATE_LABELS,
  FLOOR_GROUNDFLOOR,
  FLOOR_MEZZANINE,
  FLOOR_ENTIRE_BUILDING,
  UNIT_RENOVATION_GRADE,
  UNIT_RENOVATION_STATUS,
  FEATURE_EDITOR,
  PAYMENT_DONE,
  SUPER_ADMIN,
  FEATURE_PREMIUM,
  FEATURE_LONG_LIST,
  FEATURE_SEARCHER,
  FEATURE_PORTFOLIO,
  FEATURE_TEASER,
  FEATURE_OFFEROR,
  BROKER,
  LANDLORD, ASSETMANAGER, TENANT, PRICE_TYPE_ON_DEMAND, PRICE_TYPE_PRIVATE_NEGOTIATION,
} from "../config/config";
import Auth from "../auth/auth";
import axios from "axios";

export const HourList = [
  "08:00 - 10:00",
  "10:00 - 12:00",
  "14:00 - 16:00",
  "16:00 - 18:00",
];

export const sizelistname = [
  {
    id: 1,
    valuemin: 0,
    valuemax: 500,
    label: "< 500",
  },
  {
    id: 2,
    valuemin: 500,
    valuemax: 800,
    label: "500-800",
  },
  {
    id: 3,
    valuemin: 800,
    valuemax: 1200,
    label: "800-1200",
  },
  {
    id: 4,
    valuemin: 1200,
    valuemax: 2000,
    label: "1200-2000",
  },
  {
    id: 5,
    valuemin: 2000,
    valuemax: 3000,
    label: "2000-3000",
  },
  {
    id: 6,
    valuemin: 3000,
    valuemax: 4000,
    label: "3000-4000",
  },
  {
    id: 7,
    valuemin: 4000,
    valuemax: 100000,
    label: "> 4000",
  },
];

export const askingrentlist = [
  {
    id: 1,
    valuemin: 100,
    valuemax: 200,
    label: "100 - 200 €",
  },
  {
    id: 2,
    valuemin: 200,
    valuemax: 300,
    label: "200 - 300 €",
  },
  {
    id: 3,
    valuemin: 300,
    valuemax: 400,
    label: "300 - 400 €",
  },
  {
    id: 4,
    valuemin: 400,
    valuemax: 500,
    label: "400 - 500 €",
  },
  {
    id: 5,
    valuemin: 500,
    valuemax: 0,
    label: "> 500 €",
  },
];

export const askingsaleslist = [
  {
    id: 1,
    valuemin: 0,
    valuemax: 2000,
    label: "< 2.000 €",
  },
  {
    id: 2,
    valuemin: 2000,
    valuemax: 5000,
    label: "2.000 -  5.000 €",
  },
  {
    id: 3,
    valuemin: 5000,
    valuemax: 10000,
    label: "5.000 - 10.000 €",
  },
  {
    id: 4,
    valuemin: 10000,
    valuemax: 20000,
    label: "10.000 - 20.000 €",
  },
  {
    id: 5,
    valuemin: 20000,
    valuemax: 100000,
    label: "> 20.000 €",
  },
];

export function Counter(callback) {
  this.value = 0;
  this.inc = (log) => {
    this.value++;
    // console.log("Inc: %o %o", this.value, log || "");
    callback(this.value);
    return this.value;
  };
  this.dec = (log) => {
    if (this.value > 0) {
      this.value--;
    }
    // console.log("Dec: %o %o", this.value, log || "");
    callback(this.value);
    return this.value;
  };
  this.get = () => {
    return this.value;
  };
}

export function returnDescription(key, all) {
  let element_found = "";
  all.forEach((element) => {
    if (Number(element.id) === Number(key)) {
      element_found = element.translations;
    }
  });
  return element_found;
}

export function returnDescriptionById(id, list, userLanguage) {
  let description = "";
  list.forEach((element) => {
    if (Number(element.id) === Number(id)) {
      description = returnStringTranslation(element.description, userLanguage);
    }
  });

  return description;
}

/**
 * @deprecated
 * @param id
 * @param dictionary
 * @returns {*}
 */
export function returnUnitGradeById(id, dictionary) {
  return dictionary[UNIT_RENOVATION_GRADE[id]];
}

/**
 * @deprecated
 * @param id
 * @param dictionary
 * @returns {*}
 */
export function returnRenovationStatusById(id, dictionary) {
  return dictionary[UNIT_RENOVATION_STATUS[id]];
}

export function ReturnDealTypeParamInverse(deal_type) {
  switch (Number(deal_type)) {
    case "for-rent":
      return 1;
    case "for-sale":
      return 2;
    default:
      return 1;
  }
}

export function ReturnDealTypeParam(deal_type) {
  switch (deal_type) {
    case 1:
      return "for-rent";
    case 2:
      return "for-sale";
    default:
      return "for-rent";
  }
}

export async function directToSubdomain(prefix) {
  const routes = await (
    await fetch("/routes.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
  ).json();
  const foundRoute = routes.routes.find((e) => e.URL === prefix);
  return Boolean(foundRoute)
    ? {
        found: Boolean(foundRoute),
        props: {
          slug: foundRoute?.page_slug,
          companyID: foundRoute?.company_id,
        },
      }
    : false;
}

export function ReturnAssetClassParamInverse(asset_class) {
  //const { dictionary} = React.useContext(LanguageContext);

  switch (asset_class) {
    case "office":
      return 1;
    case "retail":
      return 2;
    case "industrial":
      return 3;
    default:
      return 1;
  }
}

export function ReturnAssetClassParam(asset_class) {
  //const { dictionary} = React.useContext(LanguageContext);

  switch (Number(asset_class)) {
    case 1:
      return "office";
    case 2:
      return "retail";
    case 3:
      return "industrial";
    default:
      return "office";
  }
}

export function ReturnDealTypeDescription(dictionary, deal_type) {
  switch (Number(deal_type)) {
    case 1:
      return dictionary.ForRent;
    case 2:
      return dictionary.ForSale;
    default:
      return dictionary.ForRent;
  }
}

export function ReturnAssetClassDescription(dictionary, asset_class) {
  //const { dictionary} = React.useContext(LanguageContext);

  switch (Number(asset_class)) {
    case 1:
      return dictionary.Office;
    case 2:
      return dictionary.Retail;
    case 3:
      return dictionary.Industrial;
    default:
      return dictionary.Office;
  }
}

export function ReturnPriceUnitDescription(dictionary, price_unit) {
  //const { dictionary} = React.useContext(LanguageContext);

  switch (Number(price_unit)) {
    case 1:
      return dictionary.rentValue1;
    case 2:
      return dictionary.rentValue2;
    case 3:
      return dictionary.rentValue3;
    default:
      return "€";
  }
}
export function ReturnOfficeTypeDescription(dictionary, officetype) {
  //const { dictionary} = React.useContext(LanguageContext);

  switch (Number(officetype)) {
    case 1:
      return dictionary.OnyOffice;
    case 2:
      return dictionary.OfficeRetail;
    case 3:
      return dictionary.OfficeResidential;
    default:
      return dictionary.OnyOffice;
  }
}

export function formatDate(dateString) {
  const parts = dateString.split("-");
  const year = parts[0];
  const month = parts[1];
  return `${month}/${year}`;
}
export function DecorateTeaser(teaser) {
  teaser.info = new TeaserInfo(teaser);
}

export function TeaserInfo(teaser) {
  const newTeaser = teaser;
  this.getSearcheableText = (dictionary) => {
    const buffer =
      newTeaser.building_name_gb +
      " " +
      newTeaser.building_name_it +
      " " +
      newTeaser.address +
      " " +
      newTeaser.comments_gb +
      " " +
      newTeaser.comments_it +
      " " +
      newTeaser.mandate +
      " " +
      this.getAssetClassLabel(dictionary) +
      " " +
      this.getDealTypeLabel(dictionary);
    return buffer.toLowerCase();
  };
  this.getAddress = (language) => {
    if (typeof newTeaser.address == "string") {
      return newTeaser.address;
    }
    if (typeof newTeaser.address == "object") {
      if (typeof newTeaser.address[language] !== "string") {
        const rv = Object.values(newTeaser.address).find(
            (value) => value.length > 0
        );
        return rv === undefined ? "No valid address" : rv;
      }
      return newTeaser.address[language];
    }
    return "No address";
  };

  /**
   * TEASER
   * @param sizes
   * @param prices
   * @param assetClass
   * @param dealType
   * @returns {*|*[]}
   */
  this.filterBySizesRangeUnitFilterInfo = ({
    sizes,
    prices,
    assetClass,
    dealType,
  }) => {
    // console.log("Pices %o %o",typeof  prices,prices);
    // const sp=sizes.reduce(function(prev,curr){return prev + " [ "+ curr.min + " " + curr.max + " ]";},"Sizes ");
    let res;
    const uv = newTeaser.units_filter_info?.filter(
      (unit) =>
        (!isNumeric(dealType) || unit.deal_type == dealType) &&
        (!isNumeric(assetClass) || unit.asset_class == assetClass) &&
        (prices.length === 0 ||
          prices.find(
            (price) =>
              unit.price_sqm >= price.min && unit.price_sqm <= price.max
          )) &&
        (sizes.length === 0 || sizes.find((size) => unit.size <= size.max)) // tengo solo le unità <= del requisito massimo
    );

    // non ho sizes e ho già filtrato per price, ritorno l'array di units pulito
    if (sizes.length === 0) {
      return uv.map((unit) => unit.size);
    }

    // I got some size
    const size_total = uv
      .map((unit) => unit.size)
      .reduce((prev, curr) => prev + curr, 0);
    // prendo l'elenco delle size soddisfabili
    const sizesSatisfiable = sizes.filter((size) => size_total >= size.min);
    // con tutta l'area non soddisfo nessun requisito minimo
    if (sizesSatisfiable.length === 0) {
      return [];
    }
    // console.log("Calocolo del totale: %o Passa %o ", size_total, sizeReq);
    // uv.length > 0 && console.log("filterBySizesRangeUnitFilterInfo \n S %o\nP %o\nUL %o",sizes,prices, "uv");

    const unitsSizes = uv.map((unit) => unit.size || 0);

    // pericolo CPU hog!!!
    const rv = sizesSatisfiable.find((size) => {
      res = canFillKnapsackWithWeights(unitsSizes, size.min, size.max).filter(
        (size) => size > 0
      );
      return res.length > 0;
    });
    // console.log("Res CFKWW: %o",res);
    // res.length > 0 && console.log("trovato ",sp)
    return res;
  };

  this.getDealTypeLabel = (dictionary) => {
    // console.log("dictionary-list ===>: %o\nuser-language",dictionaryList)
    if (typeof DEAL_TYPES[newTeaser?.deal_type] === "undefined") {
      return null;
    }
    return dictionary[DEAL_TYPES[newTeaser.deal_type]];
  };

  this.getAssetClassLabel = (dictionary) => {
    if (typeof ASSET_CLASSES[newTeaser?.asset_class] === "undefined") {
      return null;
    }
    // console.log("ASSET_CLASSES %o",ASSET_CLASSES[newTeaser.asset_class]);

    return typeof dictionary[ASSET_CLASSES[newTeaser.asset_class]] ===
      "undefined"
      ? ""
      : dictionary[ASSET_CLASSES[newTeaser.asset_class]];
  };
}
export function DecorateBuildingWithUnits(building) {
  DecorateBuilding(building);
  building.units?.forEach(function (unit, i) {
    DecorateUnit(unit);
  });
}

export function DecorateUnitWithBuilding(unit) {
  DecorateUnit(unit);
  if (typeof unit.building !== "undefined") {
    DecorateBuilding(unit.building);
  }
}

export function DecorateBuilding(building) {
  building.detailAddress = new DetailedAddress(
    building.addressdetails || {},
    building
  );
  building.info = new BuildingInfo(building);
  // console.log("BUILDING: %o\n",building.detailAddress);
  return building;
}

export function DecorateUnit(unit) {
  unit.info = new UnitInfo(unit);
  return unit;
}

export function DecorateOfferor(offeror) {
  offeror.info = new OfferorInfo(offeror);
}

export function DecorateOfferorWithSearcher(offeror) {
  offeror.info = new OfferorInfo(offeror);
  if (typeof offeror.searcher != "undefined") {
    offeror.searcher.info = new SearcherInfo(offeror.searcher);
  }
}

export function DecorateSearcher(searcher) {
  searcher.info = new SearcherInfo(searcher);
}

export function DecorateUser(user) {
  user.isAdmin = function () {
    return this.role === SUPER_ADMIN;
  }
  user.isEditor = function () {
    return this.isAdmin()
        || this.company?.features?.filter((feature) => feature === FEATURE_EDITOR).length > 0
    ;
  }
  user.isPremium = function () {
    console.log("isPremium",this.company);
    return this.isAdmin()
        || this.company.features.filter((feature) => feature === FEATURE_PREMIUM).length > 0;
  }
  user.isTenant = function () {
    return this.role===TENANT;
  }
  user.isBroker = function () {
    return this.role===BROKER;
  }
  user.isLandlord = function () {
    return this.role===LANDLORD;
  }
  user.isAssetmanager = function () {
    return this.role===ASSETMANAGER;
  }
  user.isPortfolioUser = function () {
    return this.isAssetmanager() || this.isLandlord() || this.isBroker();
  }
  user.isSearcherUser = function () {
    return this.isAssetmanager() || this.isLandlord() || this.isBroker()|| this.isTenant();
  }
  user.isOfferorUser = function () {
    return  this.isAssetmanager() || this.isLandlord() || this.isBroker();
  }
  user.hasCompany = function () {
    return Object.values(this.company).length > 0
  }
  user.hasLongList = function () {
    // console.log("USER: %o", this);
    return this.isAdmin() || this.isPortfolioUser() && Boolean(this?.company?.features.find((feature) => feature === FEATURE_LONG_LIST));
  }
  user.hasSearcher = function () {
    // console.log("USER: %o", this);
    return this.isAdmin() || this.isSearcherUser() &&  Boolean(this?.company?.features.find((feature) => feature === FEATURE_SEARCHER));
  }
  user.hasOfferor = function () {
    // console.log("USER hasOfferor: %o: %o"
    //     ,Boolean(this?.company?.features.find((feature) => feature === FEATURE_OFFEROR, this))
    //     ,this);
    return this.isAdmin() || this.isOfferorUser() && Boolean(this?.company?.features.find((feature) => feature === FEATURE_OFFEROR));
  }
  user.hasPortfolio = function () {
    // console.log("USER hasPortfolio: %o: %o"
    //     , (this.isBroker() || Boolean(this?.company?.features.find((feature) => feature === FEATURE_PORTFOLIO)))
    //     , this);
    return this.isAdmin() || this.isPortfolioUser() && Boolean(this?.company?.features.find((feature) => feature === FEATURE_PORTFOLIO));
  }
  user.hasTeaser = function () {
    // console.log("USER hasTeaser: %o: %o"
    //     , Boolean( this?.company?.features.find((feature) => feature === FEATURE_TEASER))
    //     , this);
    return this.isAdmin() || this.isPortfolioUser() && Boolean( this?.company?.features.find((feature) => feature === FEATURE_TEASER));
  }
}

export function DetailedAddress(addressdetails) {
  // take a string or an object
  const objMl =
    typeof addressdetails == "object"
      ? addressdetails
      : JSON.parse(addressdetails);
  this.storage = {};
  // console.log("Google: %o\nTHIS: %o",objMl,this)

  Object.keys(languageOptions).forEach((language) => {
    this[language] = {};
    if (typeof objMl[language] == "object") {
      this.storage[language] = objMl[language] || {};
    } else {
      this.storage[language] = objMl;
    }
    // console.log("Elab: %o\nBuilding: %o\nAddress: %o",language,this,objMl)

    if (typeof this.storage[language].address_components == "undefined") {
      // console.log("address_components: %o",this[language]?.address_components || this[language]);
      // @todo should not happens, managed outside of the class
      this.storage[language].address_components = [
        { long_name: "città", types: ["administrative_area_level_3"] },
        { long_name: "provincia", types: ["administrative_area_level_2"] },
        { long_name: "regione", types: ["administrative_area_level_1"] },
        { long_name: "nazione", types: ["country"] },
      ];
    }
    this[language].address = this.storage[language].formatted_address;

    this.storage[language].address_components.forEach((element) => {
      if (element.types[0] === "administrative_area_level_3") {
        this[language].city = element.long_name;
      }
      if (element.types[0] === "administrative_area_level_2") {
        this[language].county = element.long_name;
      }
      if (element.types[0] === "administrative_area_level_1") {
        this[language].region = element.long_name;
      }
      if (element.types[0] === "country") {
        this[language].country = element.long_name;
      }
      if (element.types[0] === "postal_code") {
        this[language].zip = element.long_name;
      }
    });
  });

  this.isDefined = (language) => {
    return typeof this[language] === "object";
  };

  this.getLanguageObj = (language) => {
    const rv = {};
    rv[language] = this.storage[language];
    // console.log("getLanguageObj %o\n %o\n %o\n",rv,this.storage,this.storage[language]);
    return rv;
  };

  this.getFormattedAddress = (language) => {
    return this[language].address;
  };
  this.getCityCountry = (language) => {
    return this[language].city + ", " + this[language].country;
  };
  this.getCity = (language) => {
    return this[language].city;
  };
  this.getCountry = (language) => {
    return this[language].country;
  };

  this.getCounty = (language) => {
    return this[language].county;
  };

  this.getRegion = (language) => {
    return this[language].region;
  };
}
function canFillKnapsackWithWeights(weights, minCapacity, maxCapacity) {
  // console.log("The same MiS %o Mas %o UL %o",minCapacity,maxCapacity,weights);
  const n = weights.length;
  const dp = Array.from({ length: n + 1 }, () =>
    Array(maxCapacity + 1).fill(false)
  );

  // Initialize the first row as true since you can achieve a size of 0 with no items.
  for (let i = 0; i <= n; i++) {
    dp[i][0] = true;
  }

  for (let i = 1; i <= n; i++) {
    const currentWeight = weights[i - 1];
    for (let w = 1; w <= maxCapacity; w++) {
      if (currentWeight <= w) {
        dp[i][w] = dp[i - 1][w] || dp[i - 1][w - currentWeight];
      } else {
        dp[i][w] = dp[i - 1][w];
      }
    }
  }

  const canFill = [];
  for (let w = minCapacity; w <= maxCapacity; w++) {
    if (dp[n][w]) {
      canFill.push(w);
    }
  }

  return canFill;
}

export const normalizeMl = (value) => {
  let jsonObject, testObject;
  if (typeof value === "string") {
    // console.log("is string: %o",value);
    try {
      jsonObject = JSON.parse(value);
      // console.log("parsed: %o",jsonObject);
    } catch (e) {
      jsonObject = Object.keys(isoLanguageMap).reduce((prev, curr) => {
        prev[curr] = value;
        return prev;
      }, {});
      // console.log("fill with string: %o",jsonObject);
    }
  } else if (typeof value !== "object") {
    // todo evaluate the situation
    // console.log("Unexpected type [%o] for [%o]",typeof value,value);
    return value;
  } else {
    jsonObject = value;
  }

  testObject = Object.keys(isoLanguageMap).reduce((prev, curr) => {
    if (typeof value[curr] !== "undefined") prev[curr] = jsonObject[curr];
    return prev;
  }, {});
  // console.log("testy object: %o",testObject);

  if (testObject === {}) {
    jsonObject = Object.keys(isoLanguageMap).reduce((prev, curr) => {
      prev[curr] = value;
      return prev;
    }, {});
    // console.log("fill with object: %o",jsonObject);
  }

  // console.log("jsonObject: %o",jsonObject);

  const fisrtElement = Object.keys(isoLanguageMap).reduce(
    (prev, curr) =>
        prev !== null
            ? prev
            : typeof jsonObject[curr] === "undefined"
                ? null
                : jsonObject[curr]
    ,null
  );
  // console.log("First: %o",fisrtElement);
  const rv = Object.keys(isoLanguageMap).reduce((prev, curr) => {
    prev[curr] =
      typeof jsonObject[curr] === "undefined" ? fisrtElement : jsonObject[curr];
    return prev;
  }, {});
  // console.log("Result: %o",rv);
  return rv;
};
export function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

export function BuildingInfo(building) {
  let newBuilding = building;

  this.setBuilding = (clonedBuilding) => {
    newBuilding = clonedBuilding;
    building = clonedBuilding;
  };
  this.getAddress = (language) => {
    if (typeof newBuilding.address == "string") {
      return newBuilding.address;
    }
    if (typeof newBuilding.address == "object") {
      if (typeof newBuilding.address[language] !== "string") {
        const rv = Object.values(newBuilding.address).find(
          (value) => value.length > 0
        );
        return rv === undefined ? "No valid address" : rv;
      }
      return newBuilding.address[language];
    }
    return "No address";
  };

  // building contiene i filtri dell'immobile
  this.isPassingFilter = ({ building }) => {
    if (building.macroareas?.length > 0) {
      const hasMacroarea = building.macroareas.find(
        (macroarea) => Number(newBuilding.macroarea_list) === Number(macroarea)
      );
      if (typeof hasMacroarea === "undefined") {
        return false;
      }
    }
    return true;
  };

  // this.filterBySizeRange = (min,max,assetClass=null,dealType=null,priceMin=null,priceMax=null) => {
  //   return canFillKnapsackWithWeights(
  //       newBuilding.units?.filter(
  //           (unit) => (dealType===null || unit.deal_type == dealType)
  //               && (assetClass===null || unit.asset_class == assetClass)
  //               && (priceMin===null || unit.price_sqm >= priceMin)
  //               && (priceMax===null || unit.price_sqm <= priceMax)
  //           )
  //           .map((unit)=>unit.size || 0)
  //       ,min
  //       ,max
  //   );
  // }

  // this.filterBySizeRangeUnitFilterInfo = (min,max,assetClass=null,dealType=null,priceMin=null,priceMax=null) => {
  //   const uv=newBuilding.units_filter_info?.filter(
  //       (unit) => (dealType == 0  || unit.deal_type == dealType)
  //           && (assetClass == 0 || unit.asset_class == assetClass)
  //           && (priceMin===null || unit.price_sqm >= priceMin)
  //           && (priceMax===null || unit.price_sqm <= priceMax)
  //   );
  //   // console.log("filterBySizeRangeUnitFilterInfo MiS %o Mas %o MiP %o MaP %o UL %o",min,max,priceMin,priceMax, uv.map((unit)=>unit.size || 0));
  //
  //   // min e max sono null, già filtrato per price, ritorno l'array di units pulito
  //   if(min === null && max == null) {
  //     return uv.map((unit) => unit.size );
  //   }
  //
  //   const res= canFillKnapsackWithWeights(
  //       uv.map((unit)=>unit.size || 0)
  //       ,min
  //       ,max
  //   ).filter((size) => size>0);
  //   // console.log("Res CFKWW: %o",res);
  //   return res;
  // }

  /**
   * Building
   * @param sizes
   * @param prices
   * @param assetClass
   * @param dealType
   * @returns {*|*[]}
   */
  this.filterBySizesRangeUnitFilterInfo = ({
    sizes,
    prices,
    assetClass,
    dealType,
  }) => {
    // console.log("Prices %o %o",typeof  prices,prices);
    // console.log("Sizes %o %o",typeof  sizes,sizes);
    // const sp=sizes.reduce(function(prev,curr){return prev + " [ "+ curr.min + " " + curr.max + " ]";},"Sizes ");
    let res;
    const uv = newBuilding.units_filter_info?.filter(
      (unit) =>
        (!isNumeric(dealType) || unit.deal_type == dealType) &&
        (!isNumeric(assetClass) || unit.asset_class == assetClass) &&
        (prices.length === 0 ||
          prices.find(
            (price) =>
              unit.price_sqm >= price.min && unit.price_sqm <= price.max
          )) &&
        (sizes.length === 0 || sizes.find((size) => unit.size <= size.max)) // tengo solo le unità <= del requisito massimo
    );

    // non ho sizes e ho già filtrato per price, ritorno l'array di units pulito
    if (sizes.length === 0) {
      return uv.map((unit) => unit.size);
    }

    // I got some size
    const size_total = uv
      .map((unit) => unit.size)
      .reduce((prev, curr) => prev + curr, 0);
    // prendo l'elenco delle size soddisfabili
    const sizesSatisfiable = sizes.filter((size) => size_total >= size.min);
    // con tutta l'area non soddisfo nessun requisito minimo
    if (sizesSatisfiable.length === 0) {
      return [];
    }
    // console.log("Calocolo del totale: %o Passa %o ", size_total, sizeReq);
    // uv.length > 0 && console.log("filterBySizesRangeUnitFilterInfo \n S %o\nP %o\nUL %o",sizes,prices, "uv");

    const unitsSizes = uv.map((unit) => unit.size || 0);

    // pericolo CPU hog!!!
    const rv = sizesSatisfiable.find((size) => {
      res = canFillKnapsackWithWeights(unitsSizes, size.min, size.max).filter(
        (size) => size > 0
      );
      return res.length > 0;
    });
    // console.log("Res CFKWW: %o",res);
    // res.length > 0 && console.log("trovato ",sp)
    return res;
  };

  this.getMinUnitSize = (multiple, dealType = null, assetClass = null) => {
    return newBuilding.units
      ?.filter(
        (unit) =>
          (dealType === null || unit.deal_type == dealType) &&
          (assetClass === null || unit.asset_class == assetClass)
      )
      .map((unit) => unit.size || 0)
      .reduce(
        (previousVal, currentVal) =>
          previousVal < currentVal ? previousVal : currentVal,
        0
      );
  };

  this.getMaxUnitSize = (multiple, dealType = null, assetClass = null) => {
    return newBuilding.units
      ?.filter(
        (unit) =>
          (dealType === null || unit.deal_type == dealType) &&
          (assetClass === null || unit.asset_class == assetClass)
      )
      .map((unit) => unit.size || 0)
      .reduce((previousVal, currentVal) => {
        if (multiple) {
          return previousVal + currentVal;
        } else {
          return previousVal > currentVal ? previousVal : currentVal;
        }
      }, 0);
  };

  this.getMinUnitPrice = (multiple, dealType = null, assetClass = null) => {
    return newBuilding.units
      ?.filter(
        (unit) =>
          (dealType === null || unit.deal_type == dealType) &&
          (assetClass === null || unit.asset_class == assetClass)
      )
      .map((unit) => unit.price_sqm || 0)
      .reduce(
        (previousVal, currentVal) =>
          previousVal < currentVal ? previousVal : currentVal,
        0
      );
  };

  this.getMaxUnitPrice = (multiple, dealType = null, assetClass = null) => {
    return newBuilding.units
      ?.filter(
        (unit) =>
          (dealType === null || unit.deal_type == dealType) &&
          (assetClass === null || unit.asset_class == assetClass)
      )
      .map((unit) => unit.price_sqm || 0)
      .reduce(
        (previousVal, currentVal) =>
          previousVal > currentVal ? previousVal : currentVal,
        0
      );
  };

  this.getUrl = () => {
    return "/buildingpresentation/" + newBuilding.slug;
  };
  this.getFullUrl = () => {
    return window.location.origin + this.getUrl();
  };
  this.getCanonicalUrl = (userLanguage) => {
    const alternates = this.getAlternateUrls();
    // console.log("Language: %o\nDict: %o\nAlternates: %o\n",language,typeof dictionary,alternates);

    return alternates[isoLanguageMap[userLanguage]];
  };
  this.getAlternateUrls = () => {
    // console.log("dictionaryList list %o",dictionaryList);
    const rv = {};
    Object.keys(isoLanguageMap).forEach(function (language) {
      const dealTypes = [
        ...new Set(newBuilding.units?.map((obj) => obj.deal_type)),
      ]
        .sort()
        .map((dt) => DEAL_TYPES[dt])
        .map((liters_deal_type) => dictionaryList[language][liters_deal_type])
        .join("-");

      const assetClasses = [
        ...new Set(newBuilding.units?.map((obj) => obj.asset_class)),
      ]
        .sort()
        .map((ac) => ASSET_CLASSES[ac])
        .map((liters_deal_type) => dictionaryList[language][liters_deal_type])
        .join("-");
      const city = newBuilding.detailAddress.getCity(language).toLowerCase();
      const country = newBuilding.detailAddress
        .getCountry(language)
        .toLowerCase();
      rv[
        isoLanguageMap[language]
      ] = `/b-${language}/${assetClasses}/${dealTypes}/${city}-${country}/${newBuilding.slug}`;
    });
    // console.log("getAlternateUrls ",rv);
    return rv;
  };

  /**
   *
   * @returns {Promise<string>}
   */
  this.getShareUrlPrivate = async () => {
    try {
      const res = await axios.get(API_SHARE_BUILDING + newBuilding.slug, {
        headers: {
          Authorization: "Bearer " + Auth.getToken(),
          "Content-Type": "application/json",
        },
        params: { visibility: "extended" },
      });
      // console.log("res.data.token : ", res.data.token);
      return `/s/${res.data.token}`;
    } catch (error) {
      console.log(error);
    }
  };

  this.getBuildingStatusLabel = (userLanguage, state) => {
    // console.log("Id %o -> External state %o  nBs %o  iBs state %o\n",newBuilding.id,state,newBuilding.state,building.state);
    switch (newBuilding.state) {
      case BUILDING_STATE_OFFLINE:
        return dictionaryList[userLanguage].offline;

      case BUILDING_STATE_ONLINE:
        return dictionaryList[userLanguage].online;

      case BUILDING_STATE_PROPOSED_DRAFT:
        return dictionaryList[userLanguage].proposed_draft;

      case BUILDING_STATE_DRAFT:
        return dictionaryList[userLanguage].draft;
      default:
        return dictionaryList[userLanguage].unknow;
    }
  };
}

export const getCallerInfo = () => {
  try {
    throw new Error();
  } catch (error) {
    // The Error object contains a stack trace with call stack information
    const stackLines = error.stack.split("\n");

    // The second line of the stack trace usually contains the caller information
    if (stackLines.length >= 2) {
      // Extract the file name and line number from the second line
      const callerInfo = stackLines[2].match(/(([^/\\]+)\.js):(\d+):(\d+)/);

      if (callerInfo && callerInfo.length === 5) {
        const [, fileName, functionName, lineNumber] = callerInfo;
        return {
          fileName,
          functionName,
          lineNumber: parseInt(lineNumber, 10),
        };
      }
    }
  }

  // If caller information is not available, return null or an empty object
  return null;
};

/**
 *
 * @type {{getSearcherUnitUrl: ((function(*): Promise<string|null>)|*)}}
 */
export const searcherUtils = {
  /**
   *
   * @param searcherUnitId
   * @returns {Promise<null|string>}
   */
  getSearcherUnitUrl: async (searcherUnitId) => {
    try {
      const res = await axios.get(routeReplace(API_SHARE_SEARCHER_UNIT,{searcherUnitId: searcherUnitId}), {
        headers: {
          Authorization: "Bearer " + Auth.getToken(),
          "Content-Type": "application/json",
        },
        params: { visibility: "full" },
      });
      return `/s/${res.data.token}`;
    } catch (error) {
      console.log(error);
    }
    return null;
  },
};
export const unitFloorLabel = (floor) =>
  UNIT_FLOOR.reduce(
    (previous, current) =>
      current.level(Number(floor)) && previous === null
        ? current.label
        : previous,
    null
  );
export function UnitInfo(unit) {
  const newUnit = unit;
  this.getAssetForDeal = (dictionary) => {
    return (
      getAssetClassesText(newUnit.asset_class + "", dictionary) +
      " " +
      dictionary.for +
      " " +
      getDealTypeText(newUnit.deal_type + "", dictionary)
    );
  };

  this.getGradeLabel = (userLanguage) =>{
    return dictionaryList[userLanguage][UNIT_RENOVATION_GRADE[newUnit.renovationgrade]];
  }

  this.getRenovationStatusLabel = (userLanguage) =>{
    return dictionaryList[userLanguage][UNIT_RENOVATION_STATUS[newUnit.renovation_status]];
  }

  // unit contiene i filtri delle unità
  this.isPassingFilter = ({ unit }) => {
    return true;
  };

  this.getAssetClassLabel = (userLanguage) => {
    return getAssetClassesText(
      String(newUnit.asset_class),
      dictionaryList[userLanguage]
    );
  };
  this.getDealTypeLabel = (userLanguage) => {
    return dictionaryList[userLanguage][DEAL_TYPES[newUnit.deal_type]];
  };
  this.getOrdinal = (n, userLanguage) => {
    let ord = dictionaryList[userLanguage].th;
    if (n % 10 === 1 && n % 100 !== 11) {
      ord = dictionaryList[userLanguage].st;
    } else if (n % 10 === 2 && n % 100 !== 12) {
      ord = dictionaryList[userLanguage].nd;
    } else if (n % 10 === 3 && n % 100 !== 13) {
      ord = dictionaryList[userLanguage].rd;
    }
    return ord;
  };

  this.getFloorLabel = (userLanguage) => {
    const label = unitFloorLabel(newUnit.floor);
    const labelFloor = `${newUnit.floor | 0}${this.getOrdinal(
      newUnit.floor | 0,
      userLanguage
    )}`;
    // console.log(
    //   "Label [%o][%o]",
    //   label,
    //   unit.floor,
    //   dictionaryList[userLanguage][label]
    // );
    // console.log("DL %o",labelFloor );

    return dictionaryList[userLanguage][label] !== undefined
      ? routeReplace(dictionaryList[userLanguage][label], {
          floor: labelFloor || 0,
        })
      : label;
  };
  this.getAssetClassLabel = (userLanguage) => {
    return dictionaryList[userLanguage][ASSET_CLASSES[newUnit.asset_class]];
  };
  this.getUnitStatus = (userLanguage) => {
    let label = UNIT_STATE_LABELS.reduce(
      (previous, current) =>
        Number(current.state) === Number(newUnit.state) && previous === null
          ? current.label
          : previous,
      null
    );
    // console.log(
    //   "Label [%o][%o]",
    //   label,
    //   unit.state,
    //   dictionaryList[userLanguage][label]
    // );
    return routeReplace(dictionaryList[userLanguage][label], {
      available_from:
        newUnit.state === UNIT_STATE_AVAILABLE_FROM
          ? formatDate(newUnit.listing_date)
          : "",
    });

    if (userLanguage === "it") {
      switch (newUnit.state) {
        case UNIT_STATE_AVAILABLE:
          return "Ora";
        case UNIT_STATE_AVAILABLE_FROM:
          return `Da ${formatDate(newUnit.listing_date)}`;
        case UNIT_STATE_NOT_AVAILABLE:
          return "Trattativa chiusa";
        case UNIT_STATE_STANDBY:
          return "Trattativa in Corso";
        case UNIT_STATE_OFFMARKET:
          return "Confidenziale";
        default:
          return "Stato non definito";
      }
    } else {
      switch (newUnit.state) {
        case 1:
          return "Now";
        case 2:
          return `From ${formatDate(newUnit.listing_date)}`;
        case 3:
          return "Not Available";
        case 4:
          return "Stand by";
        case 5:
          return "Off Market";
        default:
          return "Undefined state";
      }
    }
  };

  this.calculateUnitPrice = (userLanguage) => {
    let label = `${dictionaryList[userLanguage].onRequest}`
    if (newUnit?.price_type === PRICE_TYPE_PRIVATE_NEGOTIATION || newUnit?.price_type === PRICE_TYPE_ON_DEMAND) {
      return label;
    } else if (newUnit.deal_type === DEAL_TYPE_LEASE_INT) {
      if(newUnit.asking_rent > 0) {
        const number = NumericUtils.fromNumberToFormatted(newUnit.asking_rent, userLanguage)
        label = number + `€/${dictionaryList[userLanguage].sqm}/${dictionaryList[userLanguage].year}`
      } else if (newUnit.total_asking_rent > 0){
        const number = NumericUtils.fromNumberToFormatted(Math.floor(newUnit.total_asking_rent / newUnit.size), userLanguage)
        label = number + `€/${dictionaryList[userLanguage].sqm}/${dictionaryList[userLanguage].year}`
      }
    } else if (newUnit.deal_type === DEAL_TYPE_SALE_INT) {
      if (newUnit.total_asking_rent > 0){
        const number = NumericUtils.fromNumberToFormatted(newUnit.total_asking_rent, userLanguage)
        label = number + "€"
      } else if(newUnit.asking_rent > 0) {
        const number = NumericUtils.fromNumberToFormatted((newUnit.asking_rent * newUnit.size), userLanguage)
        label =  number + "€"
      }
    }
    return label
  }
}

export function SearcherInfo(searcher) {
  // const  dictionary  = {}
  const newSearcher = searcher;

  this.isPayed = () => {
    // console.log("newSearcher isPayed: " ,newSearcher);
    return newSearcher.payment_state === PAYMENT_DONE;
  }
  this.isUnpayed = () => !this.isPayed();

  this.getDealTypeLabel = (dictionary) => {
    // console.log("DICTIONARY ===>: %o",typeof dictionary)
    if (typeof DEAL_TYPES[newSearcher?.deal_type] === "undefined") {
      return null;
    }
    return dictionary[DEAL_TYPES[newSearcher.deal_type]];
  };

  this.getSearcherTitle = (dictionary) => {
    return `${this.getAssetClassLabel(dictionary)} ${newSearcher.location}`;
  };

  this.getAssetClassLabel = (dictionary) => {
    if (typeof ASSET_CLASSES[newSearcher?.asset_class] === "undefined") {
      return null;
    }
    // console.log("ASSET_CLASSES %o",ASSET_CLASSES[newSearcher.asset_class]);

    return typeof dictionary[ASSET_CLASSES[newSearcher.asset_class]] ===
      "undefined"
      ? ""
      : dictionary[ASSET_CLASSES[newSearcher.asset_class]];
  };

  this.getFormattedFromDate = (dictionary) => {
    const date = new Date(newSearcher.available_from);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const from = dictionary.From;
    return ` ${from} ${month.toString().padStart(2, "0")}/${year} `;
  };

  this.getSizeLabels = (dictionary) => {
    const sizeLabels = [];
    newSearcher.size.forEach(function (elem, idx) {
      const size = sizelistname.find((s) => s.id === elem);
      if (size) {
        sizeLabels.push(size.label);
      }
    });
    return sizeLabels;
  };

  this.getSizeLabel = (dictionary) => {
    const sizeLabels = this.getSizeLabels(dictionary);

    switch (sizeLabels.length) {
      case 0:
        return " - ";
      case 1:
        return sizeLabels[0];
      default:
        return `${sizeLabels.length} ${dictionary.Sizes}`;
    }
  };

  this.getPhaseMessage = (dictionary) => {
    switch (newSearcher.phase) {
      case SEARCHER_PHASE_SUBMITTED:
      case SEARCHER_PHASE_RECEIVED:
      case SEARCHER_PHASE_TAKEN_OVER:
        return `${dictionary.WeAreProcessing}`;
      default:
        return;
    }
  };

  this.getPhaseChipStyle = (dictionary) => {
    switch (newSearcher.phase) {
      case SEARCHER_PHASE_SUBMITTED:
      case SEARCHER_PHASE_MATCHED:
        return {
          backgroundColor: "#e6efe6",
          color: "#338037",
          fontSize: "13px",
          cursor: "inherit",
        };
      case SEARCHER_PHASE_TAKEN_OVER:
        return {
          backgroundColor: "#fdede1",
          color: "#ed6c02",
          fontSize: "13px",
          cursor: "inherit",
        };
      case SEARCHER_PHASE_RECEIVED:
      default:
        return {
          backgroundColor: "#e8eefc",
          color: "#1c55e5",
          fontSize: "13px",
          cursor: "inherit",
        };
    }
  };
  this.getPriceLabels = (dictionary) => {
    const prices = [];
    newSearcher.price.forEach(function (elem, idx) {
      const price =
        newSearcher.deal_type === 1
          ? askingrentlist.find((s) => s.id === elem)
          : askingsaleslist.find((s) => s.id === elem);
      // console.log("DealType: %o Price: %o",newSearcher.deal_type,price.label);
      if (price) {
        prices.push(price.label);
      }
    });
    return prices;
  };

  this.getPriceLabel = (dictionary) => {
    const prices = this.getPriceLabels(dictionary);
    switch (prices.length) {
      case 0:
        return " ";
      case 1:
        return (
          prices[0] +
          `${newSearcher.deal_type === 1 ? `/${dictionary.year};  ` : ";"}`
        );
      default:
        const label =
          newSearcher.deal_type === 1 ? dictionary.Rents : dictionary.Prices;
        return `${prices.length} ${label}`;
    }
  };

  this.getSearcheableText = (dictionary) => {
    const buffer =
      newSearcher.location +
      " " +
      this.getAssetClassLabel(dictionary) +
      " " +
      this.getDealTypeLabel(dictionary);
    return buffer.toLowerCase();
  };
  this.getMessage = () => {
    return newSearcher.message;
  };
  this.getTotalStars = () => {
    let totalStars = 0;
    if (newSearcher.searcher_units.length > 0)
      newSearcher.searcher_units.map((searcherUnit) => {
        if (searcherUnit.star === 1) totalStars++;
      });
    return totalStars > 0 ? totalStars : "-";
  };
  this.getTotalBells = () => {
    let totalBells = 0;
    if (newSearcher.searcher_units.length > 0)
      newSearcher.searcher_units.map((searcherUnit) => {
        if (!searcherUnit.seen_at) totalBells++;
      });
    return totalBells > 0 ? totalBells : "-";
  };
  this.getTenantEmail = () => {
    return newSearcher.tenant?.email;
  };
  this.getTenantPhone = () => {
    return newSearcher.tenant?.mobilephone;
  };

  this.getAdminSearchableText = (dictionary) => {
    const b = [];
    b.push(
      "s" + newSearcher.id,
      newSearcher.location,
      this.getAssetClassLabel(dictionary),
      this.getDealTypeLabel(dictionary),
      newSearcher.tenant?.name,
      newSearcher.tenant?.surname,
      newSearcher.tenant?.mobilephone,
      newSearcher.tenant?.email,
      ...this.getPriceLabels(dictionary),
      ...this.getSizeLabels(dictionary)
    );
    newSearcher.searcher_units.forEach(function (su) {
      su.searcher_offerors?.forEach(function (so) {
        b.push("o" + so.id, so.company.companyname);
      });
    });
    // console.log("----------> Search P: %o\n", newSearcher);
    return b.join(" ").toLowerCase();
  };
}

export function OfferorInfo(offeror) {
  const newOfferor = offeror;
  this.getOfferorTitle = (dictionary) => {
    return newOfferor.searcher.info.getSearcherTitle(dictionary);
  };

  this.getPhaseChipStyle = (dictionary) => {
    switch (newOfferor.phase) {
      case OFFEROR_PHASE_MATCHED:
        return {
          backgroundColor: "#e6efe6",
          color: "#338037",
          fontSize: "13px",
          cursor: "inherit",
        };
      case OFFEROR_PHASE_TAKEN_OVER:
        return {
          backgroundColor: "#fdede1",
          color: "#ed6c02",
          fontSize: "13px",
          cursor: "inherit",
        };
      case OFFEROR_PHASE_RECEIVED:
      default:
        return {
          backgroundColor: "#e8eefc",
          color: "#1c55e5",
          fontSize: "13px",
          cursor: "inherit",
        };
    }
  };

  this.getPhaseLabel = (dictionary) => {
    switch (newOfferor.phase) {
      case OFFEROR_PHASE_MATCHED:
        return dictionary.Active;
      case OFFEROR_PHASE_RECEIVED:
        return dictionary.Received;
      case OFFEROR_PHASE_TAKEN_OVER:
      default:
        return dictionary.Accepted;
    }
  };

  this.getOfferorLocation = () => {
    return newOfferor.searcher.location;
  };
  this.getOfferorOffer = (dictionary) => {
    return getOfferParameter(
      newOfferor.searcher.deal_type,
      newOfferor.units_ids,
      dictionary
    );
  };
  this.getOfferorSize = () => {
    return getSizeParameter(newOfferor.searcher.size);
  };
  this.getOfferorFromDate = (dictionary) => {
    return dictionary.From + " " + newOfferor.searcher.available_from;
  };
  this.getTenantEmail = () => {
    return newOfferor.tenant?.email;
  };
  this.getTenantPhone = () => {
    return newOfferor.tenant?.mobilephone;
  };
  this.getTotalStars = () => {
    let totalStars = 0;
    if (newOfferor?.searcher_units?.length > 0)
      newOfferor.searcher_units.map((searcherUnit) => {
        if (searcherUnit.star === 1) totalStars++;
      });
    return totalStars > 0 ? totalStars : "-";
  };
  this.getSearcheableText = (dictionary) => {
    const buffer =
      newOfferor.location +
      " " +
      this.getOfferorLocation() +
      " " +
      this.getOfferorOffer(dictionary);
    return buffer.toLowerCase();
  };
}

export const getOfferParameter = (deal_type, units_ids, dictionary) => {
  if (units_ids.length == 0) {
    return;
  } else if (units_ids.length > 1) {
    return deal_type === 1
      ? `${units_ids.length} ${dictionary.Rents}`
      : `${units_ids.length} ${dictionary.sale}`;
  } else {
    const rentItem =
      deal_type === 1
        ? askingrentlist.find((item) => item.id === units_ids[0])?.label
        : askingsaleslist.find((item) => item.id === units_ids[0])?.label;
    return deal_type === 1
      ? `${dictionary.Rent} ${rentItem}`
      : `${units_ids.length} ${dictionary.sale}`;
  }
};

export const getSizeParameter = (size, dictionary) => {
  if (size.length == 0) {
    return;
  } else if (size.length > 1) {
    return `${size.length} ${dictionary.Size}`;
  } else {
    const sizeItem = sizelistname.find((item) => item.id === size[0])?.label;

    return `${dictionary.Size} ${sizeItem}`;
  }
};

export function returnAddressInformation(addressdetails) {
  if (addressdetails !== "" && addressdetails !== undefined) {
    const obj =
      typeof addressdetails == "object"
        ? addressdetails
        : JSON.parse(addressdetails);
    let description = "";
    if (obj !== null && obj.address_components !== undefined) {
      obj.address_components.forEach((element) => {
        if (element.types[0] === "locality") {
          description = element.long_name;
        }

        if (element.types[0] === "country") {
          description += "," + element.long_name;
        }
      });
    }

    return description;
  } else {
    return "";
  }

  //
}
export function returnAddressInformationBreadcrumb(addressdetails) {
  if (addressdetails !== "" && addressdetails !== undefined) {
    const obj =
      typeof addressdetails == "object"
        ? addressdetails
        : JSON.parse(addressdetails);
    let description = "";
    if (obj !== null && obj.address_components !== undefined) {
      obj.address_components.forEach((element) => {
        if (element.types[0] === "administrative_area_level_3") {
          description = element.long_name;
        }

        if (element.types[0] === "country") {
          description += "," + element.long_name;
        }
      });
    }

    return description;
  } else {
    return "";
  }

  //
}

/**
 * replace %0, %1, ... in `format` with the following arguments
 * @param format
 * @param args
 * @returns {*}
 */
export function tokenReplace(format, ...args) {
  return format.replace(/%(\d+)/g, (match, index) => {
    console.log("tokenReplace %o %o", match, index);
    return args[index] !== undefined ? args[index] : match;
  });
}

export function routeReplace(format, obj) {
  // console.log("Format: %o Objext: %o",format,obj);
  return format.replace(/:(\w+)\b/g, (match, index) => {
    // console.log("routeReplace %o %o %o", match,index, obj[index]);
    return obj[index] !== undefined ? obj[index] : match;
  });
}

export function GetMacroAreaString(macro, userLanguage) {
  return (
    returnStringTranslation(macro.description, userLanguage) + ", " + macro.city
  );
}

export function ReturnListOfCity(macroareabuildings) {
  //macroareabuildings.filter((item,i,ar) => ar.indexOf(item.city)===i).map((macroareacity,indexcity)=>(
  let unique = [...new Set(macroareabuildings.map((item) => item.city))];

  return unique;
}

export const Flag = (code) => {
  return "/flags/" + code + ".svg";
};

export const FeaturesMapping = (commFeatures, techFeatures, renoStatus) => {
  const mapping = [
    {
      Title: {
        gb: "Certifications",
        it: "Certificazioni",
      },
      type: "checkbox-any-of",
      Values: [
        {
          Title: {
            gb: "LEED",
            it: "LEED",
          },
          ValuesComm: [20, 19, 18, 29],
          ValuesTech: [],
        },
        {
          Title: {
            gb: "BREEAM",
            it: "BREEAM",
          },
          ValuesComm: [37, 25, 39, 40],
          ValuesTech: [],
        },
        {
          Title: {
            gb: "WELL",
            it: "WELL",
          },
          ValuesComm: [41, 22, 23, 24, 48],
          ValuesTech: [],
        },
      ],
    },
    {
      Title: {
        gb: "Building Status",
        it: "Stato immobile ",
      },
      SubTitles: [
        {
          Title: {
            gb: "Construction Status",
            it: "Stato di conservazione",
          },
          ValuesComm: [28, 30, 46],
          ValuesTech: [],
          type: "radio",
        },
        {
          Title: {
            gb: "Heritage",
            it: "Storico",
          },
          ValuesComm: [],
          ValuesTech: [12],
          type: "yes/no",
        },
        {
          Title: {
            gb: "Smart Building",
            it: "Smart Building",
          },
          ValuesComm: [21],
          ValuesTech: [],
          type: "yes/no",
        },
      ],
    },
    {
      Title: {
        gb: "Building Services",
        it: "Servizi immobile",
      },
      SubTitles: [
        {
          Title: {
            gb: "Entrance",
            it: "Ingresso",
          },
          ValuesComm: [3, 4],
          ValuesTech: [],
          type: "radio",
        },
        {
          Title: {
            gb: "",
            it: "",
          },
          ValuesComm: [5],
          ValuesTech: [],
          type: "radio",
        },
        {
          Title: {
            gb: "Shared Areas",
            it: "Aree comuni",
          },
          ValuesComm: [6, 14, 13, 27, 12, 11, 15, 17],
          ValuesTech: [21],
          type: "checkbox",
        },
        {
          Title: {
            gb: "Services",
            it: "Servizi extra",
          },
          ValuesComm: [],
          ValuesTech: [22],
          type: "checkbox",
        },
      ],
    },
    {
      Title: {
        gb: "Mobility",
        it: "Mobilità",
      },
      SubTitles: [
        {
          Title: {
            gb: "Parking",
            it: "Parcheggi",
          },
          ValuesComm: [1, 2, 36, 9],
          ValuesTech: [],
          type: "checkbox",
        },
        {
          Title: {
            gb: "Sustainable Mobility",
            it: "Mobilità sostenibile",
          },
          ValuesComm: [8],
          ValuesTech: [],
          type: "checkbox",
        },
      ],
    },
    {
      Title: {
        gb: "Delivery Status",
        it: "Status di consegna",
      },
      ValuesComm: [],
      ValuesTech: [],
      // for correct order or unrelated values
      allValues: [
        { value: 3, type: "reno" },
        { value: 5, type: "reno" },
        { value: 1, type: "reno" },
        { value: 2, type: "reno" },
        { value: 4, type: "reno" },
      ],
      type: "checkbox",
    },
    {
      Title: {
        gb: "Security",
        it: "Impianti di Sicurezza",
      },
      SubTitles: [
        {
          Title: {
            gb: "Fire Emergency",
            it: "Impianti antincendio",
          },
          ValuesComm: [],
          ValuesTech: [8, 19],
          type: "checkbox",
        },
      ],
    },
    {
      Title: {
        gb: "Electrical System",
        it: "Impianti elettrici",
      },
      ValuesComm: [],
      ValuesTech: [7, 6, 5, 10],
      type: "checkbox",
    },
    {
      Title: {
        gb: "Heating/Air Con",
        it: "Riscaldamento aria condizionata ",
      },
      SubTitles: [
        {
          Title: {
            gb: ["Number of Pipes Heating/", "Air Con System"],
            it: ["Tipologia di impianto ", ""],
          },
          ValuesComm: [],
          ValuesTech: [18, 2],
          type: "checkbox",
        },
        {
          Title: {
            gb: "",
            it: "",
          },
          ValuesComm: [],
          ValuesTech: [],
          type: "checkbox",
        },
        {
          Title: {
            gb: "Control System",
            it: "Impianto di regolazione",
          },
          ValuesComm: [],
          ValuesTech: [4],
          type: "checkbox",
        },
      ],
    },
  ];
  // return filters mapped in sections
  return mapping.filter((el) => {
    if (el.type && el.allValues) {
      el.allValues = el.allValues.filter((item) => {
        const value = item.value;
        const type = item.type;
        if (type === "comm") {
          return commFeatures.some((e) => e === value);
        } else {
          return renoStatus.some((e) => e === value);
        }
      });
      return el;
    } else if (el.type && el.type === "checkbox-any-of") {
      el.Values = el.Values.filter((val) => {
        return (
          val.ValuesComm.some((comm) => commFeatures.includes(comm)) ||
          val.ValuesTech.some((tech) => techFeatures.includes(tech))
        );
      });
      return el;
    } else if (el.type) {
      el.ValuesComm = el.ValuesComm.filter((id) =>
        commFeatures.some((e) => Number(e) === id)
      );
      el.ValuesTech = el.ValuesTech.filter((id) =>
        techFeatures.some((e) => Number(e) === id)
      );
      return el;
    }
    el.SubTitles = el.SubTitles.filter((sub) => {
      sub.ValuesComm = sub.ValuesComm.filter((id) =>
        commFeatures.some((e) => Number(e) === id)
      );
      sub.ValuesTech = sub.ValuesTech.filter((id) =>
        techFeatures.some((e) => Number(e) === id)
      );
      return sub;
    });
    return el;
  });
};

export const advancedFiltersMapping = (filtersArray, item) => {
  filters.attributes.map((filter) => {
    if (item[filter.variable_name]) {
      if (item[filter.variable_name] !== -1)
        filtersArray.push(filter.variable_name);
    }
  });
  filtersArray = [...new Set(filtersArray)];
  return filtersArray;
};

export const sortMacroareas = (macroareas) => {
  return macroareas
    .filter((a) => ![9, 8, 7, 6].includes(a.id))
    .sort((a, b) => {
      if (a.city !== b.city) {
        return a.city > b.city ? 1 : -1;
      }
      return a.order - b.order;
    });
};

export function renovationStatusString(renoId, dictionary) {
  switch (renoId) {
    case 1:
      return dictionary.CatA;
    case 2:
      return dictionary.CatB;
    case 3:
      return dictionary.ShellAndCore;
    case 4:
      return dictionary.PlugAndPlay;
    case 5:
      return dictionary.ToBeRenovated;
    default:
      return dictionary.ShellAndCore;
  }
}

export function latLng2Point(latLng, map) {
  var topRight = map
    .getProjection()
    ?.fromLatLngToPoint(map.getBounds()?.getNorthEast());
  var bottomLeft = map
    .getProjection()
    ?.fromLatLngToPoint(map.getBounds()?.getSouthWest());
  var scale = Math.pow(2, map.getZoom());
  var worldPoint = map.getProjection()?.fromLatLngToPoint(latLng);

  // eslint-disable-next-line no-undef
  return new google.maps.Point(
    (worldPoint.x - bottomLeft.x) * scale,
    (worldPoint.y - topRight.y) * scale
  );
}

export function mapBounds2Point(map) {
  var topRight = latLng2Point(map.getBounds()?.getNorthEast(), map);
  var bottomLeft = latLng2Point(map.getBounds()?.getSouthWest(), map);
  return { topRight, bottomLeft };
}

export function point2LatLng(point, map) {
  var topRight = map
    .getProjection()
    .fromLatLngToPoint(map.getBounds().getNorthEast());
  var bottomLeft = map
    .getProjection()
    .fromLatLngToPoint(map.getBounds().getSouthWest());
  var scale = Math.pow(2, map.getZoom());
  // eslint-disable-next-line no-undef
  var worldPoint = new google.maps.Point(
    point.x / scale + bottomLeft.x,
    point.y / scale + topRight.y
  );
  return map.getProjection().fromPointToLatLng(worldPoint);
}

export function getServiceTierDescription(dictionary, tier_id) {
  switch (tier_id) {
    case 1:
      return dictionary.PortfolioServiceTiersBronze;
    case 2:
      return dictionary.PortfolioServiceTiersSilver;
    case 3:
      return dictionary.PortfolioServiceTiersGold;
    default:
      return dictionary.PortfolioServiceTiersBronze;
  }
}

export const getServiceTierColors = (id) => {
  if (id === 3) {
    return "radial-gradient(62.89% 50% at 50% 50%, #FEFDD8 0%, #E9E2A4 44.79%, #DBCD89 71.88%, #C2A55B 100%)";
  } else if (id === 2) {
    return "radial-gradient(62.89% 50% at 50% 50%, #FFFFFF 0%, #EDEEF0 42.19%, #D6D7DD 63.02%, #A3A3AC 100%)";
  } else if (id === 1) {
    return "radial-gradient(50% 50% at 50% 50%, #FFE1AF 0%, #F4B576 43.75%, #E98F51 76.04%, #C77854 100%)";
  } else {
    return "radial-gradient(50% 50% at 50% 50%, #FFE1AF 0%, #F4B576 43.75%, #E98F51 76.04%, #C77854 100%)";
  }
};

export const getStatusColor = (status) => {
  if (status === 1) {
    return "#E6EFE6";
  } else if (status === 3) {
    return "#FAE6E6";
  } else if (status === 0) {
    return "#FDEDE1";
  } else if (status === 4) {
    return "#F3E5F6";
  } else if (status === 2) {
    return "#E1F1F9";
  }
};

export const getStatusLabelColor = (status) => {
  if (status === 1) {
    return "#2E7D32";
  } else if (status === 3) {
    return "#D32F2F";
  } else if (status === 0) {
    return "#ED6C02";
  } else if (status === 4) {
    return "#9C27B0";
  } else if (status === 2) {
    return "#0288D1";
  }
};

export const getMailToLink = (recipient, subject, body, link) => {
  /*   console.log("subject %o body %o link %o", subject, body, link);*/
  return `mailto:${recipient}?subject=${subject}&body=${body} ${link}`;
};

export const getTeaserStatusTranslation = (statusId, dictionary) => {
  switch (statusId) {
    case 0:
      return dictionary.PortfolioStatusDraft;
    case 1:
      return dictionary.PortfolioStatusPublished;
    case 2:
      return dictionary.PortfolioStatusStandby;
    case 3:
      return dictionary.PortfolioStatusDeactivated;
    case 4:
      return dictionary.PortfolioStatusOffmarket;

    default:
      break;
  }
};

export const getServiceLabel = (serviceId, dictionary) => {
  switch (serviceId) {
    case 0:
      return dictionary.PropertyInspection;
    case 1:
      return dictionary.ServicesFotoSectionText1;
    case 2:
      return dictionary.SectionVirtualText1;
    case 3:
      return dictionary.SectionBrochureName;
    case 4:
      return dictionary.VirtualStaging;
    case 5:
      return dictionary.ReportingService;
    case 6:
      return dictionary.ServiceLongList;
    case 7:
      return dictionary.SectionVirtualDataText1;

    default:
      break;
  }
};
export const getTeaserStatusColor = (statusId) => {
  switch (statusId) {
    case 0:
      return "#FDEDE1";
    case 1:
      return "#E6EFE6";
    default:
      break;
  }
};

export const getTeaserStatusLabelColor = (statusId) => {
  switch (statusId) {
    case 0:
      return "#ED6C02";
    case 1:
      return "#2E7D32";
    default:
      break;
  }
};

export const getInspectionStatusList = (dictionary) => {
  return [
    { id: 0, label: dictionary.PropertyInspectionRequestStatusInProgress },
    { id: 1, label: dictionary.PropertyInspectionRequestStatusReady },
    {
      id: 2,
      label: dictionary.PropertyInspectionRequestStatusPendingSignature,
    },
    { id: 3, label: dictionary.PropertyInspectionRequestStatusPending },
  ];
};

export const getCommentsBoxMaxLength = (numOfMaxStation) => {
  if (numOfMaxStation === 4) return 9999;
  return 500 - numOfMaxStation * 45 - (numOfMaxStation > 0 ? 100 : 0);
};

export const getPropertyInspectionRequestStatusTranslation = (
  statusId,
  dictionary
) => {
  switch (statusId) {
    case 0:
      return dictionary.PropertyInspectionRequestStatusInProgress;
    case 1:
      return dictionary.PropertyInspectionRequestStatusReady;
    case 2:
      return dictionary.PropertyInspectionRequestStatusPendingSignature;
    case 3:
      return dictionary.PropertyInspectionRequestStatusPending;
    default:
      break;
  }
};

export const getPropertyInspectionRequestStatusColor = (statusId) => {
  switch (statusId) {
    case 0:
      return "#FDEDE1";
    case 1:
      return "#E6EFE6";
    case 2:
      return "#E1F1F9";
    case 3:
      return "#EBEBEB";
    default:
      break;
  }
};

export const getPropertyInspectionRequestStatusLabelColor = (statusId) => {
  switch (statusId) {
    case 0:
      return "#ED6C02";
    case 1:
      return "#2E7D32";
    case 2:
      return "#0288D1";
    case 3:
      return "#000000";
    default:
      break;
  }
};

export const getBuildingStatusColor = (status) => {
  if (status === 1) {
    return "#E6EFE6";
  } else if (status === 3) {
    return "#FAE6E6";
  } else if (status === 0) {
    return "#FDEDE1";
  } else if (status === 4 || status === 5) {
    return "#F3E5F6";
  } else if (status === 2) {
    return "#E1F1F9";
  }
};

export const getBuildingStatusLabelColor = (status) => {
  if (status === 1) {
    return "#2E7D32";
  } else if (status === 3) {
    return "#D32F2F";
  } else if (status === 0) {
    return "#ED6C02";
  } else if (status === 4 || status === 5) {
    return "#9C27B0";
  } else if (status === 2) {
    return "#0288D1";
  }
};

export const getBuildingStatusTranslation = (statusId, dictionary) => {
  switch (statusId) {
    case 0:
      return dictionary.offline;
    case 1:
      return dictionary.online;
    case 2:
      return dictionary.AvailableFrom;
    case 3:
      return dictionary.NotAvailable;
    case 4:
      return dictionary.PortfolioStatusStandby;
    case 5:
      return dictionary.PortfolioStatusOffmarket;
    default:
      return dictionary.NotVisible;
  }
};

export const getBuildingStatusTranslationFilters = (statusId, dictionary) => {
  switch (statusId) {
    // case 0:
    //   return dictionary.NotVisible;
    case 1:
      return dictionary.Available;
    case 2:
      return dictionary.AvailableFrom;
    case 3:
      return dictionary.NotAvailable;
    case 4:
      return dictionary.PortfolioStatusStandby;
    case 5:
      return dictionary.PortfolioStatusOffmarket;
    // default:
    //   return dictionary.NotVisible;
  }
};

export const getAvailabilityRequestStatusTranslation = (
  statusId,
  dictionary
) => {
  switch (statusId) {
    case 1:
      return dictionary.AvailabilityRequestStatusWip;
    case 2:
      return dictionary.AvailabilityRequestStatusCold;
    case 3:
      return dictionary.AvailabilityRequestStatusHot;
    default:
      break;
  }
};

export const getLeadInterestTranslation = (id, dictionary) => {
  switch (id) {
    case 0:
      return dictionary.InterestLow;
    case 1:
      return dictionary.InterestNormal;
    case 2:
      return dictionary.InterestHigh;

    default:
      break;
  }
};

export const getSourceTranslation = (id, dictionary) => {
  switch (id) {
    case 0:
      return dictionary.SourceDc;
    case 1:
      return dictionary.SourceD4re;
    case 2:
      return dictionary.SourceCorporate;
    case 3:
      return dictionary.SourceOther;
    default:
      break;
  }
};

export const getAvailabilityRequestStatusColor = (statusId) => {
  switch (statusId) {
    case 1:
      return "#FDEDE1";
    case 2:
      return "#E1F1F9";
    case 3:
      return "#FAE6E6";
    default:
      break;
  }
};

export const getAvailabilityRequestStatusLabelColor = (statusId) => {
  switch (statusId) {
    case 1:
      return "#ED6C02";
    case 2:
      return "#0288D1";
    case 3:
      return "#D32F2F";

    default:
      break;
  }
};

export const getGradeTranslation = (gradeId, dictionary) => {
  switch (gradeId) {
    case 0:
      return "A";
    case 1:
      return "B";
    case 2:
      return "C";
    default:
      break;
  }
};

export const buildingGrades = [
  { id: 1, label: "A" },
  { id: 2, label: "B" },
  { id: 3, label: "C" },
];

export const buildingStatuses = [
  { id: 0, description: "Draft" },
  { id: 1, description: "Published" },
];

export const getTenantStatus = (request, dictionary) => {
  switch (request) {
    case 1:
      return dictionary.AvailabilityRequestStatusPending;
    case 2:
      return dictionary.AvailabilityRequestStatusAccepted;
    case 3:
      return dictionary.AvailabilityRequestStatusRejected;
    default:
      return dictionary.AvailabilityRequestStatusPending;
  }
};
export const getTenantStatusLabelColor = (request, dictionary) => {
  switch (request) {
    case 1:
      return "#EBEBEB";
    case 2:
      return "#E6EFE6";
    case 3:
      return "#FAE6E6";
    default:
      return "#EBEBEB";
  }
};

export const getInspectionTypeFromID = (inspectionTypeId, dictionary) => {
  switch (inspectionTypeId) {
    case 1:
      return dictionary.InspectionType1;
    case 2:
      return dictionary.InspectionType2;
    case 3:
      return dictionary.InspectionType3;
    case 4:
      return dictionary.InspectionType4;
    case 5:
      return dictionary.InspectionType5;
    default:
      return dictionary.InspectionType1;
  }
};

export const getTenantStatusLabelText = (request, dictionary) => {
  switch (request) {
    case 1:
      return "#000000DE";

    case 2:
      return "#2E7D32";
    case 3:
      return "#D32F2F";
    default:
      return "#000000DE";
  }
};

// fake a fuzzy search for city names with known city name variations
export const fuzzyCityRegex = (cityName) => {
  // console.log(cityName);
  if (cityName.toLowerCase() === "roma") {
    return "roma|rome";
  } else if (cityName.toLowerCase() === "milano") {
    return "milano|milan";
  } else if (cityName.toLowerCase() === "torino") {
    return "torino|turin";
  } else if (cityName.toLowerCase() === "bologna") {
    return "bologna";
  } else if (cityName.toLowerCase() === "bergamo") {
    return "bergamo";
  } else if (cityName.toLowerCase() === "italia") {
    return "italia";
  }
};

// re-usable function to fetch city info from building or teaser object
/**
 * @deprecated
 * @param entity
 * @param cities
 * @returns {string|*|string}
 */
export const getCity = (entity, cities) => {
  const city =
    returnAddressInformation(entity.addressdetails) ||
    cities.find(
      (e) =>
        entity.address?.match(
          new RegExp(fuzzyCityRegex(e.split(",")[0]), "ig")
        ) ||
        entity.addressdetails?.match(
          new RegExp(fuzzyCityRegex(e.split(",")[0]), "ig")
        )
    ) ||
    "";
  if (city && city.split(",").length < 2) {
    return city + ",Italy";
  }
  return city;
};

// re-usable function to fetch city info from building or teaser object
/**
 * @deprecated
 * @param entity
 * @param cities
 * @returns {string|*|string}
 */
export const getCityBreadCrumb = (entity, cities) => {
  const city =
    returnAddressInformationBreadcrumb(entity.addressdetails) ||
    cities.find(
      (e) =>
        entity.address?.match(
          new RegExp(fuzzyCityRegex(e.split(",")[0]), "ig")
        ) ||
        entity.addressdetails?.match(
          new RegExp(fuzzyCityRegex(e.split(",")[0]), "ig")
        )
    ) ||
    "";
  if (city && city.split(",").length < 2) {
    return city + ",Italy";
  }
  return city;
};

// re-usable function to generate the text of the building's units asset_class
export const getAssetClassesText = (asset_classes, dictionary) => {
  const arr = asset_classes.split(",");

  if (arr.includes("1") && arr.includes("2") && arr.includes("3")) {
    return `${dictionary.Office}, ${dictionary.Retail}, ${dictionary.Industrial}`;
  } else if (arr.includes("1") && arr.includes("2")) {
    return `${dictionary.Office}, ${dictionary.Retail}`;
  } else if (arr.includes("1") && arr.includes("3")) {
    return `${dictionary.Office}, ${dictionary.Industrial}`;
  } else if (arr.includes("2") && arr.includes("3")) {
    return `${dictionary.Retail}, ${dictionary.Industrial}`;
  } else if (arr.includes("1")) {
    return `${dictionary.Office}`;
  } else if (arr.includes("2")) {
    return `${dictionary.Retail}`;
  } else if (arr.includes("3")) {
    return `${dictionary.Industrial}`;
  } else return "";
};

//re-usable function to generate the text of the building's units deal_type
export const getDealTypeText = (deal_types, dictionary) => {
  const arr = deal_types.split(",");
  if (arr.includes("1") && arr.includes("2")) {
    return `${dictionary.sale}, ${dictionary.lease}`;
  } else if (arr.includes("2")) {
    return `${dictionary.sale}`;
  } else if (arr.includes("1")) {
    return `${dictionary.lease}`;
  }
};

export const getFloorText = (floor, dictionary, lang) => {
  if (!floor) return "-";
  const floorsArr = floor?.split(",");
  const floorNum = Number(floorsArr[0]);

  function getOrdinal(n) {
    let ord = dictionary.th;
    if (n % 10 === 1 && n % 100 !== 11) {
      ord = dictionary.st;
    } else if (n % 10 === 2 && n % 100 !== 12) {
      ord = dictionary.nd;
    } else if (n % 10 === 3 && n % 100 !== 13) {
      ord = dictionary.rd;
    }
    return ord;
  }
  if (floorNum === 0) {
    return dictionary.Groundfloor;
  } else if (floorNum < 0) {
    return dictionary.floorUnit + " " + floorNum;
  } else {
    return floorNum + getOrdinal(floorNum) + " " + dictionary.floorUnit;
  }
};

export const getFeaturesArray = (building, dictionary) => {
  let featuresArray = [];
  if (building.heritage_building === 1) {
    featuresArray.push(dictionary.heritageBuilding);
  }
  if (building.smart_building === 1) {
    featuresArray.push(dictionary.smartBuilding);
  }
  // if (building.sustainability !== 0) {
  //   switch (building.sustainability) {
  //     case 1:
  //       featuresArray.push(dictionary.LEED_Platinum);
  //       break;
  //     case 2:
  //       featuresArray.push(dictionary.LEED_Silver);
  //       break;
  //     case 3:
  //       featuresArray.push(dictionary.LEED_Gold);
  //       break;
  //   }
  // }
  // if (building.sustainability_breeam !== 0) {
  //   switch (building.sustainability) {
  //     case 1:
  //       featuresArray.push(dictionary.BREEAM_Outstanding);
  //       break;
  //     case 2:
  //       featuresArray.push(dictionary.BREEAM_Excellent);
  //       break;
  //     case 3:
  //       featuresArray.push(dictionary.BREEAM_Very_Good);
  //       break;
  //     case 4:
  //       featuresArray.push(dictionary.BREEAM_Good);
  //       break;
  //     case 5:
  //       featuresArray.push("BREEAM Pass");
  //       break;
  //     case 6:
  //       featuresArray.push(dictionary.BREEAM_Unclassified);
  //   }
  // }
  // if (building.workplace_wellness !== 0) {
  //   switch (building.sustainability) {
  //     case 1:
  //       featuresArray.push(dictionary.WELL_Platinum);
  //       break;
  //     case 2:
  //       featuresArray.push(dictionary.WELL_Gold);
  //       break;
  //     case 3:
  //       featuresArray.push(dictionary.WELL_Silver);
  //       break;
  //   }
  // }
  // if (building.energy_performance_certificate) {
  //   featuresArray.push(
  //     dictionary.energyPerformanceCertificate +
  //       " " +
  //       building.energy_performance_certificate
  //   );
  // }
  // if (building.construction_status !== 0) {
  //   switch (building.sustainability) {
  //     case 1:
  //       featuresArray.push(dictionary.BREEAM_Outstanding);
  //       break;
  //     case 2:
  //       featuresArray.push(dictionary.BREEAM_Excellent);
  //       break;
  //     case 3:
  //       featuresArray.push(dictionary.BREEAM_Very_Good);
  //       break;
  //     case 4:
  //       featuresArray.push(dictionary.BREEAM_Good);
  //       break;
  //     case 5:
  //       featuresArray.push("BREEAM Pass");
  //       break;
  //     case 6:
  //       featuresArray.push(dictionary.BREEAM_Unclassified);
  //   }
  // }
  if (building.restrictions_superintendence === 1) {
    featuresArray.push(dictionary.restrictionsSuperintendence);
  }
  // if (building.renovationgrade !== 0) {
  //   if (building.renovationgrade === 1) {
  //     featuresArray.push(dictionary.Grade + ": A");
  //   }
  //   if (building.renovationgrade === 2) {
  //     featuresArray.push(dictionary.Grade + ": B");
  //   }
  //   if (building.renovationgrade === 3) {
  //     featuresArray.push(dictionary.Grade + ": C");
  //   }
  //   if (building.renovationgrade === 4) {
  //     featuresArray.push(dictionary.Grade + ": A Green");
  //   }
  // }
  if (building.entrance_reception === 1) {
    featuresArray.push("Reception");
  } else if (building.entrance_reception === 2) {
    featuresArray.push("24h reception");
  }
  if (building.shared_meeting_rooms === 1) {
    featuresArray.push(dictionary.sharedMeetingRooms);
  }
  if (building.shared_leisure_area === 1) {
    featuresArray.push(dictionary.sharedLeisureArea);
  }
  if (building.auditorium === 1) {
    featuresArray.push(dictionary.Auditorium);
  }
  if (building.canteen === 1) {
    featuresArray.push(dictionary.canteen);
  }
  if (building.bar === 1) {
    featuresArray.push("Bar");
  }
  if (building.loading_bay === 1) {
    featuresArray.push(dictionary.loadingBay);
  }
  // if (building.archive_warehouse_area !== 0) {
  //   featuresArray.push(dictionary.warehouseArea);
  // }
  // if (building.changing_rooms_lockers !== 0) {
  //   featuresArray.push(dictionary.lockers);
  // }
  // if (building.shared_rooftop_area !== 0) {
  //   featuresArray.push(dictionary.sharedRooftopArea);
  // }
  if (building.seperate_goods_lift === 1) {
    featuresArray.push(dictionary.separateGoodsLift);
  }
  if (building._24_hour_security_guards === 1) {
    featuresArray.push(dictionary.securityGuards);
  }
  if (building.access_turnstiles === 1) {
    featuresArray.push(dictionary.accessTurnstiles);
  }
  // if (building.garage !== 0) {
  //   featuresArray.push("Garage");
  // }
  // if (building.outdoor_car_parking !== 0) {
  //   featuresArray.push(dictionary.outdoorParking);
  // }
  // if (building.visitors_parking !== 0) {
  //   featuresArray.push(dictionary.visitorParking);
  // }
  // if (building.charging_stations !== 0) {
  //   featuresArray.push(dictionary.chargingStations);
  // }
  if (building.motorcycle_parking === 1) {
    featuresArray.push(dictionary.motorcycleParking);
  }
  if (building.bicycle_parking === 1) {
    featuresArray.push(dictionary.bicycleParking);
  }
  if (building.sprinkler_watermist === 1) {
    featuresArray.push("Sprinkler-Watermist");
  }
  if (building.smoke_detectors_system === 1) {
    featuresArray.push(dictionary.smokeDetectors);
  }
  if (building.emergency_light_system === 1) {
    featuresArray.push(dictionary.lightSystem);
  }
  if (building.high_efficency_electrical_system === 1) {
    featuresArray.push(dictionary.highEff);
  }
  if (building.ups === 1) {
    featuresArray.push("UPS");
  }
  if (building.generator === 1) {
    featuresArray.push(dictionary.generator);
  }
  if (building.data_room === 1) {
    featuresArray.push(dictionary.DataRoom);
  }
  if (building.electrical_substation === 1) {
    featuresArray.push(dictionary.substation);
  }
  if (building._2_pipes_air_con_heating_system === 1) {
    featuresArray.push(dictionary["2pipes"]);
  }
  if (building._4_pipes_air_con_heating_system === 1) {
    featuresArray.push(dictionary["4pipes"]);
  }
  if (building.split_air_con_heating_system === 1) {
    featuresArray.push(dictionary.split);
  }
  if (building.indipendent_air_con_heating_system === 1) {
    featuresArray.push(dictionary.independent);
  }
  if (building.bms === 1) {
    featuresArray.push("BMS");
  }

  return featuresArray;
};

export const Shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
/**
 * @deprecated
 * @see NumericUtils
 * @param num
 * @param userLanguage
 * @returns {*}
 */
export const formatNumberIntl = (num, userLanguage) => {
  let cleanNum = 0;
  if (num.length > 0) {
    cleanNum = parseFloat(num.replace(/\./g, ""));
  }
  const newNum = Intl.NumberFormat(isoCountryMap[userLanguage], {
    style: "decimal",
  }).format(num);
  console.log(
    "Number [%o] %o %o %o",
    isoCountryMap[userLanguage],
    num,
    newNum,
    cleanNum
  );

  return newNum;
};
export const NumericUtils = {
  fromNumberToFormatted: (number, language) => {
    const newNum = Intl.NumberFormat(isoCountryMap[language], {
      style: "decimal",
    }).format(number);
    // console.log("Number [%o] %o %o -> %o",language,isoCountryMap[language], number,newNum);
    return newNum;
  },
  fromFormattedToNumber: (formatted, language) => {
    const numberFormat = new Intl.NumberFormat(isoCountryMap[language]);
    const separators = numberFormat.format(1000.1).replace(/\d/g, "");
    const separatorDecimal = new RegExp(`/${separators[1]}/g`);
    const separatorThousand = new RegExp(`/${separators[0]}/g`);
    const newNum = parseFloat(
      formatted.replace(separatorThousand, "").replace(separatorDecimal, ".")
    );
    // console.log("Formatted [%o] %o %o -> %o",isoCountryMap[language], separators,formatted,newNum);
    return newNum;
  },
};


export const getPriceLabel = (unit, dictionary, userLanguage) => {

  const condition = unit?.price_type === 1 || unit?.price_type === 2
  if (condition) {
    return `${dictionary.onRequest}`;
  }

  let label = `${dictionary.onRequest}`
  if (unit.deal_type === DEAL_TYPE_LEASE_INT) {
    if (unit.total_asking_rent > 0){
      const number = NumericUtils.fromNumberToFormatted(Math.floor(unit.total_asking_rent / unit.size), userLanguage)
      label = number + `€/${dictionary.sqm}/${dictionary.year}`
    } else if(unit.asking_rent > 0) {
      const number = NumericUtils.fromNumberToFormatted(unit.asking_rent, userLanguage)
      label = number + `€/${dictionary.sqm}/${dictionary.year}`
    }
  } else {
    if (unit.total_asking_rent > 0){
      const number = NumericUtils.fromNumberToFormatted(unit.total_asking_rent, userLanguage)
      label = number + "€"
    } else if(unit.asking_rent > 0) {
      const number = NumericUtils.fromNumberToFormatted((unit.asking_rent * unit.size), userLanguage)
      label =  number + "€"
    }
  }

  return label

}
export const selftCasting = (target) => {
  const regexFloat = /((\+|-|)\d+(.\d+|.|))/;

  if (target.type === "number" || target.name === "number") {
    return parseInt(target.value);
  } else if (target.name === "float") {
    const match = target.value.match(regexFloat);
    // console.log("FLOAT %o %o",target.value,match)
    return match[0];
  }
  return target.value;
};

export const toggleValue = (value) => {
  return value === 1 ? 0 : 1;
};

export const unitUtils = {
  SQM: "sqm",
  TOTAL: "total",
  /**
   * apply to the answer of the API
   * @param unit the unit data returned by API
   * @returns {{price_unit: null, price: null}}
   */
  getPriceInfo: (unit) => {
    const rv = {
      price: null,
      price_unit: null,
    };
    if (
      true &&
      (!unit.asking_rent || unit.asking_rent == "0") &&
      (!unit.total_asking_rent || unit.total_asking_rent == "0")
    ) {
      rv.price = null;
      rv.price_unit = unitUtils.SQM;
      return rv;
    } else if (unit.deal_type == DEAL_TYPE_LEASE_INT) {
      if (unit.asking_rent > 0 && unit.size > 0) {
        rv.price = unit.asking_rent;
        rv.price_unit = unitUtils.SQM;
        return rv;
      } else if (unit.total_asking_rent > 0) {
        rv.price = unit.total_asking_rent;
        rv.price_unit = unitUtils.TOTAL;
        return rv;
      } else {
        rv.price = null;
        rv.price_unit = unitUtils.SQM;
        return rv;
      }
    } else if (unit.deal_type == DEAL_TYPE_SALE_INT) {
      if (unit.total_asking_rent > 0) {
        rv.price = unit.total_asking_rent;
        rv.price_unit = unitUtils.TOTAL;
        return rv;
      } else if (unit.asking_rent > 0 && unit.size > 0) {
        rv.price = unit.asking_rent;
        rv.price_unit = unitUtils.SQM;
        return rv;
      } else {
        rv.price = null;
        rv.price_unit = unitUtils.SQM;
        return rv;
      }
    } else {
      rv.price = null;
      rv.price_unit = unitUtils.SQM;
      return rv;
    }
  },
  /**
   * Apply before call the POST/PUT/PATCH API
   * @param unit the object that will be sent by api
   * @param price value from the form (Lease Price, Sale Price)
   * @param price_unit  value from the form (Price Range)
   */
  setPriceInfo: (unit, price, price_unit) => {
    if (price_unit == unitUtils.TOTAL) {
      unit.total_asking_rent = price;
    }

    if (price_unit == unitUtils.SQM) {
      unit.asking_rent = price;
    }
  },
};

// Custom hook to generate floor options with specific values
export const customFloorOptions = (
  underground_floors,
  floors,
  userLanguage
) => {
  const options = [];
  // console.log(
  //   "customFloorOptions\nunderground_floors : %o\nfloors : \n%o",
  //   underground_floors,
  //   floors
  // );

  // Push other floor options
  for (
    let i = underground_floors > 0 ? -underground_floors : 0;
    i <= (floors > 0 ? floors : 0);
    i++
  ) {
    if (i === 0) {
      // Push special options
      options.push({
        label: unitFloorLabel(FLOOR_GROUNDFLOOR),
        value: FLOOR_GROUNDFLOOR,
      });
      continue;
    }
    if (i === 1) {
      // Push special options
      options.push({
        label: unitFloorLabel(FLOOR_MEZZANINE),
        value: FLOOR_MEZZANINE,
      });
    }
    options.push({ label: unitFloorLabel(i), value: i });
  }
  // Push special options
  options.push({
    label: unitFloorLabel(FLOOR_ENTIRE_BUILDING),
    value: FLOOR_ENTIRE_BUILDING,
  });

  const optionsTranslated = options.map((option) => {
    const newOpt = { value: option.value };
    newOpt.label = routeReplace(dictionaryList[userLanguage][option.label], {
      floor: option.value,
    });
    return newOpt;
  });

  return optionsTranslated;
};

//inititate the yes with value with empty string

/**
 * @deprecated
 * @see NoYesWithValueUtils.initiateYesWithValue
 * @param selectValue
 * @returns {string|*}
 */
export const initiateYesWithValue = (selectValue) => {
  return selectValue === "1" ? "" : selectValue;
};
/**
 *
 * @type {{fromApi: ((function(*): (string))|*), initiateYesWithValue: (function(*): string|*)}}
 */
export const NoYesWithValueUtils = {
  initiateYesWithValue: (selectValue) => {
    return selectValue === "1" ? "" : selectValue;
  },
  fromValueToDrop: (value) => {
    let rv;
    if (typeof value == "undefined" || value === null) {
      rv = "null";
    } else if (Number(value) > 0 || !isNumeric(value)) {
      rv = "1";
    } else {
      rv = "0";
    }
    // console.log("fromValueToDrop %o -> %o",value,rv);
    return rv;
  },
  fromDropToInput: (value, callback) => {
    let rv = "init";
    if (value === "null" || value === null || value === "0") {
      rv = JSON.parse(value);
      callback(rv);
    }
    // console.log("fromDropToInput %o -> %o",value,rv);
    return rv;
  },
};

export const TranslationUtils = {
  getCustomMessages: (userLanguage) => ({
    required: dictionaryList[userLanguage].validation_required,
    integer: dictionaryList[userLanguage].validation_integer,
    min: {
      numeric: dictionaryList[userLanguage].validation_min_numeric,
      file: dictionaryList[userLanguage].validation_min_file,
      string: dictionaryList[userLanguage].validation_min_string,
      array: dictionaryList[userLanguage].validation_min_array,
    },
    max: {
      numeric: dictionaryList[userLanguage].validation_max_numeric,
      file: dictionaryList[userLanguage].validation_max_file,
      string: dictionaryList[userLanguage].validation_max_string,
      array: dictionaryList[userLanguage].validation_max_array,
    },
  }),
};

export const processApiErrors = (apiResponseErrors, errors, setErrors) => {
  let newAlertMessages = { ...errors };
  for (const field in apiResponseErrors) {
    const messages = apiResponseErrors[field];
    messages.forEach((message) => {
      console.log("field : ", field);
      console.log("message : ", message);
      // Check if the field is "asking_rent" or "total_asking_rent" and change it to "price"
      const normalizedField =
        field === "asking_rent" || field === "total_asking_rent"
          ? "price"
          : field;

      // Check if the field already exists in ALERT_BUILDING_MESSAGES

      // If the field exists, update the message and target
      const newFieldError = {
        messages: [message],
        target: `${normalizedField}-section`,
      };
      newAlertMessages[field] = newFieldError;
    });

    console.log("newAlertMessages : ", newAlertMessages);
    setErrors((prev) => ({ ...prev, ...newAlertMessages }));
  }
};

export const removeHTMLTags = (input) => {
  return input.replace(/<\/?[^>]+(>|$)/g, "");
};

export const displayUnitResultPriceText = (
  deal_type,
  price_unit,
  priceResult,
  dictionary
) => {
  let textHelper = "";
  if (deal_type === DEAL_TYPE_LEASE_INT) {
    if (priceResult === null) return;

    textHelper = `${
      price_unit === "sqm"
        ? dictionary.SqmYearPriceWillBeDisplayed
        : dictionary.AnnualRentPriceWillBeDisplayed
    } ( ${priceResult} )`;
  }
  if (deal_type === DEAL_TYPE_SALE_INT) {
    if (priceResult === null) return;

    textHelper = `${
      price_unit === "sqm"
        ? dictionary.PricePerSqmWillBeDisplayed
        : dictionary.PricePerUnitWillBeDisplayed
    } ( ${priceResult} )`;
  }

  return textHelper;
};
