import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { makeStyles } from "@material-ui/core/styles";
import Auth from "../auth/auth";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LanguageContext } from "../containers/languageprovider";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalpaper: {
    position: "absolute",
    width: 570,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: "46px",
  },
  GridInfoOptions: {
    paddingBottom: "20px",
  },
}));

export default function LoginForm(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const history = useHistory();

  const { dictionary } = React.useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const [msgmodal, setMsgModal] = useState("");
  const [loading, setLoading] = React.useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOpen = (msg) => {
    setMsgModal(msg);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loginUser = (e) => {
    e.preventDefault();
    setLoading(true);
    Auth.signin(email, values.password)
      .then((payload) => {
        const user = Auth.getUser();
        if (user.use_temp_password) {
          history.push("/changepassword");
        }

        setLoading(false);
        props.infoClose();
        if (payload.user.role === 2) {
          history.push("/portfolio");
        }
      })
      .catch((error) => {
        setLoading(false);
        handleOpen(dictionary[error]);
      });
  };

  return (
    <Fragment>
      <form className={classes.form} noValidate onSubmit={loginUser}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label={dictionary.Emailaddress}
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <FormControl className={classes.form} variant="outlined">
          <InputLabel htmlFor="password">{dictionary.Password}</InputLabel>
          <OutlinedInput
            name="password"
            id="password"
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
          className={classes.submit}
        >
          {dictionary.SignIn}
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        <Grid className={classes.GridInfoOptions} container>
          <Grid item xs={6}>
            <Link onClick={() => {props.handleLoginAction("password")}} variant="body2">
              {dictionary.forgotttenPassword}
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Link
              style={{ float: "right" }}
              variant="body2"
              onClick={() => {props.handleLoginAction("signup")}}
            >
              {dictionary.NeedAccount}
            </Link>
          </Grid>
        </Grid>
      </form>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {msgmodal}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
