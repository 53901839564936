import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import React from "react";

import {
  updateUnitCommercialFeatures,
  updateUnitTechnicalFeatures,
} from "../../../../reducers/UnitSlice";

const UnitFeatures = ({
  classes,
  dictionary,
  unit,
  dispatch,
  unitErrors,
  setUnitErrors,
}) => {
  const changeUnitRaisedFloorHandler = (event) => {
    const { checked } = event.target;
    let updatedTechnicalFeatures = [...unit.technical_features];

    // Toggle the presence of 1 in the array based on the switch status
    if (checked) {
      updatedTechnicalFeatures.push(1);
    } else {
      updatedTechnicalFeatures = updatedTechnicalFeatures.filter(
        (feature) => feature !== 1
      );
    }
    dispatch(updateUnitTechnicalFeatures(updatedTechnicalFeatures));
  };

  const changeUnitAcousticCeilingHandler = (event) => {
    const { checked } = event.target;
    let updatedTechnicalFeatures = [...unit.technical_features];

    // Toggle the presence of 17 in the array based on the switch status
    if (checked) {
      updatedTechnicalFeatures.push(17);
    } else {
      updatedTechnicalFeatures = updatedTechnicalFeatures.filter(
        (feature) => feature !== 17
      );
    }
    dispatch(updateUnitTechnicalFeatures(updatedTechnicalFeatures));
  };

  const changeUnitRemovableTilesFalseCeilingHandler = (event) => {
    const { checked } = event.target;
    let updatedTechnicalFeatures = [...unit.technical_features];

    // Toggle the presence of 16 in the array based on the switch status
    if (checked) {
      updatedTechnicalFeatures.push(16);
    } else {
      updatedTechnicalFeatures = updatedTechnicalFeatures.filter(
        (feature) => feature !== 16
      );
    }
    dispatch(updateUnitTechnicalFeatures(updatedTechnicalFeatures));
  };

  const changeUnitInnerGardenHandler = (event) => {
    const { checked } = event.target;
    let updatedCommercialFeatures = [...unit.commercial_features];

    // Toggle the presence of 35 in the array based on the switch status
    if (checked) {
      updatedCommercialFeatures.push(35);
    } else {
      updatedCommercialFeatures = updatedCommercialFeatures.filter(
        (feature) => feature !== 35
      );
    }
    dispatch(updateUnitCommercialFeatures(updatedCommercialFeatures));
  };

  const changeUnitPlugAndPlayHandler = (event) => {
    const { checked } = event.target;
    let updatedCommercialFeatures = [...unit.commercial_features];

    // Toggle the presence of 49 in the array based on the switch status
    if (checked) {
      updatedCommercialFeatures.push(49);
    } else {
      updatedCommercialFeatures = updatedCommercialFeatures.filter(
        (feature) => feature !== 49
      );
    }
    dispatch(updateUnitCommercialFeatures(updatedCommercialFeatures));
  };

  return (
    <>
      <Grid
        item
        xs={12}
        style={{ marginTop: "2rem" }}
        id="unit-features-section"
      >
        <Typography className={classes.MenuTitle}>
          {dictionary.UnitFeatures}
        </Typography>
      </Grid>

      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6} id="technical_features-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(unit.technical_features.includes(1))}
                onChange={changeUnitRaisedFloorHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${unitErrors.sublet ? "red" : ""}`,
                }}
              >
                {dictionary.RaisedFloor}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(unit.technical_features.includes(17))}
                onChange={changeUnitAcousticCeilingHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${unitErrors.coworking ? "red" : ""}`,
                }}
              >
                {dictionary.AcousticCeiling}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(unit.technical_features.includes(16))}
                onChange={changeUnitRemovableTilesFalseCeilingHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${unitErrors.coworking ? "red" : ""}`,
                }}
              >
                {dictionary.RemovableTiles}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6} id="commercial_features-section">
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(unit.commercial_features.includes(35))}
                onChange={changeUnitInnerGardenHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${unitErrors.business ? "red" : ""}`,
                }}
              >
                {dictionary.InnerGarden}
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(unit.commercial_features.includes(49))}
                onChange={changeUnitPlugAndPlayHandler}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span
                style={{
                  color: `${unitErrors.coworking ? "red" : ""}`,
                }}
              >
                {dictionary.PlugAndPlay}
              </span>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UnitFeatures;
