import {Grid, useMediaQuery} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Carousel } from "react-responsive-carousel";
import {Link as RouterLink, useHistory} from "react-router-dom";
import {
  ROUTE_TEASER_EDIT_ITEM,
} from "../../../../config/config";
import { LanguageContext } from "../../../../containers/languageprovider";
import { buildingsStore } from "../../../../lib/store";
import globalStyles from "../../../globalStyles";
import {
  EuroIcon,
  LeftIcon,
  RightIcon,
  SolutionIcon,
} from "../../../iconlist.js";
import { returnStringTranslation } from "../../../printLanguageSelect";

import "./Slider-style.css";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarsIcon from "@material-ui/icons/Stars";
import {routeReplace} from "../../../utils";

const useStyles = makeStyles((theme) => ({
  CardBuilding: {
    maxWidth:"450px",
    background: "transparent",
  },
  ArrowsCarousel: {
    width: "20px",
    height: "20px",
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 25px)",
    cursor: "pointer",
  },
  GridInfo: {
    minHeight: "60px",
    width: "100%",
  },
  InfoPrice: {
    fontFamily: "Barlow, sans-serif, Verdana, Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    border: "none",
    margin: "-6px",
    cursor: "pointer",
  },
  InfoCity: {
    fontFamily: "Barlow, sans-serif, Verdana, Arial",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "all-small-caps",
    color: "#FFFFFF",
    background: theme.breadCrumbsBar.background,
    textAlign: "left",
    paddingLeft: "20px",
  },
  TitleCard: {
    height: "40px",
    fontFamily: "Barlow, sans-serif, Verdana, Arial",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "left",
    color: "#000000",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  SubTitleCard: {
    fontFamily: "Barlow, sans-serif, Verdana, Arial",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "left",
    color: "#000000",
  },

  FilledStarIcon: {
    pointerEvents: "none",
    color: "#59c170",
    fontSize: "30px",
  },
}));

export default function OfferedUnit({ searcherUnit }) {
  const classes = useStyles();
  const { dictionary, userLanguage } = React.useContext(LanguageContext);
  const [unitImages, setUnitImages] = useState([]);
  const theme = useTheme();
  const history = useHistory();
  const MobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    //use the building images if the units has no images
    const images =
      searcherUnit.unit.images.length > 0
        ? searcherUnit.unit.images
        : searcherUnit.unit.building.building_photos;


    setUnitImages(images);
  }, [searcherUnit]);

  const cities = buildingsStore((state) => state.cities);

  return (
    <Card className={classes.CardBuilding}>
      {unitImages !== null && unitImages.length > 0 && (
        <Carousel
          showIndicators={true}
          showThumbs={false}
          showArrows={true}
          autoPlay={false}
          swipeable={false}
          transitionTime={1000}
          animationHandler="fade"
          showStatus={false}
          className="Slider-carousel"
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <div
                className={classes.ArrowsCarousel}
                onClick={onClickHandler}
                style={{ left: 5, zIndex: 10 }}
              >
                <LeftIcon
                  className={classes.IconArrow}
                  viewBox={"0 0 24 24"}
                  fill={"#ffffff"}
                />
              </div>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <div
                className={classes.ArrowsCarousel}
                onClick={onClickHandler}
                style={{ right: 5, zIndex: 10 }}
              >
                <RightIcon
                  className={classes.IconArrow}
                  viewBox={"0 0 60 60"}
                  fill={"#ffffff"}
                />
              </div>
            )
          }
        >
          {unitImages.map((image, i) => (
            <div
              key={`img-${i}`}
              style={{
                height: "200px",
                objectFit: "cover",
              }}
            >
              <img
                style={{
                  height: "200px",
                  objectFit: "cover",
                }}
                src={image.image}
                alt={returnStringTranslation(image.description, userLanguage)}
              />
            </div>
          ))}
        </Carousel>
      )}

      {(!unitImages || unitImages.length === 0) && (
        <Carousel
          showIndicators={false}
          showThumbs={false}
          showArrows={false}
          autoPlay={false}
          swipeable={false}
          infiniteLoop={false}
          showStatus={false}
          className="Slider-carousel"
        >
          <div
            className={globalStyles.LinkImageCarousel}
            style={{
              height: "300px",
              width: "100%",
            }}
          >
            <img src="/images/placeholder.png" alt="Building PlaceHolder" />
          </div>
        </Carousel>
      )}

      <Box className={classes.InfoCity}>
        {searcherUnit.unit.building.detailAddress.getCityCountry(userLanguage)}
      </Box>

      <CardContent style={{ background: "white", height: "190px" }}>
        <Grid container spacing={3}>
          <Grid item md={10} xs={10}>
            <Typography
              className={classes.SubTitleCard}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {searcherUnit.unit.info.getAssetForDeal(dictionary)}
            </Typography>
            <Link
                style={{ textDecoration: "none", cursor: "pointer" }}
                component={RouterLink}
                color="inherit"
                to={"#"}
                onClick={async (event) => {
                  event.preventDefault();
                  let url = routeReplace(ROUTE_TEASER_EDIT_ITEM,{teaserId:searcherUnit.unit.building.id });

                  if (searcherUnit.unit.building.slug) {
                    url = await searcherUnit.unit.building.info.getShareUrlPrivate();
                  }
                  if (MobileScreen) {
                    history.push(url);
                  } else {
                    window.open(url, "_blank");
                  }
                  return;
                }}
            >
              <Typography
                  className={classes.TitleCard}
                  gutterBottom
                  variant="h5"
                  component="h2"
              >
                {returnStringTranslation(
                    searcherUnit.unit.building.name,
                    userLanguage
                )}
              </Typography>
            </Link>
          </Grid>
          <Grid item md={2} xs={2}>
            {searcherUnit.star === 0 && <StarBorderIcon />}
            {searcherUnit.star === 1 && (
              <StarsIcon className={classes.FilledStarIcon} />
            )}
          </Grid>
        </Grid>
        <Grid className={classes.GridInfo} container spacing={2}>
          <Grid item xs>
            <Chip
              className={classes.InfoPrice}
              variant="outlined"
              size="medium"
              icon={<SolutionIcon />}
              label={searcherUnit.unit.size + " m²"}
            />
          </Grid>

          <Grid item xs>
            <Chip
              className={classes.InfoPrice}
              variant="outlined"
              size="medium"
              icon={<EuroIcon />}
              label={
                <NumberFormat
                  value={searcherUnit.unit.asking_rent}
                  displayType={"text"}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                  suffix={" " + dictionary.MqYear}
                  prefix={" € "}
                />
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
