import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  IconButton,
    Grid,
    FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  MenuItem,
  Checkbox,

} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, { useContext, useState } from "react";
import MatchedUnits from "./MatchedUnits";
import Parameters from "../OfferedUnits/Parameters";
import { LanguageContext } from "../../../../containers/languageprovider";
import AddNewBuildingDialog from "../AddNewBuildingDialog/AddNewBuildingDialog";
import CloseIcon from "@material-ui/icons/Close";
import {returnStringTranslation} from "../../../printLanguageSelect";
import {API_URL_ALLMACROAREA} from "../../../../config/config";
import Auth from "../../../../auth/auth";
import {sortMacroareas} from "../../../utils";
import axios from "axios";
import _ from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  dialog:{
    padding: "0 30px 30px",
  },
  TitleContainer: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    width:"100%",
  },
  AddNewBuildingButton: {
    position: "absolute",
    right: "2%",
    top: "-15%",
  },
  CloseDialogButton: {
    color: theme.palette.grey[500],
    position: "absolute",
    right: "-1%",
    top: "-25%",
    cursor: "pointer",
  },
  ProposeButton: {
    fontSize: "20px",
    marginBottom: "2rem",
    marginTop:"0.7rem",
    fontWeight: 600,
    fontFamily: "Monserrat, sans-serif, Arial, Helvetica",
    letterSpacing: "0.43px",
    backgroundColor: "#1c55e5",
    padding: "10px 24px",
    textAlign: "center",
    borderRadius: "10px",
    height:"52",
  },
  ProposeButtonInBar: {
    fontSize: "18px",
    marginBottom: "1rem",
    marginTop:"0.7rem",
    fontWeight: 600,
    fontFamily: "Monserrat, sans-serif, Arial, Helvetica",
    // letterSpacing: "0.43px",
    backgroundColor: "#1c55e5",
    padding: "10px 10px",
    textAlign: "center",
    borderRadius: "10px",
    height:"52",
  },
}));

const ProposeBuildingDialog = ({
  open,
  closeDialogHandler,
  matchedUnits,
  searcherUnits,
  location,
  offer,
  size,
  fromDate,
  handleCheckboxChange,
  selectedMatchedUnits,
  proposeBuidingSelectedClickHandler,
  setMatchedUnits,
}) => {
  const classes = useStyles();
  const { dictionary, userLanguage } = useContext(LanguageContext);
  const [allMacroareas, setAllMacroAreas] = React.useState([]);
  const [availableMacroareas, setAvailableMacroareas] = React.useState([])
  const [filterMacroAreas, setFilterMacroAreas] = React.useState([]);
  const [openAddNewBuildingDialog, setOpenAddNewBuildingDialog] =
    useState(false);
  const theme = useTheme();
  const [filteredUnits, setFilteredUnits] = React.useState([])
  const [listOfFilters, setListOfFilters] = React.useState({
    building: {
      macroareas: [],
    },
    unit: {
    }
  })

  // const [filterObj, setFilterObj] = React.useState({
  //   building: {
  //     macroareas: [],
  //   },
  //   unit: {
  //   }
  // })

  const openAddBuildingDialogHandler = () => {
    setOpenAddNewBuildingDialog(true);
    closeDialogHandler();
  };

  // Initial population of filteredUnits and following updates by filter
  React.useEffect(() => {
    try{
      // console.log("filters %o\nselectedMatchedUnits %o\nmatchedUnits %o"
      //     ,listOfFilters
      //     ,selectedMatchedUnits
      //     ,matchedUnits.map((unit)=>({
      //       uid: unit.id,
      //       id: unit.building.id,
      //       macroarea: unit.building.macroarea_list,
      //       address: unit.building.addressdetails?.gb?.formatted_address,
      //     })
      //     )
      // );

      setFilteredUnits(
          matchedUnits.filter(
              (unit) =>
                  selectedMatchedUnits.includes(`${unit.id}`) ||
                  unit.info.isPassingFilter(listOfFilters) &&
                  unit.building.info.isPassingFilter(listOfFilters)
          )
      );

    }catch (e){
      console.log("React.useEffect listOfFilters,matchedUnits: %o",e)
    }
  }, [listOfFilters,matchedUnits]);

  React.useEffect(() => {
    axios
        .get(API_URL_ALLMACROAREA, {
          headers: {
            Authorization: "Bearer " + Auth.getToken(),
            "Content-Type": "application/json",
          },
        })
        .then((payload) => {
          const sortedMacroareas = sortMacroareas(payload.data.all);
          setAllMacroAreas(sortedMacroareas);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  React.useEffect(() => {
    if (location && allMacroareas && location.length > 0 && allMacroareas.length > 0) {
      const newFilter = allMacroareas
          .filter((item) => {
            const l_location = location.toLowerCase();
            const l_city = item.city ? item.city.toLowerCase() : '';
            return l_city && l_location.includes(l_city);
          });
      setAvailableMacroareas(newFilter)
    }
  }, [location, allMacroareas]);

  const handleFilterMacroArea = (value) => {
    setFilterMacroAreas(value);
    const newListOfFilters = { ...listOfFilters, building: {...listOfFilters.building, macroareas: value}  };
    // console.log("Updating listOfFilters",newListOfFilters);
    setListOfFilters(newListOfFilters);
  }




  const getCheckedMacroArea = (value, temp) => {
    return filterMacroAreas.indexOf(value) !== -1;
  };



//function that checks if all the selected units are contained in searcherUnits for disabling the button
const matches = (array, secondArray) => {
  let unitIds = secondArray.map(obj => obj.unit_id);
  for (let obj of array) {
    const number =  Number(obj)
    if (!unitIds.includes(number)){
      return false
    }
  }
  return true
}



  return (
    <>
      <Dialog
        onClose={closeDialogHandler}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={!useMediaQuery(theme.breakpoints.down("xs"))}
        fullScreen={useMediaQuery(theme.breakpoints.down("xs"))}
        maxWidth={"lg"}

      >
        <DialogTitle id="customized-dialog-title">
          <div className={classes.TitleContainer}>
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
            >

            <Grid item xs={6} sm={3} style={{margin: "0", maxWidth: "20rem"}} >
              <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ m: 1, width: 300 }}
              >
                <InputLabel id="demo-multiple-checkbox-label">
                  {dictionary.FiltersMacroArea}
                </InputLabel>
                <Select
                    disabled={availableMacroareas.length <= 0}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={filterMacroAreas}
                    label={dictionary.FiltersMacroArea}
                    renderValue={(selected) => {
                      if (selected.length > 2) {
                        return (
                            <>
                              {selected.length} {dictionary.macroAreasFilters}
                            </>
                        );
                      } else {
                        return selected
                            .map((e) =>
                                returnStringTranslation(
                                    allMacroareas.find((a) => a.id === e).description,
                                    userLanguage
                                )
                            )
                            .join(", ");
                      }
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      handleFilterMacroArea(e.target.value);
                    }}
                    MenuProps={{
                      getContentAnchorEl: null,
                    }}
                    //MenuProps={MenuProps}
                >
                  {availableMacroareas
                      .map((item, index) => (
                          <MenuItem
                              key={"macroareas" + item.id + index}
                              value={item.id}
                          >
                            <FormControlLabel
                                control={
                                  <Checkbox
                                      key={item.id}
                                      checked={getCheckedMacroArea(item.id)}
                                  />
                                }
                                label={
                                    item.city +
                                    ", " +
                                    returnStringTranslation(item.description, userLanguage)
                                }
                            />
                          </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}  >
              <Button
                  color="primary"
                  variant="contained"
                  className={classes.ProposeButtonInBar}
                  onClick={proposeBuidingSelectedClickHandler}
                  disabled={matches(selectedMatchedUnits, searcherUnits)}
              >
                {dictionary.ProposeSelected}
              </Button>
            </Grid>
              <Hidden only="xs">

                <Grid item xs={1} sm={3}>
                  <Button
                      // className={classes.AddNewBuildingButton}
                      onClick={openAddBuildingDialogHandler}
                  >

                    <Typography>{dictionary.AddNewBuilding}</Typography>
                    <AddCircleIcon
                        color="primary"
                        fontSize="large"
                        style={{
                          marginLeft: "10px", width: "40px",
                          height: "40px",
                        }}
                    />
                  </Button>
                </Grid>
              </Hidden>
            </Grid>
            <span className={classes.CloseDialogButton} onClick={closeDialogHandler}><CloseIcon/></span>


          </div>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <MatchedUnits
            location={location}
            matchedUnits={filteredUnits}
            searcherUnits={searcherUnits}
            handleCheckboxChange={handleCheckboxChange}
            selectedMatchedUnits={selectedMatchedUnits}
          />
        </DialogContent>
      </Dialog>
      <AddNewBuildingDialog
        openAddNewBuildingDialog={openAddNewBuildingDialog}
        setOpenAddNewBuildingDialog={setOpenAddNewBuildingDialog}
      />
    </>
  );
};

export default ProposeBuildingDialog;
