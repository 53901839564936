import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import SearchRequestsStep1 from "./Steps/SearchRequestsStep1";
import SearchRequestsStep2 from "./Steps/SearchRequestsStep2";
import SearchRequestsStep3 from "./Steps/SearchRequestsStep3";
import CloseIcon from "@material-ui/icons/Close";
import Auth from "../auth/auth";
import SignUpForm from "../components/signupForm";
import { LanguageContext } from "../containers/languageprovider";
import axios from "axios";
import {
  API_SEARCHER_REQUEST,
  API_SEARCHER_REQUEST_GUEST,
  API_URL_BROKER_COMPANIES,
} from "../config/config";
import authMethods from "../auth/auth";
import {
  sizelistname,
  askingrentlist,
  askingsaleslist,
} from "../components/utils";

const useStyles = makeStyles((theme) => ({
  SignUpFormTitleContainer: {
    fontFamily:"Barlow, sans-serif, Verdana",
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },
  DialogTitle: {
    paddingTop: "0",
  },
  CloseButton: {
    position: "absolute",
    right: "2%",
    top: "10px",
    fontSize: "20px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  closeDialogButton: {
    position: "absolute",
    right: "0px",
    top: "3px",
    color: theme.palette.grey[500],
  },
  dialog: {
    overflow: "hidden",
    "& .MuiDialog-paper": {
      maxHeight: "100%",
    },
  },
}));

const SearchRequestsModal = ({
  open,
  closeDialog,
  searchRequestData,
  type,
}) => {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const user = Auth.getUser();
  const [openSignUpForm, setOpenSignUpForm] = useState(false);
  const { dictionary, userLanguage } = useContext(LanguageContext);
  const token = authMethods.getToken();
  axios.defaults.headers.common["Authorization"] = token
    ? "Bearer " + authMethods.getToken()
    : null;
  axios.defaults.headers.common["Content-Type"] = "application/json";

  // step 1 fields
  const [assetClassSelect, setAssetClass] = useState(
    searchRequestData?.assetClass || 1
  );
  const [dealType, setDealType] = useState(searchRequestData?.dealType || 1);
  const [selectedPrices, setselectedPrices] = useState([]);
  const [selectedRentPrices, setSelectedRentPrices] = useState([]);
  const [selectedSalePrices, setSelectedSalePrices] = useState([]);
  const [location, setLocation] = useState(searchRequestData?.location || "");
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [message, setMessage] = useState("");
  const [sizeModal, setSizeModal] = useState("xl");

  // step 1 form errors
  const [locationError, setLocationError] = useState("");
  const [sizeError, setSizeError] = useState("");

  // step 2 fields
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");

  //step2 form errors
  const [nameError, setNameError] = useState("");
  const [surNameError, setSurNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  //all companies
  const [allCompanies, setAllCompanies] = React.useState([]);

  //processing
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (step === 1) {
      setSizeModal("xl");
    } else {
      setSizeModal("xs");
    }
  }, [step]);

  //initiate all companies
  useEffect(() => {
    if(open){
    axios
      .get(API_URL_BROKER_COMPANIES)
      .then((companyPayLoad) => {
        let data = companyPayLoad.data.all;
        setAllCompanies(data);
      });}
  }, [open]);

  const handleChangeRents = (event) => {
    setselectedPrices(event.target.value);
    if (dealType == 1) setSelectedRentPrices(event.target.value);
    else {
      setSelectedSalePrices(event.target.value);
    }
  };

  const handleChangeSizes = (event) => {
    setSizeError("");
    setSelectedSizes(event.target.value);
  };

  const handleChangeCompanies = (event) => {
    setSelectedCompanies(event.target.value);
  };

  const handleDateChange = (date) => {
    // console.log("change date  : ", date);
    setSelectedDate(date);
  };

  const handleDescriptionChange = (event) => {
    setMessage(event.target.value);
  };

  const step1FormValidationHandler = () => {
    let formIsValid = true;
    if (location === "") {
      formIsValid = false;
      setLocationError(`${dictionary.LocationErrorMessage}`);
    }
    if (selectedSizes.length === 0) {
      formIsValid = false;
      setSizeError(`${dictionary.SizeErrorMessage}`);
    }
    return formIsValid;
  };

  const step2FormValidationHandler = () => {
    let formIsValid = true;
    if (name === "") {
      formIsValid = false;
      setNameError(`${dictionary.InvalidNameSurname}`);
    }
    if (surname === "") {
      formIsValid = false;
      setSurNameError(`${dictionary.InvalidSurname}`);
    }
    if (email === "") {
      formIsValid = false;
      setEmailError(`${dictionary.EmailRequired}`);
    }
    if (phoneNumber === "") {
      formIsValid = false;
      setPhoneNumberError(`${dictionary.PhoneRequired}`);
    }

    return formIsValid;
  };

  const openSignUpFormHandler = () => {
    setOpenSignUpForm(true);
  };

  const closeSignUpFormHandler = () => {
    setOpenSignUpForm(false);
  };

  const goNextStepHandler = () => {
    if (step === 1) {
      const formIsValid = step1FormValidationHandler();
      if (formIsValid) {
        // check if the user is logged in
        if (user) {
          // send request
          sendSearcherRequest();
        } else {
          setStep(2);
        }
      }
    } else {
      const formIsValid = step2FormValidationHandler();
      if (formIsValid) {
        sendSearcherRequestGuest();
      }
    }
  };

  const goBackStepHandler = () => {
    if (step === 3) {
      //check if the user is logged in
      if (user) setStep(1);
      else setStep(2);
    } else setStep(1);
  };

  const sendSearcherRequest = () => {
    //store the ids instead of labels
    const selectedPricesIds =
      dealType === 1
        ? askingrentlist
            .filter((askingRent) =>
              selectedRentPrices.includes(askingRent.label)
            )
            .map((askingRent) => askingRent.id)
        : askingsaleslist
            .filter((askingRent) =>
              selectedSalePrices.includes(askingRent.label)
            )
            .map((askingRent) => askingRent.id);

    const selectedSizesIds = sizelistname
      .filter((size) => selectedSizes.includes(size.label))
      .map((size) => size.id);

    const selectedCompaniesIds = allCompanies
      .filter((c) => selectedCompanies.includes(c.companyname))
      .map((c) => c.id);

    // console.log("sendSearcherRequest : ");

    //show progress icon
    setProcessing(true);
    const reqBody = {
      asset_class: assetClassSelect,
      deal_type: dealType,
      price: selectedPricesIds,
      location: location,
      size: selectedSizesIds,
      available_from: selectedDate,
      broker_company_ids: selectedCompaniesIds,
      message: message,
      language: userLanguage,
    };
    axios
        .post(API_SEARCHER_REQUEST, reqBody)
        .then(() => {
          setStep(3);
          setProcessing(false);
        })
        .catch(() => {
          setProcessing(false);
        });
  };

  // to review
  const sendSearcherRequestGuest = async () => {
    //store the ids instead of labels
    const selectedPricesIds =
      dealType === 1
        ? askingrentlist
            .filter((askingRent) => selectedPrices.includes(askingRent.label))
            .map((askingRent) => askingRent.id)
        : askingsaleslist
            .filter((askingSale) => selectedPrices.includes(askingSale.label))
            .map((askingSale) => askingSale.id);

    const selectedSizesIds = sizelistname
      .filter((size) => selectedSizes.includes(size.label))
      .map((size) => size.id);

    const selectedCompaniesIds = allCompanies
      .filter((c) => selectedCompanies.includes(c.companyname))
      .map((c) => c.id);

    //show progress icon
    setProcessing(true);

    const reqBody = {
      asset_class: assetClassSelect,
      deal_type: dealType,
      price: selectedPricesIds,
      location: location,
      size: selectedSizesIds,
      available_from: selectedDate,
      broker_company_ids: selectedCompaniesIds,
      message: message,
      name: name,
      surname: surname,
      email: email,
      phone_number: phoneNumber,
      user_company_name: companyName,
      language: userLanguage,
    };

    try {
      const res = await axios.post(API_SEARCHER_REQUEST_GUEST, reqBody, {
        params: { language: userLanguage },
      });
      setStep(3);
      setEmailError("");
      setPhoneNumberError("");
      setProcessing(false);
    } catch (err) {
      const errors = err.response.data.errors;
      setEmailError(errors.email ? errors.email[0] : "");
      setPhoneNumberError(errors.phone_number ? errors.phone_number[0] : "");
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (type === "advansed_filters") {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);

      const location = params.get("regionSelect") || params.get("countySelect") || params.get("citySelect") || params.get("postalCodeSelect") || params.get("macroAreaSelect")
      const dealType = params.get("dealType"); // "1"
      const assetClass = params.get("assetClass"); // "1"

      setLocation(location);
      setDealType(dealType);
      setAssetClass(assetClass);
    }
  }, []);

  useEffect(() => {
    if (type === "home_page") {
      setAssetClass(searchRequestData?.assetClass);
      setDealType(searchRequestData?.dealType);
      setLocation(searchRequestData?.location);
    }
    if (type === "advansed_filters") {
      //store the lables
      const rents =
        dealType === 1
          ? askingrentlist
              .filter((askingRent) =>
                searchRequestData?.rent.map(Number).includes(askingRent.id)
              )
              .map((askingRent) => askingRent.label)
          : askingsaleslist
              .filter((askingRent) =>
                searchRequestData?.rent.map(Number).includes(askingRent.id)
              )
              .map((askingRent) => askingRent.label);

      // console.log("rents : ", rents);
      if (dealType === 1) {
        setSelectedRentPrices(rents);
      } else {
        setSelectedSalePrices(rents);
      }
      setselectedPrices(rents);

      const sizes = sizelistname
        .filter((size) => searchRequestData?.size.map(Number).includes(size.id))
        .map((size) => size.label);
      setSelectedSizes(sizes);

      const companies = allCompanies
        .filter((c) => searchRequestData?.companies.includes(c.id))
        .map((c) => c.companyname);

      setSelectedCompanies(companies);
    }
    // console.log("searchRequestData : ", searchRequestData);
  }, [searchRequestData]);

  const resetFirstForm = () => {
    setAssetClass(1);
    setDealType(1);
    setselectedPrices([]);
    setSelectedSizes([]);
    setSelectedCompanies([]);
    setMessage("");
  };

  const closeDialogHandler = () => {
    setStep(1);
    closeDialog();
    resetFirstForm();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={closeDialogHandler}
        aria-labelledby="form-dialog-title"
        maxWidth={"xs"}
      >
        <IconButton
            aria-label="close"
            className={classes.closeDialogButton}
            onClick={closeDialogHandler}
        >
          <CloseIcon />
        </IconButton>
        {step === 1 ? (
          <SearchRequestsStep1
            type={type}
            user={user}
            goNextStep={goNextStepHandler}
            assetClassSelect={assetClassSelect}
            setAssetClass={setAssetClass}
            dealType={dealType}
            setDealType={setDealType}
            selectedRentPrices={selectedRentPrices}
            selectedSalePrices={selectedSalePrices}
            selectedPrices={selectedPrices}
            setselectedPrices={setselectedPrices}
            location={location}
            setLocation={setLocation}
            locationError={locationError}
            setLocationError={setLocationError}
            selectedSizes={selectedSizes}
            selectedCompanies={selectedCompanies}
            sizeError={sizeError}
            selectedDate={selectedDate}
            handleChangeRents={handleChangeRents}
            handleChangeSizes={handleChangeSizes}
            handleChangeCompanies={handleChangeCompanies}
            handleDateChange={handleDateChange}
            handleDescriptionChange={handleDescriptionChange}
            allCompanies={allCompanies}
            sizelistname={sizelistname}
            askingrentlist={askingrentlist}
            askingsaleslist={askingsaleslist}
            processing={processing}
          />
        ) : step === 2 ? (
          <SearchRequestsStep2
            goBackStep={goBackStepHandler}
            goNextStep={goNextStepHandler}
            name={name}
            setName={setName}
            surname={surname}
            setSurname={setSurname}
            email={email}
            setEmail={setEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            companyName={companyName}
            setCompanyName={setCompanyName}
            nameError={nameError}
            setNameError={setNameError}
            surNameError={surNameError}
            setSurNameError={setSurNameError}
            emailError={emailError}
            setEmailError={setEmailError}
            phoneNumberError={phoneNumberError}
            setPhoneNumberError={setPhoneNumberError}
            processing={processing}
          />
        ) : (
          <SearchRequestsStep3
            user={user}
            closeDialog={closeDialogHandler}
            openSignUp={openSignUpFormHandler}
          />
        )}
      </Dialog>

      <Dialog
        open={openSignUpForm}
        onClose={closeSignUpFormHandler}
        className={classes.dialog}
      >
        <DialogTitle className={classes.DialogTitle}>
          <div className={classes.SignUpFormTitleContainer}>
            <p>{dictionary.CreateAnAccount}</p>
              <IconButton
                  aria-label="close"
                  className={classes.closeDialogButton}
                  onClick={closeSignUpFormHandler}
              >
                  <CloseIcon />
              </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            maxWidth: "480px",
          }}
        >
          <SignUpForm
            name={name}
            surname={surname}
            email={email}
            phoneNumber={phoneNumber}
            infoClose={() => {
              closeSignUpFormHandler();
              closeDialogHandler();
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SearchRequestsModal;
