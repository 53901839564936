import React, {useState} from "react";
import {
    Button,
    Dialog,
    Grid,
    makeStyles,
    Select,
    MenuItem,
    DialogActions,
    DialogContent,
    DialogContentText,
    Checkbox,
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@material-ui/core";
import { LanguageContext } from "../../../containers/languageprovider";
import globalStyles from "../../globalStyles";
import {returnStringTranslation} from "../../printLanguageSelect";
import { useDispatch, useSelector } from "react-redux";
import {
    updateBuildingAmenties,
    updateBuildingMacroarea,
} from "../../../reducers/BuildingSlice";

const useStyles = makeStyles((theme) => ({
    buttonDialog: {
        width:"100%",
        margin: "10px",
        fontFamily: "Barlow",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.03em",
        fontVariant: "none",
        height: "36px",
        borderRadius: "10px",
    },
    dialog: {
        padding:"10px 30px",
    },
    contentText: {
        color: "black",
    },
}));


export default function MultipleMacroareasDialog(props) {
    const classes = useStyles();
    const globalClasses = globalStyles();
    //const history = useHistory();
    const { open, setOpen, setMacroAreaSelect, macroareaArray, setMacroareaArray } = props;
    const [selectedMacroArea, setSelectedMacroArea] = useState("");
    const { dictionary, userLanguage,  } = React.useContext(LanguageContext);
    const dispatch = useDispatch();


    React.useEffect(()=>{
        setSelectedMacroArea(macroareaArray[0])
    }, [])



    const handleConfirm = () => {
            const selectedDescription = selectedMacroArea?.description;
            if (selectedMacroArea) {
                const match = macroareaArray.find(macroarea => macroarea === selectedMacroArea || macroarea.description === selectedDescription || macroarea.description === selectedMacroArea);
                if (match) {
                    setMacroAreaSelect([match]);
                }
                else {
                    return
                }
            }
            setMacroareaArray([])
        setOpen(false);
    };



    const handleClose = () => {
        setOpen(false);
    };


    const handleMacroAreaChange = (event) => {
        setSelectedMacroArea(event.target.value);
    };





    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
            maxWidth={"xs"}
            disableBackdropClick={true}
        >
            <Grid className={classes.dialog}>
            <DialogContent >
                <DialogContentText  className={classes.contentText}>
                    {dictionary.TwoMacroareasText}
                </DialogContentText>

                <RadioGroup aria-label="gender" name="gender1" value={selectedMacroArea} onChange={handleMacroAreaChange}>
                {macroareaArray.map((macroarea) => (
                    <FormControlLabel
                        key={macroarea.id}
                        value={macroarea.description}
                        control={
                            <Radio/>
                        }
                        label={returnStringTranslation(
                            macroarea.description,
                            userLanguage
                        )}
                    />
                ))}
                </RadioGroup>

            </DialogContent>
            <DialogActions >
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonDialog}
                    onClick={handleConfirm}
                >
                    {dictionary.Confirm}
                </Button>

            </DialogActions>
            </Grid>
        </Dialog>
    );
}
