export const filters = {
  attributes: [
    {
      category: "Certifications",
      variable_name: "sustainability",
      display_gb: "LEED",
      display_it: "To Translate:LEED",
      type: "nullable dropdown",
      options: [
        {
          value: 1,
          order: 1,
          display_gb: "platinum",
          display_it: "platinum",
        },
        {
          value: 2,
          order: 2,
          display_gb: "gold",
          display_it: "gold",
        },
        {
          value: 3,
          order: 3,
          display_gb: "silver",
          display_it: "silver",
        },
        {
          value: 0,
          order: 4,
          display_gb: "none",
          display_it: "none",
        },
      ],
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Certifications",
      variable_name: "sustainability_breeam",
      display_gb: "BREEM",
      display_it: "To Translate:BREEM",
      type: "nullable dropdown",
      options: [
        {
          value: 1,
          order: 1,
          display_gb: "outstanding",
          display_it: "outstanding",
        },
        {
          value: 2,
          order: 2,
          display_gb: "excellent",
          display_it: "excellent",
        },
        {
          value: 3,
          order: 3,
          display_gb: "very good",
          display_it: "very good",
        },
        {
          value: 4,
          order: 4,
          display_gb: "good",
          display_it: "good",
        },
        {
          value: 5,
          order: 5,
          display_gb: "pass",
          display_it: "pass",
        },
        {
          value: 6,
          order: 6,
          display_gb: "unclassified",
          display_it: "unclassified",
        },
        {
          value: 0,
          order: 7,
          display_gb: "none",
          display_it: "none",
        },
      ],
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Certifications",
      variable_name: "workplace_wellness",
      display_gb: "WELL",
      display_it: "To Translate:WELL",
      type: "nullable dropdown",
      options: [
        {
          value: 1,
          order: 1,
          display_gb: "platinum",
          display_it: "platinum",
        },
        {
          value: 2,
          order: 2,
          display_gb: "gold",
          display_it: "gold",
        },
        {
          value: 3,
          order: 3,
          display_gb: "silver",
          display_it: "silver",
        },
        {
          value: 0,
          order: 4,
          display_gb: "none",
          display_it: "none",
        },
      ],
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Certifications",
      variable_name: "energy_performance_certificate",
      display_gb: "Energy Performance Certificate",
      display_it: "To Translate:Energy Performance Certificate",
      type: "dropdown",
      options: [
        {
          value: 1,
          order: 1,
          display_gb: "A+",
          display_it: "A+",
        },
        {
          value: 2,
          order: 2,
          display_gb: "A",
          display_it: "A",
        },
        {
          value: 3,
          order: 3,
          display_gb: "B",
          display_it: "B",
        },
        {
          value: 4,
          order: 4,
          display_gb: "C",
          display_it: "C",
        },
        {
          value: 5,
          order: 5,
          display_gb: "D",
          display_it: "D",
        },
        {
          value: 6,
          order: 6,
          display_gb: "E",
          display_it: "E",
        },
        {
          value: 7,
          order: 7,
          display_gb: "F",
          display_it: "F",
        },
        {
          value: 8,
          order: 8,
          display_gb: "G",
          display_it: "G",
        },
      ],
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Status",
      variable_name: "construction_status",
      display_gb: "Construction Status",
      display_it: "To Translate:Construction Status",
      type: "dropdown",
      options: [
        {
          value: 1,
          order: 1,
          display_gb: "New",
          display_it: "New",
        },
        {
          value: 2,
          order: 2,
          display_gb: "Refurbished",
          display_it: "Refurbished",
        },
        {
          value: 3,
          order: 3,
          display_gb: "To be refurbished",
          display_it: "To be refurbished",
        },
        {
          value: 4,
          order: 4,
          display_gb: "In refurbishment",
          display_it: "In refurbishment",
        },
        {
          value: 5,
          order: 5,
          display_gb: "In contruction",
          display_it: "In contruction",
        },
        {
          value: 0,
          order: 6,
          display_gb: "Not specified",
          display_it: "Not specified",
        },
      ],
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Status",
      variable_name: "construction_year",
      display_gb: "CONSTRUCTION_YEAR",
      display_it: "To Translate:CONSTRUCTION_YEAR",
      type: "nullable Integer",
      conditional: {
        is_conditional: true,
        attribute: "construction_status",
        value: "New",
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Status",
      variable_name: "refurbishment_year",
      display_gb: "REFURBISHMENT_YEAR",
      display_it: "To Translate:REFURBISHMENT_YEAR",
      type: "nullable Integer",
      conditional: {
        is_conditional: true,
        attribute: "construction_status",
        value: "Refurbished",
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Status",
      variable_name: "delivery_date_refurbish",
      display_gb: "DELIVERY_DATE_REFURBISH",
      display_it: "To Translate:DELIVERY_DATE_REFURBISH",
      type: "nullable date",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Status",
      variable_name: "delivery_date_construction",
      display_gb: "DELIVERY_DATE_CONSTRUCTION",
      display_it: "To Translate:DELIVERY_DATE_CONSTRUCTION",
      type: "nullable date",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Status",
      variable_name: "not_specified_open_field",
      display_gb: "NOT_SPECIFIED_OPEN_FIELD",
      display_it: "To Translate:NOT_SPECIFIED_OPEN_FIELD",
      type: "string(255)",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Status",
      variable_name: "heritage_building",
      display_gb: "Heritage Building",
      display_it: "To Translate:Heritage Building",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Status",
      variable_name: "restrictions_superintendence",
      display_gb: "Restrictions Superintendence",
      display_it: "To Translate:Restrictions Superintendence",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Status",
      variable_name: "smart_building",
      display_gb: "Smart Building",
      display_it: "To Translate:Smart Building",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Status",
      variable_name: "smart_building_details",
      display_gb: "Smart Building Details",
      display_it: "To Translate:Smart Building Details",
      type: "string(255)",
      conditional: {
        is_conditional: true,
        attribute: "smart_building",
        value: "TRUE",
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Status",
      variable_name: "renovationgrade",
      display_gb: "Grade",
      display_it: "To Translate:Grade",
      type: "dropdown",
      options: [
        {
          value: 1,
          order: 1,
          display_gb: "A",
          display_it: "A",
        },
        {
          value: 2,
          order: 2,
          display_gb: "B",
          display_it: "B",
        },
        {
          value: 3,
          order: 3,
          display_gb: "C",
          display_it: "C",
        },
        {
          value: 4,
          order: 4,
          display_gb: "A Green",
          display_it: "A Green",
        },
        {
          value: 0,
          order: 5,
          display_gb: "Not specified",
          display_it: "Not specified",
        },
      ],
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Services",
      variable_name: "entrance_reception",
      display_gb: "Entrance Reception",
      display_it: "To Translate:Entrance Reception",
      type: "nullable dropdown",
      options: [
        {
          value: 1,
          order: 1,
          display_gb: "Reception",
          display_it: "Reception",
        },
        {
          value: 2,
          order: 2,
          display_gb: "24h reception",
          display_it: "24h reception",
        },
        {
          value: 0,
          order: 3,
          display_gb: "none",
          display_it: "none",
        },
      ],
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Services",
      variable_name: "access_turnstiles",
      display_gb: "access turnstiles",
      display_it: "To Translate:access turnstiles",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Services",
      variable_name: "shared_rooftop_area",
      display_gb: "Shared Rooftop Area",
      display_it: "To Translate:Shared Rooftop Area",
      type: "nullable Integer",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Services",
      variable_name: "shared_meeting_rooms",
      display_gb: "Shared meeting rooms",
      display_it: "To Translate:Shared meeting rooms",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Services",
      variable_name: "auditorium",
      display_gb: "Auditorium",
      display_it: "To Translate:Auditorium",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Services",
      variable_name: "archive_warehouse_area",
      display_gb: "Archive - Warehouse Area",
      display_it: "To Translate:Archive - Warehouse Area",
      type: "nullable Integer",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Services",
      variable_name: "bar",
      display_gb: "bar",
      display_it: "To Translate:bar",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Services",
      variable_name: "canteen",
      display_gb: "canteen",
      display_it: "To Translate:canteen",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Services",
      variable_name: "changing_rooms_lockers",
      display_gb: "Changing Rooms - Lockers",
      display_it: "To Translate:Changing Rooms - Lockers",
      type: "nullable Integer",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Services",
      variable_name: "_24_hour_security_guards",
      display_gb: "24 hour security guards",
      display_it: "To Translate:24 hour security guards",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Services",
      variable_name: "shared_leisure_area",
      display_gb: "Shared Leisure Area",
      display_it: "To Translate:Shared Leisure Area",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Services",
      variable_name: "loading_bay",
      display_gb: "loading bay",
      display_it: "To Translate:loading bay",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Building Services",
      variable_name: "seperate_goods_lift",
      display_gb: "Seperate goods lift",
      display_it: "To Translate:Seperate goods lift",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Mobility",
      variable_name: "garage",
      display_gb: "Garage",
      display_it: "To Translate:Garage",
      type: "integer",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Mobility",
      variable_name: "outdoor_car_parking",
      display_gb: "Outdoor car parking",
      display_it: "To Translate:Outdoor car parking",
      type: "integer",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Mobility",
      variable_name: "motorcycle_parking",
      display_gb: "Motorcycle Parking",
      display_it: "To Translate:Motorcycle Parking",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Mobility",
      variable_name: "bicycle_parking",
      display_gb: "Bicycle Parking",
      display_it: "To Translate:Bicycle Parking",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Mobility",
      variable_name: "visitors_parking",
      display_gb: "Visitor's parking",
      display_it: "To Translate:Visitor's parking",
      type: "nullable Integer",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Mobility",
      variable_name: "charging_stations",
      display_gb: "Charging stations",
      display_it: "To Translate:Charging stations",
      type: "nullable Integer",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Delivery status",
      variable_name: "renovation_status",
      display_gb: "Delivery Status",
      display_it: "To Translate:Delivery Status",
      type: "dropdown",
      options: [
        {
          value: 1,
          order: 1,
          display_gb: "Cat A",
          display_it: "Cat A",
        },
        {
          value: 2,
          order: 2,
          display_gb: "Cat B",
          display_it: "Cat B",
        },
        {
          value: 3,
          order: 3,
          display_gb: "Shell and core",
          display_it: "Shell and core",
        },
        {
          value: 4,
          order: 4,
          display_gb: "Plug&Play",
          display_it: "Plug&Play",
        },
        {
          value: 5,
          order: 5,
          display_gb: "To be furnished",
          display_it: "To be furnished",
        },
        {
          value: 0,
          order: 6,
          display_gb: "Not specified",
          display_it: "Not specified",
        },
      ],
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Delivery status",
      variable_name: "furnishing_time",
      display_gb: "Furnishing time",
      display_it: "To Translate:Furnishing time",
      type: "integer",
      conditional: {
        is_conditional: true,
        attribute: "renovation_status",
        value: "To be furnished",
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Security / Fire Emergency",
      variable_name: "sprinkler_watermist",
      display_gb: "Sprinkler - Watermist",
      display_it: "To Translate:Sprinkler - Watermist",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Security / Fire Emergency",
      variable_name: "smoke_detectors_system",
      display_gb: "Smoke detectors system",
      display_it: "To Translate:Smoke detectors system",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Security / Fire Emergency",
      variable_name: "emergency_light_system",
      display_gb: "Emergency Light System",
      display_it: "To Translate:Emergency Light System",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Electrical system",
      variable_name: "high_efficency_electrical_system",
      display_gb: "High Efficency Electrical System",
      display_it: "To Translate:High Efficency Electrical System",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Electrical system",
      variable_name: "ups",
      display_gb: "UPS",
      display_it: "To Translate:UPS",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Electrical system",
      variable_name: "generator",
      display_gb: "Generator",
      display_it: "To Translate:Generator",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Electrical system",
      variable_name: "data_room",
      display_gb: "Data Room",
      display_it: "To Translate:Data Room",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Electrical system",
      variable_name: "electrical_substation",
      display_gb: "Electrical Substation",
      display_it: "To Translate:Electrical Substation",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Heating / Air Con",
      variable_name: "_2_pipes_air_con_heating_system",
      display_gb: "2 pipes Air Con/Heating System",
      display_it: "To Translate:2 pipes Air Con/Heating System",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Heating / Air Con",
      variable_name: "_4_pipes_air_con_heating_system",
      display_gb: "4 pipes Air Con/Heating System",
      display_it: "To Translate:4 pipes Air Con/Heating System",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Heating / Air Con",
      variable_name: "split_air_con_heating_system",
      display_gb: "Split Air Con/Heating System",
      display_it: "To Translate:Split Air Con/Heating System",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Heating / Air Con",
      variable_name: "indipendent_air_con_heating_system",
      display_gb: "Indipendent Air Con/Heating System",
      display_it: "To Translate:Indipendent Air Con/Heating System",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
    {
      category: "Heating / Air Con",
      variable_name: "bms",
      display_gb: "BMS",
      display_it: "To Translate:BMS",
      type: "checkbox",
      conditional: {
        is_conditional: false,
      },
      filter: {
        boolean: false,
        unfilterable: false,
        range: false,
      },
    },
  ],
  categories: [
    {
      category: "Certifications",
      gb: "Certifications",
      it: "Translate:Certifications",
    },
    {
      category: "Building Status",
      gb: "Building Status",
      it: "Translate:Building Status",
    },
    {
      category: "Building Services",
      gb: "Building Services",
      it: "Translate:Building Services",
    },
    {
      category: "Mobility",
      gb: "Mobility",
      it: "Translate:Mobility",
    },
    {
      category: "Delivery status",
      gb: "Delivery status",
      it: "Translate:Delivery status",
    },
    {
      category: "Security / Fire Emergency",
      gb: "Security / Fire Emergency",
      it: "Translate:Security / Fire Emergency",
    },
    {
      category: "Electrical system",
      gb: "Electrical system",
      it: "Translate:Electrical system",
    },
    {
      category: "Heating / Air Con",
      gb: "Heating / Air Con",
      it: "Translate:Heating / Air Con",
    },
  ],
};
