import React,{useEffect} from "react";
import {
  languageOptions,
  dictionaryList,
  siteLanguageMap,
  isoLanguageMap,
  siteLanguageMapNumeric
} from "../components/languages";

// it provides the language context to app
export function LanguageProvider({ children }) {
  let browserLang = navigator.language?.split("-")[0].toLowerCase();
  const defaultLanguage =
    window.localStorage.getItem("rcml-lang") || siteLanguageMap[browserLang]? siteLanguageMap[browserLang] : siteLanguageMap.default;
  const [userLanguage, setUserLanguage] = React.useState(
    defaultLanguage
  );
  const [isoLanguage, setIsoLanguage] = React.useState(
      isoLanguageMap[defaultLanguage]
  )
  const [numericLanguage, setNumericLanguage] = React.useState(
      siteLanguageMapNumeric[defaultLanguage]
  )

  const provider = {
    userLanguage,
    isoLanguage,
    numericLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: (selected) => {
      const newLanguage = languageOptions[selected] ? selected : siteLanguageMap.default;
      setUserLanguage(newLanguage);
      window.localStorage.setItem("rcml-lang", newLanguage);
    },
  };

  useEffect(() => {
    setIsoLanguage(isoLanguageMap[userLanguage])
    setNumericLanguage(siteLanguageMapNumeric[userLanguage])

  }, [userLanguage]);
  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
}

// create the language context with default selected language
export const LanguageContext = React.createContext({
  userLanguage: siteLanguageMap.default,
  isoLanguage:   isoLanguageMap[siteLanguageMap.default],
  numericLanguage: siteLanguageMapNumeric[siteLanguageMap.default],
  dictionary: dictionaryList.it,
  languageOptions: languageOptions,
});
