import axios from "axios";
import {
  API_AUTH_CHANGEPASSWORD,
  API_AUTH_LOGIN,
  API_AUTH_LOGOUT,
  API_AUTH_RECOVERINFO,
  API_AUTH_RECOVERPASSWORD,
  API_AUTH_REFRESH,
  API_AUTH_SIGNUP,
  API_AUTH_VERIFYTOKEN,
  API_URL_USER_REFRESH_ROUTE,
  ASSETMANAGER,
  AUTH_URL,
  BROKER,
  LANDLORD,
  OTHER,
  SUPER_ADMIN,
  TENANT
} from "../config/config";
import {DecorateUser, routeReplace} from "../components/utils";

function Auth() {
  const isTokenExpired = () => {
    const expires = +localStorage.getItem("token-expires");
    const res = new Date() > expires;
    if (res) {
      localStorage.removeItem("token-expires");
      localStorage.removeItem("auth");
    }
    return res;
  };

  const TokenNeedRefresh = () => {
    const expires = +localStorage.getItem("token-expires");
    const res = expires - new Date();
    const seconds = Math.trunc(res / 1000);

    if (seconds > 0 && seconds <= 30) {
      return true;
    }
    return false;
  };

  const handleError = (resp) => {
    let message = "";
    /*switch (+resp.status) {
            case 401:
                message = resp.data.error;
                break;
            case 500:
                message = resp.data.message;
                break;
            default:
                message = 'Error contacting server';
        }*/
    message = resp.data.errorcode;
    return message;
  };
  const addAxiosToken = () => {
    const token = getToken();

    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  };

  const signin = async (email, password) => {
    try {
      const result = await axios.post(API_AUTH_LOGIN, {
        email,
        password,
      });
      return manageResult(result);
    } catch (e) {
      return Promise.reject(handleError(e.response));
    }
  };

  const verifytoken = async (remember_token) => {
    // await waitPending("verifytoken");
    try {
      const result = await axios.post(API_AUTH_VERIFYTOKEN, {
        remember_token,
      });
      return manageResult(result);
    } catch (e) {
      return Promise.reject(handleError(e.response));
    }
  };

  const getToken = () => {
    if (isTokenExpired()) {
      return null;
    }
    if (TokenNeedRefresh()) {
      refresh();
    }
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
      return auth.access_token;
    }
    return null;
  };

  const getUser = () => {
    if (isTokenExpired()) {
      return null;
    }
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
      DecorateUser(auth.user)
      return auth.user;
    }
    return null;
  };

  function manageResult(result) {
    const data = result["data"];
    if (!data || !data["access_token"]) {
      return Promise.reject("Invalid server response");
    }
    // console.log("Data: %o",result.data);
    // result.data.user.isAdmin=function (){
    //   return this.role===SUPER_ADMIN;
    // }
    // result.data.user.isBroker=function (){
    //   return this.role===BROKER;
    // }
    // result.data.user.isTenant=function (){
    //   return this.role===TENANT;
    // }
    // result.data.user.isOther=function (){
    //   return this.role===OTHER;
    // }
    // result.data.user.isLandlord=function (){
    //   return this.role===LANDLORD;
    // }
    // result.data.user.isAssetmanager=function (){
    //   return this.role===ASSETMANAGER;
    // }

    // console.log("AUTH Manage; ",result.data)
    const expireDate = new Date().getTime() + data["expires_in"] * 1000;
    localStorage.setItem("token-expires", expireDate);
    localStorage.setItem("auth", JSON.stringify(result.data));

    return result.data;
  }

  const signup = async (
    email,
    phoneNumber,
    companyname,
    surname,
    name,
    subjecttype,
    role,
    contract_terms_and_conditions,
    accept_privacy,
    language
  ) => {
    try {
      const result = await axios.post(API_AUTH_SIGNUP, {
        email,
        phoneNumber,
        companyname,
        surname,
        name,
        subjecttype,
        role,
        contract_terms_and_conditions,
        accept_privacy,
        language,
      });
      return result;
      //return manageResult(result);
    } catch (e) {
      return Promise.reject(handleError(e.response));
    }
  };

  const recoverPassword = async (email) => {
    try {
      const result = await axios.post(API_AUTH_RECOVERPASSWORD, {
        email,
      });
      return result;
      //return manageResult(result);
    } catch (e) {
      return Promise.reject(handleError(e.response));
    }
  };

  const recoverInfo = async (email) => {
    try {
      const result = await axios.post(API_AUTH_RECOVERINFO, {
        email,
      });
      return result;
      //return manageResult(result);
    } catch (e) {
      return Promise.reject(handleError(e.response));
    }
  };

  const logout = async () => {
    addAxiosToken();
    try {
      const result = await axios.post(API_AUTH_LOGOUT);

      localStorage.removeItem("auth");
      return result;
    } catch (e) {
      localStorage.removeItem("auth");
      return Promise.reject(handleError(e.response));
    }
  };

  const changepassword = async (newpassword, retypepassword) => {
    addAxiosToken();
    try {
      const result = await axios.post(API_AUTH_CHANGEPASSWORD, {
        newpassword,
        retypepassword,
      });
      return result;
    } catch (e) {
      return Promise.reject(handleError(e.response));
    }
  };

  // const refreshProm = new Promise((resolve, reject) => {
  //   const auth = JSON.parse(localStorage.getItem("auth"));
  //   if (!auth) {
  //     reject(false);
  //   }
  //   const token = auth.access_token;
  //   axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  //   const result = axios.post(API_AUTH_REFRESH)
  //       .then(
  //           (result) => {
  //             const pre=JSON.parse(localStorage.getItem("auth"));
  //             manageResult(result);
  //             const post=JSON.parse(localStorage.getItem("auth"));
  //             console.log("PRE: %o\nPost: %o",pre.access_token,post.access_token);
  //             resolve(true);
  //           },
  //           (err) => {
  //             handleError(err.response)
  //             reject(false);
  //           }
  //         )
  //   ;
  // });
  //
  // const waitPending = async (pos)=>{
  //   console.log("waitPending: %o %o",pos,refresh);
  //   await refresh;
  //   console.log("waitPending: %o %o","END",refresh);
  // }

  const refresh = async () => {
    //addAxiosToken();

    const auth = JSON.parse(localStorage.getItem("auth"));
    if (!auth) {
      return false;
    }
    const token = auth.access_token;
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    try {
      const result = await axios.post(API_AUTH_REFRESH);
      return manageResult(result);
    } catch (e) {
      return Promise.reject(handleError(e.response));
    }
  };

  const refreshUser = async ()=>{
    const auth = JSON.parse(localStorage.getItem("auth"));
    if(auth===null){
      return;
    }
    const url = routeReplace(API_URL_USER_REFRESH_ROUTE,{userId: auth.user.id});
    axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
          },
        })
        .then((payload) => {
          console.log("AUTHAUTH",auth);
          console.log("AUTH Payload",payload.data);

          auth.user = payload.data.user;
          manageResult({data: auth});

        })
  }

  return {
    getUser,
    signin,
    signup,
    changepassword,
    recoverPassword,
    recoverInfo,
    logout,
    refresh,
    refreshUser,
    isTokenExpired,
    verifytoken,
    getToken,
  };
}
const authMethods = Auth();
export default authMethods;
