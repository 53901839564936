import React, { Fragment } from "react";
import { LanguageContext } from "../containers/languageprovider";

export function returnStringTranslation(value, userLanguage) {
  if (value && typeof value === "object") {
    return value[userLanguage];
  }
  const LangText = value === null || value === "" ? [] : JSON.parse(value);
  return LangText[userLanguage] === undefined ? "" : LangText[userLanguage];
}

export default function PrintLanguageSelect(props) {
  const { userLanguage } = React.useContext(LanguageContext);
  const LangText =
    props.value === null || props.value === undefined || props.value === ""
      ? []
      : JSON.parse(props.value);

  return (
    <Fragment>
      {LangText[userLanguage] === undefined ? "" : LangText[userLanguage]}
    </Fragment>
  );
}
