import React, { useState } from "react";
import { Link } from "react-scroll";
import "./StickyMenu.css";

const BuildingStickyMenu = ({ dictionary }) => {
  const [activeSection, setActiveSection] = useState(null);

  const handleSetActiveSection = (section) => {
    setActiveSection(section);
  };

  
  const handleLastLinkClick = (linkId) => {
    setTimeout(() => {
      setActiveSection(linkId);
    }, 500);
  };

  return (
    <nav className="sticky-menu" style={{ position: "fixed" }}>
      <ul>
        <li>
          <h3>{dictionary._1of2AddBuilding}</h3>
        </li>
      </ul>
      <ul>
        <li>
          <Link
            to="name-location-section"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => handleSetActiveSection("name-location")}
            className={activeSection === "name-location" ? "active" : ""}
          >
            {dictionary.BuildingNameAndLocation}
          </Link>
          <ul>
            <li>
              <Link
                to="name-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("name")}
                className={activeSection === "name" ? "active" : ""}
              >
                {dictionary.Name}
              </Link>
            </li>
            <li>
              <Link
                to="location-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("location")}
                className={activeSection === "location" ? "active" : ""}
              >
                {dictionary.location}
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link
            to="description-section"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => handleSetActiveSection("description")}
            className={activeSection === "description" ? "active" : ""}
          >
            {dictionary.BuildingDescription}
          </Link>
          <ul>
            <li>
              <Link
                to="visual-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("visual")}
                className={activeSection === "visual" ? "active" : ""}
              >
                {dictionary.VisualContents}
              </Link>
            </li>
            <li>
              <Link
                to="text-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("text")}
                className={activeSection === "text" ? "active" : ""}
              >
                {dictionary.TextDescriptions}
              </Link>
            </li>
            <li>
              <Link
                to="attachments-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("attachments")}
                className={activeSection === "attachments" ? "active" : ""}
              >
                {dictionary.Attachments}
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link
            to="information-section"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => handleSetActiveSection("information")}
            className={activeSection === "information" ? "active" : ""}
          >
            {dictionary.BuildingInformations}
          </Link>
          <ul>
            <li>
              <Link
                to="specifics-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("specifics")}
                className={activeSection === "specifics" ? "active" : ""}
              >
                {dictionary.Specifics}
              </Link>
            </li>
            <li>
              <Link
                to="certifications-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("certifications")}
                className={activeSection === "certifications" ? "active" : ""}
              >
                {dictionary.certifications}
              </Link>
            </li>
            <li>
              <Link
                to="status-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("status")}
                className={activeSection === "status" ? "active" : ""}
              >
                {dictionary.buildingStatus}
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link
            to="facilities-section"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onSetActive={() => handleSetActiveSection("facilities")}
            className={activeSection === "facilities" ? "active" : ""}
          >
            {dictionary.BuildingFacilities}
          </Link>
          <ul>
            <li>
              <Link
                to="services-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("services")}
                className={activeSection === "services" ? "active" : ""}
              >
                {dictionary.Service}
              </Link>
            </li>
            <li>
              <Link
                to="shared-area-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("shared-area")}
                className={activeSection === "shared-area" ? "active" : ""}
              >
                {dictionary.shared_areas}
              </Link>
            </li>
            <li>
              <Link
                to="mobility-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("mobility")}
                className={activeSection === "mobility" ? "active" : ""}
              >
                {dictionary.mobility}
              </Link>
            </li>
            <li>
              <Link
                to="security-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("security")}
                className={activeSection === "security" ? "active" : ""}
              >
                {dictionary.SecurityAndElectricalSystem}
              </Link>
            </li>
            <li>
              <Link
                to="heating-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("heating")}
                onClick={() => handleLastLinkClick("heating")}
                className={activeSection === "heating" ? "active" : ""}
              >
                {dictionary.HeatingAirCondition}
              </Link>
            </li>
            <li>
              <Link
                to="control-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={() => handleSetActiveSection("control")}
                onClick={() => handleLastLinkClick("control")}
                className={activeSection === "control" ? "active" : ""}
              >
                {dictionary.ControlSystem}
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default BuildingStickyMenu;
