import {
  Button,
  Chip,
  Container,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Snackbar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DomainIcon from "@material-ui/icons/Domain";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OfferedUnit from "./OfferedUnit";
import { LanguageContext } from "../../../../containers/languageprovider";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import {
  API_OFFEROR,
  API_OFFEROR_MATCHING, API_OFFEROR_MATCHING_ROUTE,
  API_OFFEROR_UNITS,
} from "../../../../config/config";
import {
  askingrentlist,
  askingsaleslist,
  DecorateOfferor,
  DecorateOfferorWithSearcher,
  DecorateUnit,
  DecorateUnitWithBuilding, routeReplace,
  sizelistname,
} from "../../../utils";
import Auth from "../../../../auth/auth";
import ProposeBuildingDialog from "../ProposeBuildingDialog/ProposeBuildingDialog";
import DrawerDashBoard from "../../../DrawerDashBoard";
import globalStyles from "../../../globalStyles";
import Footer from "../../../Footer";
import Parameters from "./Parameters";
import Alert from "@material-ui/lab/Alert/Alert";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
/* import {
  sizelistname,
  askingrentlist,
  askingsaleslist,
} from "../../components/utils"; */
import "../../../../css/SearcherArea.css"

const useStyles = makeStyles((theme) => ({
  GridSpacingSM: {
    [theme.breakpoints.down("sm")]: {
      gap: "16px 0px",
    },
  },
  TitleContainer: {
    padding: "16px 24px",
  },
  MenuTitle: {
    fontFamily: "Montserrat",
    fontSize: "36px",
    fontWeight: 600,
  },
  mainContainer: {
    padding: "12px 24px",
  },
  filterSizeLabel: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "32px",
  },
  LengthText: {
    transition: "0.25s ease",
  },
  AnimatedText: {
    animation: `$shake 200ms ${theme.transitions.easing.easeInOut}`,
    transform: "translateX(0%)",
  },
  "@keyframes shake": {
    "0%": {
      transform: "translateX(0%)",
    },
    "25%": {
      transform: "translateX(5px)",
    },
    "50%": {
      transform: "translateX(-5px)",
    },
    "75%": {
      transform: "translateX(5px)",
    },
    "100%": {
      transform: "translateY(0)",
    },
  },
  TableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
  },
  GoBackStep: {
    display: "flex",
    alignItems: "center",
  },
  ProposeBuildingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "17px",
    flexShrink: 0,
    fontWeight: 500,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  BrokerInfoContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  BrokerInfo: {
    fontSize: "15px",
    fontWeight: "500",
    margin: "0",
  },
}));

function Contacts(props) {
  return <>
    {(props.offeror.info?.getTenantPhone() ||
        props.offeror.info?.getTenantEmail()) && (
        <Grid item sm={2} xs={12} >
          <span className={props.classes.BrokerInfo}>
            {props.dictionary.Contacts}:
          </span>
        </Grid>
    )}

    {props.offeror.info?.getTenantPhone() && (
        <Grid
            item
            xs={12}
            sm={3}
            className={props.classes.BrokerInfoContainer}
        >
          <PhoneIcon
              fontSize="small"
              style={{marginRight: "10px"}}
          />
          <span className={props.classes.BrokerInfo}>
            {props.offeror.info?.getTenantPhone()}
          </span>
        </Grid>
    )}

    {props.offeror.info?.getTenantEmail() && (
        <Grid
            item
            xs={12}
            sm={3}
            className={props.classes.BrokerInfoContainer}
        >
          <EmailIcon
              fontSize="small"
              style={{marginRight: "10px"}}
          />
          <span className={props.classes.BrokerInfo}>
            {props.offeror.info?.getTenantEmail()}
          </span>
        </Grid>
    )}
  </>;
}

const OfferedUnits = () => {
  const classes = useStyles();
  const { dictionary } = React.useContext(LanguageContext);

  const globalClasses = globalStyles();

  //getting the id fron the url
  const { id } = useParams();

  //useHistory to redirect the user to the offerors page
  const history = useHistory();

  //Offeror
  const [offeror, setOfferor] = useState({});

  //Units
  const [searcherUnits, setSearcherUnits] = useState([]);

  //Matched Units
  const [matchedUnits, setMatchedUnits] = useState([]);

  //Selected Matched Units
  const [selectedMatchedUnits, setSelectedMatchedUnits] = useState([]);

  //open propose building dialog
  const [openProposeBuildingDialog, setOpenProposeBuildingDialog] =
    useState(false);

  //parameters of the units
  const [location, setLocation] = useState("");
  const [offer, setOffer] = useState("");
  const [size, setSize] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [expanded, setExpanded] = React.useState(false);
  const hiddenForNow = false;

  //open success message
  const [openSuccess, setOpenSuccess] = useState(false);

  const theme = useTheme();
  const xsBreakpoint = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true,
  });
  const smBreakpoint = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const footerRef = useRef();
  

  //close Matching units dialog
  const closeDialogHandler = () => {
    setOpenProposeBuildingDialog(false);
  };

  const clickProposeBuildingHandler = () => {
    axios
      .get(routeReplace(API_OFFEROR_MATCHING_ROUTE ,{offerorId: id}), {
        headers: {
          Authorization: "Bearer " + Auth.getToken(),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const units = res.data.units;
        const unitsIds = res.data.units_ids;
        //decorate the offeror before persisting it
        units.map((unit) => {
          DecorateUnitWithBuilding(unit);
        });
        setMatchedUnits(units);
        setSelectedMatchedUnits(unitsIds);
        setOpenProposeBuildingDialog(true);
      });
  };


  //Go back to the offeror page handler
  const goBackHandler = () => {
    history.push("/offerors");
  };


  /**
   * @deprecated
   * @param deal_type
   * @param units_ids
   * @returns {string}
   */
  const getTheOfferParameter = (deal_type, units_ids) => {
    if (units_ids.length == 0) {
      return;
    } else if (units_ids.length > 1) {
      return deal_type === 1
        ? `${units_ids.length} ${dictionary.Rents}`
        : `${units_ids.length} ${dictionary.sale}`;
    } else {
      const rentItem =
        deal_type === 1
          ? askingrentlist.find((item) => item.id === units_ids[0])?.label
          : askingsaleslist.find((item) => item.id === units_ids[0])?.label;
      return deal_type === 1
        ? `${dictionary.Rent} ${rentItem}`
        : `${units_ids.length} ${dictionary.sale}`;
    }
  };
  const getTheSizeParameter = (size) => {
    if (size.length == 0) {
      return;
    } else if (size.length > 1) {
      return `${size.length} ${dictionary.Size}`;
    } else {
      const sizeItem = sizelistname.find((item) => item.id === size[0])?.label;

      return `${dictionary.Size} ${sizeItem}`;
    }
  };

  //calling the apis to get the data once we got the id
  useEffect(() => {
    axios
      .get(API_OFFEROR_UNITS + id, {
        headers: {
          Authorization: "Bearer " + Auth.getToken(),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const searcherUnits = Object.values(res.data.searcher_units);
        searcherUnits.map((seacherUnit) => {
          DecorateUnitWithBuilding(seacherUnit.unit);
        });
        if (res.data.searcher_units.length === 0) {
          setExpanded(true);
        }
        //change from object of objects to array of objects
        setSearcherUnits(searcherUnits);
        axios
          .get(API_OFFEROR + id, {
            headers: {
              Authorization: "Bearer " + Auth.getToken(),
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            const offeror = res.data;
            //decorate the offeror before persisting it
            DecorateOfferorWithSearcher(offeror);
            // console.log("DecorateOfferorWithSearcher offeror decorated: %o\nDeal:"
            //     , offeror
            //     ,offeror.searcher.info.getSearcherTitle(dictionary));

            setOfferor(offeror);
            // setLocation(offeror.searcher.location);
            // setOffer( offeror.searcher.info.getDealTypeLabel(dictionary) );
            // setSize(offeror.searcher.info.getSizeLabels(dictionary).join(", "));
            // setFromDate(offeror.searcher.info.getFormattedFromDate(dictionary));

            // console.log(
            //   "getTheSizeParameter : ",
            //   getTheSizeParameter(offeror.searcher.size)
            // );
          });
      });
  }, [id]);

  const handleCheckboxChange = (unitId) => (event) => {
    // Toggle the unit's ID in the selectedMatchedUnits array
    const newunitId = unitId.toString();
    setSelectedMatchedUnits((prevSelected) => {
      if (prevSelected.includes(newunitId)) {
        return prevSelected.filter((id) => id !== newunitId);
      } else {
        return [...prevSelected, newunitId];
      }
    });
  };

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const proposeBuidingSelectedClickHandler = () => {
    axios
      .post(
        API_OFFEROR_UNITS + id,
        { units_ids: selectedMatchedUnits },
        {
          headers: {
            Authorization: "Bearer " + Auth.getToken(),
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setOpenProposeBuildingDialog(false);
        // const units = Object.values(res.data.units);
        // console.log("new units : ", units);
        axios
          .get(API_OFFEROR_UNITS + id, {
            headers: {
              Authorization: "Bearer " + Auth.getToken(),
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            const searcherUnits = Object.values(res.data.searcher_units);
            searcherUnits.map((seacherUnit) => {
              DecorateUnitWithBuilding(seacherUnit.unit);
            });
            if (res.data.searcher_units.length === 0) {
              setExpanded(true);
            }
            //change from object of objects to array of objects
            setSearcherUnits(searcherUnits);
            setOpenSuccess(true);
          });
      });
  };


  useEffect(() => {
    setLocation(offeror.searcher?.location);
    if (offeror.searcher?.info.getPriceLabels() != "") {
      setOffer(
        offeror.searcher?.info.getDealTypeLabel(dictionary) +
          " " +
          offeror.searcher?.info.getPriceLabels(dictionary).join(", ")
      );
    } else {
      setOffer(offeror.searcher?.info.getDealTypeLabel(dictionary));
    }
    setSize(offeror.searcher?.info.getSizeLabels(dictionary).join(", "));
    setFromDate(offeror.searcher?.info.getFormattedFromDate(dictionary));
  }, [offeror, dictionary]);

  return (
    <main className={globalClasses.maincontent}>
      <Container maxWidth={false}>
        <Grid container spacing={3} wrap="nowrap" justifyContent="space-between">
          <Hidden smDown implementation="css">
            <div style={{height:"100%", minWidth: "230px", position: "relative"}}>
            <Grid item md={2} xs={12}>
              <DrawerDashBoard sel="offerors"  footerRef={footerRef}/>
            </Grid>
            </div>
          </Hidden>

          <Grid
            item
            md={10}
            xs={12}
            className={globalClasses.ContainerContent}
          >
            <div className={"minHeight100"}>
                <Grid container direction="column">
                  {/* MAIN AREA */}
                  <Grid
                    item
                    style={{ width: "100%" }}
                    className={classes.mainContainer}
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginTop: "12px",
                          marginBottom: "20px",
                        }}
                        justifyContent="space-between"
                      >
                        <Grid item xs={12} md={3}>
                          <div
                            className={classes.GoBackStep}
                            style={{ cursor: "pointer" }}
                            onClick={goBackHandler}
                          >
                            <ArrowBackIcon
                              style={{ marginRight: "10px",  color: "#6a6a6a", fontSize:"24px"}}
                              fontSize={"24px"}
                            />
                            <span
                                style={{
                                  color: "#6a6a6a",
                                  fontWeight: "500",
                                  fontSize: "20px",
                                }}
                            >
                              {offeror.info?.getOfferorTitle(dictionary)}
                            </span>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={3}
                          alignItems="center"
                          className={classes.ProposeBuildingContainer}
                        >
                          <div className={classes.ProposeBuildingContainer}>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ fontSize: "10px" }}
                              onClick={clickProposeBuildingHandler}
                            >
                              <DomainIcon style={{ marginRight: "10px" }} />
                              {dictionary.ProposeBuilding}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                      {!expanded && smBreakpoint &&
                          <Grid container spacing={1} style={{marginBottom: 8,}}>
                          <Contacts offeror={offeror} classes={classes} dictionary={dictionary}/>
                          </Grid>
                      }
                      <Accordion
                        expanded={expanded}
                        onChange={handleChange}
                        style={{ marginBottom: "30px" }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography xs={12} className={classes.heading}>
                            <span style={{ fontWeight: 500}}>{dictionary.RequestDetails}</span>
                          </Typography>

                          {!expanded && !smBreakpoint && (
                            <Grid
                                item
                                container
                                md={7}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "5rem",
                                }}
                                spacing={8}
                            >
                              <Contacts offeror={offeror} classes={classes} dictionary={dictionary}/>
                            </Grid>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid
                            container
                            direction="column"
                            spacing={1}
                            style={{ marginBottom: "30px" }}
                          >
                            {location && (
                              <Grid item>
                                <strong>
                                  {dictionary.LocationUppercaseFirst}:
                                </strong>{" "}
                                {location}
                              </Grid>
                            )}
                            {offer && (
                              <Grid item>
                                <strong>{dictionary.Offer}:</strong> {offer}
                              </Grid>
                            )}
                            {size && (
                              <Grid item>
                                <strong>{dictionary.Size}:</strong> {size}
                              </Grid>
                            )}
                            {fromDate && (
                              <Grid item>
                                <strong>{dictionary.Availability}:</strong>{" "}
                                {fromDate}
                              </Grid>
                            )}
                            {offeror.searcher?.info.getMessage() && (
                              <div>
                                <h3>{dictionary.description + ":"}</h3>
                                <Grid item>
                                  <p>{offeror.searcher.info?.getMessage()}</p>
                                </Grid>
                              </div>
                            )}
                            {!xsBreakpoint &&
                              (offeror.info?.getTenantPhone() ||
                                offeror.info?.getTenantEmail()) && (
                                <Grid
                                  item
                                  style={{
                                    display: "flex",
                                    alignContent: "center",
                                  }}
                                >
                                  <strong style={{ marginRight: "10px" }}>
                                    {" "}
                                    {dictionary.Contacts} :
                                  </strong>

                                  {offeror.info?.getTenantPhone() && (
                                    <>
                                      <PhoneIcon
                                        fontSize="small"
                                        style={{ marginRight: "10px" }}
                                      />
                                      {offeror.info?.getTenantPhone()}
                                    </>
                                  )}
                                  {offeror.info?.getTenantEmail() && (
                                    <>
                                      <EmailIcon
                                        fontSize="small"
                                        style={{
                                          marginRight: "10px",
                                          marginLeft: "10px",
                                        }}
                                      />
                                      {offeror.info?.getTenantEmail()}
                                    </>
                                  )}
                                </Grid>
                              )}
                            {xsBreakpoint &&
                              (offeror.info?.getTenantPhone() ||
                                offeror.info?.getTenantEmail()) && (
                                <>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      alignContent: "center",
                                    }}
                                  >
                                    <strong style={{ marginRight: "10px" }}>
                                      {" "}
                                      {dictionary.Contacts} :
                                    </strong>
                                  </Grid>
                                  <Grid item>
                                    {offeror.info?.getTenantPhone() && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <PhoneIcon
                                          fontSize="small"
                                          style={{ marginRight: "10px" }}
                                        />
                                        {offeror.info?.getTenantPhone()}
                                      </div>
                                    )}
                                  </Grid>
                                  <Grid item>
                                    {offeror.info?.getTenantEmail() && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <EmailIcon
                                          fontSize="small"
                                          style={{
                                            marginRight: "10px",
                                          }}
                                        />
                                        {offeror.info?.getTenantEmail()}
                                      </div>
                                    )}
                                  </Grid>
                                </>
                              )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>

                      <Grid
                        item
                        container
                        xs={12}
                        justifyContent={"left"}
                        spacing={2}
                      >
                        {/*    Table container    */}
                        {searcherUnits.map((searcherUnit) => (
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <OfferedUnit searcherUnit={searcherUnit} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <ProposeBuildingDialog
                  open={openProposeBuildingDialog}
                  closeDialogHandler={closeDialogHandler}
                  matchedUnits={matchedUnits}
                  searcherUnits={searcherUnits}
                  location={location}
                  offer={offer}
                  size={size}
                  fromDate={fromDate}
                  setMatchedUnits={setMatchedUnits}
                  handleCheckboxChange={handleCheckboxChange}
                  selectedMatchedUnits={selectedMatchedUnits}
                  proposeBuidingSelectedClickHandler={
                    proposeBuidingSelectedClickHandler
                  }
                />
            </div>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.paper}>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={() => setOpenSuccess(false)}
        >
          <Alert onClose={() => setOpenSuccess(false)} severity={"success"}>
            {dictionary.ConirmationUnitProposed}
          </Alert>
        </Snackbar>
      </div>
      <div ref={footerRef}>
      <Footer />
      </div>
    </main>
  );
};

export default OfferedUnits;
