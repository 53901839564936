import DateFnsUtils from "@date-io/date-fns";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import itLocale from "date-fns/locale/it";
import React, {
  Fragment,
  lazy,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Route, Switch, useLocation } from "react-router-dom";
//import {NAME_APP} from './config/config';
import { LoadScript } from "@react-google-maps/api";
import TagManager from "react-gtm-module";
import PrivateRoute from "./components/privateroute";
import { directToSubdomain } from "./components/utils";
import {
  ACCESS_LEVEL_LONG_LIST, ACCESS_LEVEL_SEARCHER,
  API_URL,
  BROKER, FEATURE_LONG_LIST, FEATURE_OFFEROR, FEATURE_PORTFOLIO, FEATURE_SEARCHER, FEATURE_TEASER,
  GOOGLE_API_KEY,
  LANDLORD, ROUTE_PORTFOLIO_BUILDING_EDITOR_HP,
  ROUTE_PORTFOLIO_EDIT_BUILDING,
  SUPER_ADMIN,
  tagManagerArgs,
  TENANT,
} from "./config/config";
import { LanguageProvider } from "./containers/languageprovider";
import WhatsappButton from "./components/Whatsapp/WhatsappButton";
import Iubenda from "./components/Iubenda";
import HeaderPublic from "./components/HeaderPublic";
import Offerors from "./components/broker-views/Offerors/Offerors";
import OfferedUnits from "./components/broker-views/Offerors/OfferedUnits/OfferedUnits";
import AddBuilding from "./components/Buildings/AddBuilding/AddBuilding";
import AddUnit from "./components/Units/AddUnit/AddUnit";
import ManageUnits from "./components/Units/ManageUnits/ManageUnits";
import UnitThankYouPage from "./components/Units/UnitThankYouPage/UnitThankYouPage";
import Auth from "./auth/auth";

const LeadsManagement = lazy(() =>
  import("./components/broker-views/LeadsManagement")
);
const LeadsManagementOld = lazy(() =>
  import("./components/broker-views/LeadsManagementOld")
);
const HomePage = lazy(() => import("./components/HomePage"));
const Login = lazy(() => import("./components/login"));
const SignUp = lazy(() => import("./components/signup"));
const Logout = lazy(() => import("./components/logout"));
const SearchResult = lazy(() => import("./components/searchresults"));
const CompareUnits = lazy(() => import("./components/compareunits"));
const CompareBuilding = lazy(() => import("./components/comparebuilding"));
const BuildingPresentation = lazy(() =>
  import("./components/buildingpresentation")
);

const TokenBuildingPresentation = lazy(() =>
  import("./components/BuildingPresentation/TokenBuildingPresentaion")
);
const SlugBuildingPresentation = lazy(() =>
  import("./components/BuildingPresentation/SlugBuildingPresentation")
);
const AllNews = lazy(() => import("./components/AllNews"));
const News = lazy(() => import("./components/News"));
const EditNews = lazy(() => import("./components/EditNews"));
const NewsCategory = lazy(() => import("./components/NewsCategory"));
const ListNews = lazy(() => import("./components/ListNews"));
const Favorites = lazy(() => import("./components/favorites"));
const ProposeBuilding = lazy(() => import("./components/ProposeBuilding"));
const ThankyouPage = lazy(() => import("./components/thankyoupage"));
const Recover = lazy(() => import("./components/recover"));

const ChangePassword = lazy(() => import("./components/changepassword"));
const UsersList = lazy(() => import("./components/usersList"));
const CompaniesList = lazy(() => import("./components/companiesList"));
const UserProfile = lazy(() => import("./components/userprofile"));
const CompanyProfile = lazy(() => import("./components/companyprofile"));
const MacroAreaList = lazy(() => import("./components/MacroAreaList"));
const TechnicalFeaturesList = lazy(() =>
  import("./components/TechnicalFeaturesList")
);
const CommercialFeaturesList = lazy(() =>
  import("./components/CommercialFeaturesList")
);
const PlaceTypeList = lazy(() => import("./components/PlaceTypesList"));
const BuildingLists = lazy(() => import("./components/BuildingsLists"));
const BuildingProfile = lazy(() => import("./components/buildingprofile"));
const UnitProfile = lazy(() => import("./components/unitprofile"));
const AboutUs = lazy(() => import("./components/AboutUs/AboutUs"));
const PortfolioManagementView = lazy(() =>
  import("./components/broker-views/PortfolioManagementView")
);
const LonglistView = lazy(() =>
  import("./components/broker-views/LonglistView")
);
const PropertyAvailabilityView = lazy(() =>
  import("./components/broker-views/PropertyAvailabilityView")
);
const PropertyInspectionView = lazy(() =>
  import("./components/broker-views/PropertyInspectionView")
);

const Searches = lazy(() =>
  import("./components/broker-views/SuperSearcher/Searchers")
);
const SearchUnits = lazy(() =>
  import("./components/broker-views/SuperSearcher/SearchUnits")
);

const SearchersManagement = lazy(() =>
  import("./components/broker-views/SuperAdmin/SearchersManagement")
);

const SearcherDetails = lazy(() =>
  import("./components/broker-views/SuperAdmin/SearcherDetails")
);

const TeaserView = lazy(() =>
  import("./components/broker-views/Teaser/TeaserView")
);

const Services = lazy(() => import("./components/ServicesPage"));

//import Iubenda from 'react-iubenda-policy'

const libraries_google = ["places", "geometry"];

console.originalError = console.error;
console.error = function (...params) {
  const message = params[0] || null;
  const consoleError = (
    typeof message === "string"
      ? ["Warning: ", "Material-UI: "].some((prefix) =>
          message.startsWith(prefix)
        )
      : false
  )
    ? console.warn
    : console.originalError;

  try {
    consoleError(...params);
  } catch (e) {
    console.log("Catch in console.error override:\n%o", e);
  }
};

function App() {
  const urlOrigin = window.location.origin;
  const urlPathName = window.location.pathname;

  const theme = createTheme({
    typography: {
      fontFamily: "Barlow, Arial",
    },
    palette: {
      background: {
        default: "#ffffff",
      },
      primary: {
        light: "#757ce8",
        main: "#1C55E5",
        dark: "#141B4D",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#111111",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
    overrides: {
      MuiDialogTitle: {
        root: {
          paddingTop: "24px",
          paddingBottom: "4px",
          marginBottom: "0px",
        },
      },
      MuiTypography: {
        h6: {
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "24px",
          lineHeight: "31px",
          color: "#000000",
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: "10px",
        },
      },
      MuiDialog: {
        paper: {
          borderRadius: "20px",
        },
      },
      MuiButton: {
        root: {
          textTransform: "none",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "21px",
          lineHeight: "24px",
          borderRadius: "10px",
          Height: "46px",
        },
        text: {
          borderRadius: 50,
        },
      },
      MuiBottomNavigation: {
        root: {
          justifyContent: "flexStart",
        },
      },
      MuiBottomNavigationAction: {
        root: {
          minWidth: "50px",
          width: "64px",
          background: "#F5F5F5",
          borderRadius: "5px",
          margin: "15px",
          "&$selected": {
            background: "rgba(28, 85, 229, 0.1)",
            color: "#1C55E5",
          },
        },
        wrapper: {
          //background:"red",

          fontFamily: "Barlow",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "16px",
          lineHeight: "24px",
          textAlign: "center",
          letterSpacing: "0.03em",
          color: "#6A6A6A",
        },
      },
      CountdownPage: {
        backgroundColor: "yellow",
        backgroundImage: "url(/images/countdown.png)",
        height: "100%",
        position: "absolute",
        left: "0",
        width: "100%",
        overflow: "hidden",
      },
      logo: {
        position: "absolute",
        height: "50px",
        top: "0px",
        left: "0px",
        width: "140px",
      },
      MuiAutocomplete: {
        option: {
          borderTop: "solid 1px #ccc",
        },
      },
    },
  });
  // const MobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const cssVariables = {
    breadCrumbsBar: {
      background:
        "linear-gradient(90deg, #CDE763 0.03%, #C5E464 7.37%, #AEDD67 18.88%, #8AD06B 33.12%, #5AC071 48.72%, #58BD75 59.16%, #52B37F 68.49%, #49A292 77.39%, #3B8AAB 86.03%, #296CCC 94.39%, #1C55E5 99.72%)",
    },
  };

  const refPurposes = React.useRef();

  const cookieListener = React.useCallback((e) => {
    if (e.detail.purposes[5] && e.detail.purposes[4])
      TagManager.initialize(tagManagerArgs);
    else if (
      refPurposes.current &&
      (e.detail.purposes[5] !== refPurposes.current[5] ||
        e.detail.purposes[4] !== refPurposes.current[4])
    ) {
      window.location.reload();
    }
    refPurposes.current = { ...e.detail.purposes };
  }, []);

  const [brokerParams, setBrokerParams] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("cookie-change", cookieListener);
    return () => {
      window.removeEventListener("cookie-change", cookieListener);
    };
  }, [cookieListener]);

  React.useEffect(async () => {
    const urlPrefix = window.location.hostname.split(".")[0];
    if (
      !["dev", "web", "local", "localhost", "d4re", "staging", "www"].includes(
        urlPrefix.toLowerCase()
      )
    ) {
      setBrokerParams(true);
      const res = await directToSubdomain(urlPrefix);
      if (res && window.location.pathname !== "/") {
        window.location.replace("/");
      }
      setBrokerParams(res);
    }
    if (
      ["dev", "web", "local", "localhost", "staging"].includes(
        urlPrefix.toLowerCase()
      )
    ) {
      // console.log("Api URL: %o\nEnv: %o", API_URL, process.env);
    }
    typeof Auth.getUser() === "object" && Auth.refreshUser();
  }, []);

  useEffect(() => {
    if (urlPathName !== "/b") {
      // Set canonical link
      const canonicalLink = document.querySelector('link[rel="canonical"]');
      if (canonicalLink) {
        canonicalLink.href = urlOrigin + urlPathName;
      } else {
        const canonicalLink = document.createElement("link");
        const head = document.head;
        canonicalLink.rel = "canonical";
        canonicalLink.href = urlOrigin + urlPathName;
        head.appendChild(canonicalLink);
      }
    }
    console.log("url path : ", urlPathName);
  }, [urlPathName]);

  /*******************************  Header Public logic ******************************/
  const searchRef = useRef();
  const currentSearchPos = useRef(100);
  const location = useLocation();

  const [headerSearchVisible, setHeaderSearchVisible] = useState(false);
  const onScroll = () => {
    const offsetTop = searchRef.current.getBoundingClientRect().top;
    if (offsetTop < 0 && currentSearchPos.current >= 0) {
      setHeaderSearchVisible(true);
    } else if (offsetTop >= 0 && currentSearchPos.current < 0) {
      setHeaderSearchVisible(false);
    }
    currentSearchPos.current = offsetTop;
  };

  // useEffect(() => {
  //   console.log("location === ", location);
  // }, [location]);

  /* */
  return (
    <LanguageProvider>
      <Iubenda />
      <Helmet>
        <meta
          property="og:image"
          content="https://www.d4re.com/images/slide/slider.jpg"
        />
      </Helmet>
      <ThemeProvider theme={{ ...theme, ...cssVariables }}>
        <CssBaseline />

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
          {!location.pathname.includes("/buildingpresentation") &&
            !location.pathname.includes("/s/") && (
              <HeaderPublic
                Position="fixed"
                AppBarVisible={true}
                breadCrumpsBar={false}
                hideSearch={
                  location.pathname === "/" ? !headerSearchVisible : null
                }
              />
            )}

          <WhatsappButton />
          <Suspense fallback={null}>
            <Switch>
              <Route exact path="/">
                {brokerParams && brokerParams.found && (
                  <LoadScript
                    googleMapsApiKey={GOOGLE_API_KEY}
                    libraries={libraries_google}
                  >
                    <BuildingPresentation {...brokerParams.props} />
                  </LoadScript>
                )}
                {brokerParams && !brokerParams.found && <></>}
                {!brokerParams && (
                  <HomePage onScroll={onScroll} searchRef={searchRef} />
                )}
              </Route>
              <Route path="/thankyou/:slug">
                <ThankyouPage />
              </Route>

              {!brokerParams && (
                <Switch>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/signup">
                    <SignUp />
                  </Route>
                  <Route path="/recoverpassword">
                    <Recover what="password" />
                  </Route>

                  <Route path="/recoverinfo">
                    <Recover what="info" />
                  </Route>

                  <Route path="/changepassword">
                    <ChangePassword />
                  </Route>

                  <Route path="/buildingpresentation/:slug">
                    <LoadScript
                      googleMapsApiKey={GOOGLE_API_KEY}
                      libraries={libraries_google}
                    >
                      <SlugBuildingPresentation />
                    </LoadScript>
                  </Route>

                  <Route path="/s/:token">
                    <LoadScript
                      googleMapsApiKey={GOOGLE_API_KEY}
                      libraries={libraries_google}
                    >
                      <TokenBuildingPresentation />
                    </LoadScript>
                  </Route>

                  <Route path="/b-:lang/:asset_class/:deal_type/:city_country/:slug">
                    <LoadScript
                      googleMapsApiKey={GOOGLE_API_KEY}
                      libraries={libraries_google}
                    >
                      <SlugBuildingPresentation />
                    </LoadScript>
                  </Route>

                  <Route path="/comparebuildings">
                    <CompareBuilding />
                  </Route>

                  <Route path="/compareunits/:id">
                    <CompareUnits />
                  </Route>

                  <Route path="/about-us">
                    <AboutUs />
                  </Route>

                  <Route path="/searchresults">
                    <LoadScript
                      googleMapsApiKey={GOOGLE_API_KEY}
                      libraries={libraries_google}
                    >
                      <SearchResult />
                    </LoadScript>
                  </Route>

                  <Route exact path="/news/:id" render={() => <News />} />
                  <Route exact path="/allnews" render={() => <AllNews />} />

                  <Route
                    exact
                    path="/proposebuilding"
                    render={() => <ProposeBuilding />}
                  />

                  <Route exact path="/services" render={() => <Services />} />

                  <PrivateRoute
                    accesslevel={SUPER_ADMIN}
                    exact
                    path="/userslist"
                    render={() => <UsersList />}
                  />

                  <PrivateRoute
                    accesslevel={SUPER_ADMIN}
                    exact
                    path="/companies"
                    render={() => <CompaniesList />}
                  />

                  <PrivateRoute
                    accesslevel={SUPER_ADMIN}
                    exact
                    path="/placetypes"
                    render={() => <PlaceTypeList />}
                  />
                  <PrivateRoute
                    accesslevel={SUPER_ADMIN}
                    exact
                    path="/commercial_features"
                    render={() => <CommercialFeaturesList />}
                  />
                  <PrivateRoute
                    accesslevel={SUPER_ADMIN}
                    exact
                    path="/technical_features"
                    render={() => <TechnicalFeaturesList />}
                  />
                  <PrivateRoute
                    accesslevel={SUPER_ADMIN}
                    exact
                    path="/macroarea"
                    render={() => <MacroAreaList />}
                  />

                  <PrivateRoute
                    accesslevel={SUPER_ADMIN}
                    exact
                    path="/listnews"
                    render={() => <ListNews />}
                  />
                  <PrivateRoute
                    accesslevel={LANDLORD}
                    path="/editnews/:id"
                    render={() => <EditNews />}
                  />

                  <PrivateRoute
                    accesslevel={LANDLORD}
                    path="/editnews/new"
                    render={() => <EditNews />}
                  />
                  <PrivateRoute
                    accesslevel={SUPER_ADMIN}
                    exact
                    path="/newscategory"
                    render={() => <NewsCategory />}
                  />

                  <PrivateRoute
                    accesslevel={LANDLORD}
                    exact
                    path="/buildings"
                    render={() => <BuildingLists />}
                  />
                  <PrivateRoute
                    accesslevel={BROKER}
                    exact
                    path={ROUTE_PORTFOLIO_EDIT_BUILDING}
                    render={() => (
                      <LoadScript
                        googleMapsApiKey={GOOGLE_API_KEY}
                        libraries={libraries_google}
                      >
                        <AddBuilding />
                      </LoadScript>
                    )}
                  />
                  <PrivateRoute
                    accesslevel={BROKER}
                    exact
                    path={ROUTE_PORTFOLIO_BUILDING_EDITOR_HP}
                    render={() => <ManageUnits />}
                  />
                  <PrivateRoute
                    accesslevel={BROKER}
                    exact
                    path="/buildings/edit/:buildingId/units/:unitId"
                    render={() => <AddUnit />}
                  />
                  <PrivateRoute
                    accesslevel={BROKER}
                    exact
                    path="/buildings/thankyou/:buildingId"
                    render={() => <UnitThankYouPage />}
                  />


                  <PrivateRoute
                    accesslevel={BROKER}
                    path="/units/:building_id/:id/:action?"
                    render={() => <UnitProfile />}
                  />

                  <PrivateRoute
                    accesslevel={BROKER}
                    path="/buildings/:id/:action?"
                    render={() => <BuildingProfile />}
                  />

                  <PrivateRoute
                    accesslevel={BROKER}
                    path="/buildings/new"
                    render={() => <BuildingProfile />}
                  />

                  <PrivateRoute
                    exact
                    path="/favorites"
                    render={() => <Favorites />}
                  />

                  <PrivateRoute
                    exact
                    path="/profile"
                    render={() => <UserProfile />}
                  />

                  <PrivateRoute
                    accesslevel={SUPER_ADMIN}
                    path="/profile/:id"
                    render={() => <UserProfile />}
                  />

                  <PrivateRoute
                    accesslevel={SUPER_ADMIN}
                    path="/companies/:id"
                    render={() => <CompanyProfile />}
                  />

                  <PrivateRoute
                    accesslevel={SUPER_ADMIN}
                    path="/searchers-management/:id"
                    render={() => <SearcherDetails />}
                  />

                  <PrivateRoute
                    accesslevel={SUPER_ADMIN}
                    path="/searchers-management"
                    render={() => <SearchersManagement />}
                  />

                  <PrivateRoute
                    accesslevel={FEATURE_PORTFOLIO}
                    path="/portfolio/:companyId?"
                    render={() => <PortfolioManagementView />}
                  />
                  <PrivateRoute
                    accesslevel={FEATURE_LONG_LIST}
                    path="/longlist/:id?"
                    render={() => <LonglistView />}
                  />

                  <PrivateRoute
                    accesslevel={TENANT}
                    path="/property-inspection"
                    render={() => <PropertyInspectionView />}
                  />
                  <PrivateRoute
                    accesslevel={TENANT}
                    path="/property-availability"
                    render={() => <PropertyAvailabilityView />}
                  />
                  <PrivateRoute
                    accesslevel={FEATURE_SEARCHER}
                    path="/searches/:id"
                    render={() => <SearchUnits />}
                  />
                  <PrivateRoute
                    accesslevel={FEATURE_SEARCHER}
                    path="/searches"
                    render={() => <Searches />}
                  />

                  <PrivateRoute
                    accesslevel={BROKER}
                    path="/leads-management-old"
                    render={() => <LeadsManagementOld />}
                  />
                  <PrivateRoute
                    accesslevel={BROKER}
                    path="/leads-management"
                    render={() => <LeadsManagement />}
                  />
                  <PrivateRoute
                    accesslevel={FEATURE_OFFEROR}
                    path="/offerors/:id"
                    render={() => <OfferedUnits />}
                  />
                  <PrivateRoute
                    accesslevel={FEATURE_OFFEROR}
                    path="/offerors"
                    render={() => <Offerors />}
                  />

                  <PrivateRoute
                    accesslevel={FEATURE_TEASER}
                    path="/teaser/:action?/:id?"
                    render={() => (
                      <LoadScript
                        googleMapsApiKey={GOOGLE_API_KEY}
                        libraries={libraries_google}
                      >
                        <TeaserView />
                      </LoadScript>
                    )}
                  />

                  <PrivateRoute
                    exact
                    path="/logout"
                    render={() => <Logout />}
                  />
                </Switch>
              )}
            </Switch>
          </Suspense>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;

/*
<div className={classes.root}>
      </div>
*/
