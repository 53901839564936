import React, { useState } from "react";
import { Avatar, Popover, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  circle: {
    border: "2px solid black",
    width: "18px",
    height: "18px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginTop: "5px",
    backgroundColor: "transparent",
    color: "#212121",
    fontSize: "12px",
  },
  popover: {
    fontSize: "11px",
    width: "120px",
  },
}));

const CirclePopper = ({ text }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? "circle-popper" : undefined;

  return (
    <>
      <Avatar
        className={classes.circle}
        onClick={handleClick}
        style={{ fontWeight: "bold" }}
      >
        i
      </Avatar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Typography style={{ padding: 5 }} className={classes.popover}>
          {text}
        </Typography>
      </Popover>
    </>
  );
};

export default CirclePopper;
