import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Snackbar, makeStyles, Grid, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { LanguageContext } from "../../containers/languageprovider";
import "../../css/EditHtmlStlyes.css";

const useStyles = makeStyles((theme) => ({
  SectionContainer: {
    marginTop: theme.spacing(2),
  },
  boxHtml: {
    minHeight: "300px",
    borderRadius: "5px",
    overflow: "auto",
    width: 1,
    border: "1px solid lightgray",
    padding: "5px",
  },
  LengthText: {
    transition: "0.25s ease",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function RichTextEditor(props) {
  const { dictionary, userLanguage } = React.useContext(LanguageContext);
  const contentBlock = htmlToDraft(
    props.editval === undefined ? "" : props.editval
  );
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(contentState)
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertseverity, setAlertseverity] = useState("success");
  const [alertmsg, setAlertmsg] = useState("");
  const classes = useStyles();

  const MAX_LENGTH = +props.maxLength;

  const handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;

    if (currentContentLength > MAX_LENGTH - 1) {
      setAlertmsg(
        `${dictionary.TeaserCommentMaxCharWarning1} ${MAX_LENGTH} ${dictionary.TeaserCommentMaxCharWarning2}`
      );
      setAlertseverity("warning");
      setAlertOpen(true);

      return "handled";
    }
  };

  const handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;

    if (currentContentLength + pastedText.length > MAX_LENGTH) {
      setAlertmsg(
        `${dictionary.TeaserCommentMaxCharWarning1} ${MAX_LENGTH} ${dictionary.TeaserCommentMaxCharWarning2}`
      );
      setAlertseverity("warning");
      setAlertOpen(true);

      return "handled";
    }
  };

  const updateTextDescription = (state) => {
    setEditorState(state);
    const data = convertToRaw(state.getCurrentContent());
    props.onChange(draftToHtml(data));
  };

  React.useEffect(() => {
    const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (data !== props.editval) {
      const cB = htmlToDraft(props.editval === undefined ? "" : props.editval);
      const cS = ContentState.createFromBlockArray(cB.contentBlocks);
      setEditorState(EditorState.createWithContent(cS));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editval]);

  const stripHTML = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    let text = tmp.textContent || tmp.innerText || "";
    return text.trim();
  };

  return (
    <>
      <Grid item container xs={12} className={classes.SectionContainer}>
        <Grid
          item
          xs={true}
          className={props.style ? props.style : classes.boxHtml}
          container
          style={{ border: `${props.error ? "1px solid red" : ""}` }}
        >
          <Grid item xs={12} style={{ overflow: "hidden" }}>
            <Editor
              toolbar={{
                options: [
                  "inline",
                  "list",
                  "textAlign",
                  "colorPicker",
                  "link",
                  "embedded",
                  "remove",
                  "history",
                ],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={updateTextDescription}
              handleBeforeInput={handleBeforeInput}
              handlePastedText={handlePastedText}
              placeholder={props.placeholder}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} spacing={3} container justifyContent="flex-end">
        <Grid item>
          <Typography
            className={classes.LengthText}
            style={{
              color:
                stripHTML(props.editval).length >= MAX_LENGTH
                  ? "red"
                  : "black",
            }}
          >
            {`${
              stripHTML(props.editval).length === MAX_LENGTH ? 
                dictionary.FieldLimitReached : " "
            } ${
                stripHTML(props.editval).length > MAX_LENGTH ?
                 dictionary.FieldLimitExceeded : " "
            } ${stripHTML(props.editval).length} / ${MAX_LENGTH}`}
          </Typography>
        </Grid>
      </Grid>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert onClose={() => setAlertOpen(false)} severity={alertseverity}>
          {alertmsg}
        </Alert>
      </Snackbar>
    </>
  );
}
