import { Checkbox, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Carousel } from "react-responsive-carousel";
import {Link as RouterLink, useHistory} from "react-router-dom";
import { LanguageContext } from "../../../../containers/languageprovider";
import globalStyles from "../../../globalStyles";
import { EuroIcon, SolutionIcon } from "../../../iconlist.js";
import { returnStringTranslation } from "../../../printLanguageSelect";
import {routeReplace} from "../../../utils";
import {ROUTE_TEASER_EDIT_ITEM} from "../../../../config/config";

const useStyles = makeStyles((theme) => ({
  CardBuilding: {
    background: "transparent",
  },
  ArrowsCarousel: {
    width: "20px",
    height: "20px",
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 25px)",
    cursor: "pointer",
  },
  GridInfo: {
    minHeight: "60px",
    width: "100%",
  },
  InfoPrice: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000000",
    border: "none",
    margin: "-6px",
    cursor: "pointer",
  },
  InfoCity: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03em",
    fontVariant: "all-small-caps",
    color: "#FFFFFF",
    background: theme.breadCrumbsBar.background,
    textAlign: "left",
    paddingLeft: "20px",
  },
  TitleCard: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "left",
    color: "#000000",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  SubTitleCard: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "left",
    color: "#000000",
  },
  AddressCard: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    maxWidth:"400px",
    maxHeight:"20px",
    textOverflow: "ellipsis",
    overflow:"hidden",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "24px",
    textAlign: "left",
    color: "#000000",
  },
}));

export default function MatchedUnit({
  unit,
  handleCheckboxChange,
  selectedMatchedUnits,
  searcherUnits,
  MobileScreen,
}) {
  const classes = useStyles();
  const { dictionary, userLanguage } = React.useContext(LanguageContext);
  const [unitImages, setUnitImages] = useState([]);
  const history = useHistory();

  useEffect(() => {
    //use the building images if the units has no images
    const images =
      unit.images.length > 0 ? unit.images : unit.building.building_photos;


    setUnitImages(images);
  }, [unit]);


  return (
    <Card className={classes.CardBuilding}>
      {unitImages !== null && unitImages.length > 0 && (
        <div
          style={{
            height: "200px",
            objectFit: "cover",
          }}
        >
          <img
            loading={"lazy"}
            style={{
              height: "200px",
              objectFit: "cover",
            }}
            src={unitImages[0].image}
            alt={returnStringTranslation(
              unitImages[0].description,
              userLanguage
            )}
          />
        </div>
      )}

      {(!unitImages || unitImages.length === 0) && (
          <div
              style={{
                height: "200px",
                objectFit: "cover",
              }}
          >
            <img
                loading={"lazy"}
                style={{

                  height: "200px",
                  objectFit: "cover",
                }}
                src="/images/placeholder.png"
                alt="Building PlaceHolder"
            />
          </div>
      )}

      <CardContent style={{ background: "white", height: "190px" }}>
        <Grid container spacing={3}>
          <Grid item md={10} xs={10}>
            <Typography
              className={classes.SubTitleCard}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {unit.info.getAssetForDeal(dictionary)}
            </Typography>
            <Link
              style={{ textDecoration: "none", cursor: "pointer" }}
              component={RouterLink}
              color="inherit"
              target={"_blank"}


              to={"#"}
              onClick={async (event) => {
                event.preventDefault();
                let url = routeReplace(ROUTE_TEASER_EDIT_ITEM,{teaserId:unit.building.id });
                if (unit.building.slug) {
                  url = await unit.building.info.getShareUrlPrivate();
                }
                if (MobileScreen) {
                  history.push(url);
                } else {
                  window.open(url, "_blank");
                }
                return;

              }}

              // to={{
              //   pathname: unit.building.info.getUrl(),
              //   state: {
              //     prev: window.location.pathname,
              //     buildingId: unit.building.id,
              //   },
              // }}

            >

              <Typography
                className={classes.TitleCard}
                gutterBottom
                variant="h5"
                component="h2"
              >
                {returnStringTranslation(
                    unit.building.name,
                    userLanguage
                )}
              </Typography>
              <Typography
                  className={classes.AddressCard}
                  variant="body2"
                  color="textSecondary"
                  component="p"
              >
                {unit.building.detailAddress.getFormattedAddress(userLanguage)}
              </Typography>
            </Link>
          </Grid>
          <Grid item md={2} xs={2}>
            <Checkbox
              size="small"
              onChange={handleCheckboxChange(unit.id)}
              checked={selectedMatchedUnits.includes(`${unit.id}`)}
              disabled={searcherUnits.some(
                (searcherUnit) => searcherUnit.unit_id === unit.id
              )}
            />
          </Grid>
        </Grid>
        <Grid className={classes.GridInfo} container spacing={2}>
          <Grid item xs={12}>
            {unit.size && (
              <Chip
                className={classes.InfoPrice}
                variant="outlined"
                size="medium"
                icon={<SolutionIcon />}
                label={unit.size + " m²"}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            {unit.asking_rent && (
              <Chip
                className={classes.InfoPrice}
                variant="outlined"
                size="medium"
                icon={<EuroIcon />}
                label={
                  <NumberFormat
                    value={unit.asking_rent}
                    displayType={"text"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    suffix={" " + dictionary.MqYear}
                    prefix={" € "}
                  />
                }
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
