import { createSlice } from "@reduxjs/toolkit";
import { languageOptions } from "../components/languages";
import { FLOOR_GROUNDFLOOR } from "../config/config";

const description = {};
// Dynamically generate properties based on languageOptions keys
Object.keys(languageOptions).forEach((key) => {
  description[key] = "";
});

// Define the initial data
const initialData = {
  // unit description
  description: description,
  images: [],
  virtual_tour: "",
  video: "",
  attachements: [],

  // unit informations

  // specifics
  floor: FLOOR_GROUNDFLOOR,
  asset_class: 1,
  size: 0,
  type_size: 3,
  price_type: 1,
  service_charges: 0,

  // transaction type
  deal_type: 1,
  price_unit: "",
  price: 0, //lease or sale price
  sublet: "",
  coworking: "",
  investment: "",
  business: "",

  // exclusive areas
  size_archive: null,
  size_terraces: null,
  divisible: "",

  // unit status
  state: 1, //online status and it's read only
  listing_date: null,
  renovation_status: 0,
  renovationdate: null,
  renovationgrade: 0,

  // unit features
  technical_features: [],
  commercial_features: [],

  // Mandate Agreement

  mandate_agreement: 1,
  mandate_broker: [],

  //broker
  brokers: [],

  //Unit is new
  unitIsNew: false,
};

const UnitSlice = createSlice({
  name: "unit",
  initialState: initialData,
  reducers: {
    // Reset to the initial state
    resetUnitSlice: () => initialData,
    updateUnitSlice: (state, action) => {
      const { payload } = action;
      const allowedKeys = Object.keys(state);
      const updatedState = { ...state };

      for (const key in payload) {
        if (allowedKeys.includes(key) && payload[key] !== null) {
          updatedState[key] = payload[key];
        }
      }

      return updatedState;
    },

    //unit description

    updateUnitTextDescription: (state, action) => {
      const { language, textDescription } = action.payload;
      state.description[language] = textDescription;
    },
    updateUnitImages: (state, action) => {
      const { payload } = action;
      state.images = payload;
    },
    updateUnitLinkVirualTour: (state, action) => {
      const { payload } = action;
      state.virtual_tour = payload;
    },
    updateUnitLinkVideo: (state, action) => {
      const { payload } = action;
      state.video = payload;
    },
    updateUnitAttachements: (state, action) => {
      const { payload } = action;
      state.attachements = payload;
    },

    //unit informations

    //Specifics
    updateUnitFloor: (state, action) => {
      const { payload } = action;
      state.floor = payload;
    },
    updateUnitAssetClass: (state, action) => {
      const { payload } = action;
      state.asset_class = payload;
    },
    updateUnitTotalSize: (state, action) => {
      const { payload } = action;
      state.size = payload;
    },
    updateUnitSizeType: (state, action) => {
      const { payload } = action;
      state.type_size = payload;
    },
    updateUnitPriceType: (state, action) => {
      const { payload } = action;
      state.price_type = payload;
    },
    updateUnitServiceCharges: (state, action) => {
      const { payload } = action;
      state.service_charges = payload;
    },

    //transaction type

    updateUnitDealType: (state, action) => {
      const { payload } = action;
      state.deal_type = payload;
    },
    updateUnitPriceUnit: (state, action) => {
      // console.log("updating pice unit", action)
      const { payload } = action;
      state.price_unit = payload;
    },
    updateUnitPrice: (state, action) => {
      const { payload } = action;
      // console.log("price updating", action)
      state.price = payload;
    },
    updateUnitSublet: (state, action) => {
      const { payload } = action;
      state.sublet = payload;
    },
    updateUnitCoworking: (state, action) => {
      const { payload } = action;
      state.coworking = payload;
    },
    updateUnitInvestment: (state, action) => {
      const { payload } = action;
      state.investment = payload;
    },
    updateUnitBusiness: (state, action) => {
      const { payload } = action;
      state.business = payload;
    },

    //exclusive areas

    updateUnitSizeArchive: (state, action) => {
      const { payload } = action;
      state.size_archive = payload;
    },
    updateUnitSizeTerraces: (state, action) => {
      const { payload } = action;
      state.size_terraces = payload;
    },
    updateUnitDivisible: (state, action) => {
      const { payload } = action;
      state.divisible = payload;
    },

    //unit status

    updateUnitState: (state, action) => {
      const { payload } = action;
      state.state = payload;
    },
    updateUnitListingDate: (state, action) => {
      const { payload } = action;
      state.listing_date = payload;
    },
    updateUnitRenovationStatus: (state, action) => {
      const { payload } = action;
      state.renovation_status = payload;
    },
    updateUnitRenovationDate: (state, action) => {
      const { payload } = action;
      state.renovationdate = payload;
    },
    updateUnitRenovationGrade: (state, action) => {
      const { payload } = action;
      state.renovationgrade = payload;
    },

    //unit features

    updateUnitTechnicalFeatures: (state, action) => {
      const { payload } = action;
      state.technical_features = payload;
    },
    updateUnitCommercialFeatures: (state, action) => {
      const { payload } = action;
      state.commercial_features = payload;
    },

    //Mandate Agreement

    updateUnitMandateAgreement: (state, action) => {
      const { payload } = action;
      state.mandate_agreement = payload;
    },
    updateUnitMandateBroker: (state, action) => {
      const { payload } = action;
      state.mandate_broker = payload;
    },

    //broker
    updateUnitBroker: (state, action) => {
      const { payload } = action;
      state.brokers = payload;
    },

    //Unit is new
    updateUnitIsNew: (state, action) => {
      const { payload } = action;
      state.unitIsNew = payload;
    },
  },
});

export const {
  //reset unit data
  resetUnitSlice,

  //unit slice
  updateUnitSlice,

  //unit description
  updateUnitImages,
  updateUnitLinkVirualTour,
  updateUnitLinkVideo,
  updateUnitTextDescription,
  updateUnitAttachements,

  //unit informations

  updateUnitFloor,
  updateUnitAssetClass,
  updateUnitTotalSize,
  updateUnitSizeType,
  updateUnitPriceType,
  updateUnitServiceCharges,

  //transaction type

  updateUnitDealType,
  updateUnitPriceUnit,
  updateUnitPrice,
  updateUnitSublet,
  updateUnitCoworking,
  updateUnitInvestment,
  updateUnitBusiness,

  //exclusive areas

  updateUnitSizeArchive,
  updateUnitSizeTerraces,
  updateUnitDivisible,

  //unit status

  updateUnitState,
  updateUnitListingDate,
  updateUnitRenovationStatus,
  updateUnitRenovationDate,
  updateUnitRenovationGrade,

  //unit features

  updateUnitTechnicalFeatures,
  updateUnitCommercialFeatures,

  //Certifications

  updateUnitMandateAgreement,
  updateUnitMandateBroker,

  //broker
  updateUnitBroker,

  //Unit is new
  updateUnitIsNew,
} = UnitSlice.actions;

export default UnitSlice.reducer;
