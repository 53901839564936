import React from "react";
import {
  Typography, useMediaQuery,
} from "@material-ui/core";
import MatchedUnit from "./MatchedUnit";
import { Grid } from "@material-ui/core";
import {LanguageContext} from "../../../../containers/languageprovider";
import {useTheme} from "@material-ui/core/styles";

const MatchedUnits = ({
  matchedUnits = [],
  searcherUnits,
  handleCheckboxChange,
  selectedMatchedUnits,
  location,
}) => {
  const { dictionary } = React.useContext(LanguageContext);
  const theme = useTheme();
  const MobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid
        container
        spacing={2}
        justifyContent="left"
    >
      {matchedUnits.length === 0 &&
          <Grid item xs={11}>
          <Typography>
            {dictionary.NoMatchesInPortfolio1 + location + dictionary.NoMatchesInPortfolio2}
          </Typography>
          </Grid>
      }
      {matchedUnits.map((matchedUnit, i) => (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
        >
          <MatchedUnit
            key={i}
            unit={matchedUnit}
            searcherUnits={searcherUnits}
            handleCheckboxChange={handleCheckboxChange}
            selectedMatchedUnits={selectedMatchedUnits}
            MobileScreen={MobileScreen}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default MatchedUnits;
