import React from "react";

const CloseButton = ({closeWhatsappIconHandler}) => {
  return (
    <span className="closeWhatsappButton" onClick={closeWhatsappIconHandler}>
      X
    </span>
  );
};

export default CloseButton;