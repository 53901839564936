import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CloseIcon from "@material-ui/icons/Close";
import { Link as RouterLink, useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import globalStyles from "./globalStyles";
import { LanguageContext } from "../containers/languageprovider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { FacebookIcon, LinkedinIcon, PersonSvgIcon } from "./iconlist.js";

import { BROKER, TENANT } from "../config/config";
import Auth from "../auth/auth";
import { Divider, Grid, Typography } from "@material-ui/core";
import LanguageSelector from "./LanguageSelector";

const useStyles = makeStyles((theme) => ({
  DrawerText: {
    fontSize: "21px",
    fontFamily: "Barlow",
    cursor: "pointer",
    color: "#6a6a6a",
    padding: "4px 12px",
    margin: "5px 0",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  root: {
    marginLeft: 12,
    "&.Mui-selected": {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
    "&.Mui-selected p": {
      backgroundColor: "#1C55E5 !important",
      borderRadius: "10px",
      color: "white !important",
      "& path": {
        fill: "white",
      },
      "&:hover": {
        backgroundColor: "#1C55E599 !important",
      },
    },
  },

  DialogContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 20,
    marginBottom: 20,
  },

  Inset: {
    paddingLeft: "28px",
    paddingRight: "28px",
  },
  Divider: {
    width: "100%",
    height: "1px",
    background: "#D7D7D7",
    marginTop: "10px !important",
    marginBottom: "10px !important",
  },
  GradientDivider: {
    height: "6px",
    width: "100%",
    boxShadow: "0px 3px 6px #0000004D",
    background: theme.breadCrumbsBar.background,
  },
}));

export default function DrawerMenu(props) {
  const classes = useStyles();
  const { dictionary } = React.useContext(LanguageContext);
  const user = Auth.getUser();
  const globalClasses = globalStyles();
  const history = useHistory();

  const path = window.location.pathname;
  const hideProfile = false

  const pathName = path.substring(path.lastIndexOf("/") + 1);

  const isTenantOrBroker = user?.role === 2 || user?.role === 1;

  return (
    <div className={globalClasses.FlexColumn}>
      <DialogTitle disableTypography className={globalClasses.drawerTitle}>
        <RouterLink to="/" style={{ paddingLeft: "10px" }}>
          <img src="/images/logo.svg" alt="logo" height="32" />
        </RouterLink>
        <IconButton onClick={props.handleDrawerToggle}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div className={classes.GradientDivider} />

      <DialogContent className={classes.DialogContent}>
        <List className={globalClasses.listButtonsMenu}>
          {user ? (
            <Fragment>
              <RouterLink
                onClick={props.handleDrawerToggle}
                to="/proposebuilding"
                className={globalClasses.InheritLink}
              >
                <ListItem style={{ paddingLeft: "40px" }}>
                  <PersonSvgIcon
                    style={{ marginRight: 10 }}
                    fill="#1C55E5"
                    viewBox={"0 0 18 24"}
                  />
                  <Typography
                    className={globalClasses.HeaderButtonName}
                    style={{ color: "#1C55E5" }}
                  >
                    {user.name}
                  </Typography>
                </ListItem>
              </RouterLink>
              <ListItem>
                <Divider orientation="horizontal" className={classes.Divider} />
              </ListItem>

              {user && user.role === TENANT && (
                <ListItem
                  selected={pathName === "searches"}
                  classes={{ root: classes.root }}
                >
                  <Typography className={classes.DrawerText}>
                    <RouterLink
                      onClick={props.handleDrawerToggle}
                      className={globalClasses.InheritLink}
                      to="/searches"
                    >
                      {dictionary.Searches}
                    </RouterLink>
                  </Typography>
                </ListItem>
              )}

              {user !== undefined && user !== null && user.role === TENANT && (
                <ListItem
                  selected={pathName === "property-availability"}
                  classes={{ root: classes.root }}
                >
                  <Typography className={classes.DrawerText}>
                    <RouterLink
                      onClick={props.handleDrawerToggle}
                      className={globalClasses.InheritLink}
                      to="/property-availability"
                    >
                      {dictionary.PropertyAvailability}
                    </RouterLink>
                  </Typography>
                </ListItem>
              )}

              {hideProfile && user && !isTenantOrBroker && (
                <ListItem
                  selected={pathName === "profile"}
                  classes={{ root: classes.root }}
                >
                  <Typography className={classes.DrawerText}>
                    <RouterLink
                      onClick={props.handleDrawerToggle}
                      className={globalClasses.InheritLink}
                      to="/proposebuilding"
                    >
                      {dictionary.Profile}
                    </RouterLink>
                  </Typography>
                </ListItem>
              )}
              {user.role >= BROKER && (
                <Fragment>
                  <ListItem
                    selected={pathName === "offerors"}
                    classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                        onClick={props.handleDrawerToggle}
                        className={globalClasses.InheritLink}
                        to="/offerors"
                      >
                        {dictionary.Requests}
                      </RouterLink>
                    </Typography>
                  </ListItem>
                  <ListItem
                    selected={pathName === "portfolio"}
                    classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                        onClick={props.handleDrawerToggle}
                        className={globalClasses.InheritLink}
                        to="/portfolio"
                      >
                        {dictionary.PortfolioMenu}
                      </RouterLink>
                    </Typography>
                  </ListItem>

                  <ListItem
                    selected={pathName === "teaser"}
                    classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                        onClick={props.handleDrawerToggle}
                        className={globalClasses.InheritLink}
                        to="/teaser/manage"
                      >
                        {dictionary.TeaserMenu}
                      </RouterLink>
                    </Typography>
                  </ListItem>

                  <ListItem
                    selected={pathName === "longlist"}
                    classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                        onClick={props.handleDrawerToggle}
                        className={globalClasses.InheritLink}
                        to="/longlist"
                      >
                        {dictionary.LonglistCreate}
                      </RouterLink>
                    </Typography>
                  </ListItem>

                  <ListItem
                    selected={pathName === "leads-management"}
                    classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                        onClick={props.handleDrawerToggle}
                        className={globalClasses.InheritLink}
                        to="/leads-management"
                      >
                        {dictionary.LeadsManagement}
                      </RouterLink>
                    </Typography>
                  </ListItem>

                  {/* {user !== undefined && user !== null && user.role >= TENANT && (
                    <ListItem
                      selected={pathName === "property-inspection"}
                      classes={{ root: classes.root }}
                    >
                      <Typography className={classes.DrawerText}>
                        <RouterLink
                          className={globalClasses.InheritLink}
                          to="/property-inspection"
                        >
                          {dictionary.PropertyInspection}
                        </RouterLink>
                      </Typography>
                    </ListItem>
                  )} */}
                  {/*<ListItem*/}
                  {/*  selected={pathName === "leads-management"}*/}
                  {/*  classes={{ root: classes.root }}*/}
                  {/*>*/}
                  {/*  <Typography className={classes.DrawerText}>*/}
                  {/*    <RouterLink*/}
                  {/*      className={globalClasses.InheritLink}*/}
                  {/*      to="/leads-management"*/}
                  {/*    >*/}
                  {/*      {dictionary.LeadsManagementMenu}*/}
                  {/*    </RouterLink>*/}
                  {/*  </Typography>*/}
                  {/*</ListItem>*/}
                </Fragment>
              )}

              {user !== undefined && user !== null && user.role > BROKER && (
                <>
                  <ListItem
                      selected={pathName === "searchers-management"}
                      classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                          onClick={props.handleDrawerToggle}
                          className={globalClasses.InheritLink}
                          to="/searchers-management"
                      >
                        {dictionary.SearchersManagement}
                      </RouterLink>
                    </Typography>
                  </ListItem>
                  <ListItem
                    selected={pathName === "userslist"}
                    classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                        onClick={props.handleDrawerToggle}
                        className={globalClasses.InheritLink}
                        to="/userslist"
                      >
                        {dictionary.Users}
                      </RouterLink>
                    </Typography>
                  </ListItem>
                  <ListItem
                    selected={pathName === "companies"}
                    classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                        onClick={props.handleDrawerToggle}
                        className={globalClasses.InheritLink}
                        to="/companies"
                      >
                        {dictionary.Companies}
                      </RouterLink>
                    </Typography>
                  </ListItem>
                  <Divider />

                  <ListItem
                    selected={pathName === "buildings"}
                    classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                        onClick={props.handleDrawerToggle}
                        className={globalClasses.InheritLink}
                        to="/buildings"
                      >
                        {dictionary.Buildings}
                      </RouterLink>
                    </Typography>
                  </ListItem>
                  <Divider />
                  <ListItem
                    selected={pathName === "macroarea"}
                    classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                        onClick={props.handleDrawerToggle}
                        className={globalClasses.InheritLink}
                        to="/macroarea"
                      >
                        {dictionary.MacroArea}
                      </RouterLink>
                    </Typography>
                  </ListItem>

                  <ListItem
                    selected={pathName === "commercial_features"}
                    classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                        onClick={props.handleDrawerToggle}
                        className={globalClasses.InheritLink}
                        to="/commercial_features"
                      >
                        {dictionary.commercial_features}
                      </RouterLink>
                    </Typography>
                  </ListItem>

                  <ListItem
                    selected={pathName === "technical_features"}
                    classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                        onClick={props.handleDrawerToggle}
                        className={globalClasses.InheritLink}
                        to="/technical_features"
                      >
                        {dictionary.technical_features}
                      </RouterLink>
                    </Typography>
                  </ListItem>

                  <ListItem
                    selected={pathName === "placetypes"}
                    classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                        onClick={props.handleDrawerToggle}
                        className={globalClasses.InheritLink}
                        to="/placetypes"
                      >
                        {dictionary.PlaceTypes}
                      </RouterLink>
                    </Typography>
                  </ListItem>

                  <Divider />

                  <ListItem
                    selected={pathName === "listnews"}
                    classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                        onClick={props.handleDrawerToggle}
                        className={globalClasses.InheritLink}
                        to="/listnews"
                      >
                        {dictionary.News}
                      </RouterLink>
                    </Typography>
                  </ListItem>

                  <ListItem
                    selected={pathName === "newscategory"}
                    classes={{ root: classes.root }}
                  >
                    <Typography className={classes.DrawerText}>
                      <RouterLink
                        onClick={props.handleDrawerToggle}
                        className={globalClasses.InheritLink}
                        to="/newscategory"
                      >
                        {dictionary.NewsCategory}
                      </RouterLink>
                    </Typography>
                  </ListItem>
                </>
              )}

              <Divider />

              <ListItem classes={{ root: classes.root }}>
                <Typography
                  onClick={() => {
                    props.handleDrawerToggle();
                    history.push("/changepassword");
                  }}
                  className={classes.DrawerText}
                >
                  {dictionary.ChangePassword}
                </Typography>
              </ListItem>
              {user.role !== BROKER && (
                <ListItem
                  selected={pathName === "favorites"}
                  classes={{ root: classes.root }}
                >
                  <Typography className={classes.DrawerText}>
                    <RouterLink
                      onClick={props.handleDrawerToggle}
                      className={globalClasses.InheritLink}
                      to="/favorites"
                    >
                      {dictionary.Favorites}
                    </RouterLink>
                  </Typography>
                </ListItem>
              )}
              <ListItem classes={{ root: classes.root }}>
                <Typography
                  onClick={props.onClickLogout}
                  className={classes.DrawerText}
                >
                  {dictionary.Logout}
                </Typography>
              </ListItem>
            </Fragment>
          ) : (
            <Fragment>
              <ListItem classes={{ root: classes.root }}>
                <Typography
                  onClick={() => {props.handleLoginAction("signup")}}
                  className={classes.DrawerText}
                >
                  {dictionary.Register}
                </Typography>
              </ListItem>

              <ListItem classes={{ root: classes.root }}>
                <Typography
                  onClick={() => {props.handleLoginAction("login")}}
                  className={classes.DrawerText}
                >
                  {dictionary.Login}
                </Typography>
              </ListItem>
            </Fragment>
          )}
          <ListItem>
            <Divider orientation="horizontal" className={classes.Divider} />
          </ListItem>
          <ListItem
            selected={pathName === "services"}
            classes={{ root: classes.root }}
          >
            <Typography className={classes.DrawerText}>
              <RouterLink
                onClick={props.handleDrawerToggle}
                className={globalClasses.InheritLink}
                to={{ pathname: "/services", state: { scroll: true } }}
              >
                {dictionary.Service}
              </RouterLink>
            </Typography>
          </ListItem>
          <ListItem
            selected={pathName === "about-us"}
            classes={{ root: classes.root }}
          >
            <Typography className={classes.DrawerText}>
              <RouterLink
                onClick={props.handleDrawerToggle}
                className={globalClasses.InheritLink}
                to={{ pathname: "/about-us", state: { scroll: true } }}
              >
                {dictionary.AboutUs}
              </RouterLink>
            </Typography>
          </ListItem>
          <ListItem
            selected={pathName === "allNews"}
            classes={{ root: classes.root }}
          >
            <Typography className={classes.DrawerText}>
              <RouterLink
                onClick={props.handleDrawerToggle}
                className={globalClasses.InheritLink}
                to={{ pathname: "https://blog.d4re.com/" }}
                target="_blank"
              >
                {dictionary.blog}
              </RouterLink>
            </Typography>
          </ListItem>
        </List>
        <List className={globalClasses.listButtonsMenu}>
          <ListItem className={classes.Inset}>
            <Button
              className={globalClasses.buttonSecondary}
              style={{ width: "100%", height: "46px" }}
              variant="outlined"
              onClick={props.onClickSearch}
              color="primary"
            >
              {dictionary.StartNewSearch}
            </Button>
          </ListItem>
          <ListItem className={classes.Inset}>
            <Button
              className={globalClasses.buttonPropose}
              variant="outlined"
              color="primary"
            >
              <RouterLink
                onClick={props.handleDrawerToggle}
                className={globalClasses.InheritLink}
                to="/proposebuilding"
              >
                {dictionary.ProposeBuilding}
              </RouterLink>
            </Button>
          </ListItem>
          <ListItem>
            <Divider orientation="horizontal" className={classes.Divider} />
          </ListItem>
          <ListItem className={classes.Inset}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  style={{
                    background: "#6a6a6a88",
                    minWidth: 0,
                    marginRight: 10,
                  }}
                >
                  <a
                    href="https://facebook.com/d4re.srl"
                    rel="noreferrer"
                    target="_blank"
                    className={globalClasses.InheritLink}
                  >
                    <FacebookIcon
                      fill="white"
                      style={{ overflow: "visible" }}
                      viewBox="-5 -4 22 22"
                    />
                  </a>
                </Button>
                <Button style={{ background: "#6a6a6a88", minWidth: 0 }}>
                  <a
                    href="https://linkedin.com/company/d4re/"
                    rel="noreferrer"
                    target="_blank"
                    className={globalClasses.InheritLink}
                  >
                    <LinkedinIcon
                      fill="white"
                      style={{ overflow: "visible" }}
                      viewBox="-2 -4 24 24"
                    />
                  </a>
                </Button>
              </Grid>
              <Grid item>
                <LanguageSelector ShowCode />
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </DialogContent>
    </div>
  );
}
