import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ClearOutlined } from "@material-ui/icons";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { ContactEmailIcon, PropertyIcon } from "../../../iconlist";
import {
  API_URL_ALLCOMPANIES,
  PROPOSE_BUILDING_CHANGE,
  TERMS_AND_CONDITIONS_URL_EN,
  TERMS_AND_CONDITIONS_URL_IT,
} from "../../../../config/config";
import { Alert } from "@material-ui/lab";
import { LanguageContext } from "../../../../containers/languageprovider";
import axios from "axios";
import Auth from "../../../../auth/auth";

const useStyles = makeStyles((theme) => ({
  submit: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",

    letterSpacing: "0.03em",
    fontVariant: "small-caps",

    color: "#FFFFFF",
  },

  SubTitle: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#000000",
    marginBottom: "51px",
    marginTop: "20px",
  },

  Icon: {
    //paddingTop:"12px",
    marginRight: 24,
    width: "20px",
    height: "18px",
  },

  SectionTitle: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "21px",
    fontFeatureSettings: "'tnum' on, 'lnum' on",
    color: "#000000",
  },
}));

const AddNewBuildingDialog = ({
  openAddNewBuildingDialog,
  setOpenAddNewBuildingDialog,
 
}) => {
  const { dictionary, userLanguage } = useContext(LanguageContext);
  const classes = useStyles();
  const user = Auth.getUser();
  const inputLabel = React.useRef(null);
  const inputLabel2 = React.useRef(null);
  const [labelWidth] = React.useState(132);
  const [labelWidth2] = React.useState(132);
  const [loading, setLoading] = useState(false);
  const [AlertOpen, setAlertOpen] = useState(false);
  const [alertseverity, setAlertSeverity] = useState("success");
  const [alertmsg, setAlertMsg] = useState(false);
  const [formSendSuccess, setformSendSuccess] = useState(false);
  const [brokerCompanies, setBrokerCompanies] = React.useState([]);

  const [values, setValues] = useState({
    name: user ? user.name : "",
    surname: user ? user.surname : "",
    email: user ? user.email : "",
    companyname: "",
    phonenumber: user ? user.mobilephone : "",
    city: "",
    zone: "",
    message: "",
    assetclass: "",
    dealtype: "",
    surfacemq: "",
    accept_contact: false,
    accept_privacy: false,
  });

  const [formError, setFormError] = useState({
    name: {
      error: false,
      message: "",
    },
    surname: {
      error: false,
      message: "",
    },
    email: {
      error: false,
      message: "",
    },
    companyname: {
      error: false,
      message: "",
    },
    phonenumber: {
      error: false,
      message: "",
    },
    city: {
      error: false,
      message: "",
    },
    zone: {
      error: false,
      message: "",
    },
    message: {
      error: false,
      message: "",
    },
    assetclass: {
      error: false,
      message: "",
    },
    dealtype: {
      error: false,
      message: "",
    },
    surfacemq: {
      error: false,
      message: "",
    },
    accept_contact: {
      error: false,
      message: "",
    },
    accept_privacy: {
      error: false,
      message: "",
    },
  });

  const formIsValidHandler = () => {
    let formIsValid = true;
    console.log("affecting value to formIsValid : ", formIsValid);
    // Check if firstName is empty
    if (!values.name) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        name: {
          error: true,
          message: "FirstName is required",
        },
      }));
    }
    // Check if LastName is empty
    if (!values.surname) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        surname: {
          error: true,
          message: "LastName is required",
        },
      }));
    }
    // Check if email is empty
    if (!values.email) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        email: {
          error: true,
          message: "Email is required",
        },
      }));
    }
    // Check if companyName is empty
    if (!values.companyname) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        companyname: {
          error: true,
          message: "Company Name is required",
        },
      }));
    }
    // Check if Phone is empty
    if (!values.phonenumber) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        phonenumber: {
          error: true,
          message: "Phone is required",
        },
      }));
    }
    // Check if city is empty
    if (!values.city) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        city: {
          error: true,
          message: "City is required",
        },
      }));
    }
    // Check if zone is empty
    if (!values.zone) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        zone: {
          error: true,
          message: "Zone is required",
        },
      }));
    }
    // Check if assetclass is empty
    if (!values.assetclass) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        assetclass: {
          error: true,
          message: "Assetclass is required",
        },
      }));
    }
    // Check if dealtype is empty
    if (!values.dealtype) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        dealtype: {
          error: true,
          message: "Dealtype is required",
        },
      }));
    }
    // Check if surfacemq is empty
    if (!values.surfacemq) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        surfacemq: {
          error: true,
          message: "Square netters is required",
        },
      }));
    }
    // Check if accept_contact is empty
    if (!values.accept_contact) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        accept_contact: {
          error: true,
          message: "Accept contact is required",
        },
      }));
    }
    // Check if accept_privacy is empty
    if (!values.accept_privacy) {
      formIsValid = false;
      setFormError((prevState) => ({
        ...prevState,
        accept_privacy: {
          error: true,
          message: "Accept privacy is required",
        },
      }));
    }
    console.log("validation : ", formIsValid);
    return formIsValid;
  };

  const resetErrorsHandler = () => {
    setFormError({
      name: {
        error: false,
        message: "",
      },
      surname: {
        error: false,
        message: "",
      },
      email: {
        error: false,
        message: "",
      },
      companyname: {
        error: false,
        message: "",
      },
      phonenumber: {
        error: false,
        message: "",
      },
      city: {
        error: false,
        message: "",
      },
      zone: {
        error: false,
        message: "",
      },
      message: {
        error: false,
        message: "",
      },
      assetclass: {
        error: false,
        message: "",
      },
      dealtype: {
        error: false,
        message: "",
      },
      surfacemq: {
        error: false,
        message: "",
      },
      accept_contact: {
        error: false,
        message: "",
      },
      accept_privacy: {
        error: false,
        message: "",
      },
    });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCheck = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };

  const SendRequest = (e) => {
    e.preventDefault();
    resetErrorsHandler();
    setLoading(true);

    let formIsValid = formIsValidHandler();
    //console.log(options);
    if (formIsValid) {
      try {
        axios
          .post(
            PROPOSE_BUILDING_CHANGE,
            {
              remote_ip: null,
              user_id: Auth.getUser()?.id ?? null,
              form: {
                name: values.name + " , " + values.surname,
                phone: values.phonenumber,
                email: values.email,
                company_name: values.companyname,
                message: values.message,
                city: values.city,
                address: values.zone,
                asset_class: values.assetclass,
                square_meters: values.surfacemq,
                deal_type: values.dealtype,
                commercial: values.accept_contact,
                privacy: values.accept_privacy,
              },
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Auth.getToken(),
              },
            }
          )
          .then((payload) => {
            setLoading(false);
            setformSendSuccess(true);
            handleAlertOpen("success", dictionary.RequestSended);
            setOpenAddNewBuildingDialog(false);
            setValues({
              name: user ? user.name : "",
              surname: user ? user.surname : "",
              email: user ? user.email : "",
              companyname: brokerCompanies.filter(
                (c) => c.id.toString() === user.companyname
              )[0]?.companyname,
              phonenumber: user ? user.mobilephone : "",
              city: "",
              zone: "",
              message: "",
              assetclass: "",
              dealtype: "",
              surfacemq: "",
              accept_contact: false,
              accept_privacy: false,
            });
            setAlertOpen(true);
            setAlertMsg(dictionary.ThankProposeBuilding);
          })
          .catch((error) => {
            setLoading(false);
            handleAlertOpen("error", dictionary.FieldIncomplete);
          });
      } catch (e) {
        setLoading(false);
        handleAlertOpen("error", dictionary.FieldIncomplete);
      }
    }
    setLoading(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleAlertOpen = (title, msg) => {
    setAlertSeverity(title);
    setAlertMsg(msg);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, [3000]);
  };

  useEffect(() => {
    axios
      .get(API_URL_ALLCOMPANIES, {
        headers: {
          Authorization: "Bearer " + Auth.getToken(),
          "Content-Type": "application/json",
        },
      })
      .then((payload) => {
        setBrokerCompanies(payload.data.companies);
      });
  }, []);
  return (
    <>
      <Dialog
        open={openAddNewBuildingDialog}
        onClose={() => setOpenAddNewBuildingDialog(false)}
        fullWidth
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>{dictionary.ProposeBuilding}</Grid>
            <Grid item>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setOpenAddNewBuildingDialog(false)}
              >
                <ClearOutlined />
              </div>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {" "}
          <Paper className={classes.PaperClasses} elevation={0}>
            <div
              id="anchor"
              style={{
                display: "hidden",
                position: "absolute",
                top: "-100px",
              }}
            />
            <form className={classes.form} noValidate onSubmit={SendRequest}>
              {/* <Typography className={classes.InfoTitle} gutterBottom>
                {dictionary.ProposeBuildingTitle1}
              </Typography> */}

              <Typography className={classes.SubTitle} gutterBottom>
                {dictionary.ProposeBuildingHelpSection1}
              </Typography>

              <Typography className={classes.SectionTitle} gutterBottom>
                <ContactEmailIcon
                  className={classes.Icon}
                  viewBox={"0 0 20 18"}
                />
                1.{dictionary.ProposeBuildingTitleSection1}
              </Typography>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    className={classes.marginForm}
                    error={formError.surname.error}
                    helperText={formError.surname.message}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="surname"
                    label={dictionary.LastName}
                    name="surname"
                    value={values.surname}
                    onChange={handleChange("surname")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    className={classes.marginForm}
                    error={formError.name.error}
                    helperText={formError.name.message}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label={dictionary.FirstName}
                    name="name"
                    value={values.name}
                    onChange={handleChange("name")}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    className={classes.marginForm}
                    error={formError.phonenumber.error}
                    helperText={formError.phonenumber.message}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="phonenumber"
                    label={dictionary.phonenumber}
                    name="phonenumber"
                    value={values.phonenumber}
                    onChange={handleChange("phonenumber")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    className={classes.marginForm}
                    error={formError.email.error}
                    helperText={formError.email.message}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={dictionary.Emailaddress}
                    name="email"
                    autoComplete="email"
                    value={values.email}
                    onChange={handleChange("email")}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.marginForm}
                    error={formError.companyname.error}
                    helperText={formError.companyname.message}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="companyname"
                    label={dictionary.companyName}
                    name="companyname"
                    value={values.companyname}
                    onChange={handleChange("companyname")}
                  />
                </Grid>
              </Grid>

              {/* <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.marginForm}
                    multiline
                    rows={4}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="message"
                    label={dictionary.OtherInformation}
                    name="message"
                    value={values.message}
                    onChange={handleChange("message")}
                  />
                </Grid>
              </Grid> */}

              <Typography
                className={classes.SectionTitle}
                gutterBottom
                style={{ marginTop: "20px" }}
              >
                <PropertyIcon className={classes.Icon} viewBox={"0 0 24 20"} />
                2.{dictionary.ProposeBuildingTitleSection2}
              </Typography>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    className={classes.marginForm}
                    error={formError.city.error}
                    helperText={formError.city.message}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="city"
                    label={dictionary.City}
                    name="city"
                    value={values.city}
                    onChange={handleChange("city")}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    className={classes.marginForm}
                    error={formError.zone.error}
                    helperText={formError.zone.message}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="zone"
                    label={dictionary.Zone}
                    name="zone"
                    value={values.zone}
                    onChange={handleChange("zone")}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                    style={{ marginTop: "16px" }}
                  >
                    <InputLabel ref={inputLabel}>
                      {dictionary.AssetClass}
                    </InputLabel>
                    <Select
                      labelWidth={labelWidth}
                      error={formError.assetclass.error}
                      value={values.assetclass}
                      onChange={handleChange("assetclass")}
                    >
                      <MenuItem value={1}>{dictionary.Office}</MenuItem>
                      <MenuItem value={2}>{dictionary.Retail}</MenuItem>
                      <MenuItem value={3}>{dictionary.Industrial}</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#f44336" }}>
                      {formError.assetclass.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    className={classes.marginForm}
                    error={formError.surfacemq.error}
                    helperText={formError.surfacemq.message}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="surfacemq"
                    label={dictionary.LabelMq}
                    name="surfacemq"
                    value={values.surfacemq}
                    onChange={handleChange("surfacemq")}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                    style={{ marginTop: "16px" }}
                  >
                    <InputLabel ref={inputLabel2}>
                      {dictionary.DealType}
                    </InputLabel>
                    <Select
                      labelWidth={labelWidth2}
                      error={formError.dealtype.error}
                      value={values.dealtype}
                      onChange={handleChange("dealtype")}
                    >
                      <MenuItem value={1}>{dictionary.ForRent}</MenuItem>
                      <MenuItem value={2}>{dictionary.ForSale}</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#f44336" }}>
                      {formError.dealtype.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.accept_contact}
                        onChange={handleCheck}
                        name="accept_contact"
                        color="primary"
                        required
                        style={{
                          color: formError.accept_contact.error
                            ? "#f44336"
                            : "",
                        }}
                      />
                    }
                    label={
                      <Fragment>
                        {dictionary.LabelAcceptContact + " "}
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.LinkTerm}
                          href="mailto:info@d4re.com"
                        >
                          info@d4re.com
                        </Link>
                      </Fragment>
                    }
                    className={classes.privacyTextCheck}
                  />
                  {formError.accept_contact.error && (
                    <p
                      style={{
                        color: "#f44336",
                        fontSize: "12px",
                        paddingLeft: "2rem",
                      }}
                    >
                      {formError.accept_contact.message}
                    </p>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.accept_privacy}
                        onChange={handleCheck}
                        name="accept_privacy"
                        color="primary"
                        required
                        style={{
                          color: formError.accept_privacy.error
                            ? "#f44336"
                            : "",
                        }}
                      />
                    }
                    label={
                      <Fragment>
                        {dictionary.LegalInfoForm1 + " "}
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.LinkTerm}
                          href={
                            userLanguage === "it"
                              ? TERMS_AND_CONDITIONS_URL_IT
                              : TERMS_AND_CONDITIONS_URL_EN
                          }
                        >
                          {dictionary.LegalInfoForm2}
                        </Link>
                        {dictionary.LegalInfoForm3 + " "}
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.LinkTerm}
                          href={
                            userLanguage === "it"
                              ? "/pdf/Privacy_Policy_D4RE_IT.pdf"
                              : "/pdf/Privacy_Policy_D4RE_EN.pdf"
                          }
                        >
                          {dictionary.LegalInfoForm4}
                        </Link>
                      </Fragment>
                    }
                    className={classes.privacyTextCheck}
                  />
                  {formError.accept_privacy.error && (
                    <p
                      style={{
                        color: "#f44336",
                        fontSize: "12px",
                        paddingLeft: "2rem",
                      }}
                    >
                      {formError.accept_privacy.message}
                    </p>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.submit}
                  >
                    {dictionary.SendData}
                  </Button>

                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Grid>
              </Grid>
            </form>
          </Paper>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={AlertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alertseverity}>
          {alertmsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddNewBuildingDialog;
