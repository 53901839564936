import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { NoYesWithValueUtils, selftCasting } from "../utils";

const YesWithValueDropDown = ({
  dropDownLabel,
  textFieldLabel,
  sectionId,
  dispatch,
  dictionary,
  field,
  value,
  updateValueHandler,
  error,
  setErrors,
  inputRef,
  handleInputFocus,
}) => {
  const [changeSelector, setChangeSelector] = useState("null");

  const changeSelectorHandler = (e) => {
    let selectValue = selftCasting(e.target);
    setChangeSelector(selectValue);
    NoYesWithValueUtils.fromDropToInput(
      selectValue,
      (value) => dispatch(updateValueHandler(value)) //updateValueHandler
    );
  };

  const changeInputValueHandler = (e) => {
    if (error)
      //error
      setErrors((prev) => ({ ...prev, [field]: false })); //setErrors((prev) => ({ ...prev, field: false }))

    const payload = selftCasting(e.target);

    if (payload <= 0) {
      setErrors((prev) => ({ ...prev, [field]: true }));
      return;
    }

    dispatch(updateValueHandler(payload)); //updateValueHandler
  };

  useEffect(() => {
    setChangeSelector(
      NoYesWithValueUtils.fromValueToDrop(value) //value
    );
  }, [value]); //value

  return (
    <Grid item container spacing={3} style={{ marginTop: "1rem" }}>
      <Grid item xs={6} id="section">
        <FormControl fullWidth variant="outlined">
          <InputLabel>{dropDownLabel}</InputLabel>
          {/*dropDownLabel*/}
          <Select
            value={changeSelector}
            label={dropDownLabel}
            onChange={changeSelectorHandler}
          >
            <MenuItem value={"null"}>{dictionary.no}</MenuItem>
            <MenuItem value={"0"}>{dictionary.yes}</MenuItem>
            <MenuItem value={"1"}>{dictionary.yeswithvalue}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} id={sectionId}>
        {" "}
        {/* sectionId */}
        {changeSelector === "1" && (
          <TextField
            label={textFieldLabel} //textFieldLabel
            value={value}
            fullWidth
            variant="outlined"
            onChange={changeInputValueHandler}
            type="number"
            inputProps={{ min: 0 }}
            onFocus={handleInputFocus}
            error={error} //error
            inputRef={inputRef} //inputRef
          />
        )}
      </Grid>
    </Grid>
  );
};

export default YesWithValueDropDown;
