import React, { useContext, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
    makeStyles,
    Button,
    Typography,
    Link,
} from "@material-ui/core";
import { LanguageContext } from "../../../containers/languageprovider";
import {
    useHistory,
    useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import globalStyles from "../../globalStyles";
import { Helmet } from "react-helmet";
import Footer from "../../Footer";
import { Container, CssBaseline, Grid, Hidden } from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
    ButtonContainer: {
        display: "flex",
        justifyContent: "center",
    },
    ContainedButton: {
        padding: "0.5rem 1.5rem",
        fontSize: "15px",
        marginTop: "1rem",
        marginRight: "10px",
    },
    AddUnitTextButton: {
        fontSize: "12px",
        textDecoration: "underline",
        marginBottom: "2rem",
    },
    TitlePaper: {
        paddingLeft: "20px",
        fontFamily: "Montserrat, Barlow, sans-serif",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "36px",
        lineHeight: "44px",
        color: "#0A0000",
    },
    SubTitle: {
        fontFamily: "Barlow",
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "20px",
        lineHeight: "24px",
        color: "#000000",
        marginBottom: "51px",
    },
    GoHome: {
        fontFamily: "Barlow",
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "21px",
        letterSpacing: "0em",
        width: "100%",
        fontFeatureSettings: "'tnum' on, 'lnum' on",
        color: "#000000",
        fontVariant: "small-caps",
    },
}));


const UnitThankYouPage = () => {
    const { dictionary, userLanguage } = useContext(LanguageContext);
    const globalClasses = globalStyles();
    const classes = useStyles();
    const history = useHistory();


    const backToPortfolioClickHandler = () => {
        history.push(`/portfolio`);
    };

  return (
      <main className={globalClasses.maincontent}>
          <Helmet>
              <title>{dictionary.ThankyouMessage}</title>
          </Helmet>
          <CssBaseline />
          <Container maxWidth={false} style={{minHeight: "70vh"}}>
                          <Grid item style={{height: "100%", paddingTop: "12vh"}}>
                              <Grid container justifyContent="center" alignItems="center" direction="column" spacing={3}>
                                  <Grid item>
                                      <Typography
                                          className={classes.TitlePaper}
                                          color="textSecondary"
                                          gutterBottom
                                      >
                                          {dictionary.ThankYou}
                                      </Typography>
                                  </Grid>
                                  <Grid item>
                                      <Typography
                                          className={classes.SubTitle}
                                          color="textSecondary"
                                          gutterBottom
                                      >
                                          {dictionary.BuildingThankYouMessage}
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                      <div className={classes.ButtonContainer}>
                                          <Button
                                              color="primary"
                                              variant="contained"
                                              size="medium"
                                              className={classes.ContainedButton}
                                              onClick={backToPortfolioClickHandler}
                                          >
                                              {dictionary.BackToPortfolio}
                                          </Button>
                                      </div>
                                  </Grid>
                              </Grid>
                          </Grid>
          </Container>
          <Footer />
      </main>
  )
}

export default UnitThankYouPage